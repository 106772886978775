import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { currency, longDate } from "../../libs/Utils";

export default function FundingSummaryBlock(props) {
  const [totalFundingRequests, setTotalFundingRequests] = useState(0);
  const [totalValueOfFundingRequests, setTotalValueOfFundingRequests] = useState(0);
  const [totalValueApproved, setTotalValueApproved] = useState(0);
  const [dateOfLastRequest, setDateOfLastRequest] = useState("-");
  const [dateOfLastApproval, setDateOfLastApproval] = useState("-");
  const [totalRejectedFundingRequests, setTotalRejectedFundingRequests] = useState(0);

  useEffect(() => {
    if (props.applications.length > 0) {
      setTotalFundingRequests(props.applications.length);
      var totalValueOfFundingRequests = 0;
      var totalValueOfApprovedRequests = 0;
      var totalRejectedFundingRequests = 0;
      props.applications.forEach((application) => {
        totalValueOfFundingRequests += application.requestedAmount;
        if (application.status === "Approved") {
          totalValueOfApprovedRequests += application.approvedAmount;
          setDateOfLastApproval(application.approvedAt);
        } else if (application.status === "Rejected") {
          totalRejectedFundingRequests += 1;
        }
      });
      setTotalValueOfFundingRequests(currency(totalValueOfFundingRequests));
      setTotalValueApproved(currency(totalValueOfApprovedRequests));
      setTotalRejectedFundingRequests(totalRejectedFundingRequests);
      const latestApplication = props.applications[props.applications.length - 1];
      setDateOfLastRequest(longDate(latestApplication.createdAt));
    }
  }, [props.applications]);

  return (
    <div className="mt-2 overflow-hidden bg-white">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Funding Summary</h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-3">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500"># of Requests</dt>
            <dd className="mt-1 text-sm text-gray-900">{totalFundingRequests}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">$ of Requests</dt>
            <dd className="mt-1 text-sm text-gray-900">{totalValueOfFundingRequests}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">$ Approved</dt>
            <dd className="mt-1 text-sm text-gray-900">{totalValueApproved}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Date of last request</dt>
            <dd className="mt-1 text-sm text-gray-900">{dateOfLastRequest}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Date of last approval</dt>
            <dd className="mt-1 text-sm text-gray-900">{dateOfLastApproval}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500"># of rejected requests</dt>
            <dd className="mt-1 text-sm text-gray-900">{totalRejectedFundingRequests}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

FundingSummaryBlock.propTypes = {
  applications: PropTypes.array,
};
