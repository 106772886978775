import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import AddFundingApplicationFee from "./AddFundingApplicationFee";
import { classNames, percentage, currency } from "../../../libs/Utils";
import { client, clientError } from "../../../libs/Client";

export default function EditFundingApplication(props) {
  const [open, setOpen] = useState(false);
  const [requestedAmount, setRequestedAmount] = useState(0);
  const [approvedAmount, setApprovedAmount] = useState(0);
  const [overnightCheck, setOvernightCheck] = useState(false);
  const [buyout, setBuyout] = useState(false);
  const [fundingCompanies, setFundingCompanies] = useState([]);
  const [fundingCompany, setFundingCompany] = useState(null);
  const [buyoutPayoff, setBuyoutPayoff] = useState(0);
  const [buyoutFee, setBuyoutFee] = useState(0);
  const [accidentStateFeeNotes, setAccidentStateFeeNotes] = useState("No fee notes");
  const [plaintiffStateFeeNotes, setPlaintiffStateFeeNotes] = useState("No fee notes");
  const [fees, setFees] = useState([]);

  useEffect(() => {
    if (open) {
      Promise.all([
        client().get(`/states/${props.plaintiffStateID}`),
        client().get(`/states/${props.accidentStateID}`),
        client().get(`/funding-applications/${props.id}`),
        client().get(`/funding-companies`),
      ])
        .then((res) => {
          const plaintiffState = res[0].data;
          const accidentState = res[1].data;
          const fundingApplcation = res[2].data;

          if (accidentState.notes && accidentState.notes !== "") {
            setAccidentStateFeeNotes(accidentState.notes);
          }

          if (plaintiffState.notes && plaintiffState.notes !== "") {
            setPlaintiffStateFeeNotes(plaintiffState.notes);
          }

          if (fundingApplcation.requestedAmount > 0) {
            setRequestedAmount(fundingApplcation.requestedAmount / 100);
          }

          if (fundingApplcation.approvedAmount > 0) {
            setApprovedAmount(fundingApplcation.approvedAmount / 100);
          }

          setOvernightCheck(fundingApplcation.overnightCheck);

          if (fundingApplcation.buyoutFee) {
            setBuyoutFee(fundingApplcation.buyoutFee * 100);
            setBuyout(true);
          }
          if (fundingApplcation.buyoutCompany) {
            setFundingCompanies(
              res[3].data.map((element) => ({
                value: element.id,
                label: element.name,
              }))
            );
            setFundingCompany({
              value: fundingApplcation.buyoutCompany.id,
              label: fundingApplcation.buyoutCompany.name,
            });
            setBuyout(true);
          }

          if (fundingApplcation.buyoutAmount) {
            setBuyoutPayoff(fundingApplcation.buyoutAmount / 100);
            setBuyout(true);
          }

          setFees(
            fundingApplcation.fees.map((element) => ({
              id: element.id,
              label: element.label,
              annual: element.annual,
              type: element.percentage ? "Percentage" : "Fixed Amount",
              percentage: element.percentage,
              amount: element.amount,
            }))
          );
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [open, props.id, props.plaintiffStateID, props.accidentStateID]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleBuyoutChange(data) {
    setBuyout(data);
    if (data) {
      client()
        .get(`/funding-companies`)
        .then((res) => {
          setFundingCompanies(
            res.data.map((element) => ({
              value: element.id,
              label: element.name,
            }))
          );
        })
        .catch((err) => {
          clientError(err);
        });
    } else {
      setFundingCompanies(null);
    }
  }

  function handleAddFundingApplicationFee(data) {
    setFees([...fees, data]);
  }

  function handleSave() {
    if (requestedAmount === "") {
      toast.error("Amount required");
      return;
    }

    if (parseInt(requestedAmount) <= 0) {
      toast.error("Amount must be greater than 0");
      return;
    }

    var data = {
      requestedAmount: parseInt(requestedAmount) * 100,
      approvedAmount: parseInt(approvedAmount) * 100,
      overnightCheck: overnightCheck,
      buyout: buyout,
    };

    if (buyout) {
      data.buyoutCompanyID = fundingCompany.value;
      if (parseFloat(buyoutPayoff) > 0) {
        data.buyoutAmount = Math.floor(parseFloat(buyoutPayoff) * 100);
      } else {
        data.buyoutAmount = 0;
      }

      if (parseInt(buyoutFee) > 0) {
        data.buyoutFee = parseFloat(buyoutFee) / 100;
      } else {
        data.buyoutFee = 0;
      }
    }

    var feeList = [];
    fees.forEach((fee) => {
      var feeItem = {
        label: fee.label,
        annual: fee.annual,
      };

      if (fee.type === "Fixed Amount") {
        feeItem.amount = fee.amount;
      } else {
        feeItem.percentage = fee.percentage;
      }
      feeList.push(feeItem);
    });

    data.fees = feeList;
    client()
      .put(`/funding-applications/${props.id}`, data)
      .then(() => {
        setOpen(false);
        props.saved(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleDeleteFee(idToDelete) {
    const newFees = fees.filter((object) => {
      return object.id !== idToDelete;
    });
    setFees(newFees);
  }

  return (
    <div>
      {props.tooltip ? (
        <Tippy content={props.tooltip}>
          <button
            type="button"
            className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
            onClick={handleClickOpen}
          >
            <PencilSquareIcon className="h-4 w-4 text-indigo-500" />
          </button>
        </Tippy>
      ) : (
        <button
          type="button"
          className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
          onClick={handleClickOpen}
        >
          <PencilSquareIcon className="h-4 w-4 text-indigo-500" />
        </button>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Update Funding Application
                            </Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="requestedAmount" className="block text-sm font-medium text-gray-700">
                                  Requested Amount
                                </label>
                                <div className="relative mt-1 rounded-md shadow-sm">
                                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span className="text-gray-500 sm:text-sm">$</span>
                                  </div>
                                  <input
                                    type="number"
                                    name="requestedAmount"
                                    id="requestedAmount"
                                    className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="0.00"
                                    aria-describedby="price-currency"
                                    onChange={(e) => setRequestedAmount(e.target.value)}
                                    value={requestedAmount}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="approvedAmount" className="block text-sm font-medium text-gray-700">
                                  Approved Amount
                                </label>
                                <div className="relative mt-1 rounded-md shadow-sm">
                                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span className="text-gray-500 sm:text-sm">$</span>
                                  </div>
                                  <input
                                    type="number"
                                    name="approvedAmount"
                                    id="approvedAmount"
                                    className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="0.00"
                                    aria-describedby="price-currency"
                                    onChange={(e) => setApprovedAmount(e.target.value)}
                                    value={approvedAmount}
                                  />
                                </div>
                              </div>
                              <div>
                                <Switch.Group as="div" className="flex items-center justify-between">
                                  <span className="flex flex-grow flex-col">
                                    <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                      Overnight Check
                                    </Switch.Label>
                                    <Switch.Description
                                      as="span"
                                      className="text-sm text-gray-500"
                                    ></Switch.Description>
                                  </span>
                                  <Switch
                                    checked={overnightCheck}
                                    onChange={setOvernightCheck}
                                    className={classNames(
                                      overnightCheck ? "bg-brand-700" : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        overnightCheck ? "translate-x-5" : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                              <div>
                                <Switch.Group as="div" className="flex items-center justify-between">
                                  <span className="flex flex-grow flex-col">
                                    <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                      Is This a Buyout?
                                    </Switch.Label>
                                    <Switch.Description
                                      as="span"
                                      className="text-sm text-gray-500"
                                    ></Switch.Description>
                                  </span>
                                  <Switch
                                    checked={buyout}
                                    onChange={(data) => handleBuyoutChange(data)}
                                    className={classNames(
                                      buyout ? "bg-brand-700" : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        buyout ? "translate-x-5" : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                              {buyout && (
                                <div>
                                  <label
                                    htmlFor="priorFundingCompany"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Prior Funding Company
                                  </label>
                                  <div className="remove-input-txt-border mt-1 text-sm">
                                    <ReactSelect
                                      options={fundingCompanies}
                                      isClearable
                                      onChange={(data) => {
                                        setFundingCompany(data);
                                      }}
                                      value={fundingCompany}
                                    />
                                  </div>
                                </div>
                              )}
                              {buyout && (
                                <div>
                                  <label htmlFor="buyoutPayoff" className="block text-sm font-medium text-gray-700">
                                    Buyout Payoff
                                  </label>
                                  <div className="relative mt-1 rounded-md shadow-sm">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                      <span className="text-gray-500 sm:text-sm">$</span>
                                    </div>
                                    <input
                                      type="number"
                                      name="buyoutPayoff"
                                      id="buyoutPayoff"
                                      className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      placeholder="0.00"
                                      onChange={(e) => setBuyoutPayoff(e.target.value)}
                                      value={buyoutPayoff}
                                    />
                                  </div>
                                </div>
                              )}
                              {buyout && (
                                <div>
                                  <label htmlFor="buyoutFee" className="block text-sm font-medium text-gray-700">
                                    Buyout Fee
                                  </label>
                                  <div className="relative mt-1 rounded-md shadow-sm">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                      <span className="text-gray-500 sm:text-sm">%</span>
                                    </div>
                                    <input
                                      type="number"
                                      name="buyoutFee"
                                      id="buyoutFee"
                                      className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      placeholder="0.00"
                                      onChange={(e) => setBuyoutFee(e.target.value)}
                                      value={buyoutFee}
                                    />
                                  </div>
                                </div>
                              )}
                              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                                <li>
                                  <label htmlFor="feeNotes" className="block text-sm font-medium text-gray-700">
                                    Plaintiff State Fee Notes
                                  </label>
                                  <div className="mt-1 whitespace-pre-wrap rounded-sm bg-gray-50 p-4 text-sm text-gray-600">
                                    {plaintiffStateFeeNotes}
                                  </div>
                                </li>
                                <li>
                                  <label htmlFor="feeNotes" className="block text-sm font-medium text-gray-700">
                                    Accident State Fee Notes
                                  </label>
                                  <div className="mt-1 whitespace-pre-wrap rounded-sm bg-gray-50 p-4 text-sm text-gray-600">
                                    {accidentStateFeeNotes}
                                  </div>
                                </li>
                              </ul>
                              <div className="mt-2">
                                <span className="font-semibold">Fees</span>
                                <div className="float-right">
                                  <AddFundingApplicationFee saved={handleAddFundingApplicationFee} />
                                </div>
                              </div>
                              <div className="mt-2">
                                <hr />
                              </div>
                              {fees.length > 0 && (
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                      {fees.map((element) => (
                                        <tr key={element.id}>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                            {element.label}
                                          </td>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                            {element.annual ? "Annual" : "One Time"}
                                          </td>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                            {element.type}
                                          </td>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-right text-sm sm:pl-6">
                                            {element.type === "Percentage"
                                              ? percentage(element.percentage)
                                              : currency(element.amount)}
                                          </td>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-right text-sm sm:pl-6">
                                            <button
                                              type="button"
                                              className="inline-flex rounded border-transparent bg-red-100 px-2.5 py-1.5 text-xs font-medium hover:bg-red-200 focus:outline-none"
                                            >
                                              <TrashIcon
                                                className="h-4 w-4 text-red-500"
                                                onClick={() => handleDeleteFee(element.id)}
                                              />
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EditFundingApplication.propTypes = {
  id: PropTypes.string.isRequired,
  plaintiffStateID: PropTypes.string,
  accidentStateID: PropTypes.string,
  saved: PropTypes.func,
};
