import React, { useEffect, useState, Fragment } from "react";
import { client, clientError } from "../../../libs/Client";
import { currencyNoCents, reportDate, shortDate } from "../../../libs/Utils";
import { PrinterIcon } from "@heroicons/react/20/solid";
import EmptyRows from "../../../components/EmptyRows";
import LawFirmAddress from "../../../components/Statements/LawFirmAddress";
import StatementAddress from "../../../components/Statements/StatementAddress";
import { useParams } from "react-router-dom";

export default function AgingStatement() {
  const { id } = useParams();
  const [serviceProvider, setServiceProvider] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [aTotal, setATotal] = useState(0);
  const [bTotal, setBTotal] = useState(0);
  const [cTotal, setCTotal] = useState(0);
  const [dTotal, setDTotal] = useState(0);
  const [eTotal, setETotal] = useState(0);
  const [fTotal, setFTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    client()
      .get(`/service-providers/${id}/statements/aging`)
      .then((res) => {
        setServiceProvider(res.data.serviceProvider.name);
        setStreetAddress(res.data.serviceProvider.streetAddress);
        setCity(res.data.serviceProvider.city);
        setState(res.data.serviceProvider.state.label);
        setZipCode(res.data.serviceProvider.zip);
        setATotal(res.data.aTotal);
        setBTotal(res.data.bTotal);
        setCTotal(res.data.cTotal);
        setDTotal(res.data.dTotal);
        setETotal(res.data.eTotal);
        setFTotal(res.data.fTotal);
        setLineItems(res.data.lineItems);
        setTotal(res.data.total);
      })
      .catch((err) => {
        clientError(err);
      });
  }, [id]);

  return (
    <main className="flex-1">
      <div className="text-right p-2">
        <a
          type="button"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          href={`/print-views/aging-statement/${id}`}
          target="_blank"
          rel="noreferrer"
        >
          <PrinterIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Printer Friendly
        </a>
      </div>
      <div className="py-1 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="grid grid-cols-2 gap-4 mt-8">
            <LawFirmAddress name={serviceProvider} street={streetAddress} city={city} state={state} zipCode={zipCode} />
            <div className="text-right">
              <StatementAddress />
            </div>
          </div>
          <div className="mt-10">
            <p className="text-center text-xl font-base text-gray-900">
              <u>Statement as of {reportDate()}</u>
              <br />
              <span className="text-sm font-base text-gray-600">with aging detail</span>
            </p>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                {lineItems.length > 0 ? (
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              rowSpan={2}
                              className="px-3 py-4 text-left align-bottom text-sm font-semibold text-gray-900"
                            >
                              Client (Date of Accident)
                              <br />
                              <span className="px-6">Date of Funding</span>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-4 text-left align-bottom text-sm font-semibold text-gray-900"
                              rowSpan={2}
                            >
                              Contact
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-4 text-center text-sm font-semibold text-gray-900"
                              colSpan={6}
                            >
                              Days Aging
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-4 align-bottom text-right text-sm font-semibold text-gray-900"
                              rowSpan={2}
                            >
                              Total Payoff
                            </th>
                          </tr>
                          <tr>
                            <th scope="col" className="px-3 py-4 text-right text-sm font-semibold text-gray-900">
                              0 - 6<br />
                              Months
                            </th>
                            <th scope="col" className="px-3 py-4 text-right text-sm font-semibold text-gray-900">
                              6 - 12
                              <br />
                              Months
                            </th>
                            <th scope="col" className="px-3 py-4 text-right text-sm font-semibold text-gray-900">
                              12 - 18
                              <br />
                              Months
                            </th>
                            <th scope="col" className="px-3 py-4 text-right text-sm font-semibold text-gray-900">
                              18 - 24
                              <br />
                              Months
                            </th>
                            <th scope="col" className="px-3 py-4 text-right text-sm font-semibold text-gray-900">
                              24 - 36
                              <br />
                              Months
                            </th>
                            <th scope="col" className="px-3 py-4 text-right text-sm font-semibold text-gray-900">
                              36
                              <br />
                              Months +
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {lineItems.map((item, idx) => (
                            <Fragment key={idx}>
                              <tr key={item.fullName}>
                                <td className="px-3 py-4 align-top font-semibold text-sm text-gray-500">
                                  {item.fullName} ({shortDate(item.accidentDate)})
                                </td>
                                <td className="px-3 py-4 align-top font-semibold text-sm text-gray-500">
                                  {item.contact}
                                </td>
                                <td className="py-4 pr-4 text-right text-sm font-medium" colSpan={7}></td>
                              </tr>
                              {item.applications.map((application, idx) => (
                                <tr key={idx}>
                                  <td className="px-8 py-2 text-sm text-gray-500" colSpan={2}>
                                    {shortDate(application.createdAt)}
                                  </td>
                                  <td className="px-2 py-1 text-sm text-gray-500 text-right">
                                    {currencyNoCents(application.a)}
                                  </td>
                                  <td className="px-2 py-1 text-sm text-gray-500 text-right">
                                    {currencyNoCents(application.b)}
                                  </td>
                                  <td className="px-2 py-1 text-sm text-gray-500 text-right">
                                    {currencyNoCents(application.c)}
                                  </td>
                                  <td className="px-2 py-1 text-sm text-gray-500 text-right">
                                    {currencyNoCents(application.d)}
                                  </td>
                                  <td className="px-2 py-1 text-sm text-gray-500 text-right">
                                    {currencyNoCents(application.e)}
                                  </td>
                                  <td className="px-2 py-1 text-sm text-gray-500 text-right">
                                    {currencyNoCents(application.f)}
                                  </td>
                                  <td className="px-2 py-1 text-sm text-gray-500 text-right">
                                    {currencyNoCents(application.total)}
                                  </td>
                                </tr>
                              ))}
                              {item.partialPaymentTotal > 0 && (
                                <tr>
                                  <td className="px-8 py-2 font-base text-sm text-gray-500" colSpan={8}>
                                    Partial Payments
                                  </td>
                                  <td className="px-2 py-2 font-base text-sm text-gray-500 text-right">
                                    {currencyNoCents(-item.partialPaymentTotal)}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td className="px-3 py-2 font-semibold text-sm text-gray-500 bg-gray-50" colSpan={2}>
                                  Case Total
                                </td>
                                <td className="px-2 py-2 text-right font-semibold text-sm text-gray-500 bg-gray-50">
                                  {currencyNoCents(item.aSubTotal)}
                                </td>
                                <td className="px-2 py-2 text-right font-semibold text-sm text-gray-500 bg-gray-50">
                                  {currencyNoCents(item.bSubTotal)}
                                </td>
                                <td className="px-2 py-2 text-right font-semibold text-sm text-gray-500 bg-gray-50">
                                  {currencyNoCents(item.cSubTotal)}
                                </td>
                                <td className="px-2 py-2 text-right font-semibold text-sm text-gray-500 bg-gray-50">
                                  {currencyNoCents(item.dSubTotal)}
                                </td>
                                <td className="px-2 py-2 text-right font-semibold text-sm text-gray-500 bg-gray-50">
                                  {currencyNoCents(item.eSubTotal)}
                                </td>
                                <td className="px-2 py-2 text-right font-semibold text-sm text-gray-500 bg-gray-50">
                                  {currencyNoCents(item.fSubTotal)}
                                </td>
                                <td className="px-2 py-2 text-right font-semibold text-sm text-gray-500 bg-gray-50">
                                  {currencyNoCents(item.subTotal)}
                                </td>
                              </tr>
                            </Fragment>
                          ))}
                          <tr>
                            <td className="px-2 py-2" colSpan={9}></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th
                              scope="row"
                              colSpan={2}
                              className="bg-teal-200 text-left text-lg font-bold text-gray-900 px-2"
                            >
                              Total
                            </th>
                            <td className="px-2 py-4 text-right text-base text-gray-900 font-bold bg-teal-200">
                              {currencyNoCents(aTotal)}
                            </td>
                            <td className="px-2 py-4 text-right text-base text-gray-900 font-bold bg-teal-200">
                              {currencyNoCents(bTotal)}
                            </td>
                            <td className="px-2 py-4 text-right text-base text-gray-900 font-bold bg-teal-200">
                              {currencyNoCents(cTotal)}
                            </td>
                            <td className="px-2 py-4 text-right text-base text-gray-900 font-bold bg-teal-200">
                              {currencyNoCents(dTotal)}
                            </td>
                            <td className="px-2 py-4 text-right text-base text-gray-900 font-bold bg-teal-200">
                              {currencyNoCents(eTotal)}
                            </td>
                            <td className="px-2 py-4 text-right text-base text-gray-900 font-bold bg-teal-200">
                              {currencyNoCents(fTotal)}
                            </td>
                            <td className="px-2 py-4 text-right text-base text-gray-900 font-bold bg-teal-200">
                              {currencyNoCents(total)}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                ) : (
                  <EmptyRows
                    title="No Summary Statement"
                    subtitle="There is currently insufficient information to generate a summary statement"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
