import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { CheckCircleIcon, XCircleIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { dateComparator } from "../../libs/Utils";
import { client, clientError } from "../../libs/Client";
import FlagServiceProviderUser from "../../components/SlideOvers/ServiceProviders/FlagServiceProviderUser";
import UnflagServiceProviderUserDialog from "../../components/Dialogs/UnflagServiceProviderUserDialog";
import PageHeader from "../../components/PageHeader";
import ServiceProviderTabs from "../../layouts/FullLayout/Tabs/ServiceProviderTabs";
import ServiceProviderDetailHeader from "../../components/Headers/ServiceProviderDetailHeader";
import AddServiceProviderUser from "../../components/SlideOvers/ServiceProviders/AddServiceProviderUser";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import EditServiceProviderUser from "../../components/SlideOvers/ServiceProviders/EditServiceProviderUser";
import ConfirmRemoveDefaultDialog from "../../components/Dialogs/ConfirmRemoveDefaultDialog";
import ConfirmMakeDefaultDialog from "../../components/Dialogs/ConfirmMakeDefaultDialog";

export default function ServiceProviderUsers() {
  const { id } = useParams();
  const gridRef = useRef();
  const [providerType, setProviderType] = useState("");
  const [users, setUsers] = useState(null);

  useEffect(() => {
    document.title = "Capital Now Funding - Service Providers - Users";
    client()
      .get(`/service-providers/${id}`)
      .then((res) => {
        setProviderType(res.data.providerType);
      })
      .catch((err) => {
        clientError(err);
      });
  });

  function handleDelete(idToDelete) {
    client()
      .delete(`/service-provider-users/${idToDelete}`)
      .then(() => {
        toast.success("User deleted");
        handleRefresh();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleRemoveFlag(idToRemoveFlag) {
    client()
      .delete(`/service-provider-users/${idToRemoveFlag}/flag`)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleRefresh() {
    client()
      .get(`/service-providers/${id}/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleToggleDefault(idToToggle) {
    client()
      .put(`/service-provider-users/${idToToggle}/default`, {})
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        clientError(err);
      });
  }
  const fullNameValueGetter = (params) => {
    return params.data.lastName + ", " + params.data.firstName;
  };

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    [],
  );

  const [columnDefs] = useState([
    {
      field: "enabled",
      minWidth: 120,
      width: 120,
      filter: false,
      headerName: "Enabled",
      cellRenderer: (params) => {
        if (params.value) {
          return (
            <span>
              <CheckCircleIcon className="h-6 w-6 text-green-500" />
            </span>
          );
        }

        return (
          <span>
            <XCircleIcon className="h-6 w-6 text-red-500" />
          </span>
        );
      },
    },
    {
      field: "defaultContact",
      minWidth: 120,
      width: 120,
      filter: false,
      headerName: "Default",
      cellRenderer: (params) => {
        if (params.value) {
          return <ConfirmRemoveDefaultDialog id={params.data.id} idToRemoveDefault={handleToggleDefault} />;
        }

        return <ConfirmMakeDefaultDialog id={params.data.id} idToMakeDefault={handleToggleDefault} />;
      },
    },
    {
      field: "name",
      minWidth: 250,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      valueGetter: fullNameValueGetter,
      cellRenderer: (params) => {
        if (params.data.flagged) {
          return (
            <span className="text-red-500">
              {params.data.lastName}, {params.data.firstName}
            </span>
          );
        } else {
          return `${params.data.lastName}, ${params.data.firstName}`;
        }
      },
    },
    {
      field: "email",
      minWidth: 250,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        if (params.data.flagged) {
          return <span className="text-red-500">{params.data.email}</span>;
        } else {
          return params.data.email;
        }
      },
    },
    {
      field: "phone",
      minWidth: 250,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        if (params.data.flagged) {
          return <span className="text-red-500">{params.data.phone}</span>;
        } else {
          return params.data.phone;
        }
      },
    },
    {
      field: "role",
      minWidth: 250,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        if (params.data.flagged) {
          return <span className="text-red-500">{params.data.role}</span>;
        } else {
          return params.data.role;
        }
      },
    },
    {
      field: "createdAt",
      minWidth: 200,
      filter: false,
      headerName: "Created",
      comparator: dateComparator,
      cellRenderer: (params) => {
        if (params.data.flagged) {
          return <span className="text-red-500">{moment(params.data.createdAt).fromNow()}</span>;
        } else {
          return moment(params.data.createdAt).fromNow();
        }
      },
    },
    {
      field: "updatedAt",
      minWidth: 200,
      filter: false,
      headerName: "Updated",
      comparator: dateComparator,
      cellRenderer: (params) => {
        if (params.data.flagged) {
          return <span className="text-red-500">{moment(params.data.updatedAt).fromNow()}</span>;
        } else {
          return moment(params.data.updatedAt).fromNow();
        }
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      minWidth: 230,
      maxWidth: 230,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            {params.data.flagged ? (
              <UnflagServiceProviderUserDialog
                title="Unflag User"
                content={params.data.reason}
                id={params.data.id}
                idToRemoveFlag={handleRemoveFlag}
              />
            ) : (
              <FlagServiceProviderUser id={params.data.id} saved={handleRefresh} />
            )}
            <Tippy content="Funding Applications">
              <a
                type="button"
                className="inline-flex rounded border-transparent bg-sky-100 px-2.5 py-1.5 text-xs font-medium hover:bg-sky-200 focus:outline-none"
                href={`/service-provider-users/${params.data.id}/funding-applications`}
              >
                <ClipboardDocumentListIcon className="h-4 w-4 text-sky-500" />
              </a>
            </Tippy>

            <EditServiceProviderUser tooltip="Edit User" id={params.data.id} saved={handleRefresh} />
            <DeleteDialog
              tooltip="Delete User"
              title="Delete User"
              content="Are you sure you want to delete the user?"
              id={params.data.id}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const onGridReady = useCallback(() => {
    client()
      .get(`/service-providers/${id}/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        clientError(err);
      });
  }, [id]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <PageHeader title="Service Provider" subtitle={providerType} />
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <AddServiceProviderUser saved={handleRefresh} id={id} />
        </div>
      </div>
      <div className="mt-2">
        <ServiceProviderDetailHeader id={id} />
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
          <ServiceProviderTabs id={id} selected="Users" />
          <div className="mb-8 ml-2 mr-2">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 750 }}>
              <AgGridReact
                ref={gridRef}
                rowData={users}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
                overlayNoRowsTemplate={"No Users"}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
