import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import ReactSelect from "react-select";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import { client, clientError } from "../../../libs/Client";

export default function ApplicationStatus(props) {
  const [open, setOpen] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState(null);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (open) {
      setStatus({ value: props.status, label: props.status });
      client()
        .get(`/funding-applications/statuses`)
        .then((res) => {
          setStatuses(
            res.data.map((element) => ({
              value: element,
              label: element,
            }))
          );
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [open, props.status]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleSave() {
    if (!status) {
      toast.error("Pleases select a status");
      return;
    }
    client()
      .put(`/funding-applications/${props.id}/status`, {
        status: status.value,
        notes,
      })
      .then(() => {
        setOpen(false);
        props.saved(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div>
      {props.status === "Processing" ? (
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-gray-100 px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          onClick={handleClickOpen}
        >
          <QrCodeIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          {props.status}
        </button>
      ) : null}
      {props.status === "Rejected" ? (
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-red-200 px-3 py-2 text-sm font-medium leading-4 text-red-700 shadow-sm hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          onClick={handleClickOpen}
        >
          <QrCodeIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          {props.status}
        </button>
      ) : null}
      {props.status === "Approved" ? (
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-blue-200 px-3 py-2 text-sm font-medium leading-4 text-blue-700 shadow-sm hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={handleClickOpen}
        >
          <QrCodeIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          {props.status}
        </button>
      ) : null}
      {props.status === "Ready to Fund" ? (
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-purple-200 px-3 py-2 text-sm font-medium leading-4 text-purple-700 shadow-sm hover:bg-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
          onClick={handleClickOpen}
        >
          <QrCodeIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          {props.status}
        </button>
      ) : null}
      {props.status === "Funded" ? (
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-green-200 px-3 py-2 text-sm font-medium leading-4 text-green-700 shadow-sm hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          onClick={handleClickOpen}
        >
          <QrCodeIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          {props.status}
        </button>
      ) : null}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-md pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Funding Application Status
                            </Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                  Status
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect
                                    options={statuses}
                                    isClearable
                                    onChange={(data) => setStatus(data)}
                                    value={status}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="notes" className="block text-sm font-medium text-gray-900">
                                  Notes
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    rows="4"
                                    name="notes"
                                    id="notes"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={(e) => setNotes(e.target.value)}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

ApplicationStatus.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
