import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { CheckIcon, ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { setAuthToken } from "../../hooks/Authentication";
import { apiError, currency, percentage } from "../../libs/Utils";

import PageHeader from "../../components/PageHeader";
import AddStateFee from "../../components/SlideOvers/AddStateFee";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import EditStateFee from "../../components/SlideOvers/EditStateFee";

const { REACT_APP_API_URL } = process.env;

export default function StateFees() {
  const { id } = useParams();
  const gridRef = useRef();
  const [title, setTitle] = useState("");
  const [fees, setFees] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Admin - States - Fees";
  });

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/state-fees/${idToDelete}`)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  const [columnDefs] = useState([
    { field: "label", headerName: "Description", filter: true },
    {
      field: "annual",
      filter: false,
      cellRenderer: (params) => {
        if (params.value) {
          return <CheckIcon className="h-6 w-6 text-green-500" />;
        } else {
          return "";
        }
      },
    },
    {
      field: "fee",
      filter: false,
      type: "numericColumn",
      valueGetter: (params) => {
        if (params.data.type === "Percentage") {
          return percentage(params.data.percentage);
        } else {
          return currency(params.data.amount);
        }
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            <EditStateFee tooltip="Edit" stateFeeID={params.value} saved={handleRefresh} />
            <DeleteDialog
              tooltip="Delete"
              buttonTitle="Delete"
              title="Delete Fee"
              content="Are you sure you want to delete the fee?"
              id={params.value}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/states/${id}`)
      .then((res) => {
        setTitle(res.data.label);
        setFees(res.data.fees);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/states/${id}`)
      .then((res) => {
        setTitle(res.data.label);
        setFees(res.data.fees);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <Tippy content="States">
          <a href="/admin/states">
            <ArrowLeftCircleIcon className="h12 float-left mr-4 w-12 text-brand-700 hover:text-brand-500" />
          </a>
        </Tippy>
        <PageHeader title="State Fees" subtitle={`State Fees for ${title} `} />
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <AddStateFee stateID={id} saved={handleRefresh} />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="mb-8 ml-2 mr-2">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 750 }}>
              <AgGridReact
                ref={gridRef}
                rowData={fees}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
