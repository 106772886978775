import { Fragment, useEffect, useState, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import ReactSelect from "react-select";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { FileInput, StatusBar } from "@uppy/react";
import "@uppy/core/dist/style.min.css";
import "@uppy/file-input/dist/style.min.css";
import "@uppy/status-bar/dist/style.min.css";

import { apiError } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

import EmailShortCodes from "../ShortCodes/EmailShortCodes";
import AttachmentLabel from "../../Labels/AttachmentLabel";

const { REACT_APP_API_URL, REACT_APP_TINY_MCE_API_KEY } = process.env;

export default function ComposeEmail(props) {
  const [open, setOpen] = useState(false);
  const editorRef = useRef(null);
  const [emailSignature, setEmailSignature] = useState("");
  const [selectedOption, setSelectedOption] = useState("Primary Service Provider Contact");
  const [plaintiffEmail, setPlaintiffEmail] = useState("");
  const [primaryServiceProviderEmail, setPrimaryServiceProviderEmail] = useState("");
  const [serviceProviderEmails, setServiceProviderEmails] = useState([]);
  const [allServiceProviderEmails, setAllServiceProviderEmails] = useState([]);
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [accidentStateTemplates, setAccidentStateTemplates] = useState([]);
  const [accidentStateTemplate, setAccidentStateTemplate] = useState(null);
  const [plaintiffStateTemplates, setPlaintiffStateTemplates] = useState([]);
  const [plaintiffStateTemplate, setPlaintiffStateTemplate] = useState(null);
  const [attachments, setAttachments] = useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      setAttachments([]);
      setAuthToken();
      Promise.all([
        axios.get(`${REACT_APP_API_URL}/me/email-signature`),
        axios.get(`${REACT_APP_API_URL}/lawsuits/${props.id}`),
      ])
        .then((res) => {
          setEmailSignature(res[0].data);
          const serviceProviderContact = res[1].data.serviceProviderContact;
          const serviceProviderContacts = res[1].data.serviceProviderContacts;

          setPlaintiffEmail(res[1].data.email);
          if (serviceProviderContact) {
            setPrimaryServiceProviderEmail(serviceProviderContact.email);
            setTo(serviceProviderContact.email);
          }

          if (serviceProviderContacts.length > 0) {
            var serviceProviderContactList = [];
            serviceProviderContacts.forEach((contact) => {
              serviceProviderContactList.push(contact.email);
            });
            setServiceProviderEmails(serviceProviderContactList);
          }

          Promise.all([
            axios.get(`${REACT_APP_API_URL}/service-providers/${res[1].data.serviceProvider.id}/users`),
            axios.get(`${REACT_APP_API_URL}/templates/email?stateID=${res[1].data.accidentState.id}`),
            axios.get(`${REACT_APP_API_URL}/templates/email?stateID=${res[1].data.state.id}`),
          ])
            .then((res) => {
              if (res[0].data.length > 0) {
                var allServiceProvidersList = [];
                res[0].data.forEach((contact) => {
                  allServiceProvidersList.push(contact.email);
                });
                setAllServiceProviderEmails(allServiceProvidersList);
              }

              setAccidentStateTemplates(
                res[1].data.map((element) => ({
                  value: element.id,
                  label: element.title,
                }))
              );
              setPlaintiffStateTemplates(
                res[2].data.map((element) => ({
                  value: element.id,
                  label: element.title,
                }))
              );
            })
            .catch((err) => {
              apiError(err);
            });
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }, [open, props.id]);

  const uppy = new Uppy({ autoProceed: true });
  uppy.use(AwsS3, {
    getUploadParameters(file) {
      setAuthToken();
      return axios
        .post(`${REACT_APP_API_URL}/attachments/signed-upload-url`, {
          filename: file.name,
          mimeType: file.type,
        })
        .then((response) => {
          const data = {
            method: "PUT",
            url: response.data.url,
            fields: { key: response.data.key },
            headers: {
              "Content-Type": file.type,
            },
          };

          return data;
        })
        .catch((err) => {
          apiError(err);
        });
    },
  });

  uppy.on("upload-success", (file) => {
    const data = {
      mimeType: file.type,
      size: file.size,
      originalName: file.meta.name,
      key: file.meta.key,
    };
    var currentAttachments = attachments.slice();
    currentAttachments.push(data);
    setAttachments(currentAttachments);
  });

  function handleSelectionChange(event) {
    setSelectedOption(event.target.value);
    if (event.target.value === "Plaintiff") {
      setTo(plaintiffEmail);
      setCc("");
    } else if (event.target.value === "Primary Service Provider Contact") {
      setTo(primaryServiceProviderEmail);
      setCc("");
    } else if (event.target.value === "Additional Service Provider Contacts") {
      const emailList = serviceProviderEmails;
      setTo(primaryServiceProviderEmail);
      setCc(emailList.toString());
    } else if (event.target.value === "All Service Provider Contacts") {
      const emailList = allServiceProviderEmails;
      setTo(primaryServiceProviderEmail);
      setCc(emailList.toString());
    }
  }

  function handleChangeAccidentStateTemplate(data) {
    setAccidentStateTemplate(data);
    setPlaintiffStateTemplate(null);
    if (data) {
      setAuthToken();
      axios
        .get(`${REACT_APP_API_URL}/templates/email/${data.value}`)
        .then((res) => {
          const content = res.data.body + emailSignature;
          editorRef.current.setContent(content);
        })
        .catch((err) => {
          apiError(err);
        });
    } else {
      editorRef.current.setContent(emailSignature);
    }
  }

  function handleChangePlaintffStateTemplate(data) {
    setAccidentStateTemplate(null);
    setPlaintiffStateTemplate(data);
    if (data) {
      setAuthToken();
      axios
        .get(`${REACT_APP_API_URL}/templates/email/${data.value}`)
        .then((res) => {
          const content = res.data.body + emailSignature;
          editorRef.current.setContent(content);
        })
        .catch((err) => {
          apiError(err);
        });
    } else {
      editorRef.current.setContent(emailSignature);
    }
  }

  function handleDeleteAttachment(index) {
    var currentAttachments = attachments.slice();
    currentAttachments.splice(index, 1);
    setAttachments(currentAttachments);
  }

  function handleSend() {
    if (to === "") {
      toast.error("To required");
      return;
    }

    if (subject === "") {
      toast.error("Subject required");
      return;
    }

    var toAddresses = [];
    var ccAddresses = [];
    var bccAddresses = [];

    if (to === "") {
      toast.error("To required");
      return;
    }
    const toAddressArray = to.split(",");
    toAddressArray.forEach((address) => {
      toAddresses.push({ name: "", address: address });
    });

    var data = {
      lawsuitID: parseInt(props.id),
      to: toAddresses,
      subject,
    };

    if (cc !== "") {
      const ccAddressArray = cc.split(",");
      ccAddressArray.forEach((address) => {
        ccAddresses.push({ name: "", address: address });
      });
      if (ccAddresses.length > 0) {
        data.cc = ccAddresses;
      }
    }

    if (bcc !== "") {
      const bccAddressArray = bcc.split(",");
      bccAddressArray.forEach((address) => {
        bccAddresses.push({ name: "", address: address });
      });
      if (bccAddresses.length > 0) {
        data.bcc = bccAddresses;
      }
    }

    data.body = editorRef.current.getContent();
    data.attachments = attachments;

    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/mail`, data)
      .then(() => {
        toast.success("Message queued for sending");
        props.sent(true);
        setOpen(false);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  const body = (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={() => null}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-5xl">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-brand-700 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">Compose Email</Dialog.Title>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <div className="mt-2">
                            <label className="text-base font-medium text-gray-900">Send To</label>
                            <p className="text-sm leading-5 text-gray-500">Who do you want to email?</p>
                            <fieldset className="mt-4">
                              <legend className="sr-only">Notification method</legend>
                              <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                <div className="flex items-center">
                                  <input
                                    name="sendTo"
                                    type="radio"
                                    value="Plaintiff"
                                    className="h-4 w-4 border-gray-300 text-brand-700 focus:ring-brand-700"
                                    checked={selectedOption === "Plaintiff"}
                                    onChange={handleSelectionChange}
                                  />
                                  <label htmlFor="plaintiff" className="ml-3 block text-sm font-medium text-gray-700">
                                    Plaintiff
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    name="sendTo"
                                    type="radio"
                                    value="Primary Service Provider Contact"
                                    className="h-4 w-4 border-gray-300 text-brand-700 focus:ring-brand-700"
                                    checked={selectedOption === "Primary Service Provider Contact"}
                                    onChange={handleSelectionChange}
                                  />
                                  <label
                                    htmlFor="primaryServiceProviderContact"
                                    className="ml-3 block text-sm font-medium text-gray-700"
                                  >
                                    Primary Service Provider Contact
                                  </label>
                                </div>
                                {serviceProviderEmails.length > 0 ? (
                                  <div className="flex items-center">
                                    <input
                                      name="sendTo"
                                      type="radio"
                                      value="Additional Service Provider Contacts"
                                      className="h-4 w-4 border-gray-300 text-brand-700 focus:ring-brand-700"
                                      checked={selectedOption === "Additional Service Provider Contacts"}
                                      onChange={handleSelectionChange}
                                    />
                                    <label
                                      htmlFor="additionalServiceProviderContacts"
                                      className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                      Additional Service Provider Contacts
                                    </label>
                                  </div>
                                ) : null}

                                <div className="flex items-center">
                                  <input
                                    name="sendTo"
                                    type="radio"
                                    value="All Service Provider Contacts"
                                    className="h-4 w-4 border-gray-300 text-brand-700 focus:ring-brand-700"
                                    checked={selectedOption === "All Service Provider Contacts"}
                                    onChange={handleSelectionChange}
                                  />
                                  <label
                                    htmlFor="allServiceProviderContacts"
                                    className="ml-3 block text-sm font-medium text-gray-700"
                                  >
                                    All Service Provider Contacts
                                  </label>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                          <div className="space-y-6 pb-5 pt-6">
                            <div className="grid grid-cols-3 space-x-2">
                              <div>
                                <label htmlFor="to" className="block text-sm font-medium text-gray-900">
                                  To
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="to"
                                    id="to"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                                    onChange={(e) => setTo(e.target.value)}
                                    value={to}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="cc" className="block text-sm font-medium text-gray-900">
                                  CC
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="cc"
                                    id="cc"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                                    onChange={(e) => setCc(e.target.value)}
                                    value={cc}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="bcc" className="block text-sm font-medium text-gray-900">
                                  BCC
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="bcc"
                                    id="bcc"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                                    onChange={(e) => setBcc(e.target.value)}
                                    value={bcc}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <label htmlFor="subject" className="block text-sm font-medium text-gray-900">
                                Subject
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="subject"
                                  id="subject"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                                  onChange={(e) => setSubject(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="grid grid-cols-2 space-x-2">
                              <div>
                                <label htmlFor="subject" className="block text-sm font-medium text-gray-900">
                                  Accident State Templates
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect
                                    options={accidentStateTemplates}
                                    isClearable
                                    onChange={(data) => handleChangeAccidentStateTemplate(data)}
                                    className="z-10"
                                    value={accidentStateTemplate}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="subject" className="block text-sm font-medium text-gray-900">
                                  Plaintiff State Templates
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect
                                    options={plaintiffStateTemplates}
                                    isClearable
                                    onChange={(data) => handleChangePlaintffStateTemplate(data)}
                                    className="z-10"
                                    value={plaintiffStateTemplate}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                                <FileInput
                                  uppy={uppy}
                                  pretty
                                  locale={{
                                    strings: {
                                      chooseFiles: "Attachments",
                                    },
                                  }}
                                />
                                <StatusBar hideUploadButton hideAfterFinish={false} showProgressDetails uppy={uppy} />
                              </div>
                              <div>
                                {attachments.length > 0 && (
                                  <div className="mx-auto flex w-full space-x-4 px-4 sm:px-6 lg:px-8">
                                    {attachments.map((attachment, index) => (
                                      <AttachmentLabel
                                        originalName={attachment.originalName}
                                        key={index}
                                        index={index}
                                        s3key={attachment.key}
                                        indexToDelete={handleDeleteAttachment}
                                      />
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              <label htmlFor="body" className="block text-sm font-medium text-gray-900">
                                Body
                                <span className="float-right">
                                  <EmailShortCodes />
                                </span>
                              </label>
                              <div className="mt-1">
                                <Editor
                                  apiKey={`${REACT_APP_TINY_MCE_API_KEY}`}
                                  initialValue={emailSignature}
                                  onInit={(evt, editor) => (editorRef.current = editor)}
                                  init={{
                                    image_list: `${REACT_APP_API_URL}/images/list`,
                                    height: 300,
                                    menubar: "edit insert view format table tools",
                                    plugins: "lists link image table code",
                                    paste_as_text: false,
                                    paste_block_drop: true,
                                    toolbar:
                                      "undo redo | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | image link | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-start px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                        onClick={handleSend}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  if (props.icon) {
    return (
      <div>
        <Tippy content="Compose Email">
          <button
            type="button"
            className="inline-flex rounded border-transparent bg-sky-100 px-2.5 py-1.5 text-xs font-medium hover:bg-sky-200 focus:outline-none"
            onClick={handleClickOpen}
          >
            <PencilSquareIcon className="h-4 w-4 text-sky-500" />
          </button>
        </Tippy>
        {body}
      </div>
    );
  }

  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
          onClick={handleClickOpen}
        >
          Compose
        </button>
        {body}
      </div>
    </div>
  );
}

ComposeEmail.propTypes = {
  icon: PropTypes.bool,
  id: PropTypes.string.isRequired,
  sent: PropTypes.func,
};
