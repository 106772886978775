import React, { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";

import { client, clientError } from "../../libs/Client";
import { classNames } from "../../libs/Utils";
import { useUser } from "../../hooks/Authentication";

import SettingTabs from "../../layouts/FullLayout/Tabs/SettingTabs";

export default function Calendar() {
  const { user } = useUser();
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    client()
      .get(`/me/calendar-connected`)
      .then((res) => {
        setEnabled(res.data);
      })
      .catch((err) => {
        clientError(err);
      });
  }, []);

  function handleToggle() {
    if (enabled) {
      client()
        .post(`/ms/signout`, {})
        .then((res) => {
          window.location.replace(encodeURI(res.data));
        })
        .catch((err) => {
          clientError(err);
        });
    } else {
      client()
        .post(`/ms/auth-request`, {})
        .then((res) => {
          window.location.replace(encodeURI(res.data));
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }

  return (
    <div className="py-6">
      <div className="mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-2">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Settings</h1>
          </div>
          <div className="mt-4 rounded-md bg-white p-4">
            <SettingTabs role={user.role} selected="Calendar" />
            <div className="divide-y divide-gray-200">
              <div className="mt-8flex flex-col">
                <div className="ml-4 mr-4 pt-4">
                  <div className="sm:col-span-2">
                    <Switch.Group as="div" className="flex items-center justify-between">
                      <span className="flex flex-grow flex-col">
                        <Switch.Label as="span" className="text-sm font-medium text-gray-500" passive>
                          Connect your account to Office 365
                        </Switch.Label>
                        <Switch.Description as="span" className="text-sm text-gray-500"></Switch.Description>
                      </span>
                      <Switch
                        checked={enabled}
                        onChange={handleToggle}
                        className={classNames(
                          enabled ? "bg-brand-700" : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            enabled ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </Switch.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
