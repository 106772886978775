import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { DragDrop, StatusBar } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../datagrid.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

import { apiError, dateComparator } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

import CaseDetailHeader from "../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseDocumentTabs from "../../layouts/FullLayout/Tabs/CaseDocumentTabs";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";

const { REACT_APP_API_URL } = process.env;

export default function CaseDocumentUploads() {
  const { id } = useParams();
  const gridRef = useRef();
  const [documents, setDocuments] = useState([]);

  const uppy = new Uppy({ autoProceed: true });
  uppy.use(AwsS3, {
    getUploadParameters(file) {
      setAuthToken();
      return axios
        .post(`${REACT_APP_API_URL}/documents/signed-upload-url`, {
          filename: file.name,
          mimeType: file.type,
        })
        .then((response) => {
          const data = {
            method: "PUT",
            url: response.data.url,
            fields: { key: response.data.key },
            headers: {
              "Content-Type": file.type,
            },
          };

          return data;
        })
        .catch((err) => {
          apiError(err);
        });
    },
  });

  uppy.on("upload-success", (file) => {
    const data = {
      lawsuitID: parseInt(id),
      section: "Upload",
      mimeType: file.type,
      size: file.size,
      originalName: file.meta.name,
      key: file.meta.key,
    };
    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/documents`, data)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  });

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - Documents - Uploads";
  });

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/documents/${idToDelete}`)
      .then(() => {
        toast.success("Document deleted");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/documents?lawsuitID=${id}&section=Upload`)
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleClickDownload(documentID) {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/documents/${documentID}/download`)
      .then((response) => {
        if (response.status === 204) {
          toast.error("Upload does not exist");
          return;
        }

        window.location.href = response.data;
      })
      .catch((err) => {
        apiError(err);
      });
  }

  const [columnDefs] = useState([
    {
      field: "originalName",
      headerName: "Document",
      minWidth: 450,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "mimeType",
      headerName: "Type",
      minWidth: 250,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "createdAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Created",
      minWidth: 200,
      cellRenderer: (params) => {
        return moment(params.data.createdAt).fromNow();
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      sortable: false,
      width: 150,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            <Tippy content="Download Document">
              <button
                className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
                onClick={() => handleClickDownload(params.value)}
              >
                <DocumentArrowDownIcon className="h-4 w-4 text-indigo-500" />
              </button>
            </Tippy>

            <DeleteDialog
              tooltip="Delete Document"
              title="Delete Document?"
              content="Are you sure you want to delete the document?"
              id={params.value}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    [],
  );

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/documents?lawsuitID=${id}&section=Upload`)
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function generatePayoffLetter() {
    setAuthToken();
    axios
      .put(`${REACT_APP_API_URL}/lawsuits/${id}/payoff-letter`)
      .then(() => {
        toast.success("Payoff letter queued for processing...");
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Documents" lawsuitID={id} />
        <div className="ml-8 mr-8">
          <CaseDocumentTabs lawsuitID={id} selected="Uploads" />
        </div>
        <div className="mb-8 mt-2 flex flex-col">
          <div className="ml-8 mr-8 flex flex-col rounded-lg border-2 border-dashed">
            <DragDrop
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: "Drop here or %{browse}",
                  browse: "browse",
                },
              }}
            />
            <div className="mt-1">
              <StatusBar uppy={uppy} />
            </div>
          </div>
          <div className="ml-8 mr-8 mb-2 mt-2 flex flex-col">
            <button
              type="button"
              className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              onClick={generatePayoffLetter}
            >
              Generate Payoff Letter
            </button>
          </div>
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 350 }}>
              <AgGridReact
                ref={gridRef}
                rowData={documents}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
                overlayNoRowsTemplate={"No documents on file"}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
