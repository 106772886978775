import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Select } from "antd";
import AddServiceProvider from "../ServiceProviders/AddServiceProvider";
import AddServiceProviderUser from "../ServiceProviders/AddServiceProviderUser";
import { client, clientError } from "../../../libs/Client";

export default function EditCaseServiceProvider(props) {
  const [open, setOpen] = useState(false);
  const [loadingServiceProviders, setLoadingServiceProviders] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [serviceProvider, setServiceProvider] = useState("");
  const [contact, setContact] = useState("");
  const [additionalContacts, setAdditionalContacts] = useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      setLoadingServiceProviders(true);
      Promise.all([client().get(`/service-providers/list`), client().get(`/lawsuits/${props.id}/service-provider`)])
        .then((res) => {
          setLoadingServiceProviders(false);
          setServiceProviders(res[0].data);
          const serviceProviderDetails = res[1].data;
          if (serviceProviderDetails.serviceProvider) {
            setServiceProvider(serviceProviderDetails.serviceProvider.id);
            if (serviceProviderDetails.serviceProviderContact) {
              client()
                .get(`/service-providers/${serviceProviderDetails.serviceProvider.id}/users`)
                .then((res) => {
                  setContacts(
                    res.data.map((element) => ({
                      value: element.id,
                      label: element.firstName + " " + element.lastName,
                    })),
                  );
                  setContact(serviceProviderDetails.serviceProviderContact.id);
                })
                .catch((err) => {
                  clientError(err);
                });
            }
            if (serviceProviderDetails.serviceProviderContacts.length > 0) {
              var spc = [];
              serviceProviderDetails.serviceProviderContacts.forEach((item) => {
                spc.push(item.id);
              });
              setAdditionalContacts(spc);
            }
          }
        })
        .catch((err) => {
          setLoadingServiceProviders(false);
          clientError(err);
        });
    }
  }, [open, props.id]);

  function serviceProviderChanged(data) {
    setServiceProvider(data);
    setLoadingContacts(true);
    client()
      .get(`/service-providers/${data}/users`)
      .then((res) => {
        setLoadingContacts(false);
        setContacts(
          res.data.map((element) => ({
            value: element.id,
            label: element.firstName + " " + element.lastName,
          })),
        );
        setContact(null);
        setAdditionalContacts([]);
      })
      .catch((err) => {
        setLoadingContacts(false);
        clientError(err);
      });
  }

  function handleSave() {
    console.log(additionalContacts);
    var data = {};
    if (serviceProvider === "") {
      toast.error("You need to select a service provider");
      return;
    }
    data.serviceProviderID = serviceProvider;

    if (contact === "") {
      toast.error("You need to select a primary contact");
      return;
    }
    data.primaryContactID = contact;

    var secondaryContactIDs = [];
    if (additionalContacts) {
      additionalContacts.forEach((additionalContact) => {
        secondaryContactIDs.push(additionalContact);
      });
    }

    data.secondaryContactIDs = secondaryContactIDs;
    setProcessing(true);
    client()
      .put(`/lawsuits/${props.id}/service-provider`, data)
      .then(() => {
        setProcessing(false);
        setOpen(false);
        toast.success("Service provider updated");
        props.saved(true);
      })
      .catch((err) => {
        setProcessing(false);
        clientError(err);
      });
  }

  function handleAddedServiceProvider(data) {
    serviceProviderChanged(data);
  }

  function handleAddedServiceProviderUser(data) {
    client()
      .get(`/service-providers/${serviceProvider}/users`)
      .then((res) => {
        setContacts(
          res.data.map((element) => ({
            value: element.id,
            label: element.firstName + " " + element.lastName,
          })),
        );
        setContact(data.id);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div>
      <button
        type="button"
        className="rounded-md bg-white font-medium text-brand-700 hover:text-brand-500 focus:outline-none"
        onClick={handleClickOpen}
      >
        <PencilSquareIcon className="h-5 w-5" />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Edit Service Provider
                            </Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="label" className="block text-sm font-medium text-gray-900">
                                  Service Providers
                                </label>
                                <div className="remove-input-txt-border-ant mt-1">
                                  <Select
                                    size="large"
                                    onChange={(data) => serviceProviderChanged(data)}
                                    options={serviceProviders}
                                    placeholder="Please select"
                                    className="clear-both w-full"
                                    showSearch
                                    loading={loadingServiceProviders}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                    value={serviceProvider}
                                  />
                                </div>
                                <div className="float-right">
                                  <AddServiceProvider textLink saved={handleAddedServiceProvider} />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="label" className="block text-sm font-medium text-gray-900">
                                  Primary Contact
                                </label>
                                <div className="remove-input-txt-border-ant mt-1">
                                  <Select
                                    size="large"
                                    onChange={(data) => setContact(data)}
                                    options={contacts}
                                    placeholder="Please select"
                                    className="clear-both w-full"
                                    showSearch
                                    loading={loadingContacts}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                    value={contact}
                                  />
                                </div>
                                <div className="float-right">
                                  <AddServiceProviderUser
                                    textLink
                                    id={serviceProvider}
                                    saved={handleAddedServiceProviderUser}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="label" className="block text-sm font-medium text-gray-900">
                                  Additional Contacts
                                </label>
                                <div className="remove-input-txt-border-ant mt-1">
                                  <Select
                                    size="large"
                                    mode="multiple"
                                    placeholder="Please select"
                                    onChange={(data) => setAdditionalContacts(data)}
                                    options={contacts}
                                    className="clear-both w-full"
                                    showSearch
                                    loading={loadingContacts}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                    value={additionalContacts}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {processing ? (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="cursor-not-allowed rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex cursor-not-allowed justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-150 ease-in-out hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            <svg
                              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Saving...
                          </button>
                        </div>
                      ) : (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EditCaseServiceProvider.propTypes = {
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
