import React, { useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import parsePhoneNumber from "libphonenumber-js";
import { client, clientError } from "../../libs/Client";
import { dateFromNow, minHeight } from "../../libs/Utils";
import LeadTabs from "../../layouts/FullLayout/Tabs/LeadTabs";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import RestoreRejectedDialog from "../../components/Dialogs/RestoreRejectedDialog";

export default function RejectedLeads() {
  const gridStyle = { minHeight: minHeight };

  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/leads?status=Rejected&skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`)
      .then((res) => {
        return { data: res.data.leads, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, []);
  useEffect(() => {
    document.title = "Capital Now Funding - Leads - Rejected";
  });

  const columns = [
    {
      name: "id",
      minWidth: 150,
      header: "Reference #",
      textAlign: "end",
      headerAlign: "end",
    },
    {
      name: "firstName",
      minWidth: 200,
      header: "First Name",
    },
    {
      name: "lastName",
      minWidth: 200,
      header: "Last Name",
    },
    {
      name: "email",
      minWidth: 200,
      header: "Email",
    },
    {
      name: "phone",
      header: "Phone",
      minWidth: 200,
      render: ({ value }) => {
        if (value && value !== "") {
          return parsePhoneNumber(value, "US").formatNational();
        } else {
          return "";
        }
      },
    },
    {
      name: "lawFirm",
      header: "Law Firm",
      minWidth: 200,
    },
    {
      name: "state",
      minWidth: 200,
      header: "State",
      render: ({ data }) => {
        return data.state.label;
      },
    },
    {
      name: "createdAt",
      minWidth: 200,
      header: "Created",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "edit",
      header: "",
      minWidth: 115,
      maxWidth: 115,
      render: ({ data }) => (
        <div className="flex justify-end space-x-4">
          <RestoreRejectedDialog
            tooltip="Restore Lead"
            title="Restore Lead"
            content="Are you sure you want to restore the lead?"
            id={data.id.toString()}
            idToRestore={handleRestore}
          />
          <DeleteDialog
            tooltip="Delete Lead"
            title="Delete Lead"
            content="Are you sure you want to delete the lead?"
            id={data.id.toString()}
            idToDelete={handleDelete}
          />
        </div>
      ),
    },
  ];

  function handleDelete(idToDelete) {
    client()
      .delete(`/leads/${idToDelete}`)
      .then(() => {
        toast.success("Lead deleted");
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleRestore(idToRestore) {
    client()
      .patch(`/leads/${idToRestore}`, {
        status: "Pending",
      })
      .then(() => {
        toast.success("Lead restored");
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <LeadTabs selected="Rejected" />
        <ReactDataGrid
          idProperty="rejectedLeads"
          showColumnMenuTool={false}
          columns={columns}
          style={gridStyle}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
