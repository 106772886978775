import { useState } from "react";
import { client } from "../../libs/Client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DocumentTextIcon } from "@heroicons/react/20/solid";

export default function Search() {
  const navigate = useNavigate();
  const [caseNumber, setCaseNumber] = useState("");

  function handleSearch(e) {
    e.preventDefault();
    client()
      .get(`/lawsuits/${caseNumber}`)
      .then((res) => {
        if (res.status === 200) {
          setCaseNumber("");
          navigate(`/cases/${caseNumber}/feed`);
        } else {
          toast.error("Case does not exist");
        }
      })
      .catch((err) => {
        toast.error("Case does not exist");
      });
  }
  return (
    <form className="flex w-full md:ml-0" onSubmit={(e) => handleSearch(e)}>
      <label htmlFor="search-field" className="sr-only">
        Case #
      </label>
      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
          <DocumentTextIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          id="case-search"
          className="block h-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
          placeholder="Case #"
          type="search"
          name="case-search"
          onChange={(e) => setCaseNumber(e.target.value)}
        />
      </div>
    </form>
  );
}
