import React, { useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { client, clientError } from "../../libs/Client";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { EyeIcon } from "@heroicons/react/24/outline";
import { dateFromNow, minHeight } from "../../libs/Utils";
import AddServiceProvider from "../../components/SlideOvers/ServiceProviders/AddServiceProvider";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import EditServiceProvider from "../../components/SlideOvers/ServiceProviders/EditServiceProvider";
import AddToQuickbooks from "../../components/SlideOvers/ServiceProviders/AddToQuickbooks";
import UnlinkFromQuickbooksDialog from "../../components/Dialogs/UnlinkFromQuickbooksDialog";

export default function ServiceProviders() {
  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/service-providers?skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`)
      .then((res) => {
        return { data: res.data.serviceProviders, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, []);

  useEffect(() => {
    document.title = "Capital Now Funding - Service Providers";
  });

  const gridStyle = { minHeight: minHeight };

  function handleDelete(idToDelete) {
    client()
      .delete(`/service-providers/${idToDelete}`)
      .then(() => {
        toast.success("Law firm deleted");
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleUnlinkQuickbooks(idToUnlink) {
    client()
      .delete(`/service-providers/${idToUnlink}/quickbooks`)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleRefresh() {
    window.location.reload();
  }

  const columns = [
    {
      name: "name",
      header: "Name",
      minWidth: 400,
      render: ({ data }) => {
        if (data.flagged) {
          return <span className="text-red-500">{data.name}</span>;
        } else {
          return data.name;
        }
      },
    },
    {
      name: "providerType",
      minWidth: 250,
      header: "Provider Type",
    },
    {
      name: "createdAt",
      minWidth: 200,
      header: "Created",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "updatedAt",
      minWidth: 200,
      header: "Updated",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "edit",
      header: "",
      minWidth: 225,
      maxWidth: 225,
      render: ({ data }) => {
        return (
          <div className="flex justify-end space-x-4">
            <Tippy content="Overview & Users">
              <a
                type="button"
                className="inline-flex rounded border-transparent bg-sky-100 px-2.5 py-1.5 text-xs font-medium hover:bg-sky-200 focus:outline-none"
                href={`/service-providers/${data.id}/overview`}
              >
                <EyeIcon className="h-4 w-4 text-sky-500" />
              </a>
            </Tippy>
            {!data.quickbooksID ? (
              <AddToQuickbooks icon id={data.id} saved={handleRefresh} />
            ) : (
              <UnlinkFromQuickbooksDialog
                tooltip="Unlink from Quickboooks"
                title="Unlink from Quickbooks"
                content="Remove service provider link to Quickbooks"
                id={data.id}
                idToUnlink={handleUnlinkQuickbooks}
              />
            )}
            <EditServiceProvider tooltip="Edit Details" id={data.id} saved={loadData} />
            <DeleteDialog
              tooltip="Delete Service Provider"
              title="Delete Service Provider"
              content="Are you sure you want to delete the service provider?"
              id={data.id}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="text-right">
        <AddServiceProvider saved={loadData} />
      </div>
      <div className="mt-3 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <ReactDataGrid
            idProperty="serviceProviders"
            columns={columns}
            style={gridStyle}
            showColumnMenuTool={false}
            dataSource={dataSource}
            pagination
            defaultLimit={100}
          />
        </div>
      </div>
    </div>
  );
}
