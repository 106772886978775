import React, { useEffect, useState } from "react";
import NoApplications from "../../components/SlideOvers/Cases/NoApplications";
import { client, clientError } from "../../libs/Client";

export default function EmptyApplications() {
  const [applications, setApplications] = useState(0);
  useEffect(() => {
    client()
      .get(`/me/no-applications`)
      .then((res) => {
        setApplications(res.data.applications);
      })
      .catch((err) => {
        clientError(err);
      });
  }, []);

  if (applications > 0) {
    return <NoApplications />;
  }
}
