import PropTypes from "prop-types";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { client, clientError } from "../../libs/Client";

export default function DownloadContract(props) {
  function handleDownload() {
    client()
      .get(`/funding-application-contracts/${props.id}/download`)
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <Tippy content="Download">
      <button
        type="button"
        className="inline-flex rounded border-transparent bg-sky-100 px-2.5 py-1.5 text-xs font-medium hover:bg-sky-200 focus:outline-none"
        onClick={handleDownload}
      >
        <DocumentArrowDownIcon className="h-4 w-4 text-sky-500" />
      </button>
    </Tippy>
  );
}

DownloadContract.propTypes = { id: PropTypes.string };
