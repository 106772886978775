import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { DragDrop, StatusBar } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";

import { apiError } from "../../libs/Utils";

const { REACT_APP_API_URL } = process.env;

export default function DirectDepositAuthorization() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [customerName, setCustomerName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [lawsuitID, setLawsuitID] = useState(0);
  const [financialInstitution, setFinancialInstitution] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState(null);
  const accountTypes = [
    { value: "Checking", label: "Checking" },
    { value: "Savings", label: "Savings" },
  ];
  const [signature, setSignature] = useState("");

  const uppy = new Uppy({ autoProceed: true });
  uppy.use(AwsS3, {
    getUploadParameters(file) {
      return axios
        .post(`${REACT_APP_API_URL}/signature/dda/signed-upload-url`, {
          filename: file.name,
          mimeType: file.type,
        })
        .then((res) => {
          const data = {
            method: "PUT",
            url: res.data.url,
            fields: { key: res.data.key },
            headers: {
              "Content-Type": file.type,
            },
          };

          return data;
        })
        .catch((err) => {
          apiError(err);
        });
    },
  });

  uppy.on("upload-success", (file) => {
    const data = {
      lawsuitID: parseInt(lawsuitID),
      mimeType: file.type,
      size: file.size,
      originalName: file.meta.name,
      key: file.meta.key,
    };

    axios
      .post(`${REACT_APP_API_URL}/signature/dda/upload`, data)
      .then(() => {})
      .catch((err) => {
        apiError(err);
      });
  });

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/signature/dda/${id}`)
      .then((res) => {
        setCustomerName(res.data.firstName + " " + res.data.lastName);
        setAccountHolderName(res.data.firstName + " " + res.data.lastName);
        setLawsuitID(res.data.lawsuitID);
      })
      .catch(() => {
        navigate("/signatures/expired");
      });
  }, [navigate, id]);

  function handleApprove() {
    if (customerName === "") {
      toast.error("Customer name required");
      return;
    }

    if (accountHolderName === "") {
      toast.error("Account holder name required");
      return;
    }

    if (financialInstitution === "") {
      toast.error("Financial insitution required");
      return;
    }

    if (routingNumber === "") {
      toast.error("Routing number required");
      return;
    }

    if (accountNumber === "") {
      toast.error("Account number required");
      return;
    }

    if (!accountType) {
      toast.error("You need to select an account type");
      return;
    }

    if (signature === "") {
      toast.error("Signature required");
      return;
    }

    const data = {
      customerName,
      accountHolderName,
      financialInstitution,
      routingNumber,
      accountNumber,
      accountType: accountType.value,
      signature,
    };

    axios
      .post(`${REACT_APP_API_URL}/signature/dda/${id}/sign`, data)
      .then(() => {
        window.location.replace("https://capitalnowfunding.com/");
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-full">
        <img className="h-13 mx-auto w-auto" src="/images/logos/login-logo.png" alt="Capital Now Funding" />
        <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">DIRECT DEPOSIT AUTHORIZATION</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-7xl">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Customer Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="customerName"
                  id="customerName"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => setCustomerName(e.target.value)}
                  value={customerName}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="accountHolderName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Account Holder Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="accountHolderName"
                  id="accountHolderName"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => setAccountHolderName(e.target.value)}
                  value={accountHolderName}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="accountHolderName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Capital Now Funding Case #
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">{lawsuitID}</div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="financialInstitution"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Financial Insitution
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="financialInstitution"
                  id="financialInstitution"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => setFinancialInstitution(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="routingNumber" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Routing Number (9 Digits)
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="routingNumber"
                  id="routingNumber"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => setRoutingNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Account Number
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="accountNumber"
                  id="accountNumber"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Account Type
              </label>
              <div className="remove-input-txt-border mt-1 text-sm sm:col-span-2 sm:mt-0">
                <ReactSelect
                  options={accountTypes}
                  isClearable
                  onChange={(data) => {
                    setAccountType(data);
                  }}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="accountHolderName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Date
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">{moment().format("MM-DD-YYYY")}</div>
            </div>
          </div>
          <div className="mt-6">
            <hr />
          </div>
          <div className="mt-4 space-y-6">
            <div className="space-y-6">
              <div className="mt-1">
                <label htmlFor="typedSignature" className="block text-sm font-medium text-gray-900">
                  Signature
                </label>
                <input
                  type="text"
                  name="typedSignature"
                  id="typedSignature"
                  className="block w-full rounded-md border-gray-300 font-cedarville shadow-sm focus:border-brand-700 focus:ring-brand-700"
                  onChange={(e) => setSignature(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 space-y-6 text-center">
            <span className="font-semibold">
              COMPANY must retain copy of authorization to be produced upon originating or receiving banks request.
            </span>
          </div>
          <div className="mt-4 bg-gray-200 px-4 py-8 text-center shadow sm:rounded-lg sm:px-10">
            <span className="font-semibold">
              Attach a picture of a check or a screenshot from your bank with the Account Number and Routing Number
              clearly visible.
            </span>
            <div className="mt-4">
              <img className="object-contain" src="/images/check-paypal-example.gif" alt="Check Example" />
            </div>
            <DragDrop
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: "Drop here or %{browse}",
                  browse: "browse",
                },
              }}
            />
            <div className="mt-1">
              <StatusBar hideUploadButton hideAfterFinish={false} showProgressDetails uppy={uppy} />
            </div>
          </div>
          <div className="mt-4 space-y-6">
            <div className="mt-4">
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                onClick={handleApprove}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
