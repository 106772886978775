import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import parsePhoneNumber from "libphonenumber-js";
import { isValidPhoneNumber } from "libphonenumber-js";
import { setAuthToken } from "../../hooks/Authentication";
import { apiError } from "../../libs/Utils";

import PageHeader from "../../components/PageHeader";
import AddUser from "../../components/SlideOvers/Users/AddUser";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import EditUser from "../../components/SlideOvers/Users/EditUser";
import StatusLabel from "../../components/StatusLabel";
import EmailSignature from "../../components/SlideOvers/Users/EmailSignature";

const { REACT_APP_API_URL } = process.env;

export default function Resources() {
  const gridRef = useRef();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Admin - Users";
  });

  const [columnDefs] = useState([
    {
      field: "firstName",
      filter: true,
      minWidth: 200,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "lastName",
      filter: true,
      minWidth: 200,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "email",
      filter: true,
      minWidth: 200,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "phone",
      filter: true,
      minWidth: 150,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        if (params.value) {
          if (isValidPhoneNumber(params.value, "US")) {
            return parsePhoneNumber(params.value, "US").formatNational();
          }
          return "";
        }
        return "";
      },
    },
    {
      field: "role",
      filter: true,
      minWidth: 200,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "status",
      suppressMenu: true,
      minWidth: 150,
      cellRenderer: (params) => {
        return <StatusLabel enabled={params.data.enabled} activated={params.data.activated} />;
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      minWidth: 175,
      maxWidth: 175,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            <EmailSignature tooltip="Email Signature" id={params.value} saved={handleRefresh} />
            <EditUser tooltip="Edit User" id={params.value} saved={handleRefresh} />
            <DeleteDialog
              tooltip="Delete User"
              buttonTitle="Delete"
              title="Delete User?"
              content="Are you sure you want to delete the User?"
              id={params.value}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/users/${idToDelete}`)
      .then(() => {
        toast.success("User deleted");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <PageHeader title="Users" subtitle="User manager" />
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <AddUser saved={handleRefresh} />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="mb-8 ml-2 mr-2">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 750 }}>
              <AgGridReact
                ref={gridRef}
                rowData={users}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
                overlayNoRowsTemplate={"No Users"}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
