import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import ReactSelect from "react-select";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { client, clientError } from "../../../libs/Client";

export default function EditServiceProvider(props) {
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [name, setName] = useState("");
  const [salesRep, setSalesRep] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState(null);
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [website, setWebsite] = useState("");
  const [type, setType] = useState(null);
  const [types, setTypes] = useState([]);
  const [states, setStates] = useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      Promise.all([
        client().get(`/service-providers/${props.id}`),
        client().get(`/service-providers/types`),
        client().get(`/states`),
      ])
        .then((res) => {
          const serviceProvider = res[0].data;
          setName(serviceProvider.name);
          setSalesRep(serviceProvider.salesRep ? serviceProvider.salesRep : "");
          setStreetAddress(serviceProvider.streetAddress);
          setCity(serviceProvider.city);
          if (serviceProvider.state.id) {
            setState({
              value: serviceProvider.state.id,
              label: serviceProvider.state.label,
            });
          }

          setZip(serviceProvider.zip);
          setCountry(serviceProvider.country);
          setWebsite(serviceProvider.website);
          setType({
            value: serviceProvider.providerType,
            label: serviceProvider.providerType,
          });
          setTypes(res[1].data);
          setStates(res[2].data);
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [open, props.id]);

  function handleSave() {
    setProcessing(true);
    client()
      .put(`/service-providers/${props.id}`, {
        name,
        salesRep,
        streetAddress,
        city,
        stateID: state ? state.value : undefined,
        zip,
        country,
        website,
        type: type.value,
      })
      .then(() => {
        setProcessing(false);
        setOpen(false);
        props.saved(true);
      })
      .catch((err) => {
        setProcessing(false);
        clientError(err);
      });
  }

  return (
    <div>
      {props.tooltip ? (
        <Tippy content={props.tooltip}>
          <button
            className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
            onClick={handleClickOpen}
          >
            <PencilSquareIcon className="h-4 w-4 text-indigo-500" />
          </button>
        </Tippy>
      ) : (
        <button
          className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
          onClick={handleClickOpen}
        >
          <PencilSquareIcon className="h-4 w-4 text-indigo-500" />
        </button>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Edit Service Provider
                            </Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                                  Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                  />
                                </div>
                              </div>
                              <div className="sm:col-span-3">
                                <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                  Provider Type
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect
                                    options={types.map((type) => ({
                                      value: type,
                                      label: type,
                                    }))}
                                    isClearable
                                    value={type}
                                    onChange={(data) => setType(data)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="salesRep" className="block text-sm font-medium text-gray-900">
                                  Sales Rep
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="salesRep"
                                    id="salesRep"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setSalesRep(e.target.value)}
                                    value={salesRep}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="streetAddress" className="block text-sm font-medium text-gray-900">
                                  Street Address
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="streetAddress"
                                    id="streetAddress"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setStreetAddress(e.target.value)}
                                    value={streetAddress}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="city" className="block text-sm font-medium text-gray-900">
                                  City
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setCity(e.target.value)}
                                    value={city}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="state" className="block text-sm font-medium text-gray-900">
                                  State
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect
                                    options={states.map((state) => ({
                                      value: state.id,
                                      label: state.label,
                                    }))}
                                    isClearable
                                    value={state}
                                    onChange={(data) => setState(data)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="zip" className="block text-sm font-medium text-gray-900">
                                  Zip
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="zip"
                                    id="zip"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setZip(e.target.value)}
                                    value={zip}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="country" className="block text-sm font-medium text-gray-900">
                                  Country
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="country"
                                    id="country"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setCountry(e.target.value)}
                                    value={country}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="website" className="block text-sm font-medium text-gray-900">
                                  Website
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="website"
                                    id="website"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setWebsite(e.target.value)}
                                    value={website}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {processing ? (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="cursor-not-allowed rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex cursor-not-allowed justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-150 ease-in-out hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            <svg
                              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Saving...
                          </button>
                        </div>
                      ) : (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EditServiceProvider.propTypes = {
  tooltip: PropTypes.string,
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
