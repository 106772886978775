import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Activate from "./views/Authentication/Activate";
import Login from "./views/Authentication/Login";
import Forgot from "./views/Authentication/Forgot";
import Reset from "./views/Authentication/Reset";

import AgingStatementPrintView from "./views/PrintView/AgingStatementPrintView";
import DetailStatementPrintView from "./views/PrintView/DetailStatementPrintView";
import SummaryStatementPrintView from "./views/PrintView/SummaryStatementPrintView";

import FullLayout from "./layouts/FullLayout";
import CreateCase from "./views/Cases/CreateCase";
import Dashboard from "./views/Dashboards/Dashboard";
import Password from "./views/Settings/Password";
import Settings from "./views/Settings/Settings";
import ContractSignature from "./views/Settings/ContractSignature";
import Calendar from "./views/Settings/Calendar";

import NotFound from "./views/NotFound";

import Export from "./views/Export/Export";

// Signatures
import Expired from "./views/Signatures/Expired";
import Hippa from "./views/Signatures/Hippa";
import IdentityVerification from "./views/Signatures/IdentityVerification";
import DirectDepositAuthorization from "./views/Signatures/DirectDepositAuthorization";
import PlaintiffSignature from "./views/Signatures/PlaintiffSignature";
import ServiceProviderSignature from "./views/Signatures/ServiceProviderSignature";

// Admin
import Images from "./views/Admin/Images";
import Tags from "./views/Admin/Tags";
import Links from "./views/Admin/Links";
import FundingCompanies from "./views/Admin/FundingCompanies";
import ReferralTypes from "./views/Admin/ReferralTypes";
import States from "./views/Admin/States";
import StateFees from "./views/Admin/StateFees";
import Quickbooks from "./views/Admin/Quickbooks";

// Campaigns
import Campaigns from "./views/Campaigns/Campaigns";
import Recipients from "./views/Campaigns/Recipients";

// Cases
import ClosedCases from "./views/Cases/ClosedCases";
import FundedCases from "./views/Cases/FundedCases";
import ReferredCases from "./views/Cases/ReferredCases";
import UnfundedCases from "./views/Cases/UnfundedCases";

import CaseActivity from "./views/CaseActivity/CaseActivity";
import CaseFundingApplications from "./views/Case/CaseFundingApplications";
import CaseDocumentAttachments from "./views/Case/CaseDocumentAttachments";
import CaseDocumentContracts from "./views/Case/CaseDocumentContracts";
import CaseDocumentUploads from "./views/Case/CaseDocumentUploads";

import CaseEmailInbox from "./views/Case/CaseEmailInbox";
import CaseEmailSent from "./views/Case/CaseEmailSent";
import CaseFeed from "./views/Case/CaseFeed";
import CaseLog from "./views/Case/CaseLog";
import CaseOverview from "./views/Case/Overview/CaseOverview";
import CaseOverviewAccidentDetails from "./views/Case/Overview/CaseOverviewAccidentDetails";
import CaseOverviewPlaintiffDetails from "./views/Case/Overview/CaseOverviewPlaintiffDetails";
import CaseOverviewDDA from "./views/Case/Overview/CaseOverViewDDA";
import CaseOverviewIDVerification from "./views/Case/Overview/CaseOverviewIDVerification";
import CaseOverviewMedicalReleaseForms from "./views/Case/Overview/CaseOverviewMedicalReleaseForms";
import CaseOverviewServiceProvider from "./views/Case/Overview/CaseOverviewServiceProvider";
import CaseOverviewUnderwritingDetails from "./views/Case/Overview/CaseOverviewUnderwritingDetails";
import CaseSMSInbox from "./views/Case/CaseSMSInbox";
import CaseSMSSent from "./views/Case/CaseSMSSent";
import CaseUpdate from "./views/Case/CaseUpdate";
import LinkedCases from "./views/Case/LinkedCases";

// Email
import Inbox from "./views/Email/Inbox";
import InboxMail from "./views/Email/InboxMail";
import Sent from "./views/Email/Sent";
import SentMail from "./views/Email/SentMail";

// Funding Requests
import OpenFundingRequests from "./views/FundingRequests/OpenFundingRequests";
import MyOpenFundingRequests from "./views/FundingRequests/MyOpenFundingRequests";
import ProcessingFundingRequests from "./views/FundingRequests/ProcessingFundingRequests";
import RejectedFundingRequests from "./views/FundingRequests/RejectedFundingRequests";
import ApprovedFundingRequests from "./views/FundingRequests/ApprovedFundingRequests";
import ReadyFundingRequests from "./views/FundingRequests/ReadyFundingRequests";
import FundedFundingRequests from "./views/FundingRequests/FundedFundingRequests";

// Leads
import PendingLeads from "./views/Leads/PendingLeads";
import CreatedLeads from "./views/Leads/CreatedLeads";
import ArchivedLeads from "./views/Leads/ArchivedLeads";
import RejectedLeads from "./views/Leads/RejectedLeads";

// Notifications
import UnreadNotifications from "./views/Notifications/UnreadNotifications";
import AllNotifications from "./views/Notifications/AllNotifications";

// Search
import AdvancedSearch from "./views/Search/AdvancedSearch";
import AdvancedServiceProviderContactSearch from "./views/Search/AdvancedServiceProviderContactSearch";
import AdvancedServiceProviderSearch from "./views/Search/AdvancedServiceProviderSearch";

// Service Providers
import ServiceProviders from "./views/ServiceProviders/ServiceProviders";
import ServiceProviderFundingRequests from "./views/ServiceProviders/ServiceProviderFundingRequests";
import ServiceProviderOverview from "./views/ServiceProviders/ServiceProviderOverview";
import ServiceProviderUsers from "./views/ServiceProviders/ServiceProviderUsers";
import ServiceProviderUserFundingApplications from "./views/ServiceProviders/ServiceProviderUserFundingApplications";
import AgingStatement from "./views/ServiceProviders/Statements/AgingStatement";
import DetailStatement from "./views/ServiceProviders/Statements/DetailStatement";
import SummaryStatement from "./views/ServiceProviders/Statements/SummaryStatement";

// Tasks
import ToDoTasks from "./views/Tasks/ToDoTasks";
import DoneTasks from "./views/Tasks/DoneTasks";
import AllTasks from "./views/Tasks/AllTasks";

// Templates
import CampaignTemplates from "./views/Admin/CampaignTemplates";
import ContractTemplates from "./views/Admin/ContractTemplates";
import EmailTemplates from "./views/Admin/EmailTemplates";
import SMSTemplates from "./views/Admin/SMSTemplates";
import HippaTemplates from "./views/Admin/HippaTemplates";

import Users from "./views/Admin/Users";

import { useUser } from "./hooks/Authentication";

const { REACT_APP_WS_URL } = process.env;

function App() {
  const { user, setUser } = useUser();
  const ws = new WebSocket(REACT_APP_WS_URL);

  WebSocket.prototype.sendJsonBlob = function (userID, data) {
    const string = JSON.stringify({ client: userID, data: data });
    const blob = new Blob([string], { type: "application/json" });
    this.send(blob);
  };

  function blobToJson(blob) {
    return new Promise((resolve, reject) => {
      let fr = new FileReader();
      fr.onload = () => {
        resolve(JSON.parse(fr.result));
      };
      fr.readAsText(blob);
    });
  }

  ws.onopen = (event) => {
    if (user) {
      ws.sendJsonBlob(user.userID, { connect: true });
    }
  };

  ws.onmessage = (event) => {
    blobToJson(event.data).then((msg) => {
      if (msg.type === "Success") {
        toast.success(msg.message);
      } else if (msg.type === "Error") {
        toast.error(msg.message);
      } else if (msg.type === "Info") {
        toast.info(msg.message);
      } else if (msg.type === "Warning") {
        toast.warn(msg.message);
      }
    });
  };

  ws.onclose = () => {
    console.log("Socket is closed.");
  };

  if (!user) {
    return (
      <div>
        <Routes>
          <Route path="/activate/:token" element={<Activate />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/login" element={<Login setUser={setUser} />} />
          <Route path="/reset/:token" element={<Reset />} />
          <Route path="/signature/dda/:id" element={<DirectDepositAuthorization />} />
          <Route path="/signature/hippa/:id" element={<Hippa />} />
          <Route path="/signature/id-verification/:id" element={<IdentityVerification />} />
          <Route path="/signature/contract/:id/plaintiff" element={<PlaintiffSignature />} />
          <Route path="/signature/contract/:id/service-provider" element={<ServiceProviderSignature />} />
          <Route path="/signature/expired" element={<Expired />} />
          <Route path="*" element={<Navigate to={user ? "/" : "/login"} />} />
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          closeOnClick
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<FullLayout />}>
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/campaigns" exact element={<Campaigns />} />
          <Route path="/campaigns/:id/recipients" exact element={<Recipients />} />
          <Route path="/case-activity" exact element={<CaseActivity />} />
          <Route path="/cases/new" exact element={<CreateCase />} />
          <Route path="/cases/closed" exact element={<ClosedCases />} />
          <Route path="/cases/funded" exact element={<FundedCases />} />
          <Route path="/cases/referred" exact element={<ReferredCases />} />
          <Route path="/cases/unfunded" exact element={<UnfundedCases />} />
          <Route path="/cases/:id/applications" exact element={<CaseFundingApplications />} />
          <Route path="/cases/:id/documents/attachments" exact element={<CaseDocumentAttachments />} />
          <Route path="/cases/:id/documents/contracts" exact element={<CaseDocumentContracts />} />
          <Route path="/cases/:id/documents/uploads" exact element={<CaseDocumentUploads />} />
          <Route path="/cases/:id/email/inbox" exact element={<CaseEmailInbox />} />
          <Route path="/cases/:id/email/sent" exact element={<CaseEmailSent />} />
          <Route path="/cases/:id/feed" exact element={<CaseFeed />} />
          <Route path="/cases/:id/linked" exact element={<LinkedCases />} />
          <Route path="/cases/:id/log" exact element={<CaseLog />} />
          <Route path="/cases/:id/overview" exact element={<CaseOverview />} />
          <Route path="/cases/:id/overview/accident-details" exact element={<CaseOverviewAccidentDetails />} />
          <Route path="/cases/:id/overview/service-provider" exact element={<CaseOverviewServiceProvider />} />
          <Route path="/cases/:id/overview/plaintiff-details" exact element={<CaseOverviewPlaintiffDetails />} />
          <Route path="/cases/:id/overview/underwriting-details" exact element={<CaseOverviewUnderwritingDetails />} />
          <Route path="/cases/:id/overview/dda" exact element={<CaseOverviewDDA />} />
          <Route path="/cases/:id/overview/idv" exact element={<CaseOverviewIDVerification />} />
          <Route path="/cases/:id/overview/mrf" exact element={<CaseOverviewMedicalReleaseForms />} />
          <Route path="/cases/:id/sms/inbox" exact element={<CaseSMSInbox />} />
          <Route path="/cases/:id/sms/sent" exact element={<CaseSMSSent />} />
          <Route path="/cases/:id/update" exact element={<CaseUpdate />} />
          <Route path="/email/inbox" exact element={<Inbox />} />
          <Route path="/email/inbox/:id" exact element={<InboxMail />} />
          <Route path="/email/sent" exact element={<Sent />} />
          <Route path="/email/sent/:id" exact element={<SentMail />} />
          <Route path="/export" exact element={<Export />} />
          <Route path="/funding-requests/open" exact element={<MyOpenFundingRequests />} />
          <Route path="/funding-requests/open/all" exact element={<OpenFundingRequests />} />
          <Route path="/funding-requests/processing" exact element={<ProcessingFundingRequests />} />
          <Route path="/funding-requests/rejected" exact element={<RejectedFundingRequests />} />
          <Route path="/funding-requests/approved" exact element={<ApprovedFundingRequests />} />
          <Route path="/funding-requests/ready" exact element={<ReadyFundingRequests />} />
          <Route path="/funding-requests/funded" exact element={<FundedFundingRequests />} />
          <Route path="/leads/pending" exact element={<PendingLeads />} />
          <Route path="/leads/created" exact element={<CreatedLeads />} />
          <Route path="/leads/archived" exact element={<ArchivedLeads />} />
          <Route path="/leads/rejected" exact element={<RejectedLeads />} />
          <Route path="/login" exact element={<Navigate replace to="/" />} />
          <Route path="/notifications" exact element={<AllNotifications />} />
          <Route path="/notifications/unread" exact element={<UnreadNotifications />} />
          <Route path="/search" exact element={<AdvancedSearch />} />
          <Route path="/search/contacts" exact element={<AdvancedServiceProviderContactSearch />} />
          <Route path="/search/providers" exact element={<AdvancedServiceProviderSearch />} />
          <Route path="/service-providers" exact element={<ServiceProviders />} />
          <Route path="/service-providers/:id/funding-requests" exact element={<ServiceProviderFundingRequests />} />
          <Route path="/service-providers/:id/overview" exact element={<ServiceProviderOverview />} />
          <Route path="/service-providers/:id/users" exact element={<ServiceProviderUsers />} />
          <Route path="/service-providers/:id/statements/aging" exact element={<AgingStatement />} />
          <Route path="/service-providers/:id/statements/detail" exact element={<DetailStatement />} />
          <Route path="/service-providers/:id/statements/summary" exact element={<SummaryStatement />} />
          <Route
            path="/service-provider-users/:id/funding-applications"
            exact
            element={<ServiceProviderUserFundingApplications />}
          />
          <Route path="/settings" exact element={<Settings />} />
          <Route path="/settings/password" exact element={<Password />} />
          <Route path="/settings/contract-signature" exact element={<ContractSignature />} />
          <Route path="/settings/calendar" exact element={<Calendar />} />
          <Route path="/tasks" exact element={<AllTasks />} />
          <Route path="/tasks/done" exact element={<DoneTasks />} />
          <Route path="/tasks/todo" exact element={<ToDoTasks />} />
          <Route path="/admin/images" exact element={<Images />} />
          <Route path="/admin/states" exact element={<States />} />
          <Route path="/admin/states/:id/fees" exact element={<StateFees />} />
          <Route path="/admin/users" exact element={<Users />} />
          <Route path="/admin/templates/campaigns" exact element={<CampaignTemplates />} />
          <Route path="/admin/templates/contracts" exact element={<ContractTemplates />} />
          <Route path="/admin/templates/email" exact element={<EmailTemplates />} />
          <Route path="/admin/templates/sms" exact element={<SMSTemplates />} />
          <Route path="/admin/templates/hippa" exact element={<HippaTemplates />} />
          <Route path="/admin/funding-companies" exact element={<FundingCompanies />} />
          <Route path="/admin/referral-types" exact element={<ReferralTypes />} />
          <Route path="/admin/quickbooks" exact element={<Quickbooks />} />
          <Route path="/admin/links" exact element={<Links />} />
          <Route path="/admin/tags" exact element={<Tags />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/print-views/aging-statement/:id" element={<AgingStatementPrintView />} />
        <Route path="/print-views/detail-statement/:id" element={<DetailStatementPrintView />} />
        <Route path="/print-views/summary-statement/:id" element={<SummaryStatementPrintView />} />
        <Route path="/signature/dda/:id" element={<DirectDepositAuthorization />} />
        <Route path="/signature/hippa/:id" element={<Hippa />} />
        <Route path="/signature/id-verification/:id" element={<IdentityVerification />} />
        <Route path="/signature/contract/:id/plaintiff" element={<PlaintiffSignature />} />
        <Route path="/signature/contract/:id/service-provider" element={<ServiceProviderSignature />} />
        <Route path="/signature/expired" element={<Expired />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
