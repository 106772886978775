import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../datagrid.css";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

import { apiError, dateComparator, dateFromNow } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

import CaseDetailHeader from "../../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseOverviewTabs from "../../../layouts/FullLayout/Tabs/CaseOverviewTabs";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";
import DeleteDialog from "../../../components/Dialogs/DeleteDialog";

const { REACT_APP_API_URL } = process.env;

export default function CaseOverViewDDA() {
  const { id } = useParams();
  const gridRef = useRef();
  const [documents, setDocuments] = useState([]);
  const [name, setName] = useState("-");
  const [accountHolder, setAccountHolder] = useState("-");
  const [institution, setInstitution] = useState("-");
  const [routingNumber, setRoutingNumber] = useState("-");
  const [accountNumber, setAccountNumber] = useState("-");
  const [accountType, setAccountType] = useState("-");

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - Overview - DDA Authorization";
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/lawsuits/${id}/dda`)
      .then((res) => {
        if (res.data.hasDetails) {
          setName(res.data.details.name);
          setAccountHolder(res.data.details.accountHolder);
          setInstitution(res.data.details.institution);
          setRoutingNumber(res.data.details.routingNumber);
          setAccountNumber(res.data.details.accountNumber);
          setAccountType(res.data.details.accountType);
        }
      })
      .catch((err) => {
        apiError(err);
      });
  });

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/documents?lawsuitID=${id}&section=Direct Deposit Authorization`)
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/documents/${idToDelete}`)
      .then(() => {
        toast.success("Document deleted");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleRequest(data) {
    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/dda`, { lawsuitID: parseInt(data, 10) })
      .then(() => {
        toast.success("Request sent");
        setName("-");
        setAccountHolder("-");
        setInstitution("-");
        setRoutingNumber("-");
        setAccountNumber("-");
        setAccountType("-");
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleClickDownload(documentID) {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/documents/${documentID}/download`)
      .then((res) => {
        if (res.status === 204) {
          toast.error("Document does not exist");
          return;
        }

        window.location.href = res.data;
      })
      .catch((err) => {
        apiError(err);
      });
  }

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/documents?lawsuitID=${id}&section=Direct Deposit Authorization`)
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const [columnDefs] = useState([
    {
      field: "originalName",
      headerName: "Document",
      minWidth: 450,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "createdAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Created",
      minWidth: 200,
      maxWidth: 200,
      cellRenderer: (params) => {
        return dateFromNow(params.data.createdAt);
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      sortable: false,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            <button
              className="hover:bg-indigo-50-200 inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium focus:outline-none"
              onClick={() => handleClickDownload(params.value)}
            >
              <DocumentArrowDownIcon className="h-4 w-4 text-indigo-500" />
            </button>
            <DeleteDialog
              tooltip="Delete"
              title="Delete Document"
              content="Are you sure you want to delete the document?"
              id={params.value}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Case Overview" lawsuitID={id} />
        <div className="ml-8 mr-8">
          <CaseOverviewTabs lawsuitID={id} selected="Direct Deposit" />
        </div>
        <div className="mb-4 mt-2">
          <dl className="ml-8 mr-8 divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{name}</span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Account Holder</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{accountHolder}</span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Institution</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{institution}</span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Routing Number</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{routingNumber}</span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Account Number</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{accountNumber}</span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Account Type</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{accountType}</span>
              </dd>
            </div>
          </dl>
        </div>
        <div className="mb-8 mt-2 flex flex-col ">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 350 }}>
              <AgGridReact
                ref={gridRef}
                rowData={documents}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
                overlayNoRowsTemplate={"No documents on file"}
              ></AgGridReact>
            </div>
            <div className="float-right mt-4">
              <ConfirmDialog
                buttonTitle="Request Direct Deposit Authorization"
                title="Send DDA Request"
                content="Are you sure you want to send the DDA request?"
                id={id}
                confirmed={handleRequest}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
