import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

import { apiError } from "../../../../libs/Utils";
import { setAuthToken } from "../../../../hooks/Authentication";

const { REACT_APP_API_URL } = process.env;

export default function EditFullName(props) {
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      setAuthToken();
      axios
        .get(`${REACT_APP_API_URL}/lawsuits/${props.id}`)
        .then((response) => {
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }, [open, props.id]);

  function handleSave() {
    setProcessing(true);
    setAuthToken();
    axios
      .patch(`${REACT_APP_API_URL}/lawsuits/${props.id}`, {
        firstName,
        lastName,
      })
      .then(() => {
        setProcessing(false);
        setOpen(false);
        props.saved(true);
      })
      .catch((err) => {
        setProcessing(false);
        apiError(err);
      });
  }

  return (
    <div>
      <button
        type="button"
        className="rounded-md bg-white font-medium text-brand-700 hover:text-brand-500 focus:outline-none"
        onClick={handleClickOpen}
      >
        <PencilSquareIcon className="h-5 w-5" />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Edit Full Name</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">
                                  First Name
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    className="focus:border-brand block w-full rounded-md border-gray-300 shadow-sm focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    value={firstName}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="lastName" className="block text-sm font-medium text-gray-900">
                                  Last Name
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    className="focus:border-brand block w-full rounded-md border-gray-300 shadow-sm focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {processing ? (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="cursor-not-allowed rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex cursor-not-allowed justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-150 ease-in-out hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            <svg
                              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Saving...
                          </button>
                        </div>
                      ) : (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EditFullName.propTypes = {
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
