import React, { useEffect, useState } from "react";
import { client, clientError } from "../../../libs/Client";
import { shortDate, reportDate, currencyNoCents } from "../../../libs/Utils";
import EmptyRows from "../../../components/EmptyRows";
import { PrinterIcon } from "@heroicons/react/20/solid";
import LawFirmAddress from "../../../components/Statements/LawFirmAddress";
import StatementAddress from "../../../components/Statements/StatementAddress";
import { useParams } from "react-router-dom";

export default function SummaryStatement() {
  const { id } = useParams();
  const [serviceProvider, setServiceProvider] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [total, setTotal] = useState(0);
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    client()
      .get(`/service-providers/${id}/statements/summary`)
      .then((res) => {
        setTotal(res.data.total);
        setLineItems(res.data.lineItems);
        setServiceProvider(res.data.serviceProvider.name);
        setStreetAddress(res.data.serviceProvider.streetAddress);
        setCity(res.data.serviceProvider.city);
        setState(res.data.serviceProvider.state.label);
        setZipCode(res.data.serviceProvider.zip);
      })
      .catch((err) => {
        clientError(err);
      });
  }, [id]);

  return (
    <main className="flex-1">
      <div className="text-right p-2">
        <a
          type="button"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          href={`/print-views/summary-statement/${id}`}
          target="_blank"
          rel="noreferrer"
        >
          <PrinterIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Printer Friendly
        </a>
      </div>
      <div className="py-1 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="grid grid-cols-2 gap-4 mt-8">
            <LawFirmAddress name={serviceProvider} street={streetAddress} city={city} state={state} zipCode={zipCode} />
            <div className="text-right">
              <StatementAddress />
            </div>
          </div>
          <div className="mt-10">
            <p className="text-center text-xl font-base text-gray-900">
              <u>Statement as of {reportDate()}</u>
            </p>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                {lineItems.length > 0 ? (
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-gray-900">
                              Client (Date of Accident)
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-gray-900">
                              Primary Law Firm Contact
                            </th>
                            <th scope="col" className="px-3 py-4 text-right text-sm font-semibold text-gray-900">
                              Total Payoff
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {lineItems.map((item, idx) => (
                            <tr key={idx}>
                              <td className="px-3 py-4 text-sm text-gray-500">
                                {item.fullName} ({shortDate(item.accidentDate)})
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500">{item.contact}</td>
                              <td className="py-4 pr-4 text-right text-sm font-medium">
                                {currencyNoCents(item.totalPayoff)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th
                              scope="row"
                              colSpan={2}
                              className="pl-6 pr-3 pt-6 text-right text-base font-semibold text-gray-900 sm:table-cell md:pl-0"
                            >
                              Total
                            </th>
                            <td className="pl-3 pr-4 pt-6 text-right text-base text-gray-900 font-semibold">
                              {currencyNoCents(total)}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                ) : (
                  <EmptyRows
                    title="No Summary Statement"
                    subtitle="There is currently insufficient information to generate a summary statement"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
