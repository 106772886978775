import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { classNames } from "../../../libs/Utils";

export default function AdvancedSearchTabs(props) {
  const navigate = useNavigate();
  var tabs = [];
  var caseSearchURL = "/search";
  var contactSearchURL = "/search/contacts";
  var providersSearchURL = "/search/providers";

  function handleChange(url) {
    navigate(url);
  }

  if (props.searchString) {
    caseSearchURL = `/search?q=${encodeURIComponent(props.searchString)}`;
    contactSearchURL = `/search/contacts?q=${encodeURIComponent(props.searchString)}`;
    providersSearchURL = `/search/providers?q=${encodeURIComponent(props.searchString)}`;
  }

  if (props.selected === "Cases") {
    tabs.push({ name: "Cases", href: caseSearchURL, count: props.totalCases, current: true });
  } else {
    tabs.push({ name: "Cases", href: caseSearchURL, count: props.totalCases, current: false });
  }

  if (props.selected === "Service Provider Contacts") {
    tabs.push({
      name: "Service Provider Contacts",
      href: contactSearchURL,
      count: props.totalContacts,
      current: true,
    });
  } else {
    tabs.push({
      name: "Service Provider Contacts",
      href: contactSearchURL,
      count: props.totalContacts,
      current: false,
    });
  }

  if (props.selected === "Service Providers") {
    tabs.push({ name: "Service Providers", href: providersSearchURL, count: props.totalProviders, current: true });
  } else {
    tabs.push({ name: "Service Providers", href: providersSearchURL, count: props.totalProviders, current: false });
  }

  return (
    <div className="p-2">
      {/* Tabs */}
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand-700 focus:outline-none focus:ring-brand-700 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(e) => handleChange(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "border-brand-700 text-brand-700"
                    : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                  "flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current ? "bg-red-50 text-brand-700" : "bg-red-50 text-brand-700",
                      "ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block"
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

AdvancedSearchTabs.propTypes = {
  totalCases: PropTypes.string.isRequired,
  totalContacts: PropTypes.string.isRequired,
  totalProviders: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  searchString: PropTypes.string,
};
