import { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { Editor } from "@tinymce/tinymce-react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { setAuthToken } from "../../../hooks/Authentication";
import { apiError } from "../../../libs/Utils";

const { REACT_APP_API_URL, REACT_APP_TINY_MCE_API_KEY } = process.env;

export default function EmailSignature(props) {
  const editorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [emailSignature, setEmailSignature] = useState("");

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      setAuthToken();
      axios
        .get(`${REACT_APP_API_URL}/users/${props.id}`)
        .then((res) => {
          setEmailSignature(res.data.emailSignature);
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }, [open, props.id]);

  function handleSave() {
    setAuthToken();
    const emailSignature = editorRef.current.getContent();
    axios
      .patch(`${REACT_APP_API_URL}/users/${props.id}`, {
        emailSignature,
      })
      .then(() => {
        setOpen(false);
        props.saved(true);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      {props.tooltip ? (
        <Tippy content={props.tooltip}>
          <button
            className="inline-flex rounded border-transparent bg-sky-100 px-2.5 py-1.5 text-xs font-medium hover:bg-sky-200 focus:outline-none"
            onClick={handleClickOpen}
          >
            <EnvelopeIcon className="h-4 w-4 text-sky-500" />
          </button>
        </Tippy>
      ) : (
        <button
          className="inline-flex rounded border-transparent bg-sky-100 px-2.5 py-1.5 text-xs font-medium hover:bg-sky-200 focus:outline-none"
          onClick={handleClickOpen}
        >
          <EnvelopeIcon className="h-4 w-4 text-sky-500" />
        </button>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Edit Email Signature</Dialog.Title>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-gray-200">Edit email signature for selected user</p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <div className="mt-1">
                                  <Editor
                                    initialValue={emailSignature}
                                    apiKey={`${REACT_APP_TINY_MCE_API_KEY}`}
                                    onInit={(evt, editor) => (editorRef.current = editor)}
                                    init={{
                                      image_list: `${REACT_APP_API_URL}/images/list`,
                                      height: 300,
                                      menubar: "edit insert view format table tools",
                                      plugins: "lists link image table code",
                                      paste_as_text: false,
                                      paste_block_drop: true,
                                      toolbar:
                                        "undo redo | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | image link | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EmailSignature.propTypes = {
  tooltip: PropTypes.string,
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
