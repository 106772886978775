import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { apiError } from "../../libs/Utils";
import { setAuthToken, useUser } from "../../hooks/Authentication";

import SettingTabs from "../../layouts/FullLayout/Tabs/SettingTabs";

const { REACT_APP_API_URL } = process.env;

export default function Password() {
  const { user } = useUser();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function handleUpdate() {
    if (currentPassword === "") {
      toast.error("Current password required");
    } else if (newPassword === "") {
      toast.error("New password required");
    } else if (newPassword !== confirmPassword) {
      toast.error("Passwords must match");
    } else {
      setAuthToken();
      axios
        .put(`${REACT_APP_API_URL}/me/password`, {
          currentPassword,
          newPassword,
        })
        .then(() => {
          toast.success("Password changed");
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }

  return (
    <div className="py-6">
      <div className="mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-2">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Settings</h1>
          </div>
          <div className="mt-4 rounded-md bg-white p-4">
            <SettingTabs role={user.role} selected="Password" />
            <div className="ml-4 mr-4 divide-y divide-gray-200">
              <div className="mt-8flex flex-col">
                <div className="pt-4">
                  <div className="mt-0 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                      <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                        Current Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="currentPassword"
                          id="currentPassword"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          value={currentPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                      <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                        New Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="newPassword"
                          id="newPassword"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                          onChange={(e) => setNewPassword(e.target.value)}
                          value={newPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                      <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                        Confirm New Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <div className="flex ">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-500"
                      onClick={handleUpdate}
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
