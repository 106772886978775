import { MainNavigation, AdminNavigation } from "./SidebarMenuItems";

import { classNames } from "../../../libs/Utils";

export default function Sidebar() {
  return (
    <div className="flex flex-grow flex-col overflow-y-auto bg-brand-700 pt-5">
      <div className="flex items-center flex-shrink-0 px-4">
        <img
          className="h-8 w-auto"
          src="/images/logos/white.png"
          alt="Capital Now Funding"
        />
      </div>
      <div className="mt-6 text-center">
        <a
          type="button"
          className="order-0 rounded-md border border-transparent bg-brand-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
          href="/cases/new"
        >
          Create Case
        </a>
      </div>

      <div className="mt-5 flex flex-1 flex-col">
        <nav className="flex-1 space-y-1 px-2 pb-4">
          {MainNavigation().map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={classNames(
                item.current
                  ? "bg-white text-brand-700"
                  : "text-white hover:bg-white hover:text-brand-700",
                "group flex items-center px-2 py-2 text-base tracking-wide  rounded-md"
              )}
            >
              <item.icon
                className={classNames(
                  item.current
                    ? "text-brand-700"
                    : "text-white group-hover:text-brand-700",
                  "mr-3 flex-shrink-0 h-6 w-6"
                )}
                aria-hidden="true"
              />
              {item.name}
            </a>
          ))}
          <div className="space-y-1 pt-5">
            <h3
              className="px-3 text-sm font-semibold text-white uppercase tracking-wider"
              id="projects-headline"
            >
              Admin
            </h3>
            <div
              className="space-y-1"
              role="group"
              aria-labelledby="projects-headline"
            >
              {AdminNavigation().map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-white text-brand-700"
                      : "text-white hover:bg-white hover:text-brand-700",
                    "group flex items-center px-6 py-2 text-sm tracking-wide rounded-md"
                  )}
                >
                  <span className="truncate">{item.name}</span>
                </a>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
