export default function StatementAddress() {
  return (
    <div className="justify-right">
      <h2 className="font-semibold text-xl">Capital Now Funding</h2>
      <span className="text-sm text-gray-600">
        PO Box 43130
        <br />
        Louisville, KY 40253
      </span>
    </div>
  );
}
