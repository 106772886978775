import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { apiError, classNames, currency, shortDateUTC, longDate, nationalPhoneNumber } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

import CaseDetailHeader from "../../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseOverviewTabs from "../../../layouts/FullLayout/Tabs/CaseOverviewTabs";
import EditFullName from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditFullName";
import EditEmail from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditEmail";
import EditPhoneNumber from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditPhoneNumber";
import EditAlternatePhoneNumber from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditAlternatePhoneNumber";
import EditDateOfBirth from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditDateOfBirth";
import EditGender from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditGender";
import EditApartmentNumber from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditApartmentNumber";
import EditStreetAddress from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditStreetAddress";
import EditCity from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditCity";
import EditState from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditState";
import EditZipCode from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditZipCode";
import EditCountry from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditCountry";
import EditUserReference from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditUserReference";
import EditAmountRequested from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditAmountRequested";
import EditFundingFor from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditFundingFor";
import EditFundedPreviously from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditFundedPreviously";
import EditPreviousFundingBy from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditPreviousFundingBy";
import EditPreviousAmount from "../../../components/SlideOvers/Cases/PlaintiffDetails/EditPreviouseAmount";

const { REACT_APP_API_URL } = process.env;

export default function CaseOverviewPlaintiffDetails() {
  const { id } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [canEmail, setCanEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [canSMS, setCanSMS] = useState(false);
  const [alternatePhone, setAlternatePhone] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [apartmentNumber, setApartementNumber] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [userReference, setUserReference] = useState("");
  const [amountRequested, setAmountRequested] = useState(0);
  const [fundingFor, setFundingFor] = useState("");
  const [fundedPreviously, setFundedPreviously] = useState(false);
  const [fundedPreviouslyBy, setFundedPreviouslyBy] = useState("");
  const [previousAmount, setPreviousAmount] = useState(0);
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState("");

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - PLaintiff Details";
    setAuthToken();
    Promise.all([
      axios.get(`${REACT_APP_API_URL}/lawsuits/${id}`),
      axios.get(`${REACT_APP_API_URL}/notes?lawsuitID=${id}&section=Plaintiff`),
    ])
      .then((response) => {
        const lawsuit = response[0].data;
        setFirstName(lawsuit.firstName);
        setLastName(lawsuit.lastName);
        setEmail(lawsuit.email);
        setCanEmail(lawsuit.canEmail);
        setPhone(lawsuit.phone);
        setCanSMS(lawsuit.canSMS);
        setAlternatePhone(lawsuit.alternatePhone);
        setDateOfBirth(lawsuit.dateOfBirth);
        setGender(lawsuit.gender);
        setApartementNumber(lawsuit.apartmentNumber);
        setStreetAddress(lawsuit.address);
        setCity(lawsuit.city);
        setState(lawsuit.state.label);
        setZipCode(lawsuit.zipCode);
        setCountry(lawsuit.country);
        setUserReference(lawsuit.userReference);
        setAmountRequested(lawsuit.initialFundingRequest);
        setFundingFor(lawsuit.fundingFor);
        if (lawsuit.fundedPreviously) {
          setFundedPreviously(lawsuit.fundedPreviously);
        }

        if (lawsuit.fundedPreviouslyBy) {
          setFundedPreviouslyBy(lawsuit.fundedPreviouslyBy);
        }

        if (lawsuit.previousAmount) {
          setPreviousAmount(lawsuit.previousAmount);
        }

        setNotes(response[1].data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  function handleRefresh() {
    Promise.all([
      axios.get(`${REACT_APP_API_URL}/lawsuits/${id}`),
      axios.get(`${REACT_APP_API_URL}/notes?lawsuitID=${id}&section=Plaintiff`),
    ])
      .then((res) => {
        const lawsuit = res[0].data;
        setFirstName(lawsuit.firstName);
        setLastName(lawsuit.lastName);
        setEmail(lawsuit.email);
        setCanEmail(lawsuit.canEmail);
        setPhone(lawsuit.phone);
        setCanSMS(lawsuit.canSMS);
        setAlternatePhone(lawsuit.alternatePhone);
        setDateOfBirth(lawsuit.dateOfBirth);
        setGender(lawsuit.gender);
        setApartementNumber(lawsuit.apartmentNumber);
        setStreetAddress(lawsuit.address);
        setCity(lawsuit.city);
        setState(lawsuit.state.label);
        setZipCode(lawsuit.zipCode);
        setCountry(lawsuit.country);
        setUserReference(lawsuit.userReference);
        setAmountRequested(lawsuit.initialFundingRequest);
        setFundingFor(lawsuit.fundingFor);
        setFundedPreviously(lawsuit.fundedPreviously);
        setFundedPreviouslyBy(lawsuit.fundedPreviouslyBy);
        setPreviousAmount(lawsuit.previousAmount);
        setNotes(res[1].data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleSaveNote() {
    if (note === "") {
      toast.error("You need to enter some text for the note");
      return;
    }

    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/notes`, {
        lawsuitID: parseInt(id),
        text: note,
        section: "Plaintiff",
      })
      .then(() => {
        setNote("");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="mb-4 overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Case Overview" lawsuitID={id} />
        <div className="ml-8 mr-8">
          <CaseOverviewTabs lawsuitID={id} selected="Plaintiff Details" />
        </div>
        <div className="mb-4 mt-2">
          <dl className="ml-8 mr-8 divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Full name</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{`${firstName} ${lastName}`}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditFullName id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd
                className={classNames(
                  canEmail ? "text-gray-900" : "text-red-500",
                  "mt-1 flex text-sm  sm:col-span-2 sm:mt-0",
                )}
              >
                <span className="flex-grow">{email}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditEmail id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
              <dd
                className={classNames(
                  canSMS ? "text-gray-900" : "text-red-500",
                  "mt-1 flex text-sm  sm:col-span-2 sm:mt-0",
                )}
              >
                <span className="flex-grow">{nationalPhoneNumber(phone)}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditPhoneNumber id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Alternate Phone</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{nationalPhoneNumber(alternatePhone)}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditAlternatePhoneNumber id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Date of Birth</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{shortDateUTC(dateOfBirth)}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditDateOfBirth id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Gender</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{gender}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditGender id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
          </dl>
        </div>
        <div className="ml-8 mr-8 mt-2 text-lg font-medium">Address Details</div>
        <div className="mb-4 mt-2">
          <dl className="ml-8 mr-8 divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Street Address</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{streetAddress}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditStreetAddress id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Apt / Unit #</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{apartmentNumber}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditApartmentNumber id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">City</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{city}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditCity id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{state}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditState id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Zip Code</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{zipCode}</span>
                <EditZipCode id={id} saved={handleRefresh} />
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Country</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{country}</span>
                <EditCountry id={id} saved={handleRefresh} />
              </dd>
            </div>
          </dl>
        </div>
        <div className="ml-8 mr-8 mt-2 text-lg font-medium">Funding Request Details</div>
        <div className="mb-4 mt-2">
          <dl className="ml-8 mr-8 divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">How did you hear about Capital Now Funding</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{userReference}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditUserReference id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Initial Amount Requested</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{currency(amountRequested)}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditAmountRequested id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">What do you need the money for</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{fundingFor}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditFundingFor id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
          </dl>
        </div>
        <div className="ml-8 mr-8 mt-2 text-lg font-medium">Previous Funding Details</div>
        <div className="mb-4 mt-2">
          <dl className="ml-8 mr-8 divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Have You Previously Received Pre-Settlement Funding</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{fundedPreviously ? "Yes" : "No"}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditFundedPreviously id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            {fundedPreviously ? (
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">What Company Did You Receive Funding From</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">{fundedPreviouslyBy}</span>
                  <span className="ml-4 flex-shrink-0">
                    <EditPreviousFundingBy id={id} saved={handleRefresh} />
                  </span>
                </dd>
              </div>
            ) : null}
            {fundedPreviously ? (
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">How Much Money did you receive</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">{currency(previousAmount)}</span>
                  <span className="ml-4 flex-shrink-0">
                    <EditPreviousAmount id={id} saved={handleRefresh} />
                  </span>
                </dd>
              </div>
            ) : null}
          </dl>
        </div>
        <div className="ml-8 mr-8 mt-2 text-lg font-medium">Notes</div>
        <div className="m-4 px-4">
          <textarea
            rows="5"
            name="note"
            id="note"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          ></textarea>
        </div>
        <div className="m-2 text-right">
          <button
            type="button"
            className="mr-6 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
            onClick={handleSaveNote}
          >
            Save
          </button>
        </div>
        <div className="m-2">
          <hr />
        </div>
        <div className="mb-10 mt-5">
          {notes.length > 0 ? (
            <ul className="m-10">
              {notes.map((note) => (
                <li key={note.id} className="m-10 rounded-lg bg-gray-100 p-6 py-4">
                  <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <h3 className="text-sm font-medium">
                          {note.user.firstName} {note.user.lastName}
                        </h3>
                        <p className="text-sm text-gray-400">{longDate(note.createdAt)}</p>
                      </div>
                      <p className="text-sm text-gray-500">{note.text}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center">
              <p className="text-sm text-gray-500">No Notes</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
