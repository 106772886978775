import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { apiError, nationalPhoneNumber } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

import AdvancedSearchTabs from "../../layouts/FullLayout/Tabs/AdvancedSearchTabs";
import EmptyRows from "../../components/EmptyRows";

const { REACT_APP_API_URL } = process.env;

export default function AdvancedSearch() {
  const [searchParams] = useSearchParams();
  const [searchString, setSearchString] = useState("");
  const [totalCases, setTotalCases] = useState(0);
  const [cases, setCases] = useState([]);
  const [totalProviders, setTotalProviders] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);

  useEffect(() => {
    if (searchParams.get("q")) {
      setAuthToken();
      axios
        .post(`${REACT_APP_API_URL}/search`, { query: searchParams.get("q") })
        .then((res) => {
          setSearchString(searchParams.get("q"));
          setTotalCases(res.data.lawsuits.length);
          setCases(res.data.lawsuits);
          setTotalProviders(res.data.serviceProviders.length);
          setTotalContacts(res.data.serviceProviderContacts.length);
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }, [searchParams]);

  function handleSearch(e) {
    e.preventDefault();
    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/search`, { query: searchString })
      .then((res) => {
        setTotalCases(res.data.lawsuits.length);
        setCases(res.data.lawsuits);
        setTotalProviders(res.data.serviceProviders.length);
        setTotalContacts(res.data.serviceProviderContacts.length);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div className="py-6">
      <div className="mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-2">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Advanced Search</h1>
          </div>
          <form onClick={handleSearch}>
            <div className="mt-2 flex rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-brand-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-700 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  onChange={(e) => setSearchString(e.target.value)}
                  value={searchString}
                />
              </div>
              <button
                type="submit"
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-brand-700 ring-1 ring-inset ring-brand-700 hover:bg-brand-700 hover:text-white"
              >
                <MagnifyingGlassIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Search
              </button>
            </div>
          </form>
          <div className="mt-4 rounded-md bg-white p-4">
            <AdvancedSearchTabs
              selected="Cases"
              totalCases={totalCases.toString()}
              totalContacts={totalContacts.toString()}
              totalProviders={totalProviders.toString()}
              searchString={searchString}
            />
            {totalCases > 0 ? (
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      Case #
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Plaintiff
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Service Provider
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Phone
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {cases.map((item, idx) => (
                    <tr key={idx}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        <a href={`/cases/${item.id}/feed`} className="text-brand-700 hover:text-brand-500">
                          {item.id}
                        </a>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.firstName} {item.lastName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <a
                          href={`/service-providers/${item.serviceProvider.id}/overview`}
                          className="text-brand-700 hover:text-brand-500"
                        >
                          {item.serviceProvider.name}
                        </a>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.email}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {nationalPhoneNumber(item.phone)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <EmptyRows title="No Cases"/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
