import React, { useEffect, useState } from "react";
import { BellIcon, BellAlertIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { client, clientError } from "../../libs/Client";

export default function Search() {
  const [notifications, setNotifications] = useState(false);
  useEffect(() => {
    client()
      .get(`/me/notifications`)
      .then((res) => {
        if (res.data > 0) {
          setNotifications(true);
        } else {
          setNotifications(false);
        }
      })
      .catch((err) => {
        clientError(err);
      });
  }, []);

  if (notifications) {
    return (
      <Tippy content="Notifications">
        <a type="button" className="-m-2.5 p-2.5 text-red-400 hover:text-red-700" href="/notifications/unread">
          <span className="sr-only">View notifications</span>
          <BellAlertIcon className="h-6 w-6 animate-bounce " aria-hidden="true" />
        </a>
      </Tippy>
    );
  }

  return (
    <Tippy content="Notifications">
      <a type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-700" href="/notifications">
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
      </a>
    </Tippy>
  );
}
