import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { apiError } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

import CaseDetailHeader from "../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseSMSTabs from "../../layouts/FullLayout/Tabs/CaseSMSTabs";

const { REACT_APP_API_URL } = process.env;

export default function CaseSMSSent() {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details";
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/sms/sent?lawsuitID=${id}`)
      .then((response) => {
        setMessages(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/sms/sent?lawsuitID=${id}`)
      .then((response) => {
        setMessages(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="SMS" sent={handleRefresh} lawsuitID={id} />
        <div className="relative ml-8 mr-8">
          <CaseSMSTabs lawsuitID={id} selected="Sent" sent={handleRefresh} />
        </div>
        <div className="mb-10 mt-5">
          {messages.length > 0 ? (
            <ul className="m-10">
              {messages.map((message) => (
                <li key={message.id} className="m-10 rounded-lg bg-gray-100 p-6 py-4">
                  <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <h3 className="text-sm font-medium">{message.phone}</h3>
                        <p className="text-sm text-gray-400">{moment(message.createdAt).format("LLLL")}</p>
                      </div>
                      <p className="whitespace-pre-wrap text-sm text-gray-500">{message.text}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center">
              <p className="text-sm text-gray-500">No Messages</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
