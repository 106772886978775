import { MainNavigation } from "./SidebarMenuItems";

import { classNames } from "../../../libs/Utils";

export default function MobileSidebar() {
  return (
    <nav className="px-2 space-y-1">
      {MainNavigation().map((item) => (
        <a
          key={item.name}
          href={item.href}
          className={classNames(
            item.current
              ? "bg-white text-brand-700"
              : "text-white hover:bg-white hover:text-brand-700",
            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
          )}
        >
          <item.icon
            className={classNames(
              item.current
                ? "text-brand-700"
                : "text-white group-hover:text-brand-700",
              "mr-3 flex-shrink-0 h-6 w-6"
            )}
            aria-hidden="true"
          />
          {item.name}
        </a>
      ))}
      {/* <div>
        <h3
          className="px-3 text-sm font-medium text-gray-100 mt-6"
          id="mobile-teams-headline"
        >
          Statements
        </h3>
        <div
          className="mt-1 space-y-1"
          role="group"
          aria-labelledby="mobile-teams-headline"
        >
          {statementsNavigation.map((statement) => (
            <a
              key={statement.name}
              href={statement.href}
              className="group flex items-center px-3 py-2 text-sm leading-5 text-brand-700 rounded-md hover:text-brand-700 hover:bg-brand-700"
            >
              <span
                className={classNames(
                  statement.bgColorClass,
                  "w-2.5 h-2.5 mr-4 rounded-full"
                )}
                aria-hidden="true"
              />
              <span className="truncate">{statement.name}</span>
            </a>
          ))}
        </div>
      </div> */}
    </nav>
  );
}
