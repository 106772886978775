import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { apiError } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

const { REACT_APP_API_URL } = process.env;

export default function LinkNote(props) {
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      setAuthToken();
      axios
        .get(`${REACT_APP_API_URL}/linked-lawsuits/${props.id}/note`)
        .then((response) => {
          setNote(response.data);
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }, [open, props.id]);

  return (
    <div>
      <Tippy content="Note">
        <button
          className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
          onClick={handleClickOpen}
        >
          <DocumentTextIcon className="h-4 w-4 text-indigo-500" />
        </button>
      </Tippy>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Note</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 whitespace-pre pb-5 pt-6">{note}</div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

LinkNote.propTypes = {
  id: PropTypes.string.isRequired,
};
