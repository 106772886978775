import PropTypes from "prop-types";
import { client, clientError } from "../../libs/Client";

export default function DownloadAttachmentButton(props) {
  function handleDownload() {
    if (props.type === "Sent") {
      client()
        .get(`/attachments/sent/${props.id}/download`)
        .then((res) => {
          window.location.href = res.data;
        })
        .catch((err) => {
          clientError(err);
        });
    } else {
      client()
        .get(`/attachments/inbox/${props.id}/download`)
        .then((res) => {
          window.location.href = res.data;
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }

  return (
    <button
      type="button"
      className="m-1 inline-flex rounded border-transparent bg-sky-100 px-4 py-2 text-sm font-medium text-sky-500 hover:bg-sky-200 focus:outline-none"
      onClick={handleDownload}
    >
      {props.title}
    </button>
  );
}

DownloadAttachmentButton.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  id: PropTypes.string,
};
