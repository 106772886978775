import PropTypes from "prop-types";

export default function ServiceProviderContactSearchResult(props) {
  return (
    <a className="flex flex-col space-y-1" href={`/service-providers/${props.id}/users`}>
      <div className="space-x-4">
        <span>
          <span className="font-semibold">Service Provider Contact #:</span> {props.name}
        </span>
        <span>
          <span className="font-semibold">Service Provider:</span> {props.serviceProvider}
        </span>
      </div>
    </a>
  );
}

ServiceProviderContactSearchResult.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  serviceProvider: PropTypes.string.isRequired,
};
