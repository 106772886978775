import PropTypes from "prop-types";

import DownloadAttachmentButton from "../Buttons/DownloadAttachmentButton";

export default function AttachmentsHeader(props) {
  return (
    <div className="m-2 rounded-lg bg-white p-4 shadow">
      {props.attachments.map((attachment) => (
        <DownloadAttachmentButton
          key={attachment.id}
          type={props.type}
          title={attachment.originalName}
          id={attachment.id}
        />
      ))}
    </div>
  );
}

AttachmentsHeader.propTypes = {
  type: PropTypes.string.isRequired,
  attachments: PropTypes.array,
};
