import PropTypes from "prop-types";
import { shortDate } from "../../libs/Utils";

export default function JonesMaritimeBlock(props) {
  return (
    <div className="mt-2 overflow-hidden bg-white">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Accident Details</h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Date</dt>
            <dd className="mt-1 text-sm text-gray-900">{shortDate(props.accidentDate)}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Type</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.accidentType}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Details - What Happened?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.details}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Injuries Sustained?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.injuries}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Treatment - when, what kind, how long, are you still treating?
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{props.treatment}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Did you have health insurance at the time of the accident?
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{props.healthInsurance}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">What is the name of your employer?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.nameOfEmployer}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">What was your job title and job duty there?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.jobDetails}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Are you still currently employed there?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.stillEmployed}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

JonesMaritimeBlock.propTypes = {
  accidentDate: PropTypes.string,
  accidentType: PropTypes.string,
  details: PropTypes.string,
  injuries: PropTypes.string,
  treatment: PropTypes.string,
  healthInsurance: PropTypes.string,
  nameOfEmployer: PropTypes.string,
  jobDetails: PropTypes.string,
  stillEmployed: PropTypes.string,
};
