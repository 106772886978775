import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { apiError } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

const { REACT_APP_API_URL } = process.env;

export default function ComposeSMS(props) {
  const [open, setOpen] = useState(false);
  const [to, setTo] = useState("");
  const [plaintiffPhone, setPlaintiffPhone] = useState("");
  const [primaryContactPhone, setPrimaryContactPhone] = useState("");
  const [message, setMessage] = useState("");
  const [templates, setTemplates] = useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleOnChange(data) {
    if (data === "plaintiff") {
      setTo(plaintiffPhone);
    } else {
      setTo(primaryContactPhone);
    }
  }

  useEffect(() => {
    if (open) {
      setAuthToken();
      Promise.all([
        axios.get(`${REACT_APP_API_URL}/lawsuits/${props.lawsuitID}`),
        axios.get(`${REACT_APP_API_URL}/templates/sms`),
      ])
        .then((response) => {
          setTo(response[0].data.phone);
          setPlaintiffPhone(response[0].data.phone);
          setPrimaryContactPhone(response[0].data.serviceProviderContact.phone);
          setTemplates(response[1].data);
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }, [open, props.lawsuitID]);

  function handleSend() {
    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/sms`, {
        lawsuitID: parseInt(props.lawsuitID),
        to,
        message,
      })
      .then(() => {
        setOpen(false);
        toast.success("SMS queued for sending");
        props.sent(true);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleTemplateChanged(data) {
    const obj = templates.find((o) => o.id === data);
    setMessage(obj.body);
  }

  const notificationMethods = [
    { id: "plaintiff", title: "Plaintiff" },
    { id: "primaryContact", title: "Primary Contact" },
  ];

  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
          onClick={handleClickOpen}
        >
          Compose
        </button>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-40" onClose={() => null}>
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-brand-700 px-4 py-6 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">Compose SMS</Dialog.Title>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-400 px-4 sm:px-6">
                              <div className="space-y-6 pb-5 pt-6">
                                <div>
                                  <fieldset className="mt-4">
                                    <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                      {notificationMethods.map((notificationMethod) => (
                                        <div key={notificationMethod.id} className="flex items-center">
                                          <input
                                            id={notificationMethod.id}
                                            name="notification-method"
                                            type="radio"
                                            defaultChecked={notificationMethod.id === "plaintiff"}
                                            className="h-4 w-4 border-gray-300 text-brand-700 focus:ring-brand-700"
                                            onChange={(e) => handleOnChange(e.target.id)}
                                          />
                                          <label
                                            htmlFor={notificationMethod.id}
                                            className="ml-3 block text-sm font-medium text-gray-700"
                                          >
                                            {notificationMethod.title}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </fieldset>
                                </div>
                                <div>
                                  <label htmlFor="to" className="block text-sm font-medium text-gray-900">
                                    To
                                  </label>
                                  <div className="mt-1 text-sm">
                                    <PhoneInput
                                      country={"us"}
                                      onlyCountries={["us"]}
                                      onChange={(phoneNumber) => setTo({ phoneNumber })}
                                      value={to}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label htmlFor="smsTemplate" className="block text-sm font-medium text-gray-900">
                                    SMS Template
                                  </label>
                                  <div className="remove-input-txt-border mt-1 text-sm">
                                    <ReactSelect
                                      options={templates.map((option) => ({
                                        value: option.id,
                                        label: option.title,
                                      }))}
                                      isClearable
                                      onChange={(e) => {
                                        handleTemplateChanged(e.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                                    Message
                                  </label>
                                  <div className="remove-input-txt-border mt-1 text-sm">
                                    <textarea
                                      rows="4"
                                      name="message"
                                      id="message"
                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-800 focus:ring-gray-800 sm:text-sm"
                                      onChange={(e) => setMessage(e.target.value)}
                                      value={message}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={handleSend}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}

ComposeSMS.propTypes = {
  lawsuitID: PropTypes.string.isRequired,
  sent: PropTypes.func,
};
