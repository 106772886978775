import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { classNames } from "../../../libs/Utils";

export default function LeadTabs(props) {
  const navigate = useNavigate();

  var tabs = [];
  if (props.selected === "Pending") {
    tabs.push({ name: "Pending", href: "/leads/pending", current: true });
  } else {
    tabs.push({ name: "Pending", href: "/leads/pending", current: false });
  }

  if (props.selected === "Created") {
    tabs.push({ name: "Created", href: "/leads/created", current: true });
  } else {
    tabs.push({ name: "Created", href: "/leads/created", current: false });
  }

  if (props.selected === "Archived") {
    tabs.push({ name: "Archived", href: "/leads/archived", current: true });
  } else {
    tabs.push({ name: "Archived", href: "/leads/archived", current: false });
  }

  if (props.selected === "Rejected") {
    tabs.push({ name: "Rejected", href: "/leads/rejected", current: true });
  } else {
    tabs.push({ name: "Rejected", href: "/leads/rejected", current: false });
  }

  function handleChange(url) {
    navigate(url);
  }

  return (
    <div>
      {/* Tabs */}
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand-700 focus:outline-none focus:ring-brand-700 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(e) => handleChange(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "border-brand-700 text-brand-700"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

LeadTabs.propTypes = {
  selected: PropTypes.string.isRequired,
};
