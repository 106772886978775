import PropTypes from "prop-types";

export default function LawFirmAddress(props) {
  return (
    <div>
      <img
        className="w-40 h-35"
        src="/images/logos/square-logo.png"
        alt={props.name}
      />
      <div className="font-semibold text-bas text-gray-700 mt-4">
        <div className="font-semibold text-lg">{props.name}</div>
        <span className="text-sm text-gray-600">
          {props.street}
          <br />
          <span className="capitalize">{props.city}</span>,{" "}
          <span className="uppercase">{props.state}</span>, {props.zipCode}
        </span>
      </div>
    </div>
  );
}

LawFirmAddress.propTypes = {
  name: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
};
