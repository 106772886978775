import PropTypes from "prop-types";

export default function EmailTypeLabel(props) {
  if (props.type === "Inbox") {
    return (
      <span className="inline-flex rounded-full bg-sky-100 px-2 text-xs font-semibold leading-5 text-sky-800">
        Inbox
      </span>
    );
  } else {
    return (
      <span className="inline-flex rounded-full bg-indigo-100 px-2 text-xs font-semibold leading-5 text-indigo-800">
        Sent
      </span>
    );
  }
}

EmailTypeLabel.propTypes = {
  type: PropTypes.string.isRequired,
};
