import React, { useEffect, useCallback } from "react";
import axios from "axios";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { setAuthToken } from "../../hooks/Authentication";
import { apiError, currency, minHeight, shortDate, dateFromNow } from "../../libs/Utils";
import AvatarLabel from "../../components/Labels/AvatarLabel";
import ComposeEmail from "../../components/SlideOvers/Email/ComposeEmail";

const { REACT_APP_API_URL } = process.env;

export default function CaseActivity() {
  useEffect(() => {
    document.title = "Capital Now Funding - Case Activity";
  });

  const gridStyle = { minHeight: minHeight };

  const loadData = ({ skip, limit, sortInfo }) => {
    setAuthToken();
    return axios
      .get(`${REACT_APP_API_URL}/lawsuits/activity?skip=${skip}&limit=${limit}`)
      .then((res) => {
        return { data: res.data.lawsuits, count: res.data.count };
      })
      .catch((err) => {
        apiError(err);
      });
  };

  const dataSource = useCallback(loadData, []);

  const columns = [
    {
      name: "id",
      minWidth: 150,
      header: "Case #",
      render: ({ value }) => {
        return (
          <a className="text-brand-700 hover:text-brand-500" href={`/cases/${value}/feed`}>
            {value}
          </a>
        );
      },
    },
    {
      field: "plaintiff",
      defaultWidth: 350,
      header: "Plaintiff",
      render: ({ data }) => {
        return (
          <AvatarLabel
            firstName={data.firstName}
            lastName={data.lastName}
            email={data.email}
            profilePicURL={data.profilePicURL}
          />
        );
      },
    },
    {
      name: "serviceProvider",
      defaultWidth: 200,
      header: "Service Provider",
    },
    {
      name: "primaryContact",
      defaultWidth: 200,
      header: "Primary Contact",
      render: ({ data }) => {
        return data.primaryContactFirstName + " " + data.primaryContactLastName;
      },
    },
    {
      name: "createdAt",
      defaultWidth: 200,
      header: "Case Creation Date",
      render: ({ value }) => {
        return shortDate(value);
      },
    },
    {
      name: "accidentDate",
      defaultWidth: 200,
      header: "Accident Date",
      render: ({ value }) => {
        return shortDate(value);
      },
    },
    {
      name: "fundedAmount",
      defaultWidth: 200,
      header: "Funded",
      render: ({ value }) => {
        return currency(value);
      },
    },
    {
      name: "payoff",
      defaultWidth: 200,
      header: "Payoff",
      render: ({ value }) => {
        return currency(value);
      },
    },
    {
      name: "activeAt",
      header: "Last Updated",
      defaultWidth: 200,
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      field: "edit",
      header: "",
      minWidth: 75,
      maxWidth: 75,
      render: ({ data }) => {
        return (
          <div className="flex justify-end space-x-4">
            <ComposeEmail icon id={data.id.toString()} />
          </div>
        );
      },
    },
  ];

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <ReactDataGrid
          idProperty="caseActivity"
          columns={columns}
          style={gridStyle}
          showColumnMenuTool={false}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
