import PropTypes from "prop-types";
import { shortDate } from "../../libs/Utils";

export default function MotorVehicleAccidentBlock(props) {
  return (
    <div className="mt-2 overflow-hidden bg-white">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Accident Details</h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Date</dt>
            <dd className="mt-1 text-sm text-gray-900">{shortDate(props.accidentDate)}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Type</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.accidentType}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Details - What Happened?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.details}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Injuries Sustained?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.injuries}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Treatment - when, what kind, how long, are you still treating?
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{props.treatment}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Did you have health insurance at the time of the accident?
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{props.healthInsurance}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Did the at fault driver have insurance?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.atFaultDriverInsurance}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Were you the driver or passenger in the vehicle, or a pedestrian?
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{props.occupantType}</dd>
          </div>
          {props.occupantType === "Passenger" ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Where were you sitting as a passenger?</dt>
              <dd className="mt-1 text-sm text-gray-900">{props.passengerNotes}</dd>
            </div>
          ) : null}
          {props.occupantType === "Driver" ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Did you have insurance?</dt>
              <dd className="mt-1 text-sm text-gray-900">{props.driverInsurance}</dd>
            </div>
          ) : null}
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Were other people in the vehicle with you at the time of the accident?
            </dt>
            <dd className="mt-1 text-sm text-gray-900">{props.otherOccupants ? "Yes" : "No"}</dd>
          </div>
          {props.otherOccupants ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">How many others?</dt>
              <dd className="mt-1 text-sm text-gray-900">{props.howMany}</dd>
            </div>
          ) : null}
          {props.otherOccupants ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Other Details</dt>
              <dd className="mt-1 text-sm text-gray-900">{props.otherDetails}</dd>
            </div>
          ) : null}
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Was the vehicle totaled as a result of the accident?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.totalled}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

MotorVehicleAccidentBlock.propTypes = {
  accidentDate: PropTypes.string,
  accidentType: PropTypes.string,
  details: PropTypes.string,
  injuries: PropTypes.string,
  treatment: PropTypes.string,
  healthInsurance: PropTypes.string,
  atFaultDriverInsurance: PropTypes.string,
  occupantType: PropTypes.string,
  passengerNotes: PropTypes.string,
  driverInsurance: PropTypes.string,
  otherOccupants: PropTypes.bool,
  howMany: PropTypes.string,
  otherDetails: PropTypes.string,
  totalled: PropTypes.string,
};
