import PropTypes from "prop-types";

export default function ServiceProviderSearchResult(props) {
  return (
    <a className="flex flex-col space-y-1" href={`/service-providers/${props.id}/overview`}>
      <span>
        <span className="font-semibold">Service Provider:</span> {props.name}
      </span>
    </a>
  );
}

ServiceProviderSearchResult.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
