import { Fragment, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { FaceFrownIcon, GlobeAmericasIcon } from "@heroicons/react/24/outline";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { classNames } from "../../libs/Utils";
import { client } from "../../libs/Client";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import LawsuitSearchResult from "../../components/Labels/Search/LawsuitSearchResult";
import ServiceProviderSearchResult from "../../components/Labels/Search/ServiceProviderSearchResult";
import ServiceProviderContactSearchResult from "../../components/Labels/Search/ServiceProviderContactSearchResult";

export default function TypeaheadSearch() {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [lawsuits, setLawsuits] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [serviceProviderContacts, setServiceProviderContacts] = useState([]);
  const [hasItems, setHasItems] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleSearch(query) {
    setQuery(query);
    client()
      .post(`/search/type-ahead?q=${query}`, { query })
      .then((res) => {
        setLawsuits(res.data.lawsuits);
        setServiceProviders(res.data.serviceProviders);
        setServiceProviderContacts(res.data.serviceProviderContacts);
        if (
          res.data.serviceProviderContacts.length > 0 ||
          res.data.serviceProviders.length > 0 ||
          res.data.lawsuits.length > 0
        ) {
          setHasItems(true);
        } else {
          setHasItems(false);
        }
      })
      .catch(() => {});
  }

  return (
    <div className="flex flex-1">
      <Tippy content="Quick Search">
        <button onClick={handleClickOpen}>
          <MagnifyingGlassIcon className="h-6 w-6 text-gray-500 hover:text-gray-700" />
        </button>
      </Tippy>
      <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery("")} appear>
        <Dialog as="div" className="relative z-30" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-3xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox onChange={(item) => (window.location = item.url)}>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                      onChange={(event) => handleSearch(event.target.value)}
                    />
                  </div>

                  {query === "" && (
                    <div className="border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14">
                      <GlobeAmericasIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900">
                        Search for cases, service providers and contacts
                      </p>
                      <p className="mt-2 text-gray-500">
                        Quickly access cases, service providers and contacts by running a global search.
                      </p>
                    </div>
                  )}

                  {hasItems && (
                    <Combobox.Options
                      static
                      className="max-h-80 scroll-pb-2 scroll-pt-11 space-y-2 overflow-y-auto pb-2"
                    >
                      <li key="lawsuits">
                        <h2 className="bg-gray-100 px-4 py-2.5 text-base font-semibold text-gray-900">Cases</h2>
                        <ul className="mt-2 text-sm text-gray-800 divide-y divide-gray-200">
                          {lawsuits.map((item, idx) => (
                            <div key={idx}>
                              <Combobox.Option
                                key={`ls-${idx}`}
                                value={item}
                                className={({ active }) =>
                                  classNames(
                                    "cursor-default select-none px-4 py-2 ",
                                    active && "bg-brand-700 text-white"
                                  )
                                }
                              >
                                <LawsuitSearchResult
                                  key={`lsr-${idx}`}
                                  caseNumber={item.id.toString()}
                                  status={item.status}
                                  firstName={item.firstName}
                                  lastName={item.lastName}
                                  serviceProvider={item.serviceProvider.name}
                                  serviceProviderContact={`${item.serviceProviderContact.firstName} ${item.serviceProviderContact.lastName}`}
                                  email={item.email}
                                />
                              </Combobox.Option>
                            </div>
                          ))}
                        </ul>
                      </li>

                      <li key="serviceProviders">
                        <h2 className="bg-gray-100 px-4 py-2.5 text-base font-semibold text-gray-900">
                          Service Providers
                        </h2>
                        <ul className="mt-2 text-sm text-gray-800">
                          {serviceProviders.map((item, idx) => (
                            <div key={idx}>
                              <Combobox.Option
                                key={`sp-${idx}`}
                                value={item}
                                className={({ active }) =>
                                  classNames(
                                    "cursor-default select-none px-4 py-2",
                                    active && "bg-brand-700 text-white"
                                  )
                                }
                              >
                                <ServiceProviderSearchResult key={`spr-${idx}`} id={item.id} name={item.name} />
                              </Combobox.Option>
                            </div>
                          ))}
                        </ul>
                      </li>

                      <li key="serviceProviderContacts">
                        <h2 className="bg-gray-100 px-4 py-2.5 text-base font-semibold text-gray-900">
                          Service Provider Contacts
                        </h2>
                        <ul className="mt-2 text-sm text-gray-800">
                          {serviceProviderContacts.map((item, idx) => (
                            <div key={idx}>
                              <Combobox.Option
                                key={`spc-${idx}`}
                                value={item}
                                className={({ active }) =>
                                  classNames(
                                    "cursor-default select-none px-4 py-2",
                                    active && "bg-brand-700 text-white"
                                  )
                                }
                              >
                                <ServiceProviderContactSearchResult
                                  key={`spcr-${idx}`}
                                  id={item.serviceProviderID}
                                  name={`${item.firstName} ${item.lastName}`}
                                  serviceProvider={item.serviceProvider}
                                />
                              </Combobox.Option>
                            </div>
                          ))}
                        </ul>
                      </li>
                    </Combobox.Options>
                  )}

                  {query !== "" && !hasItems && (
                    <div className="border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14">
                      <FaceFrownIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                      <p className="mt-4 font-semibold text-gray-900">No results found</p>
                      <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
