import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../datagrid.css";

import { apiError } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

import AvatarLabel from "../../components/Labels/AvatarLabel";
import CaseDetailHeader from "../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../layouts/FullLayout/Tabs/CaseDetailTabs";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import LinkCase from "../../components/SlideOvers/Cases/LinkCase";
import LinkNote from "../../components/SlideOvers/Cases/LinkNote";

const { REACT_APP_API_URL } = process.env;

export default function LinkedCases() {
  const { id } = useParams();
  const gridRef = useRef();
  const [linkedCases, setLinkedCases] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details";
  });

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const [columnDefs] = useState([
    {
      field: "id",
      minWidth: 150,
      headerName: "Case #",
      type: "numericColumn",
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        return (
          <a className="text-brand-700 hover:text-brand-500" href={`/cases/${params.data.linkedTo.id}/feed`}>
            {params.data.linkedTo.id}
          </a>
        );
      },
    },
    {
      field: "plaintiff",
      minWidth: 550,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      valueGetter: (params) => {
        return params.data.linkedTo.firstName + " " + params.data.linkedTo.lastName + " " + params.data.linkedTo.email;
      },
      cellRenderer: (params) => {
        return (
          <AvatarLabel
            firstName={params.data.linkedTo.firstName}
            lastName={params.data.linkedTo.lastName}
            email={params.data.linkedTo.email}
            profilePicURL={params.data.linkedTo.profilePicURL}
          />
        );
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      minWidth: 175,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            <LinkNote id={params.value.toString()} />
            <DeleteDialog
              tooltip="Delete Case Link"
              title="Delete Link"
              content="Are you sure you want to delete the link?"
              id={params.value.toString()}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/lawsuits/${id}/linked`)
      .then((response) => {
        setLinkedCases(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/lawsuits/${id}/linked`)
      .then((response) => {
        setLinkedCases(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/linked-lawsuits/${idToDelete}`)
      .then(() => {
        toast.success("Case link deleted");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="mb-20 overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Linked Cases" lawsuitID={id} />
        <div className="mb-3 mr-4 mt-2 text-right">
          <LinkCase caseID={id} saved={handleRefresh} />
        </div>
        <div className="m-2">
          <div className="ag-theme-alpine" style={{ width: "100%", height: 750 }}>
            <AgGridReact
              ref={gridRef}
              rowData={linkedCases}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              suppressCellFocus={true}
              rowHeight={50}
              overlayNoRowsTemplate={"No Linked Cases"}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
}
