import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { currency, longDate, percentage } from "../../libs/Utils";
import DeleteDialog from "../Dialogs/DeleteDialog";
import EditFundingApplication from "../SlideOvers/FundingApplications/EditFundingApplication";
import ApplicationStatus from "../SlideOvers/FundingApplications/ApplicationStatus";
import SendClientStateContract from "../SlideOvers/FundingApplications/SendClientStateContract";
import SendAccidentStateContract from "../SlideOvers/FundingApplications/SendAccidentStateContract";
import FundingApplicationNotes from "../SlideOvers/FundingApplications/FundingApplicationNotes";
import LinkToQuickbooks from "../SlideOvers/Cases/FundingApplication/LinkToQuickbooks";
import UnlinkFundingApplicationFromQuickbooks from "../Dialogs/FundingApplications/UnlinkFundingApplicationFromQuickbooksDialog";
import { client, clientError } from "../../libs/Client";

export default function FundingApplicationCard(props) {
  const [emailSent, setEmailSent] = useState(false);
  const [cardColor, setCardColor] = useState("bg-white border-0");
  const [canEditDelete, setCanEditDelete] = useState(false);
  const [showContractButtons, setShowContractButtons] = useState(false);
  const [connectedToQuickbooks, setConnectedToQuickbooks] = useState(false);

  useEffect(() => {
    setEmailSent(props.application.emailSent);
    if (props.application.status === "Processing" || props.application.status === "Rejected") {
      setCanEditDelete(true);
    } else {
      setCanEditDelete(false);
    }
    if (props.application.status === "Rejected") {
      setCardColor("bg-red-50 border-red-500 border-2");
    } else if (props.application.status === "Approved") {
      if (props.application.contracts.length === 0) {
        setShowContractButtons(true);
      }
      setCardColor("bg-blue-50 border-blue-500 border-2");
    } else if (props.application.status === "Ready to Fund") {
      setCardColor("bg-purple-50 border-purple-500 border-2");
    } else if (props.application.status === "Funded") {
      setCardColor("bg-green-50 border-green-500 border-2");
    }

    if (props.application.quickbooksID && props.application.quickbooksID !== "") {
      setConnectedToQuickbooks(true);
    }
  }, [
    props.application.emailSent,
    props.application.status,
    props.application.contracts.length,
    props.application.quickbooksID,
  ]);

  function handleDelete(idToDelete) {
    client()
      .delete(`/funding-applications/${idToDelete}`)
      .then(() => {
        props.refresh(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleSent(id) {
    client()
      .put(`/funding-applications/${id}/email-sent`, {})
      .then(() => {
        setEmailSent(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleNotSent(id) {
    client()
      .put(`/funding-applications/${id}/email-not-sent`, {})
      .then(() => {
        setEmailSent(false);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleUnlinkFromQuickbooks(idToUnlink) {
    client()
      .delete(`/funding-applications/${idToUnlink}/quickbooks`)
      .then(() => {
        props.refresh(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div className={`overflow-hidden ${cardColor} mt-2 shadow sm:rounded-lg`}>
      <div className="px-4 py-5 sm:flex sm:items-center sm:px-6">
        <div className="flex items-center sm:flex-auto">
          {!connectedToQuickbooks ? (
            <LinkToQuickbooks id={props.application.id} saved={props.refresh} />
          ) : (
            <UnlinkFundingApplicationFromQuickbooks id={props.application.id} idToUnlink={handleUnlinkFromQuickbooks} />
          )}
          <h3 className="ml-1 text-lg font-medium leading-6 text-gray-900">Funding Application</h3>
        </div>

        <div className="flex justify-end space-x-4">
          {showContractButtons && <SendAccidentStateContract id={props.application.id} sent={props.refresh} />}
          {showContractButtons && <SendClientStateContract id={props.application.id} sent={props.refresh} />}
          <ApplicationStatus id={props.application.id} status={props.application.status} saved={props.refresh} />
          {emailSent ? (
            <button
              type="button"
              className="ml-4 inline-flex items-center rounded-md border border-transparent bg-green-100 px-3 py-2 text-sm font-medium leading-4 text-green-700 shadow-sm hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              onClick={(e) => handleNotSent(props.application.id)}
            >
              <EnvelopeIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              Sent
            </button>
          ) : (
            <button
              type="button"
              className="ml-4 inline-flex items-center rounded-md border border-transparent bg-red-500 px-3 py-2 text-sm font-medium leading-4 text-red-100 shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-offset-2"
              onClick={(e) => handleSent(props.application.id)}
            >
              <EnvelopeIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              Not Sent
            </button>
          )}
          <FundingApplicationNotes id={props.application.id} total={props.application.notes.length} />
        </div>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-4">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Requested Amount</dt>
            <dd className="mt-1 text-sm text-gray-900">{currency(props.application.requestedAmount)}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Approved Amount</dt>
            <dd className="mt-1 text-sm text-gray-900">{currency(props.application.approvedAmount)}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Total One Time Fees</dt>
            <dd className="mt-1 text-sm text-gray-900">{currency(props.application.totalOneTimeFees)}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Payoff Amount</dt>
            <dd className="mt-1 text-sm text-gray-900">{currency(props.application.payoffAmount)}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Total Percentage</dt>
            <dd className="mt-1 text-sm text-gray-900">{percentage(props.application.totalPercent)}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Overnight Check</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.application.overnightCheck ? "Yes" : "No"}</dd>
          </div>
          {props.application.buyoutCompany ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Funding Company</dt>
              <dd className="mt-1 text-sm text-gray-900">{props.application.buyoutCompany.name}</dd>
            </div>
          ) : null}
          {props.application.buyoutAmount ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Amount</dt>
              <dd className="mt-1 text-sm text-gray-900">{currency(props.application.buyoutAmount)}</dd>
            </div>
          ) : null}
          {props.application.buyoutFee ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Fee</dt>
              <dd className="mt-1 text-sm text-gray-900">{percentage(props.application.buyoutFee)}</dd>
            </div>
          ) : null}
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Created</dt>
            <dd className="mt-1 text-sm text-gray-900">{longDate(props.application.createdAt)}</dd>
          </div>
          {props.application.approvedAt ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Approved</dt>
              <dd className="mt-1 text-sm text-gray-900">{longDate(props.application.approvedAt)}</dd>
            </div>
          ) : null}
          {props.application.rejectedAt ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Rejected</dt>
              <dd className="mt-1 text-sm text-gray-900">{longDate(props.application.rejectedAt)}</dd>
            </div>
          ) : null}
        </dl>
        <div className="mt-8 flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Fee
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {props.application.fees.map((fee) => (
                      <tr key={fee.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {fee.label}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {fee.annual ? "Annual" : "One Time"}
                        </td>
                        {fee.percentage ? (
                          <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                            {percentage(fee.percentage)}
                          </td>
                        ) : null}
                        {fee.amount ? (
                          <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                            {currency(fee.amount)}
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="px-4 py-5 sm:flex sm:items-center sm:px-6">
        {canEditDelete ? (
          <div className="flex-auto">
            <DeleteDialog
              title="Delete Funding Application"
              content="Are you sure you want to delete the funding application"
              id={props.application.id}
              idToDelete={handleDelete}
            />
          </div>
        ) : null}
        {canEditDelete ? (
          <div className="text-right">
            <EditFundingApplication
              id={props.application.id}
              plaintiffStateID={props.plaintiffStateID}
              accidentStateID={props.accidentStateID}
              saved={props.refresh}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

FundingApplicationCard.propTypes = {
  application: PropTypes.object,
  plaintiffStateID: PropTypes.string,
  accidentStateID: PropTypes.string,
  quickbooksID: PropTypes.string,
  refresh: PropTypes.func,
};
