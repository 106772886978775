import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../datagrid.css";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

import { apiError, dateComparator } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

import CaseDetailHeader from "../../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseOverviewTabs from "../../../layouts/FullLayout/Tabs/CaseOverviewTabs";
import RequestMedicalReleaseForm from "../../../components/SlideOvers/Cases/RequestMedicalReleaseForm";
import DeleteDialog from "../../../components/Dialogs/DeleteDialog";

const { REACT_APP_API_URL } = process.env;

export default function CaseOverviewMedicalReleaseForms() {
  const { id } = useParams();
  const gridRef = useRef();
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - Overview - Medical Release Forms";
  });

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/documents?lawsuitID=${id}&section=Medical Release Form`)
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/documents/${idToDelete}`)
      .then(() => {
        toast.success("Document deleted");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleClickDownload(documentID) {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/documents/${documentID}/download`)
      .then((response) => {
        if (response.status === 204) {
          toast.error("Document does not exist");
          return;
        }

        window.location.href = response.data;
      })
      .catch((err) => {
        apiError(err);
      });
  }

  const [columnDefs] = useState([
    {
      field: "originalName",
      headerName: "Document",
      minWidth: 450,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "createdAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Created",
      minWidth: 200,
      cellRenderer: (params) => {
        return moment(params.data.createdAt).fromNow();
      },
    },
    {
      field: "updatedAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Updated",
      minWidth: 200,
      cellRenderer: (params) => {
        return moment(params.data.createdAt).fromNow();
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      sortable: false,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            <button
              className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
              onClick={() => handleClickDownload(params.value)}
            >
              <DocumentArrowDownIcon className="h-4 w-4 text-indigo-500" />
            </button>
            <DeleteDialog
              buttonTitle="Delete"
              title="Delete Document?"
              content="Are you sure you want to delete the document?"
              id={params.value}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/documents?lawsuitID=${id}&section=Medical Release Form`)
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Case Overview" lawsuitID={id} />
        <div className="ml-8 mr-8">
          <CaseOverviewTabs lawsuitID={id} selected="Medical Release Forms" />
        </div>
        <div className="mb-8 mt-2 flex flex-col ">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 350 }}>
              <AgGridReact
                ref={gridRef}
                rowData={documents}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
                overlayNoRowsTemplate={"No documents on file"}
              ></AgGridReact>
            </div>
            <div className="float-right mt-4">
              <RequestMedicalReleaseForm saved={handleRefresh} id={id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
