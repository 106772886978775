export const accidentInvolvingOptions = [
  { value: "Self", label: "Self" },
  { value: "Someone Else", label: "Someone Else" },
];

export const missedWorkOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "N/A", label: "N/A" },
];

export const yesNoOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const yesNoUnknownOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Unknown", label: "Unknown" },
];

export const driverPassengerOptions = [
  { value: "Driver", label: "Driver" },
  { value: "Passenger", label: "Passenger" },
  { value: "Pedestrian", label: "Pedestrian" },
];

export const genderOptions = [
  { value: "Female", label: "Female" },
  { value: "Male", label: "Male" },
  { value: "Unknown", label: "Unkown" },
];

export const referralOptions = [
  { value: "Attorney Referral", label: "Attorney Referral" },
  {
    value: "Internet Search/Online Advertising",
    label: "Internet Search/Online Advertising",
  },
  { value: "CompareLawsuitLoans.com", label: "CompareLawsuitLoans" },
  { value: "Friend Referral", label: "Friend Referral" },
  { value: "Chiropractor Referral", label: "Chiropractor Referral" },
  { value: "Medical Referral", label: "Medical Referral" },
  { value: "Repeat Client", label: "Repeat Client" },
  { value: "Walk-In", label: "Walk-In" },
  { value: "Other", label: "Other" },
];

export const serviceProviderTypes = [
  { value: "Insurance", label: "Insurance" },
  { value: "Law Firm", label: "Law Firm" },
];
