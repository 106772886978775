import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { setAuthToken } from "../../hooks/Authentication";
import { dateComparator, apiError } from "../../libs/Utils";

import TemplateTabs from "../../layouts/FullLayout/Tabs/TemplateTabs";
import PageHeader from "../../components/PageHeader";
import AddHippaTemplate from "../../components/SlideOvers/Templates/AddHippaTemplate";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import EditHippaTemplate from "../../components/SlideOvers/Templates/EditHippaTemplate";

const { REACT_APP_API_URL } = process.env;

export default function HippaTemplates() {
  const gridRef = useRef();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Admin - HIPPA Templates";
  });

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const [columnDefs] = useState([
    {
      field: "title",
      filter: true,
      minWidth: 500,
      headerName: "Template",
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "createdAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Created",
      minWidth: 200,
      cellRenderer: (params) => {
        return moment(params.data.createdAt).fromNow();
      },
    },
    {
      field: "updatedAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Updated",
      minWidth: 200,
      cellRenderer: (params) => {
        return moment(params.data.updatedAt).fromNow();
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            <EditHippaTemplate tooltip="Edit Template" id={params.data.id} saved={handleRefresh} />
            <DeleteDialog
              tooltip="Delete Template"
              title="Delete Template"
              content="Are you sure you want to delete the template?"
              id={params.data.id}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/templates/hippa`)
      .then((response) => {
        setTemplates(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/templates/hippa/${idToDelete}`)
      .then(() => {
        toast.success("Template deleted");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/templates/hippa`)
      .then((response) => {
        setTemplates(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <PageHeader title="Templates" subtitle="Manage Templates" />
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <AddHippaTemplate saved={handleRefresh} />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
          <TemplateTabs selected="HIPPA" />
          <div className="mb-8 ml-2 mr-2">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 750 }}>
              <AgGridReact
                ref={gridRef}
                rowData={templates}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
                overlayNoRowsTemplate={"No HIPPA Templates"}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
