import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { apiError, longDate } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

import CaseDetailHeader from "../../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseOverviewTabs from "../../../layouts/FullLayout/Tabs/CaseOverviewTabs";
import EditLiability from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditLiability";
import EditPip from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditPip";
import EditOtherLiens from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditOtherLiens";
import EditMedicalBills from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditMedicalBills";
import EditInjuries from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditInjuires";
import EditTreatment from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditTreatment";
import EditOtherOffers from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditOtherOffers";
import EditOccupantType from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditOccupantType";
import EditAtFaultVehicle from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditAtFaultVehicle";
import EditVehicleDamage from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditVehicleDamage";
import EditInsuranceCompanies from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditInsuranceCompanies";
import EditInsuranceLimits from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditInsuranceLimits";
import EditMinor from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditMinor";
import EditMinorName from "../../../components/SlideOvers/Cases/UnderwritingDetails/EditMinorName";

const { REACT_APP_API_URL } = process.env;

export default function CaseOverviewUnderwritingDetails() {
  const { id } = useParams();
  const [liability, setLiability] = useState("");
  const [pip, setPip] = useState("");
  const [liens, setLiens] = useState("");
  const [medicalBills, setMedicalBills] = useState("");
  const [injuries, setInjuries] = useState("");
  const [treatment, setTreatment] = useState("");
  const [offers, setOffers] = useState("");
  const [occupantType, setOccupantType] = useState("");
  const [atFaultVehicle, setAtFaultVehicle] = useState("");
  const [vehicleDamage, setVehicleDamage] = useState("");
  const [insuranceCompanies, setInsuranceCompanies] = useState("");
  const [insuranceLimits, setInsuranceLimits] = useState("");
  const [minor, setMinor] = useState("");
  const [minorName, setMinorName] = useState("");
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState("");

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - Underwriting Details";
    setAuthToken();
    Promise.all([
      axios.get(`${REACT_APP_API_URL}/lawsuits/${id}/underwriting`),
      axios.get(`${REACT_APP_API_URL}/notes?lawsuitID=${id}&section=Underwriting`),
    ])
      .then((response) => {
        const underwritingData = response[0].data;
        if (underwritingData.isUnderwritten) {
          setLiability(underwritingData.details.liability);
          setPip(underwritingData.details.pip);
          setLiens(underwritingData.details.liens);
          setMedicalBills(underwritingData.details.medicalBills);
          setInjuries(underwritingData.details.injuries);
          setTreatment(underwritingData.details.treatment);
          setOffers(underwritingData.details.offers);
          setOccupantType(underwritingData.details.occupantType);
          setAtFaultVehicle(underwritingData.details.atFaultVehicle);
          setVehicleDamage(underwritingData.details.vehicleDamage);
          setInsuranceCompanies(underwritingData.details.insuranceCompanies);
          setInsuranceLimits(underwritingData.details.insuranceLimits);
          setMinor(underwritingData.details.minor);
          setMinorName(underwritingData.details.minorName);
        }

        setNotes(response[1].data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  function handleRefresh() {
    Promise.all([
      axios.get(`${REACT_APP_API_URL}/lawsuits/${id}/underwriting`),
      axios.get(`${REACT_APP_API_URL}/notes?lawsuitID=${id}&section=Underwriting`),
    ])
      .then((response) => {
        const underwritingData = response[0].data;
        if (underwritingData.isUnderwritten) {
          setLiability(underwritingData.details.liability);
          setPip(underwritingData.details.pip);
          setLiens(underwritingData.details.liens);
          setMedicalBills(underwritingData.details.medicalBills);
          setInjuries(underwritingData.details.injuries);
          setTreatment(underwritingData.details.treatment);
          setOffers(underwritingData.details.offers);
          setOccupantType(underwritingData.details.occupantType);
          setAtFaultVehicle(underwritingData.details.atFaultVehicle);
          setVehicleDamage(underwritingData.details.vehicleDamage);
          setInsuranceCompanies(underwritingData.details.insuranceCompanies);
          setInsuranceLimits(underwritingData.details.insuranceLimits);
          setMinor(underwritingData.details.minor);
          setMinorName(underwritingData.details.minorName);
        }
        setNotes(response[1].data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleSaveNote() {
    if (note === "") {
      toast.error("You need to enter some text for the note");
      return;
    }

    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/notes`, {
        lawsuitID: parseInt(id),
        text: note,
        section: "Underwriting",
      })
      .then(() => {
        setNote("");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="mb-4 overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Case Overview" lawsuitID={id} />
        <div className="ml-8 mr-8">
          <CaseOverviewTabs lawsuitID={id} selected="Underwriting Details" />
        </div>
        <div className="mb-4 mt-2">
          <dl className="ml-8 mr-8 divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Liability</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{liability}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditLiability id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">PIP</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{pip}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditPip id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Other Liens</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{liens}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditOtherLiens id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Medical Bills</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{medicalBills}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditMedicalBills id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Injuries</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{injuries}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditInjuries id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Treatment</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{treatment}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditTreatment id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Other Offers</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{offers}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditOtherOffers id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Occupant Type</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{occupantType}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditOccupantType id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">At Fault Vehicle</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{atFaultVehicle}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditAtFaultVehicle id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Vehicle Damage</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{vehicleDamage}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditVehicleDamage id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Insurance Companies</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{insuranceCompanies}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditInsuranceCompanies id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Insurance Limits</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{insuranceLimits}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditInsuranceLimits id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Is this request for a minor child</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{minor ? "Yes" : "No"}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditMinor id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            {minor && (
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">Minor Name</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">{minorName}</span>
                  <span className="ml-4 flex-shrink-0">
                    <EditMinorName id={id} saved={handleRefresh} />
                  </span>
                </dd>
              </div>
            )}
          </dl>
        </div>
        <div className="ml-8 mr-8 mt-2 text-lg font-medium">Notes</div>
        <div className="m-4 px-4">
          <textarea
            rows="5"
            name="note"
            id="note"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          ></textarea>
        </div>
        <div className="m-2 text-right">
          <button
            type="button"
            className="mr-6 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
            onClick={handleSaveNote}
          >
            Save
          </button>
        </div>
        <div className="m-2">
          <hr />
        </div>
        <div className="mb-10 mt-5">
          {notes.length > 0 ? (
            <ul className="m-10">
              {notes.map((note) => (
                <li key={note.id} className="m-10 rounded-lg bg-gray-100 p-6 py-4">
                  <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <h3 className="text-sm font-medium">
                          {note.user.firstName} {note.user.lastName}
                        </h3>
                        <p className="text-sm text-gray-400">{longDate(note.createdAt)}</p>
                      </div>
                      <p className="text-sm text-gray-500">{note.text}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center">
              <p className="text-sm text-gray-500">No Notes</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
