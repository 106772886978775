import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import parsePhoneNumber from "libphonenumber-js";
import { shortDate } from "../../libs/Utils";
import { client, clientError } from "../../libs/Client";

import AddTask from "../SlideOvers/Tasks/AddTask";
import AddAppointment from "../SlideOvers/Cases/AddApointment";
import CloseCase from "../SlideOvers/Cases/CloseCase";
import LockFunding from "../SlideOvers/Cases/FundingLock/LockFunding";
import PartialPayment from "../SlideOvers/Cases/PartialPayment";
import ReferOut from "../SlideOvers/Cases/ReferOut";
import ReopenCaseDialog from "../Dialogs/ReopenCaseDialog";
import UnlockFunding from "../SlideOvers/Cases/FundingLock/UnlockFunding";

const { REACT_APP_EMAIL_DOMAIN } = process.env;

export default function CaseDetailHeader(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [serviceProviderType] = useState("Provider");
  const [serviceProviderID, setServiceProviderID] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [serviceProviderContact, setServiceProviderContact] = useState("");
  const [accidentDate, setAccidentDate] = useState(null);
  const [accidentType, setAccidentType] = useState(null);
  const [serviceProviderContactRole, setServiceProviderContactRole] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("Unfunded");
  const [accidentState, setAccidentState] = useState("");
  const [state, setState] = useState("");
  const [fundingLocked, setFundingLocked] = useState(false);
  const [partialPayment, setPartialPayment] = useState(false);
  const [flagged, setFlagged] = useState(false);
  const [referralDate, setReferralDate] = useState("");
  const [closedAt, setClosedAt] = useState(null);

  useEffect(() => {
    client()
      .get(`/lawsuits/${props.lawsuitID}/header`)
      .then((res) => {
        setFirstName(res.data.firstName);
        setLastName(res.data.lastName);
        setFundingLocked(res.data.fundingLocked);
        if (res.data.phone) {
          setPhoneNumber(parsePhoneNumber(res.data.phone, "US").formatNational());
        }
        setServiceProvider(res.data.serviceProvider);
        setServiceProviderID(res.data.serviceProviderID);
        setServiceProviderContact(res.data.primaryContact);
        setServiceProviderContactRole(res.data.primaryContactRole);
        setAssignedTo(res.data.assignedTo);
        setAccidentDate(res.data.accidentDate);
        setAccidentType(res.data.accidentType);
        setStatus(res.data.status);
        setState(res.data.clientState);
        setAccidentState(res.data.accidentState);
        setFlagged(res.data.flagged);
        if (res.data.referralDate) {
          setReferralDate(res.data.referralDate);
        }
        setClosedAt(res.data.closedAt);
        if (res.data.payments > 0 && res.data.status !== "Closed") {
          setPartialPayment(true);
        } else {
          setPartialPayment(false);
        }
      })
      .catch((err) => {
        clientError(err);
      });
  }, [props.lawsuitID]);

  function handleCloseCase() {
    window.location.reload();
  }

  function handlePartialPayment() {
    window.location.reload();
  }

  function handleFundingLocked() {
    setFundingLocked((prev) => !prev);
  }

  function handleRefresh() {
    window.location.reload();
  }

  function handleReopen(idToReopen) {
    client()
      .put(`/lawsuits/${idToReopen}/reopen`)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function fundingStatus() {
    if (status === "Funded") {
      return (
        <span className="float-right inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-500">
          Funded
        </span>
      );
    } else if (status === "Closed") {
      return (
        <span className="float-right inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-500">
          Closed
        </span>
      );
    } else if (status === "Referred Out") {
      return (
        <span className="float-right inline-flex items-center rounded-full bg-orange-100 px-3 py-0.5 text-sm font-medium text-orange-500">
          Referred Case
        </span>
      );
    } else {
      return (
        <span className="float-right inline-flex items-center rounded-full bg-yellow-100 px-3 py-0.5 text-sm font-medium text-yellow-500">
          {status}
        </span>
      );
    }
  }

  return (
    <div className="lg:sticky top-16 z-10 overflow-hidden bg-white shadow">
      <div className="bg-white p-3">
        {fundingStatus()}
        <ul className="grid grid-cols-2">
          <li>
            <div className="text-sm font-medium sm:flex sm:space-x-5">
              Case #:
              <span className="ml-1 font-light text-gray-500">{props.lawsuitID}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-medium sm:flex sm:space-x-5">
              Assigned To:
              <span className="ml-1 font-light text-gray-500">{assignedTo}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-medium sm:flex sm:space-x-5">
              Plaintiff:
              <span className="ml-1 font-light text-gray-500">
                {firstName} {lastName}
              </span>
            </div>
          </li>
          <li>
            <div className="text-sm font-medium sm:flex sm:space-x-5">
              {serviceProviderType}:
              <span className="ml-1 font-light text-gray-500">
                {flagged ? (
                  <a
                    href={`/service-providers/${serviceProviderID}/overview`}
                    className="text-red-500 hover:text-red-700"
                  >
                    {serviceProvider}
                  </a>
                ) : (
                  <a
                    href={`/service-providers/${serviceProviderID}/overview`}
                    className="text-indigo-500 hover:text-indigo-700"
                  >
                    {serviceProvider}
                  </a>
                )}
              </span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Client Phone #:
              <span className="ml-1 font-light text-gray-500">
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Primary Contact:
              <span className="ml-1 font-light text-gray-500">{serviceProviderContact}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Accident Date:
              <span className="ml-1 font-light text-gray-500">{shortDate(accidentDate)}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Primary Contact Role:
              <span className="ml-1 font-light text-gray-500">{serviceProviderContactRole}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Accident Type:
              <span className="ml-1 font-light text-gray-500">{accidentType}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Case Email:
              <span className="ml-1 font-light text-gray-500">
                case{props.lawsuitID}@{REACT_APP_EMAIL_DOMAIN}
              </span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Accident State:
              <span className="ml-1 font-light text-gray-500">{accidentState}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Client State:
              <span className="ml-1 font-light text-gray-500">{state}</span>
            </div>
          </li>
        </ul>
      </div>
      {!props.removeButtons && (
        <div className="grid grid-cols-1 sm:grid-cols-6 sm:divide-x sm:divide-y-0">
          <AddTask saved={handleRefresh} lawsuitID={props.lawsuitID} />
          <AddAppointment />
          {status === "Referred Out" ? (
            <ReferOut id={props.lawsuitID} savedReferral={handleRefresh} disabled />
          ) : (
            <ReferOut id={props.lawsuitID} savedReferral={handleRefresh} />
          )}

          {fundingLocked ? (
            <UnlockFunding id={props.lawsuitID} saved={handleFundingLocked} />
          ) : (
            <LockFunding id={props.lawsuitID} saved={handleFundingLocked} />
          )}

          <PartialPayment id={props.lawsuitID} saved={handlePartialPayment} />
          {status === "Closed" ? (
            <ReopenCaseDialog id={props.lawsuitID} idToReopen={handleReopen} />
          ) : (
            <CloseCase id={props.lawsuitID} saved={handleCloseCase} />
          )}
        </div>
      )}
      {fundingLocked ? (
        <div className="min-w-full bg-yellow-100 p-4 text-center font-semibold text-yellow-500">FUNDING LOCKED</div>
      ) : null}
      {status === "Referred Out" ? (
        <div className="min-w-full bg-orange-200 p-4 text-center font-semibold text-orange-500">
          REFERRED CASE {shortDate(referralDate)}
        </div>
      ) : null}
      {status === "Closed" ? (
        <div className="min-w-full bg-red-100 p-4 text-center font-semibold text-red-500">
          CASE CLOSED {shortDate(closedAt)}
        </div>
      ) : null}
      {partialPayment ? (
        <div className="min-w-full bg-slate-500 p-4 text-center font-semibold text-white">PARTIAL PAYMENT</div>
      ) : null}
    </div>
  );
}

CaseDetailHeader.propTypes = {
  lawsuitID: PropTypes.string.isRequired,
  referredOut: PropTypes.bool,
  removeButtons: PropTypes.bool,
  refresh: PropTypes.func,
};
