import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Dialog, Transition, Switch } from "@headlessui/react";
import ReactSelect from "react-select";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { classNames, nationalPhoneNumber, parseAddress, shortDate, percentage, currency } from "../../../libs/Utils";
import { client, clientError } from "../../../libs/Client";

export default function SendAccidentStateContract(props) {
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [caseNumber, setCaseNumber] = useState("");
  const [plaintiff, setPlaintiff] = useState("");
  const [plaintiffEmail, setPlaintiffEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [accidentDate, setAccidentDate] = useState("");
  const [accidentState, setAccidentState] = useState("");
  const [accidentType, setAccidentType] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");
  const [primaryContactEmail, setPrimaryContactEmail] = useState("");
  const [approvedAmount, setApprovedAmount] = useState(0);
  const [payoff, setPayoff] = useState(0);
  const [fees, setFees] = useState([]);
  const [totalOneTimeFees, setTotalOneTimeFees] = useState(0);
  const [totalPercent, setTotalPercent] = useState(0);
  const [contractTemplates, setContractTemplates] = useState([]);
  const [contractTemplate, setContractTemplate] = useState(null);
  const [companyRepresentatives, setCompanyRepresentatives] = useState([]);
  const [companyRepresentativeID, setCompanyRepresentativeID] = useState(null);
  const [providerSignatureRequired, setProviderSignatureRequired] = useState(false);

  useEffect(() => {
    if (open) {
      Promise.all([client().get(`/users/signatories`), client().get(`/funding-applications/${props.id}`)])
        .then((res) => {
          setCompanyRepresentatives(res[0].data);
          const lawsuit = res[1].data.lawsuit;
          const baseFees = res[1].data.fees;
          setCaseNumber(lawsuit.id);
          setPlaintiff(lawsuit.firstName + " " + lawsuit.lastName);
          setPlaintiffEmail(lawsuit.email);
          setPhoneNumber(lawsuit.phone);
          setAddress(parseAddress(lawsuit));
          setAccidentDate(lawsuit.accidentDate);
          setAccidentState(lawsuit.accidentState.label);
          setAccidentType(lawsuit.accidentType);
          setServiceProvider(lawsuit.serviceProvider.name);
          setPrimaryContact(lawsuit.serviceProviderContact.firstName + " " + lawsuit.serviceProviderContact.lastName);
          setPrimaryContactEmail(lawsuit.serviceProviderContact.email);
          setApprovedAmount(res[1].data.approvedAmount + res[1].data.buyoutAmount);
          setPayoff(res[1].data.payoffAmount);
          var theFees = [];
          baseFees.forEach((baseFee) => {
            theFees.push({ label: baseFee.label, amount: baseFee.amount, percentage: baseFee.percentage });
          });
          if (res[1].data.buyoutFee) {
            theFees.push({ label: "Buyout Fee", amount: null, percentage: res[1].data.buyoutFee });
          }
          setFees(theFees);
          setTotalOneTimeFees(res[1].data.totalOneTimeFees);
          setTotalPercent(res[1].data.totalPercent);
          client()
            .get(`/templates/contracts?accidentStateID=${res[1].data.lawsuit.accidentState.id}`)
            .then((res) => {
              setContractTemplates(
                res.data.map((element) => ({
                  value: element.id,
                  label: element.label,
                }))
              );
            })
            .catch((err) => {
              clientError(err);
            });
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [open, props.id]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleSelectionChange(event) {
    setCompanyRepresentativeID(event.target.id);
  }

  function handleSetContract(data) {
    setContractTemplate(data);
    if (data !== null) {
      client()
        .get(`/templates/contracts/${data.value}`)
        .then((res) => {
          setProviderSignatureRequired(res.data.providerSignature);
        })
        .catch((err) => {
          clientError(err);
        });
    } else {
      setProviderSignatureRequired(false);
    }
  }

  function handleSave() {
    if (!contractTemplate) {
      toast.error("You need to select a contract template");
      return;
    }

    if (companyRepresentativeID === "") {
      toast.error("You need to select a company representative");
      return;
    }

    setProcessing(true);
    const data = {
      lawsuitID: caseNumber,
      companyRepresentativeID,
      fundingApplicationID: props.id,
      contractTemplateID: contractTemplate.value,
      plaintiffSignatureRequired: true,
      plaintiffInitialsRequired: true,
      providerSignatureRequired,
    };

    client()
      .post(`/funding-application-contracts`, data)
      .then(() => {
        setProcessing(false);
        setOpen(false);
        props.sent(true);
      })
      .catch((err) => {
        setProcessing(false);
        clientError(err);
      });
  }

  return (
    <div>
      <button
        type="button"
        className="ml-4 inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-500 shadow-sm hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={handleClickOpen}
      >
        <DocumentTextIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        Accident State Contract
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Accident State Contract
                            </Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Case #</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{caseNumber}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Plaintiff Name</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{plaintiff}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Plaintiff Email Address</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{plaintiffEmail}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Plaintiff Phone #</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{nationalPhoneNumber(phoneNumber)}</dd>
                                </div>
                                <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{address}</dd>
                                </div>
                              </dl>
                              <hr />
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Accident Date</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{shortDate(accidentDate)}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Accident State</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{accidentState}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Accident Type</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{accidentType}</dd>
                                </div>
                              </dl>
                              <hr />
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Service Provider</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{serviceProvider}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Primary Contact</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{primaryContact}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Primary Contact Email</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{primaryContactEmail}</dd>
                                </div>
                              </dl>
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">ApprovedAmount</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{currency(approvedAmount)}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-sm font-medium text-gray-500">Payoff</dt>
                                  <dd className="mt-1 text-sm text-gray-900">{currency(payoff)}</dd>
                                </div>
                              </dl>
                              <div className="mt-2">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                      >
                                        Fee
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                                      >
                                        $
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                                      >
                                        %
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {fees.map((fee, idx) => (
                                      <tr key={idx}>
                                        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                          {fee.label}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                                          {fee.amount ? currency(fee.amount) : null}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                                          {fee.percentage ? percentage(fee.percentage) : null}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Total Fees
                                      </td>
                                      <td className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                        {currency(totalOneTimeFees)}
                                      </td>
                                      <td className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                        {percentage(totalPercent)}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                              <hr />
                              <div>
                                <Switch.Group as="div" className="flex items-center justify-between">
                                  <span className="flex flex-grow flex-col">
                                    <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                      Primary Service Provider Contact Signature
                                    </Switch.Label>
                                    <Switch.Description
                                      as="span"
                                      className="text-sm text-gray-500"
                                    ></Switch.Description>
                                  </span>
                                  <Switch
                                    checked={providerSignatureRequired}
                                    onChange={setProviderSignatureRequired}
                                    className={classNames(
                                      providerSignatureRequired ? "bg-brand-700" : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        providerSignatureRequired ? "translate-x-5" : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                              <div>
                                <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                  Contract
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect
                                    options={contractTemplates}
                                    isClearable
                                    onChange={(data) => handleSetContract(data)}
                                  />
                                </div>
                              </div>

                              <div>
                                <label className="text-base font-medium text-gray-900">Company Representative</label>
                                <p className="text-sm leading-5 text-gray-500">User who will be signing contract</p>
                                <fieldset className="mt-4">
                                  <div className="space-y-4">
                                    {companyRepresentatives.map((user) => (
                                      <div key={user.id} className="flex items-center">
                                        <input
                                          id={user.id}
                                          name="companyRepresentative"
                                          type="radio"
                                          value={companyRepresentativeID === user.id}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                          onChange={handleSelectionChange}
                                        />
                                        <label
                                          htmlFor={user.id}
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          {user.firstName} {user.lastName}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {processing ? (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="cursor-not-allowed rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex cursor-not-allowed justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-150 ease-in-out hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            <svg
                              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Sending...
                          </button>
                        </div>
                      ) : (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={handleSave}
                          >
                            Send
                          </button>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

SendAccidentStateContract.propTypes = {
  id: PropTypes.string.isRequired,
  sent: PropTypes.func,
};
