import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { classNames } from "../../../libs/Utils";

export default function TemplateTabs(props) {
  const navigate = useNavigate();
  var tabs = [];
  if (props.selected === "Campaigns") {
    tabs.push({
      name: "Campaigns",
      href: "/admin/templates/campaigns",
      current: true,
    });
  } else {
    tabs.push({
      name: "Campaigns",
      href: "/admin/templates/campaigns",
      current: false,
    });
  }

  if (props.selected === "Contracts") {
    tabs.push({
      name: "Contracts",
      href: "/admin/templates/contracts",
      current: true,
    });
  } else {
    tabs.push({
      name: "Contracts",
      href: "/admin/templates/contracts",
      current: false,
    });
  }

  if (props.selected === "Email") {
    tabs.push({ name: "Email", href: "/admin/templates/email", current: true });
  } else {
    tabs.push({
      name: "Email",
      href: "/admin/templates/email",
      current: false,
    });
  }

  if (props.selected === "SMS") {
    tabs.push({ name: "SMS", href: "/admin/templates/sms", current: true });
  } else {
    tabs.push({
      name: "SMS",
      href: "/admin/templates/sms",
      current: false,
    });
  }

  if (props.selected === "HIPPA") {
    tabs.push({ name: "HIPPA", href: "/admin/templates/hippa", current: true });
  } else {
    tabs.push({
      name: "HIPPA",
      href: "/admin/templates/hippa",
      current: false,
    });
  }

  function handleChange(url) {
    navigate(url);
  }

  return (
    <div className="p-2">
      {/* Tabs */}
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-700 focus:border-brand-700 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(e) => handleChange(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "border-brand-700 text-brand-700"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

TemplateTabs.propTypes = {
  selected: PropTypes.string.isRequired,
};
