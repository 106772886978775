import React, { useEffect, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import parsePhoneNumber from "libphonenumber-js";
import { client, clientError } from "../../libs/Client";
import { dateFromNow, minHeight } from "../../libs/Utils";
import LeadTabs from "../../layouts/FullLayout/Tabs/LeadTabs";

export default function CreatedLeads() {
  const gridStyle = { minHeight: minHeight };

  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/leads?status=Created&skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`)
      .then((res) => {
        return { data: res.data.leads, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };
  const dataSource = useCallback(loadData, []);

  useEffect(() => {
    document.title = "Capital Now Funding - Leads - Created";
  });

  const columns = [
    {
      name: "lawsuit",
      minWidth: 150,
      header: "Case #",
      render: ({ data }) => {
        return (
          <a className="text-brand-700 hover:text-brand-500" href={`/cases/${data.lawsuit.id}/feed`}>
            {data.lawsuit.id}
          </a>
        );
      },
      textAlign: "end",
      headerAlign: "end",
    },
    {
      name: "id",
      minWidth: 150,
      header: "Reference #",
      textAlign: "end",
      headerAlign: "end",
    },
    {
      name: "firstName",
      header: "First Name",
      minWidth: 200,
    },
    {
      name: "lastName",
      header: "Last Name",
      minWidth: 200,
    },
    {
      name: "email",
      header: "Email",
      minWidth: 200,
    },
    {
      name: "phone",
      header: "Phone",
      minWidth: 200,
      render: ({ value }) => {
        if (value && value !== "") {
          return parsePhoneNumber(value, "US").formatNational();
        } else {
          return "";
        }
      },
    },
    {
      name: "lawFirm",
      header: "Law Firm",
      minWidth: 200,
    },
    {
      name: "state",
      minWidth: 200,
      header: "State",
      render: ({ data }) => {
        return data.state.label;
      },
    },
    {
      name: "createdAt",
      minWidth: 200,
      header: "Created",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
  ];

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <LeadTabs selected="Created" />
        <ReactDataGrid
          idProperty="createdLeads"
          showColumnMenuTool={false}
          columns={columns}
          style={gridStyle}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
