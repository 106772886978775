import React, { useState, useEffect, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { CheckIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { client, clientError } from "../../libs/Client";
import { minHeight, dateFromNow } from "../../libs/Utils";
import MarkAllReadDialog from "../../components/Dialogs/MarkAllReadDialog";
import NotificationTabs from "../../layouts/FullLayout/Tabs/NotificationTabs";

export default function UnreadNotifications() {
  const [allRead, setAllRead] = useState(false);

  useEffect(() => {
    document.title = "Capital Now Funding - Notifications - Unread";
  });

  const gridStyle = { minHeight: minHeight };

  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/notifications/unread?skip=${skip}&limit=${limit}`)
      .then((res) => {
        if (res.data.notifications.length === 0) {
          setAllRead(true);
        } else {
          setAllRead(false);
        }
        return { data: res.data.notifications, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, []);

  const columns = [
    {
      name: "lawsuitID",
      defaultWidth: 150,
      header: "Case #",
      render: ({ value }) => {
        return (
          <a className="text-brand-700 hover:text-brand-500" href={`/cases/${value}/feed`}>
            {value}
          </a>
        );
      },
    },
    {
      name: "resource",
      header: "Resource",
      defaultWidth: 250,
    },
    {
      name: "plaintiff",
      header: "Plaintiff",
      defaultWidth: 200,
    },
    {
      name: "serviceProvider",
      header: "Service Provider",
      defaultWidth: 200,
    },
    {
      name: "message",
      defaultWidth: 350,
      header: "Notification",
    },
    {
      name: "lawsuitStatus",
      defaultWidth: 200,
      header: "Case Status",
    },
    {
      name: "assignedTo",
      defaultWidth: 200,
      header: "Assigned To",
    },
    {
      name: "createdAt",
      header: "Created",
      defaultWidth: 200,
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "id",
      header: "",
      minWidth: 75,
      maxWidth: 75,
      render: ({ value }) => {
        return (
          <div className="flex justify-end space-x-4">
            <Tippy content="Mark Read">
              <button
                className="inline-flex rounded border-transparent bg-emerald-100 px-2.5 py-1.5 text-xs font-medium hover:bg-emerald-200 focus:outline-none"
                onClick={() => handleMarkRead(value)}
              >
                <CheckIcon className="h-4 w-4 text-indigo-500" />
              </button>
            </Tippy>
          </div>
        );
      },
    },
  ];

  function handleMarkRead(id) {
    client()
      .put(`/notifications/${id}/read`)
      .then(() => {
        client()
          .get(`/notifications/unread`)
          .then((res) => {
            window.location.reload();
          })
          .catch((err) => {
            clientError(err);
          });
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleMarkAllRead() {
    client()
      .put(`/notifications/read`)
      .then(() => {
        client()
          .get(`/notifications/unread`)
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            clientError(err);
          });
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <NotificationTabs selected="Unread" />
        {!allRead ? <MarkAllReadDialog read={handleMarkAllRead} /> : null}

        <ReactDataGrid
          idProperty="unreadNotifications"
          columns={columns}
          style={gridStyle}
          showColumnMenuTool={false}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
