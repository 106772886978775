import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { client, clientError } from "../../../libs/Client";

export default function NoApplications() {
  const [open, setOpen] = useState(false);
  const [cases, setCases] = useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      client()
        .get(`/lawsuits/empty-applications`)
        .then((res) => {
          setCases(res.data);
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [open]);

  return (
    <div>
      <Tippy content="Cases without Applications">
        <button className="-m-2.5 p-2.5 text-orange-400 hover:text-orange-700" onClick={handleClickOpen}>
          <DocumentTextIcon className="h-6 w-6 animate-bounce " aria-hidden="true" />
        </button>
      </Tippy>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Cases without Applications
                            </Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            {cases.map((element) => (
                              <div key={element.id} className="space-y-6 whitespace-pre pb-5 pt-6">
                                <a href={`/cases/${element.id}/feed`} className="text-brand-700 hover:text-brand-500">
                                  {element.id}
                                </a>{" "}
                                - {element.firstName} {element.lastName}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
