import { useEffect, useState } from "react";
import axios from "axios";

import { apiError } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

import PageHeader from "../../components/PageHeader";
import DeleteButtonDialog from "../../components/Dialogs/DeleteButtonDialog";
import EditQuickbooksSetting from "../../components/SlideOvers/EditQuickbooksSetting";
import EditQuickbooksSettingSelect from "../../components/SlideOvers/EditQuickbooksSettingSelect";
import EditQuickbooksLookupSetting from "../../components/SlideOvers/EditQuickbooksLookupSetting";

const { REACT_APP_API_URL } = process.env;

export default function Quickbooks() {
  const [clientID, setClientID] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [verifierToken, setVerifierToken] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [environment, setEnvironment] = useState("");
  const [scope, setScope] = useState("");
  const [responseType, setResponseType] = useState("");
  const [discoveryDocumentsURL, setDiscoveryDocumentsURL] = useState("");
  const [bankAccountID, setBankAccountID] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethodID, setPaymentMethodID] = useState("");
  const [fundsReceivableAccount, setFundsReceivableAccount] = useState("");
  const [fundsReceivableAccountID, setFundsReceivableAccountID] = useState("");
  const [overnightCheckAccount, setOvernightCheckAccount] = useState("");
  const [overnightCheckAccountID, setOvernightCheckAccountID] = useState("");
  const [payoffPriorFundingAccount, setPayoffPriorFundingAccount] = useState("");
  const [payoffPriorFundingAccountID, setPayoffPriorFundingAccountID] = useState("");
  const [unearnedRevenueItem, setUnearedRevenueItem] = useState("");
  const [unearnedRevenueItemID, setUnearedRevenueItemID] = useState("");
  const [deferredRevenueAccount, setDeferredRevenueAccount] = useState("");
  const [deferredRevenueAccountID, setDeferredRevenueAccountID] = useState("");
  const [undepositedFundsAccount, setUndepositedFundsAccount] = useState("");
  const [undepositedFundsAccountID, setUndepositedFundsAccountID] = useState("");
  const [earnedIncomeAccount, setEarnedIncomeAccount] = useState("");
  const [earnedIncomeAccountID, setEarnedIncomeAccountID] = useState("");
  const [loansDiscountedAccount, setLoansDiscountedAccount] = useState("");
  const [loansDiscountedAccountID, setLoansDiscountedAccountID] = useState("");
  const [loanOverpaymentAccount, setLoanOverpaymentAccount] = useState("");
  const [loanOverpaymentAccountID, setLoanOverpaymentAccountID] = useState("");
  const [partialPaymentAccount, setPartialPaymentAccount] = useState("");
  const [partialPaymentAccountID, setPartialPaymentAccountID] = useState("");
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/settings/qbo`)
      .then((res) => {
        setAccessToken(res.data.accessToken);
        setClientID(res.data.clientID);
        setClientSecret(res.data.clientSecret);
        setVerifierToken(res.data.verifierToken);
        setCompanyID(res.data.companyID);
        setEnvironment(res.data.environment);
        setScope(res.data.scope);
        setResponseType(res.data.responseType);
        setDiscoveryDocumentsURL(res.data.discoveryDocumentsURL);
        setBankAccountID(res.data.bankAccountID);
        setBankAccount(res.data.bankAccount);
        setPaymentMethodID(res.data.paymentMethodID);
        setPaymentMethod(res.data.paymentMethod);
        setFundsReceivableAccountID(res.data.fundsReceivableAccountID);
        setFundsReceivableAccount(res.data.fundsReceivableAccount);
        setOvernightCheckAccountID(res.data.overnightCheckAccountID);
        setOvernightCheckAccount(res.data.overnightCheckAccount);
        setPayoffPriorFundingAccountID(res.data.payoffPriorFundingAccountID);
        setPayoffPriorFundingAccount(res.data.payoffPriorFundingAccount);
        setUnearedRevenueItemID(res.data.unearnedRevenueItemID);
        setUnearedRevenueItem(res.data.unearnedRevenueItem);
        setDeferredRevenueAccountID(res.data.deferredRevenueAccountID);
        setDeferredRevenueAccount(res.data.deferredRevenueAccount);
        setUndepositedFundsAccountID(res.data.undepositedFundsAccountID);
        setUndepositedFundsAccount(res.data.undepositedFundsAccount);
        setEarnedIncomeAccountID(res.data.earnedIncomeAccountID);
        setEarnedIncomeAccount(res.data.earnedIncomeAccount);
        setLoansDiscountedAccountID(res.data.loansDiscountedAccountID);
        setLoansDiscountedAccount(res.data.loansDiscountedAccount);
        setLoanOverpaymentAccountID(res.data.loanOverpaymentAccountID);
        setLoanOverpaymentAccount(res.data.loanOverpaymentAccount);
        setPartialPaymentAccountID(res.data.partialPaymentAccountID);
        setPartialPaymentAccount(res.data.partialPaymentAccount);
      })
      .catch((err) => {
        apiError(err);
      });
  }, []);

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/settings/qbo`)
      .then((res) => {
        setAccessToken(res.data.accessToken);
        setClientID(res.data.clientID);
        setClientSecret(res.data.clientSecret);
        setVerifierToken(res.data.verifierToken);
        setCompanyID(res.data.companyID);
        setEnvironment(res.data.environment);
        setScope(res.data.scope);
        setResponseType(res.data.responseType);
        setDiscoveryDocumentsURL(res.data.discoveryDocumentsURL);
        setBankAccountID(res.data.bankAccountID);
        setBankAccount(res.data.bankAccount);
        setPaymentMethodID(res.data.paymentMethodID);
        setPaymentMethod(res.data.paymentMethod);
        setFundsReceivableAccountID(res.data.fundsReceivableAccountID);
        setFundsReceivableAccount(res.data.fundsReceivableAccount);
        setOvernightCheckAccountID(res.data.overnightCheckAccountID);
        setOvernightCheckAccount(res.data.overnightCheckAccount);
        setPayoffPriorFundingAccountID(res.data.payoffPriorFundingAccountID);
        setPayoffPriorFundingAccount(res.data.payoffPriorFundingAccount);
        setUnearedRevenueItemID(res.data.unearnedRevenueItemID);
        setUnearedRevenueItem(res.data.unearnedRevenueItem);
        setDeferredRevenueAccountID(res.data.deferredRevenueAccountID);
        setDeferredRevenueAccount(res.data.deferredRevenueAccount);
        setUndepositedFundsAccountID(res.data.undepositedFundsAccountID);
        setUndepositedFundsAccount(res.data.undepositedFundsAccount);
        setEarnedIncomeAccountID(res.data.earnedIncomeAccountID);
        setEarnedIncomeAccount(res.data.earnedIncomeAccount);
        setLoansDiscountedAccountID(res.data.loansDiscountedAccountID);
        setLoansDiscountedAccount(res.data.loansDiscountedAccount);
        setLoanOverpaymentAccountID(res.data.loanOverpaymentAccountID);
        setLoanOverpaymentAccount(res.data.loanOverpaymentAccount);
        setPartialPaymentAccountID(res.data.partialPaymentAccountID);
        setPartialPaymentAccount(res.data.partialPaymentAccount);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleConnect() {
    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/settings/qbo/authenticate`)
      .then((response) => {
        window.location.replace(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleDisconnect() {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/settings/qbo`)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <PageHeader title="Quickbooks" subtitle="Quickbooks Settings" />
        {accessToken !== "" ? (
          <DeleteButtonDialog
            buttonTitle="Disconnect Quickbooks"
            title="Disconnect Quickbooks"
            content="Disconnect application from quickbooks?"
            idToDelete={handleDisconnect}
          />
        ) : (
          <button
            type="button"
            className="rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
            onClick={handleConnect}
          >
            Connect to Quickbooks
          </button>
        )}
      </div>
      <div className="mt-4 rounded-md bg-white p-4">
        <div className="flex flex-col">
          <div className="py-2">
            <div className="mt-0 divide-y divide-gray-200">
              <div className="mt-">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">Client ID</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{clientID}</span>
                      <span className="ml-4 flex-shrink-0">
                        <EditQuickbooksSetting
                          tooltip="Edit"
                          keyValue={clientID}
                          keyName="clientID"
                          title="Client ID"
                          subTitle="Update Quickbooks Client ID"
                          label="Client ID"
                          saved={handleRefresh}
                        />
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Client Secret</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{clientSecret}</span>
                      <span className="ml-4 flex flex-shrink-0 items-start space-x-4">
                        <EditQuickbooksSetting
                          tooltip="Edit"
                          keyValue={clientSecret}
                          keyName="clientSecret"
                          title="Client Secret"
                          subTitle="Update Quickbooks Client Secret"
                          label="Client Secret"
                          saved={handleRefresh}
                        />
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">Webhook Verifier Token</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{verifierToken}</span>
                      <span className="ml-4 flex-shrink-0">
                        <EditQuickbooksSetting
                          tooltip="Edit"
                          keyValue={verifierToken}
                          keyName="verifierToken"
                          title="Webhook Verifier Token"
                          subTitle="Update Quickbooks Webhook Verifier Token"
                          label="Webhook Verifier Token"
                          saved={handleRefresh}
                        />
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Company ID</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{companyID}</span>
                      <span className="ml-4 flex-shrink-0">
                        <EditQuickbooksSetting
                          tooltip="Edit"
                          keyValue={companyID}
                          keyName="companyID"
                          title="Company ID"
                          subTitle="Update Quickbooks Company ID"
                          label="Company ID"
                          saved={handleRefresh}
                        />
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Environment</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{environment}</span>
                      <span className="ml-4 flex-shrink-0">
                        <EditQuickbooksSettingSelect
                          tooltip="Edit"
                          keyValue={environment}
                          keyName="environment"
                          title="Environment"
                          subTitle="Update Quickbooks Environment"
                          label="Environment"
                          listItems={["Sandbox", "Production"]}
                          path="/settings/qbo"
                          saved={handleRefresh}
                        />
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Scope</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{scope}</span>
                      <span className="ml-4 flex-shrink-0">
                        <EditQuickbooksSetting
                          tooltip="Edit"
                          keyValue={scope}
                          keyName="scope"
                          title="Scope"
                          subTitle="Update Quickbooks Authorization Scope"
                          label="Scope"
                          saved={handleRefresh}
                        />
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Response Type</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{responseType}</span>
                      <span className="ml-4 flex-shrink-0">
                        <EditQuickbooksSetting
                          tooltip="Edit"
                          keyValue={responseType}
                          keyName="responseType"
                          title="Response Type"
                          subTitle="Update Quickbooks Authorization Response Type"
                          label="Response Type"
                          saved={handleRefresh}
                        />
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Discovery Documents URL</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{discoveryDocumentsURL}</span>
                      <span className="ml-4 flex-shrink-0">
                        <EditQuickbooksSetting
                          tooltip="Edit"
                          keyValue={discoveryDocumentsURL}
                          keyName="discoveryDocumentsURL"
                          title="Discovery Documents URL"
                          subTitle="Update Quickbooks Discovery Documents URL"
                          label="Discovery Documents URL"
                          saved={handleRefresh}
                        />
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Bank Account</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{bankAccount}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={bankAccountID}
                            keyValueName={bankAccount}
                            keyName="bankAccountID"
                            title="Bank Account"
                            subTitle="Update Bank Account"
                            label="Bank Account"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Payment Method</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{paymentMethod}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={paymentMethodID}
                            keyValueName={paymentMethod}
                            keyName="paymentMethodID"
                            title="Payment Method"
                            subTitle="Payment Method"
                            label="Payment Method"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Funds Receivable Account</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{fundsReceivableAccount}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={fundsReceivableAccountID}
                            keyValueName={fundsReceivableAccount}
                            keyName="fundsReceivableAccountID"
                            title="Funds Receivable Account"
                            subTitle="Funds Receivable Account"
                            label="Funds Receivable Account"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Overnight Check Account</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{overnightCheckAccount}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={overnightCheckAccountID}
                            keyValueName={overnightCheckAccount}
                            keyName="overnightCheckAccountID"
                            title="Overnight Check Account"
                            subTitle="Overnight Check Account"
                            label="Overnight Check Account"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Payoff Prior Funding Account</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{payoffPriorFundingAccount}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={payoffPriorFundingAccountID}
                            keyValueName={payoffPriorFundingAccount}
                            keyName="payoffPriorFundingAccountID"
                            title="Payoff Prior Funding Account"
                            subTitle="Payoff Prior Funding Account"
                            label="Payoff Prior Funding Account"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Unearned Revenue Item</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{unearnedRevenueItem}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={unearnedRevenueItemID}
                            keyValueName={unearnedRevenueItem}
                            keyName="unearnedRevenueItemID"
                            title="Unearned Revenue Item"
                            subTitle="Unearned Revenue Item"
                            label="Unearned Revenue Item"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Deferred Revenue Account</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{deferredRevenueAccount}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={deferredRevenueAccountID}
                            keyValueName={deferredRevenueAccount}
                            keyName="deferredRevenueAccountID"
                            title="Deferred Revenue Account"
                            subTitle="Deferred Revenue Account"
                            label="Deferred Revenue Account"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Undeposited Funds Account</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{undepositedFundsAccount}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={undepositedFundsAccountID}
                            keyValueName={undepositedFundsAccount}
                            keyName="undepositedFundsAccountID"
                            title="Undeposited Funds Account"
                            subTitle="Undeposited Funds Account"
                            label="Undeposited Funds Account"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Earned Income Account</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{earnedIncomeAccount}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={earnedIncomeAccountID}
                            keyValueName={earnedIncomeAccount}
                            keyName="earnedIncomeAccountID"
                            title="Earned Income Account"
                            subTitle="Earned Income Account"
                            label="Earned Income Account"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Loans Discounted Account</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{loansDiscountedAccount}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={loansDiscountedAccountID}
                            keyValueName={loansDiscountedAccount}
                            keyName="loansDiscountedAccountID"
                            title="Loans Discounted Account"
                            subTitle="Loans Discounted Account"
                            label="Loans Discounted Account"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Loan Overpayment Account</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{loanOverpaymentAccount}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={loanOverpaymentAccountID}
                            keyValueName={loanOverpaymentAccount}
                            keyName="loanOverpaymentAccountID"
                            title="Loan Overpayment Account"
                            subTitle="Loan Overpayment Account"
                            label="Loan Overpayment Account"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Partial Payment Account</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{partialPaymentAccount}</span>
                      <span className="ml-4 flex-shrink-0">
                        {accessToken !== "" ? (
                          <EditQuickbooksLookupSetting
                            tooltip="Edit"
                            keyValueID={partialPaymentAccountID}
                            keyValueName={partialPaymentAccount}
                            keyName="partialPaymentAccountID"
                            title="Partial Payment Account"
                            subTitle="Partial Payment Account"
                            label="Partial Payment Account"
                            saved={handleRefresh}
                          />
                        ) : null}
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2"></div>
    </div>
  );
}
