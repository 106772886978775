import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

export default function NotFound() {
  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="rounded-md bg-yellow-50 p-4 mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-10 w-10 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h1 className="text-xl font-medium text-yellow-800">
                Page Not Found
              </h1>
              <div className="mt-2 text text-yellow-700">
                <p>The page you requested does not exist</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
