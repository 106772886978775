import React, { useEffect, useCallback } from "react";
import moment from "moment";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { minHeight } from "../../libs/Utils";
import { client, clientError } from "../../libs/Client";
import NotificationTabs from "../../layouts/FullLayout/Tabs/NotificationTabs";

export default function AllNotifications() {
  useEffect(() => {
    document.title = "Capital Now Funding - Notifications - All";
  });

  const gridStyle = { minHeight: minHeight };

  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/notifications?skip=${skip}&limit=${limit}`)
      .then((res) => {
        return { data: res.data.notifications, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, []);

  const columns = [
    {
      name: "lawsuitID",
      defaultWidth: 150,
      header: "Case #",
      render: ({ value }) => {
        return (
          <a className="text-brand-700 hover:text-brand-500" href={`/cases/${value}/feed`}>
            {value}
          </a>
        );
      },
    },
    {
      name: "plaintiff",
      header: "Plaintiff",
      defaultWidth: 200,
    },
    {
      name: "serviceProvider",
      header: "Service Provider",
      defaultWidth: 200,
    },
    {
      name: "message",
      defaultWidth: 350,
      header: "Notification",
    },
    {
      name: "lawsuitStatus",
      defaultWidth: 200,
      header: "Case Status",
    },
    {
      name: "assignedTo",
      defaultWidth: 200,
      header: "Assigned To",
    },
    {
      name: "createdAt",
      header: "Created",
      defaultWidth: 200,
      render: ({ value }) => {
        return moment(value).fromNow();
      },
    },
  ];

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <NotificationTabs selected="All" />
        <ReactDataGrid
          idProperty="allNotifications"
          columns={columns}
          style={gridStyle}
          showColumnMenuTool={false}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
