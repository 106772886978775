import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { client, clientError } from "../../libs/Client";
import { minHeight, dateFromNow } from "../../libs/Utils";

import CaseDetailHeader from "../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseEmailTabs from "../../layouts/FullLayout/Tabs/CaseEmailTabs";

export default function CaseEmailInbox() {
  const { id } = useParams();
  const [dataSource, setDataSource] = useState([]);

  const loadData = useCallback(() => {
    const newDataSource = ({ skip, limit, sortInfo }) => {
      return client()
        .get(`/mail/inbox?lawsuitID=${id}&skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`)
        .then((res) => {
          return { data: res.data.inbox, count: res.data.count };
        })
        .catch((err) => {
          clientError(err);
        });
    };

    setDataSource(newDataSource);
  }, [id]);

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - Email - Inbox";
    loadData();
  }, [loadData]);

  const gridStyle = { minHeight: minHeight };

  const columns = [
    {
      name: "attachments",
      minWidth: 45,
      maxWidth: 45,
      header: "",
      defaultFlex: 1,
      render: ({ data }) => {
        if (data.attachments > 0) {
          return <PaperClipIcon className="h-4 w-4 text-left text-gray-800" />;
        } else {
          return "";
        }
      },
    },
    {
      name: "from",
      header: "From",
      minWidth: 250,
      defaultFlex: 1,
      render: ({ data }) => {
        if (data.fullName !== "") {
          return data.fullName;
        } else {
          return data.email;
        }
      },
    },
    {
      name: "subject",
      header: "Subject",
      defaultWidth: 350,
      defaultFlex: 1,
      render: ({ data }) => {
        return (
          <a type="button" href={`/email/inbox/${data.id}`} className="text-brand-700 hover:text-brand-500">
            {data.subject}
          </a>
        );
      },
    },
    {
      name: "receivedAt",
      header: "Received",
      minWidth: 120,
      maxWidth: 120,
      defaultFlex: 1,
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
  ];

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Email" lawsuitID={id} />
        <div className="relative ml-8 mr-8">
          <CaseEmailTabs lawsuitID={id} selected="Inbox" refresh={loadData} />
          <div className="m-2">
            <ReactDataGrid
              idProperty="caseEmailInbox"
              columns={columns}
              style={gridStyle}
              showColumnMenuTool={false}
              dataSource={dataSource}
              pagination
              defaultLimit={100}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
