import axios from "axios";
import { toast } from "react-toastify";
import { capitalize } from "lodash";
import { getUser, signOut } from "../hooks/Authentication";

const { REACT_APP_API_URL } = process.env;

export const client = (timeout = 10000) => {
  const user = getUser();
  const token = user.accessToken;

  return axios.create({
    baseURL: REACT_APP_API_URL,
    timeout: timeout,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": `application/json` },
  });
};

export const clientError = (err) => {
  if (err.code === "ECONNABORTED") {
    toast.error("Request timeout");
  } else {
    if (err.response) {
      if (err.response.status === 401) {
        signOut();
      } else {
        toast.error(capitalize(err.response.data.reason));
      }
    } else {
      toast.error("Unable to connect to the API");
    }
  }
};
