import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { currency, shortDate } from "../../libs/Utils";
import { client, clientError } from "../../libs/Client";

import CaseDetailHeader from "../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../layouts/FullLayout/Tabs/CaseDetailTabs";
import AccidentStateFundingApplication from "../../components/SlideOvers/FundingApplications/AccidentStateFundingApplication";
import ClientStateFundingApplication from "../../components/SlideOvers/FundingApplications/ClientStateFundingApplication";
import FundingApplicationCard from "../../components/Cards/FundingApplicationCard";

export default function CaseFundingApplications() {
  const { id } = useParams();
  const [stateID, setStateID] = useState("");
  const [accidentStateID, setAccidentStateID] = useState("");
  const [totalFundsRequested, setTotalFundsRequested] = useState(0);
  const [totalFundsApproved, setTotalFundsApproved] = useState(0);
  const [totalPayoff, setTotalPayoff] = useState(0);
  const [payoffRemaining, setPayoffRemaining] = useState(0);
  const [applications, setApplications] = useState([]);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Funding Applications";
    Promise.all([
      client().get(`/lawsuits/${id}`),
      client().get(`/funding-applications?lawsuitID=${id}`),
      client().get(`/lawsuits/${id}/payments`),
    ])
      .then((res) => {
        setAccidentStateID(res[0].data.accidentState.id);
        setStateID(res[0].data.state.id);

        const applications = res[1].data;
        setApplications(applications);
        var tfr = 0;
        var tfa = 0;
        var tpo = 0;
        applications.forEach((application) => {
          tfr += application.requestedAmount;
          if (
            application.status === "Approved" ||
            application.status === "Ready to Fund" ||
            application.status === "Funded"
          ) {
            if (application.approvedAmount) {
              tfa += application.approvedAmount;
              if (application.buyoutAmount) {
                tfa += application.buyoutAmount;
              }
            }

            tpo += application.payoffAmount;
          }
        });

        const payments = res[2].data;
        setPayments(payments);
        var totalPayments = 0;
        payments.forEach((payment) => {
          totalPayments += payment.amount;
        });

        setTotalFundsRequested(tfr);
        setTotalFundsApproved(tfa);
        setTotalPayoff(tpo);
        setPayoffRemaining(tpo - totalPayments);
      })
      .catch((err) => {
        clientError(err);
      });
  }, [id]);

  function handleRefresh() {
    setApplications([]);
    Promise.all([
      client().get(`/lawsuits/${id}`),
      client().get(`/funding-applications?lawsuitID=${id}`),
      client().get(`/lawsuits/${id}/payments`),
    ])
      .then((res) => {
        setAccidentStateID(res[0].data.accidentState.id);
        setStateID(res[0].data.state.id);

        const applications = res[1].data;
        setApplications(applications);
        var tfr = 0;
        var tfa = 0;
        var tpo = 0;
        applications.forEach((application) => {
          tfr += application.requestedAmount;
          if (
            application.status === "Approved" ||
            application.status === "Ready to Fund" ||
            application.status === "Funded"
          ) {
            if (application.approvedAmount) {
              tfa += application.approvedAmount;
              if (application.buyoutAmount) {
                tfa += application.buyoutAmount;
              }
            }

            tpo += application.payoffAmount;
          }
        });

        const payments = res[2].data;
        setPayments(payments);
        var totalPayments = 0;
        payments.forEach((payment) => {
          totalPayments += payment.amount;
        });

        setTotalFundsRequested(tfr);
        setTotalFundsApproved(tfa);
        setTotalPayoff(tpo);
        setPayoffRemaining(tpo - totalPayments);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Applications" lawsuitID={id} />
        <div className="m-4 rounded-lg bg-gray-50 p-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Summary
            <div className="float-right ml-2">
              <ClientStateFundingApplication lawsuitID={id} stateID={stateID} saved={handleRefresh} />
            </div>
            <div className="float-right">
              <AccidentStateFundingApplication lawsuitID={id} accidentStateID={accidentStateID} saved={handleRefresh} />
            </div>
          </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
            <div key="Total Funds Requested" className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Total Funds Requested</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {currency(totalFundsRequested)}
              </dd>
            </div>
            <div key="Total Funds Approved" className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Total Funds Approved</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {currency(totalFundsApproved)}
              </dd>
            </div>
            <div key="Total Payoff" className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Total Payoff</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{currency(totalPayoff)}</dd>
            </div>
            <div key="Payoff Remaining" className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">Payoff Remaining</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{currency(payoffRemaining)}</dd>
            </div>
          </dl>
          {payments.length > 0 && (
            <div className="mt-2 rounded-lg bg-white p-4">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Check #
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Check Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Received
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                      Amount Paid
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {payments.map((payment) => (
                    <tr key={payment.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {payment.checkNumber}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        {shortDate(payment.checkDate)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{shortDate(payment.paidAt)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-900">
                        {currency(payment.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {applications.map((application) => (
          <div key={application.id} className="m-4 rounded-lg bg-gray-50 p-4">
            <FundingApplicationCard
              key={application.id}
              application={application}
              accidentStateID={accidentStateID}
              plaintiffStateID={stateID}
              refresh={handleRefresh}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
