import { useLocation } from "react-router-dom";
import {
  HomeIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  CurrencyDollarIcon,
  BriefcaseIcon,
  BookOpenIcon,
  BuildingOfficeIcon,
  ClipboardDocumentCheckIcon,
  BellIcon,
  MagnifyingGlassIcon,
  PaperAirplaneIcon,
  ArrowDownOnSquareStackIcon,
} from "@heroicons/react/24/outline";

export const MainNavigation = () => {
  const location = useLocation();
  return [
    { name: "Dashboard", href: "/", icon: HomeIcon, current: location.pathname === "/" },
    {
      name: "Leads",
      href: "/leads/pending",
      icon: DocumentTextIcon,
      current: location.pathname.startsWith("/leads"),
    },
    { name: "Email", href: "/email/inbox", icon: EnvelopeIcon, current: location.pathname.startsWith("/email") },
    {
      name: "Funding Requests",
      href: "/funding-requests/open",
      icon: CurrencyDollarIcon,
      current: location.pathname.startsWith("/funding-requests"),
    },
    {
      name: "Cases",
      href: "/cases/closed",
      icon: BriefcaseIcon,
      current: location.pathname.startsWith("/cases"),
    },
    {
      name: "Case Activity",
      href: "/case-activity",
      icon: BookOpenIcon,
      current: location.pathname.startsWith("/case-activity"),
    },
    {
      name: "Service Providers",
      href: "/service-providers",
      icon: BuildingOfficeIcon,
      current: location.pathname.startsWith("/service-providers"),
    },
    {
      name: "Campaigns",
      href: "/campaigns",
      icon: PaperAirplaneIcon,
      current: location.pathname.startsWith("/campaigns"),
    },
    {
      name: "Tasks",
      href: "/tasks/todo",
      icon: ClipboardDocumentCheckIcon,
      current: location.pathname.startsWith("/tasks"),
    },
    {
      name: "Notifications",
      href: "/notifications/unread",
      icon: BellIcon,
      current: location.pathname.startsWith("/notifications"),
    },
    {
      name: "Export",
      href: "/export",
      icon: ArrowDownOnSquareStackIcon,
      current: location.pathname.startsWith("/export"),
    },
    {
      name: "Advanced Search",
      href: "/search",
      icon: MagnifyingGlassIcon,
      current: location.pathname.startsWith("/search"),
    },
  ];
};

export const AdminNavigation = () => {
  const location = useLocation();
  return [
    {
      name: "Funding Companies",
      href: "/admin/funding-companies",
      current: location.pathname.startsWith("/admin/funding-companies"),
    },
    {
      name: "Images",
      href: "/admin/images",
      current: location.pathname.startsWith("/admin/images"),
    },
    {
      name: "Links",
      href: "/admin/links",
      current: location.pathname.startsWith("/admin/links"),
    },
    {
      name: "Quickbooks",
      href: "/admin/quickbooks",
      current: location.pathname.startsWith("/admin/quickbooks"),
    },
    {
      name: "Referral Types",
      href: "/admin/referral-types",
      current: location.pathname.startsWith("/admin/referral-types"),
    },
    {
      name: "States",
      href: "/admin/states",
      current: location.pathname.startsWith("/admin/states"),
    },
    {
      name: "Tags",
      href: "/admin/tags",
      current: location.pathname.startsWith("/admin/tags"),
    },
    {
      name: "Templates",
      href: "/admin/templates/campaigns",
      current: location.pathname.startsWith("/admin/templates"),
    },
    {
      name: "Users",
      href: "/admin/users",
      current: location.pathname.startsWith("/admin/users"),
    },
  ];
};
