import PropTypes from "prop-types";
import { shortDate } from "../../libs/Utils";

export default function EssureProductLiabilityBlock(props) {
  return (
    <div className="mt-2 overflow-hidden bg-white">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Accident Details</h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Date</dt>
            <dd className="mt-1 text-sm text-gray-900">{shortDate(props.accidentDate)}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Type</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.accidentType}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Details - What Happened?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.details}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Do you know what year the product was placed?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.yearPlaced}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Did you have surgery to remove the product?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.surgery ? "Yes" : "No"}</dd>
          </div>
          {props.surgery ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">What year did the surgery take place?</dt>
              <dd className="mt-1 text-sm text-gray-900">{props.surgeryYear}</dd>
            </div>
          ) : null}

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Has your case reached settlement?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.settlement}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

EssureProductLiabilityBlock.propTypes = {
  accidentDate: PropTypes.string,
  accidentType: PropTypes.string,
  details: PropTypes.string,
  settlement: PropTypes.string,
  surgery: PropTypes.bool,
  surgeryYear: PropTypes.string,
  yearPlaced: PropTypes.string,
};
