import PropTypes from "prop-types";

import EditAccidentDetails from "../SlideOvers/Cases/Accident/Other/EdtiAccidentDetails";
import EditAccidentInvolves from "../SlideOvers/Cases/Accident/Other/EditAccidentInvolves";
import EditOtherName from "../SlideOvers/Cases/Accident/Other/EditOtherName";
import EditRelationship from "../SlideOvers/Cases/Accident/Other/EditRelationship";

export default function OtherAccident(props) {
  return (
    <dl className="ml-8 mr-8 divide-y divide-gray-200">
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Is this case involving you or someone else?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.involves}</span>
          <span className="ml-4 flex-shrink-0">
            <EditAccidentInvolves id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      {props.involves === "Someone Else" ? (
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">What is the name of the person?</dt>
          <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span className="flex-grow">{props.otherName}</span>
            <span className="ml-4 flex-shrink-0">
              <EditOtherName id={props.id} saved={props.saved} />
            </span>
          </dd>
        </div>
      ) : null}
      {props.involves === "Someone Else" ? (
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">
            What is your relationship to the person / Are you their POA?
          </dt>
          <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span className="flex-grow">{props.relationship}</span>
            <span className="ml-4 flex-shrink-0">
              <EditRelationship id={props.id} saved={props.saved} />
            </span>
          </dd>
        </div>
      ) : null}
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Incident Details - What happened?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.details}</span>
          <span className="ml-4 flex-shrink-0">
            <EditAccidentDetails id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
    </dl>
  );
}

OtherAccident.propTypes = {
  id: PropTypes.string.isRequired,
  involves: PropTypes.string.isRequired,
  otherName: PropTypes.string.isRequired,
  relationship: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
