import { Fragment, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { Editor } from "@tinymce/tinymce-react";
import CampaignShortCodes from "../ShortCodes/CampaignShortCodes";
import ReactSelect from "react-select";
import { client, clientError } from "./../../../libs/Client";
const { REACT_APP_API_URL, REACT_APP_TINY_MCE_API_KEY } = process.env;

export default function AddCampaign(props) {
  const [open, setOpen] = useState(false);
  const editorRef = useRef(null);
  const [subject, setSubject] = useState("");
  const [template, setTemplate] = useState("");
  const [templates, setTemplates] = useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      client()
        .get(`/templates/campaigns/list`)
        .then((res) => {
          setTemplates(res.data);
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [open]);

  function handleTemplateChange(data) {
    setTemplate(data);
    if (data) {
      client()
        .get(`/templates/campaigns/${data.value}`)
        .then((res) => {
          const content = res.data.body;
          editorRef.current.setContent(content);
        })
        .catch((err) => {
          clientError(err);
        });
    } else {
      editorRef.current.setContent("");
    }
  }

  function handleSave() {
    const body = editorRef.current.getContent();
    client()
      .post(`/service-provider-campaigns`, {
        subject,
        body,
      })
      .then(() => {
        setOpen(false);
        props.saved(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2 sm:w-auto"
        onClick={handleClickOpen}
      >
        Add Campaign
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="max-w-4-xl pointer-events-none fixed inset-y-0 right-0 flex pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-5xl">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Add Campaign</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label htmlFor="subject" className="block text-sm font-medium text-gray-900">
                                  Subject
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setSubject(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                  Template
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect
                                    className="z-30"
                                    options={templates}
                                    isClearable
                                    onChange={(data) => handleTemplateChange(data)}
                                    value={template}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="body" className="block text-sm font-medium text-gray-900">
                                  Body
                                  <span className="float-right">
                                    <CampaignShortCodes />
                                  </span>
                                </label>
                                <div className="mt-1">
                                  <Editor
                                    apiKey={`${REACT_APP_TINY_MCE_API_KEY}`}
                                    onInit={(evt, editor) => (editorRef.current = editor)}
                                    init={{
                                      image_list: `${REACT_APP_API_URL}/images/list`,
                                      height: 350,
                                      menubar: "edit insert view format table tools",
                                      plugins: "lists link image table code",
                                      paste_as_text: false,
                                      paste_block_drop: true,
                                      toolbar:
                                        "undo redo | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | image link | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

AddCampaign.propTypes = {
  saved: PropTypes.func,
};
