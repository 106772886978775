import PropTypes from "prop-types";

export default function LawsuitSearchResult(props) {
  var statusColor = "yellow";
  if (props.status === "Funded") {
    statusColor = "green";
  } else if (props.status === "Closed") {
    statusColor = "red";
  }

  return (
    <a className="flex flex-col space-y-1" href={`/cases/${props.caseNumber}/overview`}>
      <div className="space-x-4">
        <span>
          <span className="font-semibold">Case #:</span> {props.caseNumber}
        </span>
        <span
          className={`inline-flex items-center rounded-md bg-${statusColor}-100 px-2 py-1 text-xs font-medium text-${statusColor}-700`}
        >
          {props.status}
        </span>
      </div>
      <div className="space-x-4">
        <span>
          <span className="font-semibold">Plaintiff:</span> {props.firstName} {props.lastName}
        </span>
        <span>
          <span className="font-semibold">Plaintiff Email:</span> {props.email}
        </span>
      </div>

      <div className="space-x-4">
        <span>
          <span className="font-semibold">Service Provider:</span> {props.serviceProvider}
        </span>
        <span>
          <span className="font-semibold">Service Provider Contact:</span> {props.serviceProviderContact}
        </span>
      </div>
    </a>
  );
}

LawsuitSearchResult.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  serviceProvider: PropTypes.string.isRequired,
  serviceProviderContact: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};
