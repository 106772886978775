import { Fragment, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { client, clientError } from "../../../libs/Client";
import { Select } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { shortDate, longDate, nationalPhoneNumber, currency } from "../../../libs/Utils";

const { REACT_APP_TINY_MCE_API_KEY } = process.env;

export default function ReferOut(props) {
  const [open, setOpen] = useState(false);
  const editorRef = useRef(null);
  const [loadingServiceProviders, setLoadingServiceProviders] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [loadingReferralTypes, setLoadingReferralTypes] = useState(false);
  const [referralTypes, setReferralTypes] = useState([]);
  const [referralTypeID, setReferralTypeID] = useState("");
  const [serviceProviders, setServiceProviders] = useState([]);
  const [serviceProviderID, setServiceProviderID] = useState("");
  const [contacts, setContacts] = useState([]);
  const [contactID, setContactID] = useState("");
  const [additionalContacts, setAdditionalContacts] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [loadingContracts, setLoadingContracts] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [contractIds, setContractIds] = useState([]);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [additionalEmailRecipients, setAdditionalEmailRecipients] = useState("");
  const [lawsuit, setLawsuit] = useState(undefined);

  function handleClickOpen() {
    setOpen(true);
  }

  function body(lawsuit, referralType) {
    var emailBody = `<p></p>`;
    emailBody += `<h3>Client Info</h3>`;
    emailBody += `<strong>Client:</strong> ${lawsuit.client}<br/>`;
    emailBody += `<strong>Date of Birth:</strong> ${shortDate(lawsuit.dob)}<br/>`;
    emailBody += `<strong>Gender:</strong> ${lawsuit.gender}<br/>`;
    emailBody += `<strong>Email:</strong> ${lawsuit.email}<br/>`;
    emailBody += `<strong>Phone Number:</strong> ${nationalPhoneNumber(lawsuit.phoneNumber)}<br/>`;
    emailBody += `<strong>Address:</strong> ${lawsuit.address}`;
    emailBody += `<h3>Law Firm Info</h3>`;
    emailBody += `<strong>Law Firm:</strong> ${lawsuit.lawFirm}<br/>`;
    emailBody += `<strong>Primary Contact:</strong> ${lawsuit.primaryContact}<br/>`;
    emailBody += `<strong>Primary Contact Role:</strong> ${lawsuit.primaryContactRole}<br/>`;
    emailBody += `<strong>Primary Contact Email:</strong> ${lawsuit.primaryContactEmail}<br />`;
    emailBody += `<strong>Primary Contact Phone Number:</strong> ${nationalPhoneNumber(
      lawsuit.primaryContactPhone,
    )}<br/>`;
    emailBody += `<strong>Law Firm Address:</strong> ${lawsuit.lawFirmAddress}<br/>`;
    emailBody += `<h3>Case Info</h3>`;
    emailBody += `<strong>Referral Type:</strong> ${referralType}<br/>`;
    emailBody += `<strong>Accident Type:</strong> ${lawsuit.accidentType}<br/>`;
    emailBody += `<strong>Accident Date:</strong> ${shortDate(lawsuit.accidentDate)}<br/>`;
    emailBody += `<strong>Accident State:</strong> ${lawsuit.accidentState}`;
    emailBody += `<h3><strong>Accident Details:</strong></h3>`;
    emailBody += `<p><strong>What Happened?</strong><br/>${lawsuit.whatHappened}</p>`;
    emailBody += `<p><strong>Injuries Sustained?</strong><br/>${lawsuit.injuriesSustained}</p>`;
    emailBody += `<p><strong>Treatment?</strong><br/>${lawsuit.treatment}</p>`;
    emailBody += `<p><strong>Due to the incident were any days of work missed?</strong><br/>${lawsuit.workDaysMissed}</p>`;
    emailBody += `<p><strong>How much work have you missed since the accident?</strong><br/>${lawsuit.howMuch}</p>`;
    emailBody += `<p><strong>Have you been able to return to work?</strong><br/>${lawsuit.returnedToWork}</p>`;
    if (lawsuit.otherInvolves) {
      emailBody += `<p><strong>Is this case involving you or someone else?</strong><br/>${lawsuit.otherInvolves}</p>`;
    }
    if (lawsuit.otherPerson) {
      emailBody += `<p><strong>What is the name of the person?</strong><br/>${lawsuit.otherPerson}</p>`;
    }
    if (lawsuit.otherPersonRelationship) {
      emailBody += `<p><strong>What is your relationship to the person / Are you their POA?</strong><br/>${lawsuit.otherPersonRelationship}</p>`;
    }
    emailBody += `<h3><strong>Financial Info:</strong></h3>`;
    if (lawsuit.funded) {
      emailBody += `<p><strong>Does the client have prior funding with Capital Now Funding?<br/></strong>Yes</p>`;
    } else {
      emailBody += `<p><strong>Does the client have prior funding with Capital Now Funding?<br/></strong>No</p>`;
    }

    if (lawsuit.payoffAmount > 0) {
      emailBody += `<p><strong>What is the current payoff with Capital Now Funding?<br/></strong>${currency(
        lawsuit.payoffAmount,
      )}</p>`;
    }
    emailBody += `<p><strong>Have you received funding from anyone other than Capital Now Funding?</strong><br/> ${lawsuit.previouslyFunded}</p>`;

    return emailBody;
  }

  function updateReferralType(data) {
    setReferralTypeID(data);
    let obj = referralTypes.find((o) => o.value === data);
    const content = body(lawsuit, obj.label);
    setEmail(content);
    editorRef.current.setContent(content);
  }

  useEffect(() => {
    if (open) {
      setLoadingReferralTypes(true);
      setLoadingServiceProviders(true);
      setLoadingDocuments(true);
      setLoadingContracts(true);
      Promise.all([
        client().get(`/referral-types`),
        client().get(`/service-providers/list?type=Referral Company`),
        client().get(`/documents/referral?lawsuitID=${props.id}`),
        client().get(`/funding-application-contracts?lawsuitID=${props.id}`),
        client().get(`/lawsuits/${props.id}/referral-details`),
      ])
        .then((res) => {
          setLoadingReferralTypes(false);
          setLoadingServiceProviders(false);
          setLoadingDocuments(false);
          setLoadingContracts(false);
          setReferralTypes(
            res[0].data.map((element) => ({
              value: element.id,
              label: element.name,
            })),
          );
          setServiceProviders(res[1].data);
          setDocuments(
            res[2].data.map((element) => ({
              value: element.id,
              label: `${element.originalName} - ${longDate(element.createdAt)}`,
            })),
          );

          setContracts(
            res[3].data.map((element) => ({
              value: element.id,
              label: `${element.title} - ${longDate(element.updatedAt)}`,
            })),
          );
          setLawsuit(res[4].data);
          setEmail(body(res[4].data, ""));
        })
        .catch((err) => {
          setLoadingReferralTypes(false);
          setLoadingServiceProviders(false);
          setLoadingDocuments(false);
          setLoadingContracts(false);
          clientError(err);
        });
    }
  }, [open, props.id]);

  function serviceProviderChanged(data) {
    setServiceProviderID(data);
    setLoadingContacts(true);
    client()
      .get(`/service-providers/${data}/users`)
      .then((res) => {
        setLoadingContacts(false);
        setContacts(
          res.data.map((element) => ({
            value: element.id,
            label: element.firstName + " " + element.lastName,
          })),
        );
        setContactID("");
        setAdditionalContacts([]);
      })
      .catch((err) => {
        setLoadingContacts(false);
        clientError(err);
      });
  }

  function handleSave() {
    const emailContent = editorRef.current.getContent();
    var secondaryContactIDs = [];
    if (additionalContacts) {
      additionalContacts.forEach((additionalContact) => {
        secondaryContactIDs.push(additionalContact);
      });
    }

    var contractIDs = [];
    if (contractIds) {
      contractIds.forEach((contract) => {
        contractIDs.push(contract);
      });
    }

    var documentIDs = [];
    if (documentIds) {
      documentIds.forEach((document) => {
        documentIDs.push(document);
      });
    }

    var additionalEmailContacts = [];
    if (additionalEmailRecipients !== "") {
      additionalEmailContacts = additionalEmailRecipients.split(",").map((item) => item.trim());
    }

    client()
      .post(`/referrals`, {
        lawsuitID: parseInt(props.id),
        referralTypeID,
        serviceProviderID,
        contactID,
        secondaryContactIDs,
        contractIDs,
        documentIDs,
        email: emailContent,
        subject,
        additionalEmailContacts,
      })
      .then(() => {
        setOpen(false);
        props.savedReferral(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div>
      {props.disabled ? (
        <button
          className="w-full bg-orange-200 px-6 py-3 text-center text-sm font-medium text-orange-500"
          onClick={handleClickOpen}
          disabled
        >
          Refer Case
        </button>
      ) : (
        <button
          className="w-full bg-orange-200 px-6 py-3 text-center text-sm font-medium text-orange-500 hover:bg-orange-500 hover:text-white"
          onClick={handleClickOpen}
        >
          Refer Case
        </button>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-5xl">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Refer Case</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="referralTypeID" className="block text-sm font-medium text-gray-700">
                                  Referral Type
                                </label>
                                <div className="remove-input-txt-border-ant mt-1">
                                  <Select
                                    size="large"
                                    onChange={(data) => updateReferralType(data)}
                                    options={referralTypes}
                                    className="clear-both w-full"
                                    showSearch
                                    loading={loadingReferralTypes}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="serviceProvider" className="block text-sm font-medium text-gray-700">
                                  Service Provider
                                </label>
                                <div className="remove-input-txt-border-ant mt-1">
                                  <Select
                                    size="large"
                                    onChange={(data) => serviceProviderChanged(data)}
                                    options={serviceProviders}
                                    className="clear-both w-full"
                                    showSearch
                                    loading={loadingServiceProviders}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="label" className="block text-sm font-medium text-gray-900">
                                  Primary Contact
                                </label>
                                <div className="remove-input-txt-border-ant mt-1">
                                  <Select
                                    size="large"
                                    onChange={(data) => setContactID(data)}
                                    options={contacts}
                                    placeholder="Please select"
                                    className="clear-both w-full"
                                    showSearch
                                    loading={loadingContacts}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="label" className="block text-sm font-medium text-gray-900">
                                  Additional Contacts
                                </label>
                                <div className="remove-input-txt-border-ant mt-1">
                                  <Select
                                    size="large"
                                    mode="multiple"
                                    placeholder="Please select"
                                    onChange={(data) => setAdditionalContacts(data)}
                                    options={contacts}
                                    className="clear-both w-full"
                                    showSearch
                                    loading={loadingContacts}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="additionalEmailRecipients"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Additional Email Recipients
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="additionalEmailRecipients"
                                    id="additionalEmailRecipients"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setAdditionalEmailRecipients(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="label" className="block text-sm font-medium text-gray-900">
                                  Documents
                                </label>
                                <div className="remove-input-txt-border-ant mt-1">
                                  <Select
                                    size="large"
                                    mode="multiple"
                                    placeholder="Please select"
                                    onChange={(data) => setDocumentIds(data)}
                                    options={documents}
                                    className="clear-both w-full"
                                    showSearch
                                    loading={loadingDocuments}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="label" className="block text-sm font-medium text-gray-900">
                                  Contracts
                                </label>
                                <div className="remove-input-txt-border-ant mt-1">
                                  <Select
                                    size="large"
                                    mode="multiple"
                                    placeholder="Please select"
                                    onChange={(data) => setContractIds(data)}
                                    options={contracts}
                                    className="clear-both w-full"
                                    showSearch
                                    loading={loadingContracts}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="subject" className="block text-sm font-medium text-gray-900">
                                  Subject
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setSubject(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="referralTypeID" className="block text-sm font-medium text-gray-700">
                                  Email
                                </label>
                                <div className="mt-1">
                                  <Editor
                                    initialValue={email}
                                    apiKey={`${REACT_APP_TINY_MCE_API_KEY}`}
                                    onInit={(evt, editor) => (editorRef.current = editor)}
                                    init={{
                                      height: 350,
                                      menubar: "edit insert view format table tools",
                                      plugins: "lists link table code",
                                      paste_as_text: false,
                                      paste_block_drop: true,
                                      toolbar:
                                        "undo redo | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | image link | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Refer Case
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

ReferOut.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  savedReferral: PropTypes.func,
};
