import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { apiError, nationalPhoneNumber, shortDate, parseAddress } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

import CaseDetailHeader from "../../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseOverviewTabs from "../../../layouts/FullLayout/Tabs/CaseOverviewTabs";

import FundingSummaryBlock from "../../../components/Blocks/FundingSummaryBlock";
import EssureProductLiabilityBlock from "../../../components/Blocks/EssureProductLiabilityBlock";
import JonesMaritimeBlock from "../../../components/Blocks/JonesMaritimeBlock";
import OtherAccidentBlock from "../../../components/Blocks/OtherAccidentBlock";
import SlipAndFallBlock from "../../../components/Blocks/SlipAndFallBlock";
import MotorVehicleAccidentBlock from "../../../components/Blocks/MotorVehicleAccidentBlock";
import WorkersCompensationBlock from "../../../components/Blocks/WorkersCompensationBlock";

const { REACT_APP_API_URL } = process.env;

export default function CaseOverview() {
  const { id } = useParams();
  const [clientName, setClientName] = useState("-");
  const [serviceProvider, setServiceProvider] = useState("");
  const [accidentDate, setAccidentDate] = useState("");
  const [phone, setPhone] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [accidentType, setAccidentType] = useState("");
  const [accidentState, setAccidentState] = useState("");

  const [fundingApplications, setFundingApplications] = useState([]);

  const [essureDetails, setEssureDetails] = useState("-");
  const [essureSettlement, setEssureSettlement] = useState("-");
  const [essureSurgery, setEssureSurgery] = useState(false);
  const [essureSurgeryYear, setEssureSurgeryYear] = useState("-");
  const [essureYearPlaced, setEssureYearPlaced] = useState("-");

  const [jamDetails, setJamDetails] = useState("-");
  const [jamInjuries, setJamInjuries] = useState("-");
  const [jamTreatment, setJamTreatment] = useState("-");
  const [jamHealthInsurance, setJamHealthInsurance] = useState("-");
  const [jamNameOfEmployer, setJamNameOfEmployer] = useState("-");
  const [jamJobDetails, setJamJobDetails] = useState("-");
  const [jamStillEmployed, setJamStillEmployed] = useState("-");

  const [otherInvolves, setOtherInvolves] = useState("-");
  const [otherName, setOtherName] = useState("-");
  const [otherRelationship, setOtherRelationship] = useState("-");
  const [otherDetails, setOtherDetails] = useState("-");

  const [safDetails, setSafDetails] = useState("-");
  const [safInjuries, setSafInjuries] = useState("-");
  const [safTreatment, setSafTreatment] = useState("-");
  const [safHealthInsurance, setSafHealthInsurance] = useState("-");

  const [mvDetails, setMvDetails] = useState("-");
  const [mvInjuries, setMvInjuries] = useState("-");
  const [mvTreatment, setMvTreatment] = useState("-");
  const [mvHealthInsurance, setMvHealthInsurance] = useState("-");
  const [mvAtFaultDriverInsurance, setMvAtFaultDriverInsurance] = useState("-");
  const [mvOccupantType, setMvOccupantType] = useState("-");
  const [mvPassengerNotes, setMvPassengerNotes] = useState("-");
  const [mvDriverInsurance, setMvDriverInsurance] = useState("-");
  const [mvOtherOccupants, setMvOtherOccupants] = useState(false);
  const [mvHowMany, setMvHowMany] = useState("-");
  const [mvOtherDetails, setMvOtherDetails] = useState("-");
  const [mvTotalled, setMvTotalled] = useState("-");

  const [wcDetails, setWcDetails] = useState("-");
  const [wcInjuries, setWcInjuries] = useState("-");
  const [wcTreatment, setWcTreatment] = useState("-");
  const [wcHealthInsurance, setWcHealthInsurance] = useState("-");
  const [wcNameOfEmployer, setWcNameOfEmployer] = useState("-");
  const [wcJobDetails, setWcJobDetails] = useState("-");
  const [wcStillEmployed, setWcStillEmployed] = useState("-");

  const [referredBy, setReferredBy] = useState("");
  const [tag, setTag] = useState("");
  const [notes, setNotes] = useState([]);
  const [liability, setLiability] = useState("-");
  const [pip, setPip] = useState("-");
  const [otherLiens, setOtherLiens] = useState("-");
  const [medicalBills, setMedicalBills] = useState("-");
  const [injuries, setInjuries] = useState("-");
  const [treatment, setTreatment] = useState("-");
  const [otherOffers, setOtherOffers] = useState("-");
  const [occupantType, setOccupantType] = useState("-");
  const [atFaultVehicle, setAtFaultVehicle] = useState("-");
  const [vehicleDamage, setVehicleDamage] = useState("-");
  const [insuranceCompanies, setInsuranceCompanies] = useState("-");
  const [insuranceLimits, setInsuranceLimits] = useState("-");
  const [minorChild, setMinorChild] = useState("-");
  const [minorName, setMinorName] = useState("-");

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details";
    setAuthToken();
    Promise.all([
      axios.get(`${REACT_APP_API_URL}/lawsuits/${id}`),
      axios.get(`${REACT_APP_API_URL}/lawsuits/${id}/notes`),
    ])
      .then((response) => {
        const lawsuit = response[0].data;
        setClientName(lawsuit.firstName + " " + lawsuit.lastName);
        setServiceProvider(lawsuit.serviceProvider.name);
        setAccidentDate(lawsuit.accidentDate);
        setPhone(lawsuit.phone);
        setPrimaryContact(lawsuit.serviceProviderContact.firstName + " " + lawsuit.serviceProviderContact.lastName);
        setEmail(lawsuit.email);
        setAccidentType(lawsuit.accidentType);
        setAccidentState(lawsuit.accidentState.label);

        setAddress(parseAddress(lawsuit));

        setReferredBy(lawsuit.userReference);
        setTag(lawsuit.tag ? lawsuit.tag.label : "");
        setNotes(response[1].data);
        if (lawsuit.underwriting) {
          setLiability(lawsuit.underwriting.liability);
          setPip(lawsuit.underwriting.pip);
          setOtherLiens(lawsuit.underwriting.liens);
          setMedicalBills(lawsuit.underwriting.medicalBills);
          setInjuries(lawsuit.underwriting.injuries);
          setTreatment(lawsuit.underwriting.treatment);
          setOtherOffers(lawsuit.underwriting.offers);
          setOccupantType(lawsuit.underwriting.occupantType);
          setAtFaultVehicle(lawsuit.underwriting.atFaultVehicle);
          setVehicleDamage(lawsuit.underwriting.vehicleDamage);
          setInsuranceCompanies(lawsuit.underwriting.insuranceCompanies);
          setInsuranceLimits(lawsuit.underwriting.insuranceLimits);
          if (lawsuit.underwriting.minor) {
            setMinorChild("Yes");
          } else {
            setMinorChild("No");
          }

          setMinorName(lawsuit.underwriting.minorName);
        }

        if (lawsuit.type === "Product Liability - Essure") {
          if (lawsuit.essureProductLiabilityAccident) {
            setEssureDetails(lawsuit.essureProductLiabilityAccident.details);
            setEssureSettlement(lawsuit.essureProductLiabilityAccident.settlement);
            setEssureSurgery(lawsuit.essureProductLiabilityAccident.surgery);
            if (lawsuit.essureProductLiabilityAccident.surgery) {
              setEssureSurgeryYear(lawsuit.essureProductLiabilityAccident.surgeryYear.toString());
            }
            setEssureYearPlaced(lawsuit.essureProductLiabilityAccident.yearPlaced.toString());
          }
        } else if (lawsuit.accidentType === "Jones Act / Maritime") {
          if (lawsuit.jonesActAccident) {
            setJamDetails(lawsuit.jonesActAccident.details);
            setJamInjuries(lawsuit.jonesActAccident.injuries);
            setJamTreatment(lawsuit.jonesActAccident.treatment);
            setJamHealthInsurance(lawsuit.jonesActAccident.healthInsurance);
            setJamNameOfEmployer(lawsuit.jonesActAccident.nameOfEmployer);
            setJamJobDetails(lawsuit.jonesActAccident.jobDetails);
            setJamStillEmployed(lawsuit.jonesActAccident.stillEmployed);
          }
        } else if (lawsuit.accidentType === "Other") {
          if (lawsuit.otherAccident) {
            setOtherInvolves(lawsuit.otherAccident.involves);
            setOtherName(lawsuit.otherAccident.otherName);
            setOtherInvolves(lawsuit.otherAccident.involves);
            setOtherRelationship(lawsuit.otherAccident.relationship);
            setOtherDetails(lawsuit.otherAccident.details);
          }
        } else if (lawsuit.accidentType === "Slip and Fall") {
          if (lawsuit.slipAndFallAccident) {
            setSafDetails(lawsuit.slipAndFallAccident.details);
            setSafInjuries(lawsuit.slipAndFallAccident.injuries);
            setSafTreatment(lawsuit.slipAndFallAccident.treatment);
            setSafHealthInsurance(lawsuit.slipAndFallAccident.healthInsurance);
          }
        } else if (lawsuit.accidentType === "Motor Vehicle Accident") {
          if (lawsuit.motorVehicleAccident) {
            setMvDetails(lawsuit.motorVehicleAccident.details);
            setMvInjuries(lawsuit.motorVehicleAccident.injuries);
            setMvTreatment(lawsuit.motorVehicleAccident.treatment);
            setMvHealthInsurance(lawsuit.motorVehicleAccident.healthInsurance);
            setMvAtFaultDriverInsurance(lawsuit.motorVehicleAccident.atFaultDriverInsurance);
            setMvOccupantType(lawsuit.motorVehicleAccident.occupantType);
            setMvPassengerNotes(lawsuit.motorVehicleAccident.passengerNotes);
            setMvDriverInsurance(lawsuit.motorVehicleAccident.driverInsurance);
            setMvOtherOccupants(lawsuit.motorVehicleAccident.otherOccupants);
            setMvHowMany(lawsuit.motorVehicleAccident.howMany);
            setMvOtherDetails(lawsuit.motorVehicleAccident.otherDetails);
            setMvTotalled(lawsuit.motorVehicleAccident.totalled);
          }
        } else if (lawsuit.accidentType === "Workers Compensation") {
          if (lawsuit.workersCompensationAccident) {
            setWcDetails(lawsuit.workersCompensationAccident.details);
            setWcInjuries(lawsuit.workersCompensationAccident.injuries);
            setWcTreatment(lawsuit.workersCompensationAccident.treatment);
            setWcHealthInsurance(lawsuit.workersCompensationAccident.healthInsurance);
            setWcNameOfEmployer(lawsuit.workersCompensationAccident.nameOfEmployer);
            setWcJobDetails(lawsuit.workersCompensationAccident.jobDetails);
            setWcStillEmployed(lawsuit.workersCompensationAccident.stillEmployed);
          }
        }

        setFundingApplications(lawsuit.fundingApplications);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id, address]);

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Case Overview" lawsuitID={id} />
        <div className="ml-8 mr-8">
          <CaseOverviewTabs lawsuitID={id} selected="Overview" />
        </div>
        <div className="mb-8 mt-2 flex flex-col ">
          <div className="ml-8 mr-8 overflow-hidden rounded-lg bg-white shadow">
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-3">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Client Name</dt>
                  <dd className="mt-1 text-sm text-gray-900">{clientName}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Service Provider</dt>
                  <dd className="mt-1 text-sm text-gray-900">{serviceProvider}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Accident Date</dt>
                  <dd className="mt-1 text-sm text-gray-900">{shortDate(accidentDate)}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Client Phone #</dt>
                  <dd className="mt-1 text-sm text-gray-900">{nationalPhoneNumber(phone)}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Primary Contact</dt>
                  <dd className="mt-1 text-sm text-gray-900">{primaryContact}</dd>
                </div>
                <div className="sm:col-span-1"></div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900">{address}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Email Address</dt>
                  <dd className="mt-1 text-sm text-gray-900">{email}</dd>
                </div>
                <div className="sm:col-span-1"></div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Type of Case</dt>
                  <dd className="mt-1 text-sm text-gray-900">{accidentType}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">State Accident Occured</dt>
                  <dd className="mt-1 text-sm text-gray-900">{accidentState}</dd>
                </div>
                <div className="sm:col-span-1"></div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">How did Client hear about us?</dt>
                  <dd className="mt-1 text-sm text-gray-900">{referredBy}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Tag</dt>
                  <dd className="mt-1 text-sm text-gray-900">{tag}</dd>
                </div>
              </dl>
            </div>
            <FundingSummaryBlock applications={fundingApplications} />
            {accidentType === "Product Liability - Essure" && (
              <EssureProductLiabilityBlock
                accidentDate={accidentDate}
                accidentType={accidentType}
                details={essureDetails}
                settlement={essureSettlement}
                surgery={essureSurgery}
                surgeryYear={essureSurgeryYear}
                yearPlaced={essureYearPlaced}
              />
            )}
            {accidentType === "Jones Act / Maritime" && (
              <JonesMaritimeBlock
                accidentDate={accidentDate}
                accidentType={accidentType}
                details={jamDetails}
                injuries={jamInjuries}
                treatment={jamTreatment}
                healthInsurance={jamHealthInsurance}
                nameOfEmployer={jamNameOfEmployer}
                jobDetails={jamJobDetails}
                stillEmployed={jamStillEmployed}
              />
            )}
            {accidentType === "Other" && (
              <OtherAccidentBlock
                accidentDate={accidentDate}
                accidentType={accidentType}
                involves={otherInvolves}
                otherName={otherName}
                relationship={otherRelationship}
                details={otherDetails}
              />
            )}
            {accidentType === "Slip and Fall" && (
              <SlipAndFallBlock
                accidentDate={accidentDate}
                accidentType={accidentType}
                details={safDetails}
                injuries={safInjuries}
                treatment={safTreatment}
                healthInsurance={safHealthInsurance}
              />
            )}
            {accidentType === "Motor Vehicle Accident" && (
              <MotorVehicleAccidentBlock
                accidentDate={accidentDate}
                accidentType={accidentType}
                details={mvDetails}
                injuries={mvInjuries}
                treatment={mvTreatment}
                healthInsurance={mvHealthInsurance}
                atFaultDriverInsurance={mvAtFaultDriverInsurance}
                occupantType={mvOccupantType}
                passengerNotes={mvPassengerNotes}
                driverInsurance={mvDriverInsurance}
                otherOccupants={mvOtherOccupants}
                howMany={mvHowMany}
                otherDetails={mvOtherDetails}
                totalled={mvTotalled}
              />
            )}
            {accidentType === "Workers Compensation" && (
              <WorkersCompensationBlock
                accidentDate={accidentDate}
                accidentType={accidentType}
                details={wcDetails}
                injuries={wcInjuries}
                treatment={wcTreatment}
                healthInsurance={wcHealthInsurance}
                nameOfEmployer={wcNameOfEmployer}
                jobDetails={wcJobDetails}
                stillEmployed={wcStillEmployed}
              />
            )}
            <div className="mt-2 overflow-hidden bg-white">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Underwriting Questions</h3>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Liability</dt>
                    <dd className="mt-1 text-sm text-gray-900">{liability}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">PIP</dt>
                    <dd className="mt-1 text-sm text-gray-900">{pip}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Other Liens</dt>
                    <dd className="mt-1 text-sm text-gray-900">{otherLiens}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Medical Bills</dt>
                    <dd className="mt-1 text-sm text-gray-900">{medicalBills}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Injuries</dt>
                    <dd className="mt-1 text-sm text-gray-900">{injuries}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Treatment</dt>
                    <dd className="mt-1 text-sm text-gray-900">{treatment}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Other Offers</dt>
                    <dd className="mt-1 text-sm text-gray-900">{otherOffers}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Occupant Type</dt>
                    <dd className="mt-1 text-sm text-gray-900">{occupantType}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">At fault vehicle</dt>
                    <dd className="mt-1 text-sm text-gray-900">{atFaultVehicle}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Vehicle Damage</dt>
                    <dd className="mt-1 text-sm text-gray-900">{vehicleDamage}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Insurance Companies</dt>
                    <dd className="mt-1 text-sm text-gray-900">{insuranceCompanies}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Insurance Limits</dt>
                    <dd className="mt-1 text-sm text-gray-900">{insuranceLimits}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Minor Child</dt>
                    <dd className="mt-1 text-sm text-gray-900">{minorChild}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Minor Name</dt>
                    <dd className="mt-1 text-sm text-gray-900">{minorName}</dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="mt-2 overflow-hidden bg-white">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Other Notes</h3>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                {notes.length > 0 ? (
                  <ul className="m-10">
                    {notes.map((note) => (
                      <li key={note.id} className="m-10 rounded-lg bg-gray-100 p-6 py-4">
                        <div className="flex space-x-3">
                          <div className="flex-1 space-y-1">
                            <div className="flex items-center justify-between">
                              <h3 className="text-sm font-medium">
                                {note.user.firstName} {note.user.lastName}
                              </h3>
                              <p className="text-sm text-gray-400">{moment(note.createdAt).format("LLLL")}</p>
                            </div>
                            <p className="text-sm text-gray-500">{note.text}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="text-center">
                    <p className="text-sm text-gray-500">No Notes</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
