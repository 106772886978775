import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function ContractShortCodes() {
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  return (
    <div>
      <button className="font-sm text-brand-700 hover:text-brand-500" onClick={handleClickOpen}>
        Short Codes
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-sm">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Contract Short Codes</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <ul className="divide-y divide-gray-200">
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Case Number</p>
                                    <p className="text-sm text-brand-700">{"{{ CaseNumber }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Plaintiff Full Name</p>
                                    <p className="text-sm text-brand-700">{"{{ PlaintiffFullName }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Approved Amount</p>
                                    <p className="text-sm text-brand-700">{"{{ ApprovedAmount }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Amount Due</p>
                                    <p className="text-sm text-brand-700">{"{{ AmountDue }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Application Fees</p>
                                    <p className="text-sm text-brand-700">{"{{ ApplicationFees }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Total One Time Fees</p>
                                    <p className="text-sm text-brand-700">{"{{ TotalOneTimeFees }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Plaintiff Signature</p>
                                    <p className="text-sm text-brand-700">{"{{ PlaintiffSignature }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Plaintiff Address</p>
                                    <p className="text-sm text-brand-700">{"{{ PlaintiffAddress }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Plaintiff Contract Signing Date</p>
                                    <p className="text-sm text-brand-700">{"{{ PlaintiffContractSigningDate }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">ServiceProvider</p>
                                    <p className="text-sm text-brand-700">{"{{ ServiceProvider }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      Primary Service Provider Contact Signature
                                    </p>
                                    <p className="text-sm text-brand-700">
                                      {"{{ PrimaryServiceProviderContactSignature }}"}
                                    </p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      Primary Service Provider Contact Full Name
                                    </p>
                                    <p className="text-sm text-brand-700">
                                      {"{{ PrimaryServiceProviderContactFullName }}"}
                                    </p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      Primary Service Provider Contact Signing Date
                                    </p>
                                    <p className="text-sm text-brand-700">
                                      {"{{ PrimaryServiceProviderContactSigningDate }}"}
                                    </p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      Company Representative Full Name
                                    </p>
                                    <p className="text-sm text-brand-700">{"{{ CompanyRepresentativeFullName }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      Company Representative Signature
                                    </p>
                                    <p className="text-sm text-brand-700">{"{{ CompanyRepresentativeSignature }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Company Representative Title</p>
                                    <p className="text-sm text-brand-700">{"{{ CompanyRepresentativeTitle }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Current Date</p>
                                    <p className="text-sm text-brand-700">{"{{ CurrentDate }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Accident Date</p>
                                    <p className="text-sm text-brand-700">{"{{ AccidentDate }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">APR</p>
                                    <p className="text-sm text-brand-700">{"{{ APR }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Page Break</p>
                                    <p className="text-sm text-brand-700">{"{{ PageBreak }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Funded Applications</p>
                                    <p className="text-sm text-brand-700">{"{{ FundedApplications }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Prior Funding Company</p>
                                    <p className="text-sm text-brand-700">{"{{ PriorFundingCompany }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Buyout Payoff</p>
                                    <p className="text-sm text-brand-700">{"{{ BuyoutPayoff }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Buyout Fee</p>
                                    <p className="text-sm text-brand-700">{"{{ BuyoutFee }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Total Amount Due</p>
                                    <p className="text-sm text-brand-700">{"{{ TotalAmountDue }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Approved Amount + Buyout Payoff</p>
                                    <p className="text-sm text-brand-700">{"{{ ApprovedAmountPlusBuyoutPayoff }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">APR Table</p>
                                    <p className="text-sm text-brand-700">{"{{ APRTable }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">APR 12 Months</p>
                                    <p className="text-sm text-brand-700">{"{{ APR12Months }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">APR 18 Months</p>
                                    <p className="text-sm text-brand-700">{"{{ APR18Months }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">APR 24 Months</p>
                                    <p className="text-sm text-brand-700">{"{{ APR24Months }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">APR 30 Months</p>
                                    <p className="text-sm text-brand-700">{"{{ APR30Months }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">APR 36 Months</p>
                                    <p className="text-sm text-brand-700">{"{{ APR36Months }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">MinorChild</p>
                                    <p className="text-sm text-brand-700">{"{{ MinorChild }}"}</p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
