import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../datagrid.css";

import { apiError, dateComparator } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

import CaseDetailHeader from "../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseDocumentTabs from "../../layouts/FullLayout/Tabs/CaseDocumentTabs";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import EmailTypeLabel from "../../components/Labels/EmailTypeLabel";
import DownloadInboxAttachment from "../../components/Buttons/DownloadAttachment";

const { REACT_APP_API_URL } = process.env;

export default function CaseDocumentAttachments() {
  const { id } = useParams();
  const gridRef = useRef();
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - Documents - Attachments";
  });

  const [columnDefs] = useState([
    {
      field: "originalName",
      headerName: "Attachment",
      minWidth: 450,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        return <EmailTypeLabel type={params.value} />;
      },
    },
    {
      field: "createdAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Created",
      minWidth: 200,
      cellRenderer: (params) => {
        return moment(params.data.createdAt).fromNow();
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      minWidth: 150,
      cellRenderer: optionRender,
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/attachments?lawsuitID=${id}`)
      .then((res) => {
        setAttachments(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function optionRender(params) {
    return (
      <div className="flex justify-end space-x-4">
        <DownloadInboxAttachment type={params.data.type} id={params.data.id} />
        <DeleteDialog
          tooltip="Delete"
          title="Delete Attachment?"
          content="Are you sure you want to delete the attachment?"
          id={params.value}
          idToDelete={handleDelete}
        />
      </div>
    );
  }

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/attachments/${idToDelete}`)
      .then(() => {
        toast.success("Attachment deleted");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/attachments?lawsuitID=${id}`)
      .then((response) => {
        setAttachments(response.data.receivedAttachments.concat(response.data.sentAttachments));
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Documents" lawsuitID={id} />
        <div className="ml-8 mr-8">
          <CaseDocumentTabs lawsuitID={id} selected="Email Attachments" />
        </div>
        <div className="mt-2 mb-8 flex flex-col">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 750 }}>
              <AgGridReact
                ref={gridRef}
                rowData={attachments}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
                overlayNoRowsTemplate={"No attachments on file"}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
