import PropTypes from "prop-types";
import { longDate } from "../../libs/Utils";

import ReplyToSentEmail from "../SlideOvers/Email/ReplyToSentEmail";

export default function SentEmailHeader(props) {
  return (
    <div className="m-2 rounded-lg bg-white p-4 shadow">
      <ul className="grid grid-cols-2">
        <li>
          <div className="text-sm font-medium sm:flex sm:space-x-5">
            From:
            <span className="ml-1 font-light text-gray-500">{props.from}</span>
          </div>
        </li>
        <li>
          <div className="float-right text-sm font-medium sm:flex sm:space-x-5">
            <span className="ml-1 font-light text-gray-500">{longDate(props.date)}</span>
          </div>
        </li>
        <li className="col-span-2">
          <div className="text-sm font-medium sm:flex sm:space-x-5">
            To:
            <span className="ml-1 font-light text-gray-500">{props.to}</span>
          </div>
        </li>
        <li className="col-span-2">
          <div className="text-sm font-medium sm:flex sm:space-x-5">
            Cc:
            <span className="ml-1 font-light text-gray-500">{props.cc}</span>
          </div>
        </li>
        <li>
          <div className="text-sm font-medium sm:flex sm:space-x-5">
            Bcc:
            <span className="ml-1 font-light text-gray-500">{props.bcc}</span>
          </div>
        </li>
        <li>
          <div className="float-right text-sm font-medium sm:flex sm:space-x-5">
            <ReplyToSentEmail id={props.id} />
          </div>
        </li>
      </ul>
      <div className="mt-2">
        <hr />
      </div>
      <div className="mt-2">{props.subject}</div>
    </div>
  );
}

SentEmailHeader.propTypes = {
  id: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  cc: PropTypes.string,
  bcc: PropTypes.string,
  subject: PropTypes.string,
  date: PropTypes.string,
};
