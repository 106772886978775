import React, { useCallback, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { minHeight, dateFromNow } from "../../libs/Utils";
import { client, clientError } from "../../libs/Client";
import EmailTabs from "../../layouts/FullLayout/Tabs/EmailTabs";

export default function Sent() {
  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/mail/sent?skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`)
      .then((res) => {
        return { data: res.data.sent, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, []);

  useEffect(() => {
    document.title = "Capital Now Funding - Email - Sent";
  });

  const gridStyle = { minHeight: minHeight };

  const columns = [
    {
      name: "lawsuitID",
      header: "Case #",
      defaultWidth: 100,
      render: ({ value }) => (
        <a type="button" href={`/cases/${value}/feed`} className="text-brand-700 hover:text-brand-500">
          {value}
        </a>
      ),
      textAlign: "end",
      headerAlign: "end",
    },
    {
      name: "attachments",
      header: "",
      defaultWidth: 50,
      render: ({ value }) => {
        if (value > 0) {
          return <PaperClipIcon className="h-4 w-4 text-left text-gray-800" />;
        } else {
          return "";
        }
      },
    },
    {
      name: "email",
      header: "To",
      defaultWidth: 350,
    },
    {
      name: "subject",
      header: "Subject",
      defaultWidth: 350,
      defaultFlex: 1,
      render: ({ data }) => {
        return (
          <a type="button" href={`/email/sent/${data.id}`} className="text-brand-700 hover:text-brand-500">
            {data.subject}
          </a>
        );
      },
    },
    {
      name: "createdAt",
      header: "Sent",
      render: ({ value }) => dateFromNow(value),
    },
  ];

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <EmailTabs selected="Sent" />
        <ReactDataGrid
          idProperty="sent"
          showColumnMenuTool={false}
          columns={columns}
          style={gridStyle}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
