import { Fragment, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";

import { apiError } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

const { REACT_APP_API_URL } = process.env;

export default function DuplicateCases(props) {
  const [open, setOpen] = useState(false);
  const [lawsuits, setLawsuits] = useState([]);

  function handleCheckDuplicates() {
    if (props.firstName === "") {
      toast.error("First name required");
      return;
    }

    if (props.lastName === "") {
      toast.error("Last name required");
      return;
    }

    if (props.phone === "") {
      toast.error("Phone required");
      return;
    }

    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/lawsuits/duplicates`, {
        firstName: props.firstName,
        lastName: props.lastName,
        email: props.email,
        phone: props.phone,
      })
      .then((res) => {
        if (res.data.length === 0) {
          props.create(true);
        } else {
          setOpen(true);
          setLawsuits(res.data);
        }
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleCreateCase() {
    setOpen(false);
    props.create(true);
  }

  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2 sm:w-auto"
        onClick={handleCheckDuplicates}
      >
        Save
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Duplicate Cases</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 whitespace-pre pb-5 pt-6">
                              <ul className="divide-y divide-gray-100">
                                {lawsuits.map((lawsuit) => (
                                  <li key={lawsuit.id} className="relative flex justify-between gap-x-6 py-5">
                                    <div className="flex gap-x-4">
                                      <div className="min-w-0 flex-auto">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">
                                          {lawsuit.firstName} {lawsuit.lastName}
                                        </p>
                                        <p className="mt-1 flex text-xs leading-5 text-gray-500">{lawsuit.email}</p>
                                      </div>
                                    </div>
                                    <div className="flex items-center gap-x-4">
                                      <div className="hidden sm:flex sm:flex-col sm:items-end">
                                        <a
                                          href={`/cases/${lawsuit.id}/overview`}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="font-semibold text-brand-700 hover:text-brand-500"
                                        >
                                          {lawsuit.id}
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => handleCreateCase()}
                        >
                          Create Case
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

DuplicateCases.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  create: PropTypes.func,
};
