import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition, Switch } from "@headlessui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { client, clientError } from "../../../libs/Client";
import { classNames, vaporDate, currencyNoCents } from "../../../libs/Utils";

export default function CloseCase(props) {
  const [open, setOpen] = useState(false);
  const [totalPaybackDue, setTotalPaybackDue] = useState(0);
  const [amount, setAmount] = useState(0);
  const [checkNumber, setCheckNumber] = useState("");
  const [checkDate, setCheckDate] = useState("");
  const [note, setNote] = useState("");
  const [notifyServiceProvider, setNotifyServiceProvider] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      client()
        .get(`/lawsuits/${props.id}/dues`)
        .then((res) => {
          if (res.data > 0) {
            setTotalPaybackDue(res.data);
          }
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [open, props.id]);

  function handleSave() {
    client()
      .post(`/payments/close`, {
        lawsuitID: parseInt(props.id),
        amount: parseInt(amount) * 100,
        notes: note,
        checkNumber,
        checkDate: vaporDate(checkDate),
        notifyServiceProvider,
      })
      .then(() => {
        setOpen(false);
        props.saved(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div>
      <button
        className="w-full bg-red-100 px-6 py-3 text-center text-sm font-medium text-red-500 hover:bg-red-200"
        onClick={handleClickOpen}
      >
        Close Case
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Close Case</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label
                                  htmlFor="initialFundingRequestAmount"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Total Payback Due
                                </label>

                                <div className="relative mt-1 rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    name="totalPaybackDue"
                                    id="totalPaybackDue"
                                    className="block w-full rounded-md border-gray-300 pl-2.5 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    aria-describedby="price-currency"
                                    value={currencyNoCents(totalPaybackDue)}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="initialFundingRequestAmount"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Payment Received
                                </label>
                                <div className="relative mt-1 rounded-md shadow-sm">
                                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span className="text-gray-500 sm:text-sm">$</span>
                                  </div>
                                  <input
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    className="block w-full rounded-md border-gray-300 pl-7 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    aria-describedby="price-currency"
                                    onChange={(e) => setAmount(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">
                                  Check Number
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="checkNumber"
                                    id="checkNumber"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setCheckNumber(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="dueDate" className="block text-sm font-medium text-gray-900">
                                  Check Date
                                </label>
                                <div className="mt-1">
                                  <DatePicker
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                                    selected={checkDate}
                                    onChange={(date) => setCheckDate(date)}
                                    dateFormat="MM/dd/yyyy"
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="task" className="block text-sm font-medium text-gray-900">
                                  Note
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    rows="4"
                                    name="note"
                                    id="note"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-800 focus:ring-gray-800 sm:text-sm"
                                    onChange={(e) => setNote(e.target.value)}
                                  ></textarea>
                                </div>
                              </div>
                              <div>
                                <Switch.Group as="div" className="flex items-center justify-between">
                                  <span className="flex flex-grow flex-col">
                                    <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                      Notify Service Provider
                                    </Switch.Label>
                                    <Switch.Description
                                      as="span"
                                      className="text-sm text-gray-500"
                                    ></Switch.Description>
                                  </span>
                                  <Switch
                                    checked={notifyServiceProvider}
                                    onChange={setNotifyServiceProvider}
                                    className={classNames(
                                      notifyServiceProvider ? "bg-brand-700" : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        notifyServiceProvider ? "translate-x-5" : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Close Case
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

CloseCase.propTypes = {
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
