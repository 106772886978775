import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { classNames } from "../../../libs/Utils";
import { client, clientError } from "../../../libs/Client";

export default function AddServiceProviderUser(props) {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [role, setRole] = useState("");

  function handleClickOpen() {
    setOpen(true);
  }

  function handleSave() {
    const serviceProviderID = props.id;
    client()
      .post(`/service-provider-users`, {
        serviceProviderID,
        firstName,
        lastName,
        email,
        phone,
        address,
        enabled,
        role,
      })
      .then((res) => {
        setOpen(false);
        props.saved(res.data);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div>
      {props.textLink ? (
        <button className="font-base text-sm text-brand-700 hover:text-brand-500" onClick={handleClickOpen}>
          Add User
        </button>
      ) : (
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2 sm:w-auto"
          onClick={handleClickOpen}
        >
          Add User
        </button>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Add User</Dialog.Title>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-gray-200">Add new service provider user.</p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">
                                  First Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setFirstName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">
                                  Last Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setLastName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="lastName" className="block text-sm font-medium text-gray-900">
                                  Email
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                                  Phone
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="address" className="block text-sm font-medium text-gray-900">
                                  Address
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    rows="4"
                                    name="address"
                                    id="address"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={(e) => setAddress(e.target.value)}
                                  ></textarea>
                                </div>
                              </div>
                              <div>
                                <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                  Role
                                </label>
                                <select
                                  id="role"
                                  name="role"
                                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand-700 focus:outline-none focus:ring-brand-700 sm:text-sm"
                                  defaultValue=""
                                  onChange={(e) => setRole(e.target.value)}
                                >
                                  <option value="">Please select...</option>
                                  <option>Accounts Payable</option>
                                  <option>Attorney</option>
                                  <option>Case Manager</option>
                                  <option>Legal Assisstant</option>
                                  <option>Paralegal</option>
                                </select>
                              </div>
                              <div>
                                <Switch.Group as="div" className="flex items-center justify-between">
                                  <span className="flex flex-grow flex-col">
                                    <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                      Enabled
                                    </Switch.Label>
                                    <Switch.Description as="span" className="text-sm text-gray-500">
                                      Enable user in application
                                    </Switch.Description>
                                  </span>
                                  <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={classNames(
                                      enabled ? "bg-brand-700" : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        enabled ? "translate-x-5" : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

AddServiceProviderUser.propTypes = {
  textLink: PropTypes.bool,
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
