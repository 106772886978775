import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { PencilSquareIcon } from "@heroicons/react/24/outline";

import { setAuthToken } from "../../../hooks/Authentication";
import { apiError } from "../../../libs/Utils";

const { REACT_APP_API_URL } = process.env;

export default function EditSMSTemplate(props) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      setAuthToken();
      axios
        .get(`${REACT_APP_API_URL}/templates/sms/${props.id}`)
        .then((response) => {
          setTitle(response.data.title);
          setBody(response.data.body);
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }, [open, props.id]);

  function handleSave() {
    setAuthToken();
    axios
      .put(`${REACT_APP_API_URL}/templates/sms/${props.id}`, {
        title,
        body,
      })
      .then(() => {
        setOpen(false);
        toast.success("SMS template added");
        props.saved(true);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      {props.tooltip ? (
        <Tippy content={props.tooltip}>
          <button
            className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
            onClick={handleClickOpen}
          >
            <PencilSquareIcon className="h-4 w-4 text-indigo-500" />
          </button>
        </Tippy>
      ) : (
        <button
          className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
          onClick={handleClickOpen}
        >
          <PencilSquareIcon className="h-4 w-4 text-indigo-500" />
        </button>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Edit SMS Template</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label htmlFor="task" className="block text-sm font-medium text-gray-900">
                                  Template Title
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="body" className="block text-sm font-medium text-gray-900">
                                  Template Text
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    rows="4"
                                    name="address"
                                    id="address"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={(e) => setBody(e.target.value)}
                                    value={body}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EditSMSTemplate.propTypes = {
  tooltip: PropTypes.string,
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
