import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { CheckIcon, BanknotesIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { setAuthToken } from "../../hooks/Authentication";
import { apiError } from "../../libs/Utils";

import PageHeader from "../../components/PageHeader";
import EditStateNotes from "../../components/SlideOvers/EditStateNotes";

const { REACT_APP_API_URL } = process.env;

export default function States() {
  const gridRef = useRef();
  const [states, setStates] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Admin - States";
  });

  const [columnDefs] = useState([
    { field: "label", headerName: "State", filter: true },
    { field: "abbreviation", filter: true },
    {
      field: "fees",
      filter: false,
      type: "numericColumn",
      valueGetter: (params) => {
        return params.data.fees.length;
      },
    },
    {
      field: "notes",
      filter: false,
      cellRenderer: (params) => {
        if (params.value && params.value !== "") {
          return <CheckIcon className="h-6 w-6 text-green-500" />;
        } else {
          return "";
        }
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            <Tippy content="Fees">
              <a
                type="button"
                href={`/admin/states/${params.value}/fees`}
                className="inline-flex rounded border-transparent bg-emerald-100 px-2.5 py-1.5 text-xs font-medium hover:bg-emerald-200 focus:outline-none"
              >
                <BanknotesIcon className="h-4 w-4 text-emerald-500" />
              </a>
            </Tippy>
            <EditStateNotes tooltip="Edit Notes" id={params.value} saved={handleRefresh} />
          </div>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/states`)
      .then((res) => {
        setStates(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/states`)
      .then((res) => {
        setStates(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <PageHeader title="States" subtitle="State Management" />
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="mb-8 ml-2 mr-2">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 750 }}>
              <AgGridReact
                ref={gridRef}
                rowData={states}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
