import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { dateComparator, currency, shortDate, dateFromNow } from "../../libs/Utils";
import { client, clientError } from "../../libs/Client";
import PageHeader from "../../components/PageHeader";
import ServiceProviderTabs from "../../layouts/FullLayout/Tabs/ServiceProviderTabs";
import AvatarLabel from "../../components/Labels/AvatarLabel";
import ServiceProviderDetailHeader from "../../components/Headers/ServiceProviderDetailHeader";

export default function ServiceProviderFundingRequests() {
  const { id } = useParams();
  const gridRef = useRef();
  const [providerType, setProviderType] = useState("");
  const [fundingApplications, setFundingApplications] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Service Providers - Funding Requests";
    client()
      .get(`/service-providers/${id}`)
      .then((res) => {
        setProviderType(res.data.providerType);
      })
      .catch((err) => {
        clientError(err);
      });
  }, [id]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const [columnDefs] = useState([
    {
      field: "lawsuitID",
      minWidth: 150,
      headerName: "Case #",
      type: "numericColumn",
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        return (
          <a className="text-brand-700 hover:text-brand-500" href={`/cases/${params.data.lawsuitID}/feed`}>
            {params.data.lawsuitID}
          </a>
        );
      },
    },
    {
      field: "plaintiff",
      minWidth: 350,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      valueGetter: (params) => {
        return params.data.firstName + " " + params.data.lastName + " " + params.data.email;
      },
      cellRenderer: (params) => {
        return (
          <AvatarLabel
            firstName={params.data.firstName}
            lastName={params.data.lastName}
            email={params.data.email}
            profilePicURL={params.data.profilePicURL}
          />
        );
      },
    },
    {
      field: "accidentDate",
      minWidth: 200,
      filter: false,
      headerName: "Accident Date",
      comparator: dateComparator,
      cellRenderer: (params) => {
        return shortDate(params.value);
      },
    },
    {
      field: "createdAt",
      minWidth: 200,
      filter: false,
      headerName: "Application Date",
      comparator: dateComparator,
      cellRenderer: (params) => {
        return shortDate(params.data.createdAt);
      },
    },
    {
      field: "fundingApplicationStatus",
      minWidth: 200,
      filter: false,
      headerName: "Application Status",
    },
    {
      field: "status",
      minWidth: 200,
      filter: false,
      headerName: "Case Status",
    },
    {
      field: "amountRequested",
      minWidth: 200,
      headerName: "Amount Requested",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      type: "numericColumn",
      valueFormatter: (params) => {
        return currency(params.value);
      },
    },
    {
      field: "latestActivity",
      minWidth: 200,
      filter: false,
      headerName: "Latest Case Activity",
      comparator: dateComparator,
      cellRenderer: (params) => {
        return dateFromNow(params.value);
      },
    },
  ]);

  const onGridReady = useCallback(() => {
    client()
      .get(`/service-providers/${id}/funding-requests`)
      .then((res) => {
        setFundingApplications(res.data);
      })
      .catch((err) => {
        clientError(err);
      });
  }, [id]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <PageHeader title="Service Provider" subtitle={providerType} />
      </div>
      <div className="mt-2">
        <ServiceProviderDetailHeader id={id} />
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
          <ServiceProviderTabs id={id} selected="Funding Requests" />
          <div className="ml-2 mr-2 mb-8">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 750 }}>
              <AgGridReact
                ref={gridRef}
                rowData={fundingApplications}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                rowHeight={50}
                suppressCellFocus={true}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
