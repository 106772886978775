import PropTypes from "prop-types";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { client, clientError } from "../../libs/Client";

export default function EmailSent(props) {
  function handleSent() {
    client()
      .put(`/funding-applications/${props.id}/email-sent`, {})
      .then(() => {
        props.saved(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleNotSent() {
    client()
      .put(`/funding-applications/${props.id}/email-not-sent`, {})
      .then(() => {
        props.saved(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  if (props.sent) {
    return (
      <Tippy content="Set Email Unsent">
        <button
          type="button"
          className="inline-flex rounded border-transparent bg-green-100 px-2.5 py-1.5 text-xs font-medium hover:bg-green-200 focus:outline-none"
          onClick={handleNotSent}
        >
          <EnvelopeIcon className="h-4 w-4 text-green-500" />
        </button>
      </Tippy>
    );
  }

  return (
    <Tippy content="Set Email Sent">
      <button
        className="inline-flex rounded border-transparent bg-red-100 px-2.5 py-1.5 text-xs font-medium hover:bg-red-200 focus:outline-none"
        onClick={handleSent}
      >
        <EnvelopeIcon className="h-4 w-4 text-red-500" />
      </button>
    </Tippy>
  );
}

EmailSent.propTypes = {
  sent: PropTypes.bool,
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
