import PropTypes from "prop-types";

import EditAccidentDetails from "../SlideOvers/Cases/Accident/MotorVehicle/EditAccidentDetails";
import EditInjuries from "../SlideOvers/Cases/Accident/MotorVehicle/EditInjuries";
import EditTreatment from "../SlideOvers/Cases/Accident/MotorVehicle/EditTreatment";
import EditHealthInsurance from "../SlideOvers/Cases/Accident/MotorVehicle/EditHealthInsurance";
import EditAtFaultDriverInsurance from "../SlideOvers/Cases/Accident/MotorVehicle/EditAtFaultDriverInsurance";
import EditOccupantType from "../SlideOvers/Cases/Accident/MotorVehicle/EditOccupantType";
import EditDriverInsurance from "../SlideOvers/Cases/Accident/MotorVehicle/EditDriverInsurance";
import EditPassengetNotes from "../SlideOvers/Cases/Accident/MotorVehicle/EditPassengerNotes";
import EditOtherOccupants from "../SlideOvers/Cases/Accident/MotorVehicle/EditOtherOccupants";
import EditHowMany from "../SlideOvers/Cases/Accident/MotorVehicle/EditHowMany";
import EditTotalled from "../SlideOvers/Cases/Accident/MotorVehicle/EditTotalled";
import EditOtherDetails from "../SlideOvers/Cases/Accident/MotorVehicle/EditOtherDetails";

export default function MotorVehicleAccident(props) {
  return (
    <dl className="ml-8 mr-8 divide-y divide-gray-200">
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Accident Details - What Happened?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.details}</span>
          <span className="ml-4 flex-shrink-0">
            <EditAccidentDetails id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Injuries Sustained?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.injuries}</span>
          <span className="ml-4 flex-shrink-0">
            <EditInjuries id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">
          Treatment - when, what kind, how long, are you still treating?
        </dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.treatment}</span>
          <span className="ml-4 flex-shrink-0">
            <EditTreatment id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">
          Did you have health insurance at the time of the accident?
        </dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.healthInsurance}</span>
          <span className="ml-4 flex-shrink-0">
            <EditHealthInsurance id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Did the at fault driver have insurance?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.atFaultDriverInsurance}</span>
          <span className="ml-4 flex-shrink-0">
            <EditAtFaultDriverInsurance id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">
          Were you the driver or passenger in the vehicle, or a pedestrian?
        </dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.occupantType}</span>
          <span className="ml-4 flex-shrink-0">
            <EditOccupantType id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      {props.occupantType === "Passenger" && (
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">Where were you sitting as a passenger?</dt>
          <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span className="flex-grow">{props.passengerNotes}</span>
            <span className="ml-4 flex-shrink-0">
              <EditPassengetNotes id={props.id} saved={props.saved} />
            </span>
          </dd>
        </div>
      )}
      {props.occupantType === "Driver" && (
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">Did you have insurance?</dt>
          <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span className="flex-grow">{props.driverInsurance}</span>
            <span className="ml-4 flex-shrink-0">
              <EditDriverInsurance id={props.id} saved={props.saved} />
            </span>
          </dd>
        </div>
      )}
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">
          Were other people in the vehicle with you at the time of the accident?
        </dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.otherOccupants ? "Yes" : "No"}</span>
          <span className="ml-4 flex-shrink-0">
            <EditOtherOccupants id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      {props.otherOccupants && (
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">How many others?</dt>
          <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span className="flex-grow">{props.howMany}</span>
            <span className="ml-4 flex-shrink-0">
              <EditHowMany id={props.id} saved={props.saved} />
            </span>
          </dd>
        </div>
      )}
      {props.otherOccupants && (
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">Other Details</dt>
          <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span className="flex-grow">{props.otherDetails}</span>
            <span className="ml-4 flex-shrink-0">
              <EditOtherDetails id={props.id} saved={props.saved} />
            </span>
          </dd>
        </div>
      )}

      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Was the vehicle totaled as a result of the accident?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.totalled}</span>
          <span className="ml-4 flex-shrink-0">
            <EditTotalled id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
    </dl>
  );
}

MotorVehicleAccident.propTypes = {
  id: PropTypes.string,
  details: PropTypes.string,
  injuries: PropTypes.string,
  treatment: PropTypes.string,
  healthInsurance: PropTypes.string,
  atFaultDriverInsurance: PropTypes.string,
  occupantType: PropTypes.string,
  passengerNotes: PropTypes.string,
  driverInsurance: PropTypes.string,
  otherOccupants: PropTypes.bool,
  howMany: PropTypes.string,
  otherDetails: PropTypes.string,
  totalled: PropTypes.string,
  saved: PropTypes.func,
};
