import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

export default function Expired() {
  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg">
        <img
          className="mx-auto h-13 w-auto"
          src="/images/logos/login-logo.png"
          alt="Capital Now Funding"
        />
        <div className="rounded-md bg-yellow-50 p-4 mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Link no longer valid
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  The link you clicked on has expired or is no longer valid,
                  please contact us if you feel this is an error
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
