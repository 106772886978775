import { Fragment, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { setAuthToken } from "../../../hooks/Authentication";
import { apiError } from "../../../libs/Utils";

const { REACT_APP_API_URL } = process.env;

export default function AddUser(props) {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("Admin");

  function handleClickOpen() {
    setOpen(true);
  }

  function handleSave() {
    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/users`, {
        firstName,
        lastName,
        email,
        role,
        phone,
      })
      .then(() => {
        setOpen(false);
        toast.success("User added and invite sent");
        props.saved(true);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2 sm:w-auto"
        onClick={handleClickOpen}
      >
        Add User
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Add User</Dialog.Title>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-gray-200">Invite a new user to set password and login.</p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">
                                  First Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setFirstName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">
                                  Last Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setLastName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="lastName" className="block text-sm font-medium text-gray-900">
                                  Email
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                                  Phone
                                </label>
                                <div className="mt-1">
                                  <PhoneInput
                                    country={"us"}
                                    onlyCountries={["us"]}
                                    onChange={(phone) => {
                                      setPhone(phone);
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                  Role
                                </label>
                                <select
                                  id="role"
                                  name="role"
                                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand-700 focus:outline-none focus:ring-brand-700 sm:text-sm"
                                  defaultValue=""
                                  onChange={(e) => setRole(e.target.value)}
                                >
                                  <option>Admin</option>
                                  <option>Manager</option>
                                  <option>Signatory</option>
                                  <option>User</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Send Invite
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

AddUser.propTypes = {
  saved: PropTypes.func,
};
