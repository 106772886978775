import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { DragDrop, StatusBar } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../datagrid.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

import { apiError, dateComparator } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

import PageHeader from "../../components/PageHeader";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";

const { REACT_APP_API_URL } = process.env;

export default function Images() {
  const gridRef = useRef();
  const [images, setImages] = useState([]);

  const uppy = new Uppy({
    autoProceed: true,
    restrictions: {
      allowedFileTypes: ["image/*"],
    },
  });
  uppy.use(AwsS3, {
    getUploadParameters(file) {
      setAuthToken();
      return axios
        .post(`${REACT_APP_API_URL}/images/signed-upload-url`, {
          filename: file.name,
          mimeType: file.type,
        })
        .then((response) => {
          const data = {
            method: "PUT",
            url: response.data.url,
            fields: { key: response.data.key },
            headers: {
              "Content-Type": file.type,
            },
          };

          return data;
        })
        .catch((err) => {
          apiError(err);
        });
    },
  });

  uppy.on("upload-success", (file) => {
    const data = {
      mimeType: file.type,
      size: file.size,
      originalName: file.meta.name,
      key: file.meta.key,
    };
    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/images`, data)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  });

  useEffect(() => {
    document.title = "Capital Now Funding - Admin - Images";
  });

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/images/${idToDelete}`)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/images`)
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  const [columnDefs] = useState([
    {
      field: "originalName",
      headerName: "Document",
      minWidth: 450,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "mimeType",
      headerName: "Type",
      minWidth: 250,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "createdAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Created",
      minWidth: 200,
      cellRenderer: (params) => {
        return moment(params.data.createdAt).fromNow();
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      sortable: false,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            <Tippy content="Download">
              <a
                className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
                href={params.data.url}
              >
                <DocumentArrowDownIcon className="h-4 w-4 text-indigo-500" />
              </a>
            </Tippy>

            <DeleteDialog
              tooltip="Delete Image"
              title="Delete Image?"
              content="Are you sure you want to delete the image?"
              id={params.value}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/images`)
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <PageHeader title="Images" subtitle="Images for embedding in templates and email signatures" />
      </div>
      <div className="mb-8 mt-2 flex flex-col">
        <div className="flex flex-col rounded bg-white">
          <div className="m-8 flex flex-col rounded-lg border-2 border-dashed">
            <DragDrop
              uppy={uppy}
              note="Images only"
              locale={{
                strings: {
                  dropHereOr: "Drop here or %{browse}",
                  browse: "browse",
                },
              }}
            />
            <div className="mt-1">
              <StatusBar uppy={uppy} />
            </div>
          </div>
        </div>
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="ag-theme-alpine" style={{ width: "100%", height: 350 }}>
            <AgGridReact
              ref={gridRef}
              rowData={images}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              suppressCellFocus={true}
              overlayNoRowsTemplate={"No images"}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
}
