import PropTypes from "prop-types";

export default function PageHeader(props) {
  return (
    <div className="sm:flex-auto">
      <h1 className="text-xl font-semibold text-gray-900">{props.title}</h1>
      <p className="mt-2 text-sm text-gray-700">{props.subtitle}</p>
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};
