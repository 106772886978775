import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

export default function ContractDocumentLink(props) {
  function handleCopied() {
    toast.success("Copied Link");
  }

  return (
    <CopyToClipboard text={props.link} onCopy={handleCopied}>
      <button
        type="button"
        className="ml-4 inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-3 py-1 text-sm font-medium leading-4 text-indigo-500 shadow-sm hover:bg-indigo-200 focus:outline-none"
      >
        <ClipboardDocumentListIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        {props.title}
      </button>
    </CopyToClipboard>
  );
}

ContractDocumentLink.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
