import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

export default function FundingApplicationNoteCard(props) {
  const [badgeBg, setBadgeBg] = useState("bg-white");
  const [badgeText, setBadgeText] = useState("text-black");

  useEffect(() => {
    if (props.event === "Rejected") {
      setBadgeBg("bg-red-100");
      setBadgeText("text-red-800");
    } else if (props.event === "Approved") {
      setBadgeBg("bg-blue-100");
      setBadgeText("text-blue-800");
    } else if (props.event === "Ready to Fund") {
      setBadgeBg("bg-purple-100");
      setBadgeText("text-purple-800");
    } else if (props.event === "Funded") {
      setBadgeBg("bg-green-100");
      setBadgeText("text-green-800");
    } else {
      setBadgeBg("white");
      setBadgeText("text-black");
    }
  }, [props.event]);

  return (
    <li className="py-4">
      <div className="flex space-x-3">
        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium">
              {props.firstName} {props.lastName}
            </h3>
            <p className="text-sm text-gray-500">{moment(props.createdAt).fromNow()}</p>
          </div>
          <p className="text-sm text-gray-500">{props.body}</p>
          {props.event && (
            <span
              className={`float-right inline-flex items-center rounded-full ${badgeBg} px-2.5 py-0.5 text-xs ${badgeText}`}
            >
              {props.event}
            </span>
          )}
        </div>
      </div>
    </li>
  );
}

FundingApplicationNoteCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  event: PropTypes.string,
};
