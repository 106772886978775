import PropTypes from "prop-types";

export default function EmptyRows(props) {
  return (
    <div>
      <div className="p-16 text-center">
        <h3 className="mt-2 text-base font-medium text-gray-900">{props.title}</h3>
      </div>
    </div>
  );
}

EmptyRows.propTypes = {
  title: PropTypes.string.isRequired,
};
