import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { dateFromNow } from "../../libs/Utils";
import { client, clientError } from "../../libs/Client";

export default function ServiceProviderDetailHeader(props) {
  const [serviceProvider, setServiceProvider] = useState("");
  const [salesRep, setSalesRep] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [website, setWebsite] = useState("");
  const [city, setCity] = useState("");
  const [flagged, setFlagged] = useState(false);
  const [reason, setReason] = useState("");
  const [created, setCreated] = useState("");
  const [updated, setUpdated] = useState("");

  useEffect(() => {
    client()
      .get(`/service-providers/${props.id}`)
      .then((res) => {
        setServiceProvider(res.data.name);
        setSalesRep(res.data.salesRep);
        setStreetAddress(res.data.streetAddress);
        setCity(res.data.city);
        setZip(res.data.zip);
        setCountry(res.data.country);
        setState(res.data.state.label);
        setWebsite(res.data.website);
        setFlagged(res.data.flagged);
        setReason(res.data.flagReason);
        setCreated(res.data.createdAt);
        setUpdated(res.data.updatedAt);
      })
      .catch((err) => {
        clientError(err);
      });
  }, [props.id]);

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="bg-white p-4">
        <ul className="grid grid-cols-2">
          <li>
            <div className="text-sm font-medium sm:flex sm:space-x-5">
              Service Provider:
              <span className="ml-1 font-light text-gray-500">{serviceProvider}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-medium sm:flex sm:space-x-5">
              Sales Rep:
              <span className="ml-1 font-light text-gray-500">{salesRep}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-medium sm:flex sm:space-x-5">
              Street Address:
              <span className="ml-1 font-light text-gray-500">{streetAddress}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              City:
              <span className="ml-1 font-light text-gray-500">{city}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Zip:
              <span className="ml-1 font-light text-gray-500">{zip}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-medium sm:flex sm:space-x-5">
              State:
              <span className="ml-1 font-light text-gray-500">{state}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Country:
              <span className="ml-1 font-light text-gray-500">{country}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Website:
              {website !== "" ? (
                <span className="ml-1 font-light text-gray-500">
                  <a href={website} className="text-indigo-500 hover:text-indigo-700" target="_blank" rel="noreferrer">
                    {website}
                  </a>
                </span>
              ) : (
                <span className="ml-1 font-light text-gray-500">{website}</span>
              )}
            </div>
          </li>
          <li>
            <div className="text-sm font-semibold sm:flex sm:space-x-5">
              Created:
              <span className="ml-1 font-light text-gray-500">{dateFromNow(created)}</span>
            </div>
          </li>
          <li>
            <div className="text-sm font-medium sm:flex sm:space-x-5">
              Updated:
              <span className="ml-1 font-light text-gray-500">{dateFromNow(updated)}</span>
            </div>
          </li>
        </ul>
        {flagged && (
          <div className="mt-2 whitespace-pre-wrap rounded-lg bg-red-100 p-4 text-red-500 shadow">{reason}</div>
        )}
      </div>
      <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
        <a
          type="button"
          className="bg-indigo-100 hover:bg-indigo-200 text-indigo-700 px-6 py-5 text-center text-sm font-medium"
          href={`/service-providers/${props.id}/statements/summary`}
        >
          Summary Statement
        </a>
        <a
          type="button"
          className="bg-indigo-100 hover:bg-indigo-200 text-indigo-700 px-6 py-5 text-center text-sm font-medium"
          href={`/service-providers/${props.id}/statements/detail`}
        >
          Detailed Statement
        </a>
        <a
          type="button"
          className="bg-indigo-100 hover:bg-indigo-200 text-indigo-700 px-6 py-5 text-center text-sm font-medium"
          href={`/service-providers/${props.id}/statements/aging`}
        >
          Aging Statement
        </a>
      </div>
    </div>
  );
}

ServiceProviderDetailHeader.propTypes = {
  id: PropTypes.string.isRequired,
};
