import PropTypes from "prop-types";
import { shortDate } from "../../libs/Utils";

export default function OtherAccidentBlock(props) {
  return (
    <div className="mt-2 overflow-hidden bg-white">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Accident Details</h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Date</dt>
            <dd className="mt-1 text-sm text-gray-900">{shortDate(props.accidentDate)}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accident Type</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.accidentType}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Is this case involving you or someone else?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.involves}</dd>
          </div>
          {props.involves === "Someone Else" ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">What is the name of the person?</dt>
              <dd className="mt-1 text-sm text-gray-900">{props.otherName}</dd>
            </div>
          ) : null}
          {props.involves === "Someone Else" ? (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                What is your relationship to the person / Are you their POA?
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{props.relationship}</dd>
            </div>
          ) : null}

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Incident Details - What happened?</dt>
            <dd className="mt-1 text-sm text-gray-900">{props.details}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

OtherAccidentBlock.propTypes = {
  accidentDate: PropTypes.string,
  accidentType: PropTypes.string,
  involves: PropTypes.string,
  otherName: PropTypes.string,
  relationship: PropTypes.string,
  details: PropTypes.string,
};
