import React, { useEffect, useCallback } from "react";
import moment from "moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { minHeight } from "../../libs/Utils";
import { client, clientError } from "../../libs/Client";
import TaskTabs from "../../layouts/FullLayout/Tabs/TaskTabs";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import EditTask from "../../components/SlideOvers/Tasks/EditTask";
import AvatarLabel from "../../components/Labels/AvatarLabel";

export default function TodoTasks() {
  useEffect(() => {
    document.title = "Capital Now Funding - Tasks - To-Do";
  });

  const gridStyle = { minHeight: minHeight };

  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/tasks?filter=todo&skip=${skip}&limit=${limit}`)
      .then((res) => {
        return { data: res.data.tasks, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, []);

  const columns = [
    {
      name: "lawsuitID",
      minWidth: 150,
      header: "Case #",
      render: ({ value }) => {
        return (
          <a className="text-brand-700 hover:text-brand-500" href={`/cases/${value}/feed`}>
            {value}
          </a>
        );
      },
    },
    {
      name: "lawsuit",
      minWidth: 200,
      header: "Client Name",
      render: ({ data }) => {
        return <AvatarLabel firstName={data.clientFirstName} lastName={data.clientLastName} email={data.clientEmail} />;
      },
    },
    {
      name: "serviceProvider",
      minWidth: 200,
      header: "Service Provider",
    },
    {
      name: "user",
      minWidth: 200,
      header: "User",
      render: ({ data }) => {
        return <AvatarLabel firstName={data.userFirstName} lastName={data.userLastName} email={data.userEmail} />;
      },
    },
    {
      name: "text",
      minWidth: 200,
      header: "Task",
    },
    {
      name: "dueDate",
      header: "Due",
      minWidth: 200,
      render: ({ value }) => {
        return moment(value).format("LL");
      },
    },
    {
      name: "id",
      header: "",
      minWidth: 175,
      render: ({ data }) => {
        return (
          <div className="flex justify-end space-x-4">
            <Tippy content="Mark Done">
              <button
                className="inline-flex rounded border-transparent bg-emerald-100 px-2.5 py-1.5 text-xs font-medium hover:bg-emerald-200 focus:outline-none"
                onClick={() => handleMarkDone(data.id)}
              >
                <ClipboardDocumentCheckIcon className="h-4 w-4 text-emerald-500" />
              </button>
            </Tippy>
            <EditTask tooltip="Edit Task" id={data.id} saved={handleRefresh} />
            <DeleteDialog
              tooltip="Delete Task"
              title="Delete Task"
              content="Are you sure you want to delete the task?"
              id={data.id}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ];

  function handleMarkDone(id) {
    client()
      .put(`/tasks/${id}/done`, {})
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleDelete(idToDelete) {
    client()
      .delete(`/tasks/${idToDelete}`)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleRefresh() {
    window.location.reload();
  }

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <TaskTabs selected="To-Do" />
        <ReactDataGrid
          idProperty="todoTasks"
          columns={columns}
          style={gridStyle}
          showColumnMenuTool={false}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
