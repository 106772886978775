import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const { REACT_APP_API_URL } = process.env;

export default function Activate() {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Capital Now Funding - Activate";
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      axios
        .post(`${REACT_APP_API_URL}/users/activate`, { password, token })
        .then(() => {
          toast.success("Account activated");
          navigate("/", { replace: true });
        })
        .catch(() => {
          toast.error("Unable to update password");
        });
    }
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-13 w-auto"
          src="/images/logos/login-logo.png"
          alt="Capital Now Funding"
        />
        <h2 className="mt-6 text-center text-2xl font-semibold text-gray-900">
          Activate Account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-700 focus:border-brand-700 sm:text-sm"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Password must be at least 8 characters.
                </p>
              </div>
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <div className="mt-1">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-700 focus:border-brand-700 sm:text-sm"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="text-right">
              <div className="text-sm">
                <a
                  href="/"
                  className="font-medium text-brand-700 hover:text-brand-500"
                >
                  Return to login?
                </a>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-700 hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-700"
                onClick={handleSubmit}
              >
                Activate Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
