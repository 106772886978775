import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { classNames } from "../../../libs/Utils";
import ComposeSMS from "../../../components/SlideOvers/Cases/ComposeSMS";

export default function CaseSMSTabs(props) {
  const navigate = useNavigate();
  var tabs = [];

  if (props.selected === "Inbox") {
    tabs.push({
      name: "Inbox",
      href: `/cases/${props.lawsuitID}/sms/inbox`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Inbox",
      href: `/cases/${props.lawsuitID}/sms/inbox`,
      current: false,
    });
  }

  if (props.selected === "Sent") {
    tabs.push({
      name: "Sent",
      href: `/cases/${props.lawsuitID}/sms/sent`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Sent",
      href: `/cases/${props.lawsuitID}/sms/sent`,
      current: false,
    });
  }

  function handleChange(url) {
    navigate(url);
  }

  function handleSent() {
    props.sent();
  }

  return (
    <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
      <ComposeSMS lawsuitID={props.lawsuitID} sent={handleSent} />
      <div className="mt-5">
        <div className="lg:hidden">
          <label htmlFor="selected-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="selected-tab"
            name="selected-tab"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-700 focus:border-brand-700 sm:text-sm rounded-md"
            defaultValue={tabs.find((tab) => tab.current).name}
            onChange={(e) => handleChange(e.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.href}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden lg:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "border-brand-700 text-brand-700"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

CaseSMSTabs.propTypes = {
  lawsuitID: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  sent: PropTypes.func,
};
