import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import ReactSelect from "react-select";

import { apiError } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

const { REACT_APP_API_URL } = process.env;

export default function AddToQuickbooks(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      setLoading(true);
      setAuthToken();
      axios
        .get(`${REACT_APP_API_URL}/quickbooks/lawsuits?lawsuitID=${props.id}`)
        .then((res) => {
          setLoading(false);
          let data = [{ value: "AddNew", label: "Add new..." }];
          if (res.data.length > 0) {
            res.data.forEach((item) => {
              data.push({ value: item.value, label: item.label });
            });
          }
          setCustomers(data);
        })
        .catch((err) => {
          setLoading(false);
          setOpen(false);
          apiError(err);
        });
    }
  }, [open, props.id]);

  function handleSave() {
    if (!customer.value) {
      toast.error("Select required");
      return;
    }

    setAuthToken();
    if (customer.value === "AddNew") {
      axios
        .post(`${REACT_APP_API_URL}/lawsuits/${props.id}/quickbooks`, {})
        .then(() => {
          setOpen(false);
          props.saved(true);
        })
        .catch((err) => {
          apiError(err);
        });
    } else {
      axios
        .put(`${REACT_APP_API_URL}/lawsuits/${props.id}/quickbooks`, { quickbooksID: customer.value })
        .then(() => {
          setOpen(false);
          props.saved(true);
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }

  return (
    <div>
      <Tippy content="Add to Quickbooks">
        <button
          className="inline-flex rounded border-transparent bg-orange-100 px-2.5 py-1.5 text-xs font-medium hover:bg-orange-200 focus:outline-none"
          onClick={handleClickOpen}
        >
          <ArrowPathRoundedSquareIcon className="h-4 w-4 text-orange-500" />
        </button>
      </Tippy>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Add to Quickbooks</Dialog.Title>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-red-50">Add to Quickboks</p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="newValue" className="block text-sm font-medium text-gray-900">
                                  Quickbooks Customer
                                </label>
                                <div className="remove-input-txt-border mt-1">
                                  <ReactSelect options={customers} isClearable onChange={(data) => setCustomer(data)} />
                                </div>
                                {loading && (
                                  <p className="mt-2 text-sm leading-6 text-gray-600">
                                    Fetching customer list from Quickbooks...
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="focus:ring-brand rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

AddToQuickbooks.propTypes = {
  id: PropTypes.string.isRequired,
  saved: PropTypes.func.isRequired,
};
