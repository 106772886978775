import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import DOMPurify from "isomorphic-dompurify";
import { toast } from "react-toastify";

import { apiError } from "../../libs/Utils";

const { REACT_APP_API_URL } = process.env;

export default function Hippa() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [signature, setSignature] = useState("");

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/signature/hippa/${id}`)
      .then((res) => {
        setTitle(res.data.title);
        setBody(DOMPurify.sanitize(res.data.body));
      })
      .catch(() => {
        navigate("/authorizations/expired");
      });
  }, [id, navigate]);

  function handleApprove() {
    if (signature === "") {
      toast.error("Signature required");
      return;
    }

    axios
      .post(`${REACT_APP_API_URL}/signature/hippa/${id}/sign`, {
        signature: signature,
      })
      .then(() => {
        window.location.replace(
          "https://capitalnowfunding.com/?utm_source=cnf-electronic-hippa&utm_medium=hippa&utm_campaign=CNF-Electronic-HIPPA-Signature&utm_content=cnf-electronic-hippa-client"
        );
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-full">
        <img className="mx-auto h-13 w-auto" src="/images/logos/login-logo.png" alt="Capital Now Funding" />
        <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">{title}</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-7xl">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div
            className="prose font-timesnewroman text-sm max-w-prose min-w-full"
            dangerouslySetInnerHTML={{ __html: body }}
          />
          <div className="space-y-6">
            <div>
              <div className="space-y-6">
                <div className="mt-3">
                  <label htmlFor="typedSignature" className="block text-sm font-medium text-gray-900">
                    Signature
                  </label>
                  <input
                    type="text"
                    name="typedSignature"
                    id="typedSignature"
                    className="block w-full font-cedarville rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700"
                    onChange={(e) => setSignature(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-700 hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                  onClick={handleApprove}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
