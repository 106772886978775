import { Fragment, useState, useRef, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import ReactSelect from "react-select";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { setAuthToken } from "../../../hooks/Authentication";
import { apiError, classNames } from "../../../libs/Utils";

import ContractShortCodes from "../ShortCodes/ContractShortCodes";

const { REACT_APP_API_URL, REACT_APP_TINY_MCE_API_KEY } = process.env;

export default function EditContractTemplate(props) {
  const [open, setOpen] = useState(false);
  const editorRef = useRef(null);
  const [title, setTitle] = useState("");
  const [label, setLabel] = useState("");
  const [stateList, setStateList] = useState([]);
  const [contentBody, setContentBody] = useState("");
  const [providerSignature, setProviderSignature] = useState(false);
  const [selectedStates, setSelectedStates] = useState([]);
  const [accidentTypeList, setAccidentTypeList] = useState([]);
  const [selectedAccidentTypes, setSelectedAccidentTypes] = useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      setAuthToken();
      Promise.all([
        axios.get(`${REACT_APP_API_URL}/accident-types`),
        axios.get(`${REACT_APP_API_URL}/states`),
        axios.get(`${REACT_APP_API_URL}/templates/contracts/${props.id}`),
      ])
        .then((response) => {
          setTitle(response[2].data.title);
          setContentBody(response[2].data.body);
          setLabel(response[2].data.label);
          setAccidentTypeList(response[0].data);
          setStateList(response[1].data);
          setProviderSignature(response[2].data.providerSignature);
          setSelectedStates(
            response[2].data.states.map((option) => ({
              value: option.id,
              label: option.label,
            }))
          );
          setSelectedAccidentTypes(
            response[2].data.accidentTypes.map((option) => ({
              value: option.accidentType,
              label: option.accidentType,
            }))
          );
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }, [open, props.id]);

  function handleSave() {
    var states = [];
    selectedStates.forEach((item) => {
      states.push(item.value);
    });
    var accidentTypes = [];
    selectedAccidentTypes.forEach((item) => {
      accidentTypes.push(item.value);
    });
    setAuthToken();
    const body = editorRef.current.getContent();
    axios
      .put(`${REACT_APP_API_URL}/templates/contracts/${props.id}`, {
        label,
        title,
        body,
        states,
        accidentTypes,
        providerSignature,
      })
      .then(() => {
        setOpen(false);
        toast.success("Contract template saved");
        props.saved(true);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      {props.tooltip ? (
        <Tippy content={props.tooltip}>
          <button
            className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
            onClick={handleClickOpen}
          >
            <PencilSquareIcon className="h-4 w-4 text-indigo-500" />
          </button>
        </Tippy>
      ) : (
        <button
          className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
          onClick={handleClickOpen}
        >
          <PencilSquareIcon className="h-4 w-4 text-indigo-500" />
        </button>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="max-w-4-xl pointer-events-none fixed inset-y-0 right-0 flex pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-full">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Edit Contract Template
                            </Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label htmlFor="label" className="block text-sm font-medium text-gray-900">
                                  Template Label
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="label"
                                    id="label"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setLabel(e.target.value)}
                                    value={label}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="title" className="block text-sm font-medium text-gray-900">
                                  Template Title
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="title" className="block text-sm font-medium text-gray-900">
                                  Template Text
                                  <span className="float-right">
                                    <ContractShortCodes />
                                  </span>
                                </label>
                                <div className="mt-1">
                                  <Editor
                                    initialValue={contentBody}
                                    apiKey={`${REACT_APP_TINY_MCE_API_KEY}`}
                                    onInit={(evt, editor) => (editorRef.current = editor)}
                                    init={{
                                      image_list: `${REACT_APP_API_URL}/images/list`,
                                      height: 300,
                                      menubar: "edit insert view format table tools",
                                      plugins: "lists link image table code",
                                      paste_as_text: false,
                                      paste_block_drop: true,
                                      toolbar:
                                        "undo redo | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | image link | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code",
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="stateIDs" className="block text-sm font-medium text-gray-700">
                                  States
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect
                                    options={stateList.map((option) => ({
                                      value: option.id,
                                      label: option.label,
                                    }))}
                                    isClearable
                                    isMulti
                                    onChange={(e) => {
                                      if (e) {
                                        setSelectedStates(e);
                                      }
                                    }}
                                    value={selectedStates}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="accidentTypes" className="block text-sm font-medium text-gray-700">
                                  Accident Types
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect
                                    options={accidentTypeList.map((option) => ({
                                      value: option,
                                      label: option,
                                    }))}
                                    isClearable
                                    isMulti
                                    onChange={(e) => {
                                      if (e) {
                                        setSelectedAccidentTypes(e);
                                      }
                                    }}
                                    value={selectedAccidentTypes}
                                  />
                                </div>
                              </div>
                              <div>
                                <Switch.Group as="div" className="flex items-center justify-between">
                                  <span className="flex flex-grow flex-col">
                                    <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                      Provider Signature
                                    </Switch.Label>
                                    <Switch.Description as="span" className="text-sm text-gray-500">
                                      Require provider signature
                                    </Switch.Description>
                                  </span>
                                  <Switch
                                    checked={providerSignature}
                                    onChange={setProviderSignature}
                                    className={classNames(
                                      providerSignature ? "bg-brand-700" : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        providerSignature ? "translate-x-5" : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EditContractTemplate.propTypes = {
  tooltip: PropTypes.string,
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
