import PropTypes from "prop-types";

export default function StatusLabel(props) {
  if (props.enabled) {
    if (props.activated) {
      return (
        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
          Active
        </span>
      );
    }

    return (
      <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
        Pending
      </span>
    );
  }

  return (
    <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
      Disabled
    </span>
  );
}

StatusLabel.propTypes = {
  enabled: PropTypes.bool,
  activated: PropTypes.bool,
};
