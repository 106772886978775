import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { CheckBadgeIcon, LinkIcon } from "@heroicons/react/24/outline";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { setAuthToken } from "../../hooks/Authentication";
import { dateComparator, apiError } from "../../libs/Utils";

import PageHeader from "../../components/PageHeader";
import AddLink from "../../components/SlideOvers/AddLink";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import EditLink from "../../components/SlideOvers/EditLink";

const { REACT_APP_API_URL } = process.env;

export default function Links() {
  const gridRef = useRef();
  const [links, setLinks] = useState([]);
  const [linksTo, setLinksTo] = useState("https://capitalnowfunding.com");

  useEffect(() => {
    document.title = "Capital Now Funding - Admin - Links";
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/links/short-url`)
      .then((response) => {
        setLinksTo(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, []);

  const [columnDefs] = useState([
    {
      field: "active",
      filter: false,
      minWidth: 40,
      width: 40,
      headerName: "",
      cellStyle: (params) => ({
        display: "flex",
        alignItems: "center",
      }),
      cellRenderer: (params) => {
        if (params.data.active) {
          return <CheckBadgeIcon className="w-7 text-green-700" />;
        }
      },
    },
    { field: "label", filter: true },
    { field: "redirectURL", filter: true },
    {
      field: "createdAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Created",
      cellRenderer: (params) => {
        return moment(params.data.createdAt).fromNow();
      },
    },
    {
      field: "updatedAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Updated",
      cellRenderer: (params) => {
        return moment(params.data.updatedAt).fromNow();
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      minWidth: 175,
      maxWidth: 175,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            {!params.data.active && (
              <Tippy content="Activate Link">
                <button
                  type="button"
                  className="inline-flex rounded border-transparent bg-sky-100 px-2.5 py-1.5 text-xs font-medium hover:bg-sky-200 focus:outline-none"
                  onClick={() => handleActivate(params.value)}
                >
                  <LinkIcon className="h-4 w-4 text-sky-500" />
                </button>
              </Tippy>
            )}

            <EditLink tooltip="Edit Link" id={params.value} saved={handleRefresh} />
            <DeleteDialog
              tooltip="Delete Link"
              buttonTitle="Delete"
              title="Delete Link?"
              content="Are you sure you want to delete the link?"
              id={params.value}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/links`)
      .then((response) => {
        setLinks(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function handleActivate(idToActivate) {
    setAuthToken();
    axios
      .put(`${REACT_APP_API_URL}/links/${idToActivate}/activate`, {})
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/links/${idToDelete}`)
      .then(() => {
        toast.success("Link deleted");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/links`)
      .then((response) => {
        setLinks(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleCopied() {
    toast.success("Copied Link");
  }

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <PageHeader title="Links" subtitle="Link manager" />
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <CopyToClipboard text={linksTo} onCopy={handleCopied}>
            <button
              type="button"
              className="inline-flex rounded border-transparent bg-sky-100 px-2.5 py-1.5 text-xs font-medium text-sky-500 hover:bg-sky-200 focus:outline-none"
            >
              {linksTo}
            </button>
          </CopyToClipboard>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <AddLink saved={handleRefresh} />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="mb-8 ml-2 mr-2">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 750 }}>
              <AgGridReact
                ref={gridRef}
                rowData={links}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
                overlayNoRowsTemplate={"No Links"}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
