import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function EmailShortCodes() {
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  return (
    <div>
      <button className="font-sm text-brand-700 hover:text-brand-500" onClick={handleClickOpen}>
        Short Codes
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-sm">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Email Short Codes</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <ul className="divide-y divide-gray-200">
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">First Name</p>
                                    <p className="text-sm text-brand-700">{"{{ FirstName }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Last Name</p>
                                    <p className="text-sm text-brand-700">{"{{ LastName }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Phone Number</p>
                                    <p className="text-sm text-brand-700">{"{{ PhoneNumber }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Accident Date</p>
                                    <p className="text-sm text-brand-700">{"{{ AccidentDate }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Service Provider</p>
                                    <p className="text-sm text-brand-700">{"{{ ServiceProvider }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      Service Provider Contact First Name
                                    </p>
                                    <p className="text-sm text-brand-700">{"{{ ServiceProviderContactFirstName }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      Service Provider Contact Last Name
                                    </p>
                                    <p className="text-sm text-brand-700">{"{{ ServiceProviderContactLastName }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Amount Funded to Date</p>
                                    <p className="text-sm text-brand-700">{"{{ AmountFundedToDate }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Current Payoff</p>
                                    <p className="text-sm text-brand-700">{"{{ CurrentPayoff }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Current Date</p>
                                    <p className="text-sm text-brand-700">{"{{ CurrentDate }}"}</p>
                                  </div>
                                </li>
                                <li className="flex py-4">
                                  <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">Case Number</p>
                                    <p className="text-sm text-brand-700">{"{{ CaseNumber }}"}</p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
