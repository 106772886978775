import PropTypes from "prop-types";
import moment from "moment";

export default function NoteCard(props) {
  var badgeBg = "bg-red-100";
  var badgeText = "text-red-800";
  if (props.event && props.event === "Funding Unlocked") {
    badgeBg = "bg-green-100";
    badgeText = "text-green-800";
  }
  return (
    <li className="py-4">
      <div className="flex space-x-3">
        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium">
              {props.firstName} {props.lastName}
            </h3>
            <p className="text-sm text-gray-500">{moment(props.createdAt).fromNow()}</p>
          </div>
          <p className="text-sm text-gray-500">{props.body}</p>
          {props.event && (
            <span
              className={`float-right inline-flex items-center rounded-full ${badgeBg} px-2.5 py-0.5 text-xs ${badgeText}`}
            >
              {props.event}
            </span>
          )}
        </div>
      </div>
    </li>
  );
}

NoteCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  event: PropTypes.string,
};
