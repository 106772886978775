import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import DOMPurify from "isomorphic-dompurify";
import { toast } from "react-toastify";

import { apiError } from "../../libs/Utils";

const { REACT_APP_API_URL } = process.env;

export default function ServiceProviderSignature() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [signature, setSignature] = useState("");
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/signature/contract/${id}/service-provider`)
      .then((res) => {
        setTitle(res.data.title);
        setBody(DOMPurify.sanitize(res.data.body));
      })
      .catch(() => {
        navigate("/signature/expired");
      });
  }, [id, navigate]);

  function handleApprove() {
    if (signature === "") {
      toast.error("Signature required");
      return;
    }

    setProcessing(true);
    var data = {
      signature: signature,
    };

    axios
      .put(`${REACT_APP_API_URL}/signature/contract/${id}/service-provider/sign`, data)
      .then(() => {
        setProcessing(false);
        window.location.replace("https://capitalnowfunding.com");
      })
      .catch((err) => {
        setProcessing(false);
        apiError(err);
      });
  }

  return (
    <div className="py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-full">
        <img className="h-13 mx-auto w-auto" src="/images/logos/login-logo.png" alt="Capital Now Funding" />
        <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">{title}</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-7xl">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <div
            className="prose max-w-prose min-w-full text-sm font-timesnewroman"
            dangerouslySetInnerHTML={{ __html: body }}
          />
          <div className="space-y-6">
            <div>
              <div className="space-y-6">
                <div className="mt-1">
                  <h1 className="font-bold">
                    By signing below, you agree to the terms and conditions outlined in the Attorney Acknowledgement.
                    Additionally, by signing below, you agree to apply your electronic signature to the Service Provider
                    Acknowledgement.
                  </h1>
                </div>
                <div className="mt-1">
                  <div>
                    <label htmlFor="typedSignature" className="block text-base font-semibold text-gray-900">
                      Signature
                    </label>
                    <input
                      type="text"
                      name="typedSignature"
                      id="typedSignature"
                      className="block w-full rounded-md border-gray-300 font-cedarville shadow-sm focus:border-brand-700 focus:ring-brand-700"
                      onChange={(e) => setSignature(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                {processing ? (
                  <button
                    type="submit"
                    className="flex w-full cursor-not-allowed justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-150 ease-in-out hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                    disabled
                  >
                    <svg
                      className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Processing...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                    onClick={handleApprove}
                  >
                    Approve
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
