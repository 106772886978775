import { toast } from "react-toastify";
import { capitalize } from "lodash";
import moment from "moment";
import numeral from "numeral";
import parsePhoneNumber from "libphonenumber-js";
import { signOut } from "../hooks/Authentication";

export const minHeight = 750;

export function dateComparator(date1, date2) {
  var date1Number = date1 && new Date(date1).getTime();
  var date2Number = date2 && new Date(date2).getTime();

  if (date1Number == null && date2Number == null) {
    return 0;
  }

  if (date1Number == null) {
    return -1;
  } else if (date2Number == null) {
    return 1;
  }

  return date1Number - date2Number;
}

export function daysHoursMinutes(seconds) {
  // calculate (and subtract) whole days
  var days = Math.floor(seconds / 86400);
  seconds -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(seconds / 3600) % 24;
  seconds -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(seconds / 60) % 60;
  seconds -= minutes * 60;

  return "Days: " + days + ", Hours: " + hours + ", Minutes: " + minutes;
}

export function apiError(err) {
  if (err.response) {
    if (err.response.status === 401) {
      signOut();
    } else {
      toast.error(capitalize(err.response.data.reason));
    }
  } else {
    toast.error("Unable to connect to the API");
  }
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function vaporDate(date) {
  if (date !== "") {
    return date.toISOString().slice(0, -5) + "Z";
  }

  return "";
}

export function shortDateUTC(date) {
  if (date !== "") {
    return moment.utc(date).format("MM/DD/YYYY");
  }

  return "";
}

export function reportDate() {
  return moment().format("LL");
}

export function shortDate(date) {
  if (date !== "") {
    return moment(date).format("MM/DD/YYYY");
  }

  return "";
}

export function longDate(date) {
  if (date && date !== "") {
    return moment(date).format("LLLL");
  }
  return "";
}

export function dateFromNow(date) {
  if (date !== "") {
    return moment(date).fromNow();
  }
  return "";
}

export function nationalPhoneNumber(phoneNumber) {
  const parsedNumber = parsePhoneNumber(phoneNumber, "US");
  if (parsedNumber) {
    return parsedNumber.formatNational();
  }

  return "";
}

export function currency(amount) {
  if (amount) {
    return numeral(amount / 100).format("$ 0,0.00");
  }

  return numeral(0).format("$ 0,0.00");
}

export function currencyNoCents(amount) {
  if (amount) {
    return numeral(Math.floor(amount / 100)).format("$ 0,0");
  }

  return numeral(0).format("$ 0,0");
}

export function dgSizeFormatter(params) {
  return numeral(params.value).format("0.0 b");
}

export function percentage(amount) {
  if (amount) {
    return numeral(amount).format("0.00%");
  }

  return numeral(0).format("0.00%");
}

export function parseAddress(lawsuit) {
  var clientAddress = "";
  if (lawsuit.address) {
    if (clientAddress === "") {
      clientAddress += lawsuit.address;
    } else {
      clientAddress += `, ${lawsuit.address}`;
    }
  }

  if (lawsuit.apartmentNumber) {
    if (clientAddress === "") {
      clientAddress += lawsuit.apartmentNumber;
    } else {
      clientAddress += `, ${lawsuit.apartmentNumber}`;
    }
  }

  if (lawsuit.city) {
    if (clientAddress === "") {
      clientAddress += lawsuit.city;
    } else {
      clientAddress += `, ${lawsuit.city}`;
    }
  }

  if (lawsuit.state && lawsuit.state.label) {
    if (clientAddress === "") {
      clientAddress += lawsuit.state.label;
    } else {
      clientAddress += `, ${lawsuit.state.label}`;
    }
  }

  if (lawsuit.zipCode) {
    if (clientAddress === "") {
      clientAddress += lawsuit.zipCode;
    } else {
      clientAddress += `, ${lawsuit.zipCode}`;
    }
  }

  if (lawsuit.country) {
    if (clientAddress === "") {
      clientAddress += lawsuit.country;
    } else {
      clientAddress += `, ${lawsuit.country}`;
    }
  }

  return clientAddress;
}

export function parseServiceProviderAddress(serviceProvider) {
  var address = "";
  if (serviceProvider.streetAddress) {
    if (address === "") {
      address += serviceProvider.streetAddress;
    } else {
      address += `, ${serviceProvider.streetAddress}`;
    }
  }

  if (serviceProvider.apartmentNumber) {
    if (address === "") {
      address += serviceProvider.apartmentNumber;
    } else {
      address += `, ${serviceProvider.apartmentNumber}`;
    }
  }

  if (serviceProvider.city) {
    if (address === "") {
      address += serviceProvider.city;
    } else {
      address += `, ${serviceProvider.city}`;
    }
  }

  if (serviceProvider.state && serviceProvider.state.label) {
    if (address === "") {
      address += serviceProvider.state.label;
    } else {
      address += `, ${serviceProvider.state.label}`;
    }
  }

  if (serviceProvider.zip) {
    if (address === "") {
      address += serviceProvider.zip;
    } else {
      address += `, ${serviceProvider.zip}`;
    }
  }

  if (serviceProvider.country) {
    if (address === "") {
      address += serviceProvider.country;
    } else {
      address += `, ${serviceProvider.country}`;
    }
  }

  return address;
}

export function getGooglePlacesAddress(place) {
  const addressNameFormat = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    country: "long_name",
    postal_code: "short_name",
  };
  const getAddressComp = function (type) {
    for (const component of place.address_components) {
      if (component.types[0] === type) {
        return component[addressNameFormat[type]];
      }
    }
    return "";
  };

  const data = {
    streetAddress: getAddressComp("street_number") + " " + getAddressComp("route"),
    apartmentNumber: getAddressComp("street_number"),
    city: getAddressComp("locality"),
    state: getAddressComp("administrative_area_level_1"),
    zipCode: getAddressComp("postal_code"),
    country: getAddressComp("country"),
  };

  return data;
}
