import React, { useEffect, useState } from "react";
import axios from "axios";

import SettingTabs from "../../layouts/FullLayout/Tabs/SettingTabs";

import { apiError } from "../../libs/Utils";
import { setAuthToken, useUser } from "../../hooks/Authentication";

import EditFullName from "../../components/Settings/EditFullName";
import EditEmail from "../../components/Settings/EditEmail";
import EditPhone from "../../components/Settings/EditPhone";

const { REACT_APP_API_URL } = process.env;

export default function Settings() {
  const { user } = useUser();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/me/profile`)
      .then((res) => {
        setFirstName(res.data.firstName);
        setLastName(res.data.lastName);
        setEmail(res.data.email);
        setPhone(res.data.phone);
      })
      .catch((err) => {
        apiError(err);
      });
  }, []);

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/me/profile`)
      .then((res) => {
        setFirstName(res.data.firstName);
        setLastName(res.data.lastName);
        setEmail(res.data.email);
        setPhone(res.data.phone);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div className="py-6">
      <div className="mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-2">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Settings</h1>
          </div>
          <div className="mt-4 rounded-md bg-white p-4">
            <SettingTabs role={user.role} selected="Profile" />
            <div className="ml-4 mr-4 mt-0 divide-y divide-gray-200">
              <dl className="divide-y divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">
                      {firstName} {lastName}
                    </span>
                    <span className="ml-4 flex-shrink-0">
                      <EditFullName firstName={firstName} lastName={lastName} saved={handleRefresh} />
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{email}</span>
                    <span className="ml-4 flex-shrink-0">
                      <EditEmail email={email} saved={handleRefresh} />
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                  <dt className="text-sm font-medium text-gray-500">Phone</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">{phone}</span>
                    <span className="ml-4 flex-shrink-0">
                      <EditPhone phone={phone} saved={handleRefresh} />
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
