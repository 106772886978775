import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { apiError } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

const { REACT_APP_API_URL } = process.env;

export default function EditQuickbooksSetting(props) {
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState("");

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    setNewValue(props.keyValue);
  }, [props.keyValue]);

  function handleSave() {
    var data = {};
    if (props.keyName === "clientID") {
      data.clientID = newValue;
    }

    if (props.keyName === "clientSecret") {
      data.clientSecret = newValue;
    }

    if (props.keyName === "companyID") {
      data.companyID = newValue;
    }

    if (props.keyName === "verifierToken") {
      data.verifierToken = newValue;
    }

    if (props.keyName === "environment") {
      data.environment = newValue;
    }

    if (props.keyName === "redirectURL") {
      data.redirectURL = newValue;
    }

    if (props.keyName === "scope") {
      data.scope = newValue;
    }

    if (props.keyName === "responseType") {
      data.responseType = newValue;
    }

    if (props.keyName === "discoveryDocumentsURL") {
      data.discoveryDocumentsURL = newValue;
    }

    if (props.keyName === "bankAccountID") {
      data.bankAccountID = newValue;
    }

    setAuthToken();
    axios
      .patch(`${REACT_APP_API_URL}/settings/qbo`, data)
      .then(() => {
        setOpen(false);
        props.saved(true);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      {props.tooltip ? (
        <Tippy content={props.tooltip}>
          <button onClick={handleClickOpen}>
            <PencilSquareIcon className="h-5 w-5 text-brand-700 hover:text-brand-500" />
          </button>
        </Tippy>
      ) : (
        <button onClick={handleClickOpen}>
          <PencilSquareIcon className="h-5 w-5 text-brand-700 hover:text-brand-500" />
        </button>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">{props.title}</Dialog.Title>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-red-50">{props.subTitle}</p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="newValue" className="block text-sm font-medium text-gray-900">
                                  {props.label}
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="newValue"
                                    id="newValue"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setNewValue(e.target.value)}
                                    value={newValue}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="focus:ring-brand rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EditQuickbooksSetting.propTypes = {
  tooltip: PropTypes.string,
  keyValue: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  saved: PropTypes.func.isRequired,
};
