import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const { REACT_APP_API_URL } = process.env;

export default function Forgot() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Capital Now Funding - Forgot Password";
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      toast.error("Please enter your email");
    } else {
      axios
        .post(`${REACT_APP_API_URL}/users/forgot`, { email })
        .then(() => {
          toast.success("Password reset email sent");
          navigate("/", { replace: true });
        })
        .catch(() => {
          toast.success("Password reset email sent");
          navigate("/", { replace: true });
        });
    }
  };

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="h-13 mx-auto w-auto" src="/images/logos/login-logo.png" alt="Capital Now Funding" />
        <h2 className="mt-6 text-center text-sm text-gray-600">
          Please enter the email address associated with your account and We will email you a link to reset your
          password.
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow-lg sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-brand-700 focus:outline-none focus:ring-brand-700 sm:text-sm"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-right">
                <div className="text-sm">
                  <a href="/login" className="font-medium text-brand-700 hover:text-brand-500">
                    Return to login?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                >
                  Request Password Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
