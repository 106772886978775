import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { client, clientError } from "../../libs/Client";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { dateFromNow, shortDate, currencyNoCents, minHeight } from "../../libs/Utils";

export default function ServiceProviderUserFundingApplications() {
  const { id } = useParams();
  const [fullName, setFullname] = useState("");

  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/service-provider-users/${id}/funding-applications?skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`)
      .then((res) => {
        return { data: res.data.fundingApplications, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, [id]);

  useEffect(() => {
    document.title = "Capital Now Funding - Service Providers - Users - Funding Applications";
    client()
      .get(`/service-provider-users/${id}`)
      .then((res) => {
        setFullname(res.data.firstName + " " + res.data.lastName);
      })
      .catch((err) => {
        clientError(err);
      });
  }, [id]);

  const gridStyle = { minHeight: minHeight };

  const columns = [
    {
      name: "caseNumber",
      header: "Case #",
      minWidth: 125,
      maxWidth: 125,
      headerAlign: "end",
      textAlign: "end",
      render: ({ data }) => {
        return (
          <a className="text-brand-700 hover:text-brand-500" href={`/cases/${data.lawsuit.id}/feed`}>
            {data.lawsuit.id}
          </a>
        );
      },
    },
    {
      name: "plaintiff",
      minWidth: 250,
      header: "Plaintiff",
      render: ({ data }) => {
        return data.lawsuit.firstName + " " + data.lawsuit.lastName;
      },
    },
    {
      name: "createdAt",
      header: "Application Date",
      minWidth: 175,
      maxWidth: 175,
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "accidentDate",
      header: "Accident Date",
      minWidth: 125,
      render: ({ value }) => {
        return shortDate(value);
      },
    },
    {
      name: "status",
      minWidth: 150,
      header: "Status",
    },
    {
      name: "requestedAmount",
      minWidth: 180,
      maxWidth: 180,
      header: "Amount Requested",
      headerAlign: "end",
      textAlign: "end",
      render: ({ value }) => {
        return currencyNoCents(value);
      },
    },
    {
      name: "activeAt",
      minWidth: 200,
      header: "Last Case Activity",
      render: ({ data }) => {
        return dateFromNow(data.lawsuit.activeAt);
      },
    },
  ];

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="mt-2 flex flex-col">
        <div className="mb-8">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Funding applications for {fullName}
          </h2>
        </div>
        <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
          <div className="mb-8 ml-2 mr-2">
            <ReactDataGrid
              idProperty="fundingApplications"
              columns={columns}
              style={gridStyle}
              showColumnMenuTool={false}
              dataSource={dataSource}
              pagination
              defaultLimit={100}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
