import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { getGooglePlacesAddress } from "../../libs/Utils";

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

export default function GooglePlaces(props) {
  const inputRef = useRef();
  const [libraries] = useState(["places"]);
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      props.selected(getGooglePlacesAddress(place));
    }
  };

  return (
    <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <StandaloneSearchBox onLoad={(ref) => (inputRef.current = ref)} onPlacesChanged={handlePlaceChanged}>
        <input
          type="text"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
          placeholder="Lookup address"
        />
      </StandaloneSearchBox>
    </LoadScript>
  );
}

GooglePlaces.propTypes = {
  streetAddress: PropTypes.string,
  selected: PropTypes.func,
};
