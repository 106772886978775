import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {
  ClipboardIcon,
  PencilSquareIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  ScaleIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";

import { client } from "../../libs/Client";
import { longDate, apiError } from "../../libs/Utils";

import CaseDetailHeader from "../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../layouts/FullLayout/Tabs/CaseDetailTabs";

export default function CaseFeed() {
  const { id } = useParams();
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details";
    client()
      .get(`/lawsuits/${id}/feed`)
      .then((res) => {
        setTimeline(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Feed" lawsuitID={id} />
        <div className="mb-24 ml-10 mr-10 mt-4">
          <div className="flow-root">
            <ul className="-mb-8">
              {timeline.map((event, eventIdx) => (
                <li key={event.id}>
                  {event.activity === "Document" && (
                    <div className="relative pb-8">
                      {eventIdx !== timeline.length - 1 ? (
                        <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <Tippy content="Document">
                            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 ring-8 ring-white">
                              <DocumentTextIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </span>
                          </Tippy>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div className="space-y-1 text-sm text-gray-500">
                            <p>
                              <span className="font-semibold">Filename:</span> {event.documentFilename}
                            </p>
                            <p>
                              <span className="font-semibold">Event:</span> {event.documentEvent}
                            </p>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={event.createdAt}>{longDate(event.createdAt)}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {event.activity === "Funding Application Contract" && (
                    <div className="relative pb-8">
                      {eventIdx !== timeline.length - 1 ? (
                        <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <Tippy content="Funding Application Contract">
                            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-orange-600 ring-8 ring-white">
                              <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </span>
                          </Tippy>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div className="space-y-1 text-sm text-gray-500">
                            <p>
                              <span className="font-semibold">Title:</span> {event.fundingApplicationContractTitle}
                            </p>
                            <p>
                              <span className="font-semibold">Event:</span> {event.fundingApplicationContractEvent}
                            </p>
                            <p>
                              <span className="font-semibold">Status:</span> {event.fundingApplicationContractStatus}
                            </p>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={event.createdAt}>{longDate(event.createdAt)}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {event.activity === "Funding Application" && (
                    <div className="relative pb-8">
                      {eventIdx !== timeline.length - 1 ? (
                        <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <Tippy content="Funding Application">
                            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-emerald-600 ring-8 ring-white">
                              <CurrencyDollarIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </span>
                          </Tippy>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div className="space-y-1 text-sm text-gray-500">
                            <p>
                              <span className="font-semibold">Event:</span> {event.fundingApplicationEvent}
                            </p>
                            <p>
                              <span className="font-semibold">Status:</span> {event.fundingApplicationStatus}
                            </p>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={event.createdAt}>{longDate(event.createdAt)}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {event.activity === "Email" && (
                    <div className="relative pb-8">
                      {eventIdx !== timeline.length - 1 ? (
                        <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <Tippy content="Email">
                            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-fuchsia-600 ring-8 ring-white">
                              <EnvelopeIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </span>
                          </Tippy>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div className="space-y-1 text-sm text-gray-500">
                            <p>
                              <span className="font-semibold">From:</span> {event.emailFrom}
                            </p>
                            <p>
                              <span className="font-semibold">To:</span> {event.emailTo}
                            </p>
                            {event.emailSentID && (
                              <p>
                                <span className="font-semibold">Subject:</span>{" "}
                                <a
                                  className="text-fuchsia-600 hover:text-fuchsia-900"
                                  href={`/email/sent/${event.emailSentID}`}
                                >
                                  {event.emailSubject}
                                </a>
                              </p>
                            )}
                            {event.emailInboxID && (
                              <p>
                                <span className="font-semibold">Subject:</span>{" "}
                                <a
                                  className="text-fuchsia-600 hover:text-fuchsia-900"
                                  href={`/email/inbox/${event.emailInboxID}`}
                                >
                                  {event.emailSubject}
                                </a>
                              </p>
                            )}
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={event.createdAt}>{longDate(event.createdAt)}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {event.activity === "Lawsuit" && (
                    <div className="relative pb-8">
                      {eventIdx !== timeline.length - 1 ? (
                        <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <Tippy content="Lawsuit">
                            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-purple-600 ring-8 ring-white">
                              <ScaleIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </span>
                          </Tippy>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div className="space-y-1 text-sm text-gray-500">
                            {event.event !== "" ? (
                              <p>
                                <span className="font-semibold">Event:</span> {event.event}
                              </p>
                            ) : null}
                            <p>
                              <span className="font-semibold">Status:</span> {event.status}
                            </p>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={event.createdAt}>{longDate(event.createdAt)}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {event.activity === "Note" && (
                    <div className="relative pb-8">
                      {eventIdx !== timeline.length - 1 ? (
                        <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <Tippy content="Note">
                            <span className="flex h-8 w-8 items-center justify-center rounded-full bg-sky-600 ring-8 ring-white">
                              <ClipboardIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </span>
                          </Tippy>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div className="space-y-1 text-sm text-gray-500">
                            <p>
                              <span className="font-semibold">Author:</span> {event.noteAuthor}
                            </p>
                            <p className="whitespace-pre-wrap">{event.noteText}</p>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={event.createdAt}>{longDate(event.createdAt)}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
