import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactSelect from "react-select";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GooglePlaces from "../../components/Google/GooglePlaces";
import AddServiceProvider from "../../components/SlideOvers/ServiceProviders/AddServiceProvider";
import AddServiceProviderUser from "../../components/SlideOvers/ServiceProviders/AddServiceProviderUser";
import DuplicateCases from "../../components/SlideOvers/Cases/DuplicateCases";
import { nationalPhoneNumber } from "../../libs/Utils";

import { vaporDate } from "../../libs/Utils";
import {
  genderOptions,
  missedWorkOptions,
  referralOptions,
  yesNoUnknownOptions,
  yesNoOptions,
  driverPassengerOptions,
  accidentInvolvingOptions,
} from "../../libs/Lists";
import { client, clientError } from "../../libs/Client";

export default function CreateCase() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const [accidentTypes, setAccidentTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [duplicates, setDuplicates] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [accidentType, setAccidentType] = useState(null);
  const [accidentState, setAccidentState] = useState(null);

  const [mvAccidentDate, setMvAccidentDate] = useState("");
  const [mvAccidentDetails, setMvAccidentDetails] = useState("");
  const [mvInjuriesSustained, setMvInjuriesSustained] = useState("");
  const [mvTreatment, setMvTreatment] = useState("");
  const [mvHealthInsurance, setMvHealthInsurance] = useState(null);
  const [mvAtFaultDriverInsurance, setMvAtFaultDriverInsurance] = useState(null);
  const [mvDriverOrPassenger, setMvDriverOrPassenger] = useState(null);
  const [mvPassengerNote, setMvPassengerNote] = useState("");
  const [mvDriverInsurance, setMvDriverInsurance] = useState("");
  const [mvOtherPeopleInVehicle, setMvOtherPeopleInVehicle] = useState(null);
  const [mvHowManyOthers, setMvHowManyOthers] = useState("");
  const [mvOtherDetails, setMvOtherDetails] = useState("");
  const [mvWasVehicleTotalled, setmvWasVehicleTotalled] = useState(null);

  const [eplAccidentDate, setEplAccidentDate] = useState("");
  const [eplAccidentDetails, setEplAccidentDetails] = useState("");
  const [eplYearPlaced, setEplYearPlaced] = useState("");
  const [eplSurgery, setEplSurgery] = useState(null);
  const [eplSurgeryYear, setEplSurgeryYear] = useState("");
  const [eplSettlement, setEplSettlement] = useState("");

  const [otherAccidentDate, setOtherAccidentDate] = useState("");
  const [otherInvolving, setOtherInvolving] = useState(null);
  const [otherName, setOtherName] = useState("");
  const [otherRelationship, setOtherRelationship] = useState("");
  const [otherIncidentDetails, setOtherIncidentDetails] = useState("");

  const [safAccidentDate, setSafAccidentDate] = useState("");
  const [safAccidentDetails, setSafAccidentDetails] = useState("");
  const [safInjuriesSustained, setSafInjuriesSustained] = useState("");
  const [safTreatment, setSafTreatment] = useState("");
  const [safHealthInsurance, setSafHealthInsurance] = useState(null);

  const [wcAccidentDate, setWcAccidentDate] = useState("");
  const [wcAccidentDetails, setWcAccidentDetails] = useState("");
  const [wcInjuriesSustained, setWcInjuriesSustained] = useState("");
  const [wcTreatment, setWcTreatment] = useState("");
  const [wcHealthInsurance, setWcHealthInsurance] = useState(null);
  const [wcNameOfEmployer, setWcNameOfEmployer] = useState("");
  const [wcJobTitle, setWcJobTitle] = useState("");
  const [wcStillCurrentlyEmployed, setWcStillCurrentlyEmployed] = useState("");

  const [jamAccidentDate, setJamAccidentDate] = useState("");
  const [jamAccidentDetails, setJamAccidentDetails] = useState("");
  const [jamInjuriesSustained, setJamInjuriesSustained] = useState("");
  const [jamTreatment, setJamTreatment] = useState("");
  const [jamHealthInsurance, setJamHealthInsurance] = useState(null);
  const [jamNameOfEmployer, setJamNameOfEmployer] = useState("");
  const [jamJobTitle, setJamJobTitle] = useState("");
  const [jamStillCurrentlyEmployed, setJamStillCurrentlyEmployed] = useState("");

  const [missedWork, setMissedWork] = useState(null);
  const [howMuchTimeMissed, setHowMuchTimeMissed] = useState("");
  const [returnedToWork, setReturnedToWork] = useState("");
  const [referredBy, setReferredBy] = useState(null);

  const [serviceProvider, setServiceProvider] = useState("");
  const [contacts, setContacts] = useState([]);
  const [primaryContact, setPrimaryContact] = useState(null);
  const [secondaryContacts, setSecondaryContacts] = useState(null);

  const [initialFundingRequestAmount, setInitialFundingRequestAmount] = useState(0);
  const [whatDoYouNeedTheMoneyFor, setWhatDoYouNeedTheMoneyFor] = useState("");
  const [fundedPreviously, setFundedPreviously] = useState(null);
  const [previousFundingBy, setPreviousFundingBy] = useState("");
  const [previousAmountReceived, setPreviousAmountReceived] = useState(0);
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phone, setPhone] = useState("");
  const [alternatePhone, setAlternatePhone] = useState("");

  const [gender, setGender] = useState(null);
  const [streetAddress, setStreetAddress] = useState("");
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [city, setCity] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    if (searchParams.get("lead")) {
      Promise.all([
        client().get(`/states`),
        client().get(`/accident-types`),
        client().get(`/service-providers/list`),
        client().get(`/leads/${searchParams.get("lead")}`),
        client().get(`/lawsuits/duplicates?leadID=${searchParams.get("lead")}`),
      ])
        .then((res) => {
          setDuplicates(res[4].data);
          setStateList(res[0].data);
          setStates(
            res[0].data.map((element) => ({
              value: element.id,
              label: element.label,
            }))
          );
          setAccidentTypes(
            res[1].data.map((element) => ({
              value: element,
              label: element,
            }))
          );
          setServiceProviders(res[2].data);
          setFirstName(res[3].data.firstName);
          setLastName(res[3].data.lastName);
          setEmail(res[3].data.email);
          setPhone(res[3].data.phone);
          setSelectedState({
            value: res[3].data.state.id,
            label: res[3].data.state.label,
          });
        })
        .catch((err) => {
          clientError(err);
        });
    } else {
      Promise.all([client().get(`/states`), client().get(`/accident-types`), client().get(`/service-providers/list`)])
        .then((res) => {
          setStateList(res[0].data);
          setStates(
            res[0].data.map((element) => ({
              value: element.id,
              label: element.label,
            }))
          );
          setAccidentTypes(
            res[1].data.map((element) => ({
              value: element,
              label: element,
            }))
          );
          setServiceProviders(res[2].data);
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [searchParams]);

  function handleAccidentTypeChanged(data) {
    setAccidentType(data);

    setMvAccidentDate("");
    setMvAccidentDetails("");
    setMvInjuriesSustained("");
    setMvTreatment("");
    setMvHealthInsurance(null);
    setMvAtFaultDriverInsurance(null);
    setMvDriverOrPassenger(null);
    setMvPassengerNote("");
    setMvOtherPeopleInVehicle(null);
    setMvHowManyOthers("");
    setMvOtherDetails("");
    setmvWasVehicleTotalled(null);

    setEplAccidentDate("");
    setEplAccidentDetails("");
    setEplYearPlaced("");
    setEplSurgery(null);
    setEplSurgeryYear("");
    setEplSettlement("");

    setOtherAccidentDate("");
    setOtherInvolving(null);
    setOtherName("");
    setOtherRelationship("");
    setOtherIncidentDetails("");

    setSafAccidentDate("");
    setSafAccidentDetails("");
    setSafInjuriesSustained("");
    setSafTreatment("");
    setSafHealthInsurance(null);

    setWcAccidentDate("");
    setWcAccidentDetails("");
    setWcInjuriesSustained("");
    setWcTreatment("");
    setWcHealthInsurance(null);
    setWcNameOfEmployer("");
    setWcJobTitle("");
    setWcStillCurrentlyEmployed("");

    setJamAccidentDate("");
    setJamAccidentDetails("");
    setJamInjuriesSustained("");
    setJamTreatment("");
    setJamHealthInsurance(null);
    setJamNameOfEmployer("");
    setJamJobTitle("");
    setJamStillCurrentlyEmployed("");
  }

  function handleMvDriverOrPassengerChanged(data) {
    setMvDriverOrPassenger(data);
    setMvPassengerNote("");
    setMvDriverInsurance("");
  }

  function handleMvOtherPeopleInVehicleChanged(data) {
    setMvOtherPeopleInVehicle(data);
    setMvHowManyOthers("");
    setMvOtherDetails("");
  }

  function handleEplSurgeryChanged(data) {
    setEplSurgery(data);
    setEplSurgeryYear("");
  }

  function handleOtherInvolvingChanged(data) {
    setOtherInvolving(data);
    setOtherName("");
    setOtherRelationship("");
  }

  function handleServiceProviderChanged(data) {
    setServiceProvider(data);
    if (data) {
      client()
        .get(`/service-providers/${data.value}/users`)
        .then((res) => {
          setContacts(
            res.data.map((element) => ({
              value: element.id,
              label: element.firstName + " " + element.lastName,
            }))
          );
          setPrimaryContact(null);
          setSecondaryContacts(null);
        })
        .catch((err) => {
          clientError(err);
        });
    } else {
      setPrimaryContact(null);
      setSecondaryContacts(null);
    }
  }

  function handleFundedPreviouslyChanged(data) {
    setFundedPreviously(data);
    setPreviousFundingBy("");
    setPreviousAmountReceived(0);
  }

  function handleMissedWorkChanged(data) {
    setMissedWork(data);
    setHowMuchTimeMissed("");
    setReturnedToWork("");
  }

  function handleAddServiceProvider(data) {
    client()
      .get(`/service-providers/list`)
      .then((res) => {
        setServiceProviders(res.data);
        handleServiceProviderChanged({ value: data.id, label: data.name });
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handlePlacesChange(data) {
    const filteredResult = stateList.find((e) => e.abbreviation === data.state);
    setCity(data.city);
    setStreetAddress(data.streetAddress);
    setZipCode(data.zipCode);
    setSelectedState({ value: filteredResult.id, label: filteredResult.label });
    setCountry(data.country);
  }

  function handleAddUser(data) {
    client()
      .get(`/service-providers/${data.serviceProvider.id}/users`)
      .then((res) => {
        setContacts(
          res.data.map((element) => ({
            value: element.id,
            label: element.firstName + " " + element.lastName,
          }))
        );
        setPrimaryContact({
          value: data.id,
          label: data.firstName + " " + data.lastName,
        });
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleSave() {
    var data = {};
    if (searchParams.get("lead")) {
      data.leadID = parseInt(searchParams.get("lead"), 10);
    }

    if (firstName === "") {
      toast.error("First name is required");
      return;
    }
    data.firstName = firstName;

    if (lastName === "") {
      toast.error("Last name is required");
      return;
    }
    data.lastName = lastName;

    if (phone === "") {
      toast.error("Phone number required");
      return;
    }
    data.phone = phone;
    data.email = email;

    if (!accidentType) {
      toast.error("Accident type is required");
      return;
    }
    data.accidentType = accidentType.value;

    if (!accidentState) {
      toast.error("Accident state is required");
      return;
    }
    data.accidentStateID = accidentState.value;

    if (accidentType.value === "Motor Vehicle Accident") {
      data.accidentDate = vaporDate(mvAccidentDate);
      const mv = {
        accidentDetails: mvAccidentDetails,
        injuriesSustained: mvInjuriesSustained,
        treatment: mvTreatment,
        healthInsurance: mvHealthInsurance ? mvHealthInsurance.value : "",
        atFaultDriverInsurance: mvAtFaultDriverInsurance ? mvAtFaultDriverInsurance.value : "",
        driverOrPassenger: mvDriverOrPassenger ? mvDriverOrPassenger.value : "",
        passengerNote: mvPassengerNote,
        driverInsurance: mvDriverInsurance,
        otherOccupants: mvOtherPeopleInVehicle.value === "Yes" ? true : false,
        howManyOthers: mvHowManyOthers,
        otherDetails: mvOtherDetails,
        wasVehicleTotalled: mvWasVehicleTotalled ? mvWasVehicleTotalled.value : "",
      };
      data.motorVehicleAccident = mv;
    } else if (accidentType.value === "Product Liability - Essure") {
      data.accidentDate = vaporDate(eplAccidentDate);
      const epl = {
        accidentDetails: eplAccidentDetails,
        yearPlaced: parseInt(eplYearPlaced, 10),
        surgeryYear: parseInt(eplSurgeryYear, 10) ? parseInt(eplSurgeryYear, 10) : null,
        surgery: eplSurgery.value === "Yes" ? true : false,
        settlement: eplSettlement,
      };
      data.essureProductLiability = epl;
    } else if (accidentType.value === "Other") {
      data.accidentDate = vaporDate(otherAccidentDate);
      const other = {
        involving: otherInvolving ? otherInvolving.value : null,
        name: otherName,
        relationship: otherRelationship,
        incidentDetails: otherIncidentDetails,
      };
      data.otherAccident = other;
    } else if (accidentType.value === "Slip and Fall") {
      data.accidentDate = vaporDate(safAccidentDate);
      const saf = {
        accidentDetails: safAccidentDetails,
        injuriesSustained: safInjuriesSustained,
        treatment: safTreatment,
        healthInsurance: safHealthInsurance ? safHealthInsurance.value : "",
      };
      data.slipAndFall = saf;
    } else if (accidentType.value === "Workers Compensation") {
      data.accidentDate = vaporDate(wcAccidentDate);
      const wc = {
        accidentDetails: wcAccidentDetails,
        injuriesSustained: wcInjuriesSustained,
        treatment: wcTreatment,
        healthInsurance: wcHealthInsurance ? wcHealthInsurance.value : "",
        nameOfEmployer: wcNameOfEmployer,
        jobTitle: wcJobTitle,
        stillCurrentlyEmployed: wcStillCurrentlyEmployed,
      };
      data.workersCompensation = wc;
    } else if (accidentType.value === "Jones Act / Maritime") {
      data.accidentDate = vaporDate(jamAccidentDate);
      const jam = {
        accidentDetails: jamAccidentDetails,
        injuriesSustained: jamInjuriesSustained,
        treatment: jamTreatment,
        healthInsurance: jamHealthInsurance ? jamHealthInsurance.value : "",
        nameOfEmployer: jamNameOfEmployer,
        jobTitle: jamJobTitle,
        stillCurrentlyEmployed: jamStillCurrentlyEmployed,
      };
      data.jonesAct = jam;
    } else {
      toast.error("You need to select an accident type");
      return;
    }

    data.missedWork = missedWork ? missedWork.value : null;
    if (data.missedWork === "Yes") {
      data.howMuchTimeMissed = howMuchTimeMissed;
      data.returnedToWork = returnedToWork;
    }

    if (referredBy) {
      data.referredBy = referredBy.value;
    }

    if (!serviceProvider) {
      toast.error("You need to select a service provider");
      return;
    }
    data.serviceProviderID = serviceProvider.value;

    if (!primaryContact) {
      toast.error("You need to select a primary contact");
      return;
    }
    data.primaryContactID = primaryContact.value;

    var secondaryContactIDs = [];
    if (secondaryContacts) {
      secondaryContacts.forEach((contact) => {
        secondaryContactIDs.push(contact.value);
      });
    }

    data.secondaryContactIDs = secondaryContactIDs;
    data.initialFundingRequestAmount = parseInt(initialFundingRequestAmount, 10) * 100;
    data.fundingFor = whatDoYouNeedTheMoneyFor;

    if (!fundedPreviously) {
      toast.error("Funded previously required");
      return;
    }
    data.fundedPreviously = fundedPreviously.value === "Yes" ? true : false;

    if (fundedPreviously) {
      data.fundedPreviouslyBy = previousFundingBy;
      data.previousAmount = parseInt(previousAmountReceived, 10) * 100;
    }

    data.dateOfBirth = vaporDate(dateOfBirth);

    data.alternatePhone = alternatePhone;
    if (gender) {
      data.gender = gender.value;
    }

    data.streetAddress = streetAddress;
    data.apartmentNumber = apartmentNumber;
    data.city = city;
    data.stateID = selectedState.value;
    data.zipCode = zipCode;
    data.country = country;

    client()
      .post(`/lawsuits`, data)
      .then((res) => {
        toast.success("Case created");
        navigate(`/cases/${res.data.id}/feed`);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div className="py-6">
      <div className="mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-2">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Create Case</h1>
          </div>
          {duplicates.length > 0 ? (
            <div className="mt-4 rounded-md bg-white p-4">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <h1 className="text-lg font-semibold text-gray-900">Possible Duplicate Cases</h1>
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Phone
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {duplicates.map((duplicate) => (
                      <tr key={duplicate.id}>
                        <td className="whitespace-nowrap text-right py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                          <a
                            className="text-brand-700 hover:text-brand-500 font-semibold"
                            href={`/cases/${duplicate.id}/feed`}
                          >
                            {duplicate.id}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                          {duplicate.firstName}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{duplicate.lastName}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{duplicate.email}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                          {nationalPhoneNumber(duplicate.phone)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
          <div className="mt-4 rounded-md bg-white p-4">
            <div className="mt-6">
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                Plaintiff First Name <span className="text-brand-700">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                Plaintiff Last Name <span className="text-brand-700">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </div>
            </div>
            <div className="mt-6">
              <hr />
            </div>
            <div className="mt-6">
              <label htmlFor="accidentType" className="block text-sm font-medium text-gray-700">
                Accident Type
              </label>
              <div className="remove-input-txt-border mt-1 text-sm">
                <ReactSelect
                  options={accidentTypes}
                  isClearable
                  onChange={(params) => {
                    handleAccidentTypeChanged(params);
                  }}
                />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="accidentState" className="block text-sm font-medium text-gray-700">
                What state was your accident in?
              </label>
              <div className="remove-input-txt-border mt-1 text-sm">
                <ReactSelect
                  options={states}
                  isClearable
                  onChange={(params) => {
                    setAccidentState(params);
                  }}
                />
              </div>
            </div>
            {accidentType && accidentType.value === "Motor Vehicle Accident" ? (
              <div>
                <div className="mt-6">
                  <label htmlFor="mvAccidentDate" className="block text-sm font-medium text-gray-700">
                    Accident Date <span className="text-brand-700">*</span>
                  </label>
                  <div className="mt-1">
                    <DatePicker
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                      selected={mvAccidentDate}
                      onChange={(date) => setMvAccidentDate(date)}
                      dateFormat="MM/dd/yyyy"
                      maxDate={new Date()}
                      value={mvAccidentDate}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="mvAccidentDetails" className="block text-sm font-medium text-gray-700">
                    Accident Details - What Happened?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="mvAccidentDetails"
                      id="mvAccidentDetails"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setMvAccidentDetails(e.target.value)}
                      value={mvAccidentDetails}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="mvInjuriesSustained" className="block text-sm font-medium text-gray-700">
                    Injuries Sustained?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="mvInjuriesSustained"
                      id="mvInjuriesSustained"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setMvInjuriesSustained(e.target.value)}
                      value={mvInjuriesSustained}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="mvTreatment" className="block text-sm font-medium text-gray-700">
                    Treatment - when, what kind, how long, are you still treating?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="mvTreatment"
                      id="mvTreatment"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setMvTreatment(e.target.value)}
                      value={mvTreatment}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="mvHealthInsurance" className="block text-sm font-medium text-gray-700">
                    Did you have health insurance at the time of the accident?
                  </label>
                  <div className="remove-input-txt-border mt-1 text-sm">
                    <ReactSelect
                      options={yesNoUnknownOptions}
                      isClearable
                      onChange={(params) => {
                        setMvHealthInsurance(params);
                      }}
                      value={mvHealthInsurance}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="mvAtFaultDriverInsurance" className="block text-sm font-medium text-gray-700">
                    Did the at fault driver have insurance?
                  </label>
                  <div className="remove-input-txt-border mt-1 text-sm">
                    <ReactSelect
                      options={yesNoUnknownOptions}
                      isClearable
                      onChange={(params) => {
                        setMvAtFaultDriverInsurance(params);
                      }}
                      value={mvAtFaultDriverInsurance}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="mvDriverOrPassenger" className="block text-sm font-medium text-gray-700">
                    Were you the driver or passenger in the vehicle, or a pedestrian?
                  </label>
                  <div className="remove-input-txt-border mt-1 text-sm">
                    <ReactSelect
                      options={driverPassengerOptions}
                      isClearable
                      onChange={(params) => {
                        handleMvDriverOrPassengerChanged(params);
                      }}
                      value={mvDriverOrPassenger}
                    />
                  </div>
                </div>
                {mvDriverOrPassenger && mvDriverOrPassenger.value === "Passenger" ? (
                  <div className="mt-6">
                    <label htmlFor="mvPassengerNote" className="block text-sm font-medium text-gray-700">
                      Where were you sitting as a passenger?
                    </label>
                    <div className="mt-1">
                      <textarea
                        rows="4"
                        name="mvPassengerNote"
                        id="mvPassengerNote"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setMvPassengerNote(e.target.value)}
                        value={mvPassengerNote}
                      ></textarea>
                    </div>
                  </div>
                ) : null}
                {mvDriverOrPassenger && mvDriverOrPassenger.value === "Driver" ? (
                  <div className="mt-6">
                    <label htmlFor="mvDriverInsurance" className="block text-sm font-medium text-gray-700">
                      Did you have insurance?
                    </label>
                    <div className="mt-1">
                      <textarea
                        rows="4"
                        name="mvDriverInsurance"
                        id="mvDriverInsurance"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setMvDriverInsurance(e.target.value)}
                        value={mvDriverInsurance}
                      ></textarea>
                    </div>
                  </div>
                ) : null}
                <div className="mt-6">
                  <label htmlFor="mvOtherPeopleInVehicle" className="block text-sm font-medium text-gray-700">
                    Were other people in the vehicle with you at the time of the accident?
                  </label>
                  <div className="remove-input-txt-border mt-1 text-sm">
                    <ReactSelect
                      options={yesNoOptions}
                      isClearable
                      onChange={(params) => {
                        handleMvOtherPeopleInVehicleChanged(params);
                      }}
                      value={mvOtherPeopleInVehicle}
                    />
                  </div>
                </div>
                {mvOtherPeopleInVehicle && mvOtherPeopleInVehicle.value === "Yes" ? (
                  <div className="mt-6">
                    <label htmlFor="mvOtherDetails" className="block text-sm font-medium text-gray-700">
                      How many others?
                    </label>
                    <div className="mt-1">
                      <input
                        type="number"
                        name="mvOtherDetails"
                        id="mvOtherDetails"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                        onChange={(e) => setMvHowManyOthers(e.target.value)}
                        value={mvHowManyOthers}
                      />
                    </div>
                  </div>
                ) : null}
                {mvOtherPeopleInVehicle && mvOtherPeopleInVehicle.value === "Yes" ? (
                  <div className="mt-6">
                    <label htmlFor="mvOtherDetails" className="block text-sm font-medium text-gray-700">
                      Other Details
                    </label>
                    <div className="mt-1">
                      <textarea
                        rows="4"
                        name="mvOtherDetails"
                        id="mvOtherDetails"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setMvOtherDetails(e.target.value)}
                        value={mvOtherDetails}
                      ></textarea>
                    </div>
                  </div>
                ) : null}
                <div className="mt-6">
                  <label htmlFor="mvDriverPassenger" className="block text-sm font-medium text-gray-700">
                    Was the vehicle totaled as a result of the accident?
                  </label>
                  <div className="remove-input-txt-border mt-1 text-sm">
                    <ReactSelect
                      options={yesNoUnknownOptions}
                      isClearable
                      onChange={(params) => {
                        setmvWasVehicleTotalled(params);
                      }}
                      value={mvWasVehicleTotalled}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {accidentType && accidentType.value === "Product Liability - Essure" ? (
              <div>
                <div className="mt-6">
                  <label htmlFor="eplAccidentDate" className="block text-sm font-medium text-gray-700">
                    Accident Date
                  </label>
                  <div className="mt-1">
                    <DatePicker
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                      selected={eplAccidentDate}
                      onChange={(date) => setEplAccidentDate(date)}
                      dateFormat="MM/dd/yyyy"
                      maxDate={new Date()}
                      value={eplAccidentDate}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="eplAccidentDetails" className="block text-sm font-medium text-gray-700">
                    Accident Details - What happened?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="eplAccidentDetails"
                      id="eplAccidentDetails"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setEplAccidentDetails(e.target.value)}
                      value={eplAccidentDetails}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="eplYearPlaced" className="block text-sm font-medium text-gray-700">
                    Do you know what year the product was placed?
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="eplYearPlaced"
                      id="eplYearPlaced"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                      onChange={(e) => setEplYearPlaced(e.target.value)}
                      value={eplYearPlaced}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="eplSurgery" className="block text-sm font-medium text-gray-700">
                    Did you have surgery to remove the product?
                  </label>
                  <div className="remove-input-txt-border mt-1 text-sm">
                    <ReactSelect
                      options={yesNoOptions}
                      isClearable
                      onChange={(params) => {
                        handleEplSurgeryChanged(params);
                      }}
                      value={eplSurgery}
                    />
                  </div>
                </div>
                {eplSurgery && eplSurgery.value === "Yes" ? (
                  <div className="mt-6">
                    <label htmlFor="eplSurgeryYear" className="block text-sm font-medium text-gray-700">
                      What year did the surgery take place?
                    </label>
                    <div className="mt-1">
                      <input
                        type="number"
                        name="eplSurgeryYear"
                        id="eplSurgeryYear"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                        onChange={(e) => setEplSurgeryYear(e.target.value)}
                        value={eplSurgeryYear}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="mt-6">
                  <label htmlFor="eplSettlement" className="block text-sm font-medium text-gray-700">
                    Has your case reached settlement?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="eplSettlement"
                      id="eplSettlement"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setEplSettlement(e.target.value)}
                      value={eplSettlement}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : null}
            {accidentType && accidentType.value === "Other" ? (
              <div>
                <div className="mt-6">
                  <label htmlFor="otherAccidentDate" className="block text-sm font-medium text-gray-700">
                    Accident Date
                  </label>
                  <div className="mt-1">
                    <DatePicker
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                      selected={otherAccidentDate}
                      onChange={(date) => setOtherAccidentDate(date)}
                      dateFormat="MM/dd/yyyy"
                      maxDate={new Date()}
                      value={otherAccidentDate}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="otherInvolving" className="block text-sm font-medium text-gray-700">
                    Is this case involving you or someone else?
                  </label>
                  <div className="remove-input-txt-border mt-1 text-sm">
                    <ReactSelect
                      options={accidentInvolvingOptions}
                      isClearable
                      onChange={(params) => {
                        handleOtherInvolvingChanged(params);
                      }}
                      value={otherInvolving}
                    />
                  </div>
                </div>
                {otherInvolving && otherInvolving.value === "Someone Else" ? (
                  <div>
                    <div className="mt-6">
                      <label htmlFor="otherName" className="block text-sm font-medium text-gray-700">
                        What is the name of the person?
                      </label>
                      <div className="mt-1">
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="otherName"
                            id="money"
                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => setOtherName(e.target.value)}
                            value={otherName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-6">
                      <label htmlFor="otherRelationship" className="block text-sm font-medium text-gray-700">
                        What is your relationship to the person / Are you their POA?
                      </label>
                      <div className="mt-1">
                        <textarea
                          rows="4"
                          name="otherRelationship"
                          id="otherRelationship"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          onChange={(e) => setOtherRelationship(e.target.value)}
                          value={otherRelationship}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="mt-6">
                  <label htmlFor="otherIncidentDetails" className="block text-sm font-medium text-gray-700">
                    Incident Details - What happened?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="otherIncidentDetails"
                      id="otherIncidentDetails"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setOtherIncidentDetails(e.target.value)}
                      value={otherIncidentDetails}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : null}
            {accidentType && accidentType.value === "Slip and Fall" ? (
              <div>
                <div className="mt-6">
                  <label htmlFor="safAccidentDate" className="block text-sm font-medium text-gray-700">
                    Accident Date <span className="text-brand-700">*</span>
                  </label>
                  <div className="mt-1">
                    <DatePicker
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                      selected={safAccidentDate}
                      onChange={(date) => setSafAccidentDate(date)}
                      dateFormat="MM/dd/yyyy"
                      maxDate={new Date()}
                      value={safAccidentDate}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="safAccidentDetails" className="block text-sm font-medium text-gray-700">
                    Accident Details - What Happened?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="safAccidentDetails"
                      id="safAccidentDetails"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setSafAccidentDetails(e.target.value)}
                      value={safAccidentDetails}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="safInjuriesSustained" className="block text-sm font-medium text-gray-700">
                    Injuries Sustained?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="safInjuriesSustained"
                      id="safInjuriesSustained"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setSafInjuriesSustained(e.target.value)}
                      value={safInjuriesSustained}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="safTreatment" className="block text-sm font-medium text-gray-700">
                    Treatment - when, what kind, how long, are you still treating?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="safTreatment"
                      id="safTreatment"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setSafTreatment(e.target.value)}
                      value={safTreatment}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="safHealthInsurance" className="block text-sm font-medium text-gray-700">
                    Did you have health insurance at the time of the accident?
                  </label>
                  <div className="remove-input-txt-border mt-1 text-sm">
                    <ReactSelect
                      options={yesNoUnknownOptions}
                      isClearable
                      onChange={(params) => {
                        setSafHealthInsurance(params);
                      }}
                      value={safHealthInsurance}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {accidentType && accidentType.value === "Workers Compensation" ? (
              <div>
                <div className="mt-6">
                  <label htmlFor="wcAccidentDate" className="block text-sm font-medium text-gray-700">
                    Accident Date <span className="text-brand-700">*</span>
                  </label>
                  <div className="mt-1">
                    <DatePicker
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                      selected={wcAccidentDate}
                      onChange={(date) => setWcAccidentDate(date)}
                      dateFormat="MM/dd/yyyy"
                      maxDate={new Date()}
                      value={wcAccidentDate}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="mvAccidentDetails" className="block text-sm font-medium text-gray-700">
                    Accident Details - What Happened?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="wcAccidentDetails"
                      id="wcAccidentDetails"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setWcAccidentDetails(e.target.value)}
                      value={wcAccidentDetails}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="wcInjuriesSustained" className="block text-sm font-medium text-gray-700">
                    Injuries Sustained?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="wcInjuriesSustained"
                      id="wcInjuriesSustained"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setWcInjuriesSustained(e.target.value)}
                      value={wcInjuriesSustained}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="wcTreatment" className="block text-sm font-medium text-gray-700">
                    Treatment - when, what kind, how long, are you still treating?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="wcTreatment"
                      id="wcTreatment"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setWcTreatment(e.target.value)}
                      value={wcTreatment}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="wcHealthInsurance" className="block text-sm font-medium text-gray-700">
                    Did you have health insurance at the time of the accident?
                  </label>
                  <div className="remove-input-txt-border mt-1 text-sm">
                    <ReactSelect
                      options={yesNoUnknownOptions}
                      isClearable
                      onChange={(params) => {
                        setWcHealthInsurance(params);
                      }}
                      value={wcHealthInsurance}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="wcNameOfEmployer" className="block text-sm font-medium text-gray-700">
                    What is the name of your employer?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="wcNameOfEmployer"
                      id="wcNameOfEmployer"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setWcNameOfEmployer(e.target.value)}
                      value={wcNameOfEmployer}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="wcJobTitle" className="block text-sm font-medium text-gray-700">
                    What was your job title and job duty there?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="wcJobTitle"
                      id="wcJobTitle"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setWcJobTitle(e.target.value)}
                      value={wcJobTitle}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="wcStillCurrentlyEmployed" className="block text-sm font-medium text-gray-700">
                    Are you still currently employed there?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="wcStillCurrentlyEmployed"
                      id="wcStillCurrentlyEmployed"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setWcStillCurrentlyEmployed(e.target.value)}
                      value={wcStillCurrentlyEmployed}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : null}
            {accidentType && accidentType.value === "Jones Act / Maritime" ? (
              <div>
                <div className="mt-6">
                  <label htmlFor="jamAccidentDate" className="block text-sm font-medium text-gray-700">
                    Accident Date <span className="text-brand-700">*</span>
                  </label>
                  <div className="mt-1">
                    <DatePicker
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                      selected={jamAccidentDate}
                      onChange={(date) => setJamAccidentDate(date)}
                      dateFormat="MM/dd/yyyy"
                      maxDate={new Date()}
                      value={jamAccidentDate}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="jamAccidentDetails" className="block text-sm font-medium text-gray-700">
                    Accident Details - What Happened?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="jamAccidentDetails"
                      id="jamAccidentDetails"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setJamAccidentDetails(e.target.value)}
                      value={jamAccidentDetails}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="jamInjuriesSustained" className="block text-sm font-medium text-gray-700">
                    Injuries Sustained?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="jamInjuriesSustained"
                      id="jamInjuriesSustained"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setJamInjuriesSustained(e.target.value)}
                      value={jamInjuriesSustained}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="jamTreatment" className="block text-sm font-medium text-gray-700">
                    Treatment - when, what kind, how long, are you still treating?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="jamTreatment"
                      id="jamTreatment"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setJamTreatment(e.target.value)}
                      value={jamTreatment}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="jamHealthInsurance" className="block text-sm font-medium text-gray-700">
                    Did you have health insurance at the time of the accident?
                  </label>
                  <div className="remove-input-txt-border mt-1 text-sm">
                    <ReactSelect
                      options={yesNoUnknownOptions}
                      isClearable
                      onChange={(params) => {
                        setJamHealthInsurance(params);
                      }}
                      value={jamHealthInsurance}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="jamNameOfEmployer" className="block text-sm font-medium text-gray-700">
                    What is the name of your employer?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="jamNameOfEmployer"
                      id="jamNameOfEmployer"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setJamNameOfEmployer(e.target.value)}
                      value={jamNameOfEmployer}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="jamJobTitle" className="block text-sm font-medium text-gray-700">
                    What was your job title and job duty there?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="jamJobTitle"
                      id="jamJobTitle"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setJamJobTitle(e.target.value)}
                      value={jamJobTitle}
                    ></textarea>
                  </div>
                </div>
                <div className="mt-6">
                  <label htmlFor="jamStillCurrentlyEmployed" className="block text-sm font-medium text-gray-700">
                    Are you still currently employed there?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="jamStillCurrentlyEmployed"
                      id="jamStillCurrentlyEmployed"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setJamStillCurrentlyEmployed(e.target.value)}
                      value={jamStillCurrentlyEmployed}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="mt-6">
              <label htmlFor="missedWork" className="block text-sm font-medium text-gray-700">
                Due to the injuries, were any days of work missed?
              </label>
              <div className="remove-input-txt-border mt-1 text-sm">
                <ReactSelect
                  options={missedWorkOptions}
                  isClearable
                  onChange={(params) => {
                    handleMissedWorkChanged(params);
                  }}
                  value={missedWork}
                />
              </div>
            </div>
            {missedWork && missedWork.value === "Yes" ? (
              <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label htmlFor="howMuchTimeMissed" className="block text-sm font-medium text-gray-700">
                    How much time have you missed since the accident?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="howMuchTimeMissed"
                      id="howMuchTimeMissed"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setHowMuchTimeMissed(e.target.value)}
                      value={howMuchTimeMissed}
                    ></textarea>
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="returnedToWork" className="block text-sm font-medium text-gray-700">
                    Have you been able to return to work?
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows="4"
                      name="returnedToWork"
                      id="returnedToWork"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setReturnedToWork(e.target.value)}
                      value={returnedToWork}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="mt-6">
              <hr />
            </div>
            <div className="mt-6">
              <label htmlFor="referredBy" className="block text-sm font-medium text-gray-700">
                How did you hear about Capital Now Funding?
              </label>
              <div className="remove-input-txt-border mt-1 text-sm">
                <ReactSelect
                  options={referralOptions}
                  isClearable
                  onChange={(params) => {
                    setReferredBy(params);
                  }}
                />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="serviceProvider" className="block text-sm font-medium text-gray-700">
                Service Provider
              </label>
              <div className="remove-input-txt-border mt-1 text-sm">
                <ReactSelect
                  options={serviceProviders}
                  isClearable
                  onChange={(params) => {
                    handleServiceProviderChanged(params);
                  }}
                  value={serviceProvider}
                />
              </div>
              <div className="float-right mt-1">
                <AddServiceProvider textLink saved={handleAddServiceProvider} />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="primaryContactID" className="block text-sm font-medium text-gray-700">
                Primary Contact
              </label>
              <div className="remove-input-txt-border mt-1 text-sm">
                <ReactSelect
                  options={contacts}
                  onChange={(params) => setPrimaryContact(params)}
                  value={primaryContact}
                />
              </div>
              {serviceProvider && (
                <div className="float-right mt-1">
                  <AddServiceProviderUser id={serviceProvider.value} textLink saved={handleAddUser} />
                </div>
              )}
            </div>
            <div className="mt-6">
              <label htmlFor="accidentState" className="block text-sm font-medium text-gray-700">
                Secondary Contacts
              </label>
              <div className="remove-input-txt-border mt-1 text-sm">
                <ReactSelect
                  options={contacts}
                  isMulti
                  onChange={(params) => {
                    setSecondaryContacts(params);
                  }}
                  value={secondaryContacts}
                />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="initialFundingRequestAmount" className="block text-sm font-medium text-gray-700">
                How much money would you like to request?
              </label>
              <div className="mt-1">
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name="initialFundingRequestAmount"
                    id="initialFundingRequestAmount"
                    className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    aria-describedby="price-currency"
                    onChange={(e) => setInitialFundingRequestAmount(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="whatDoYouNeedTheMoneyFor" className="block text-sm font-medium text-gray-700">
                What do you need the money for?
              </label>
              <div className="mt-1">
                <textarea
                  name="whatDoYouNeedTheMoneyFor"
                  id="whatDoYouNeedTheMoneyFor"
                  rows={5}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                  onChange={(e) => setWhatDoYouNeedTheMoneyFor(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="fundedPreviously" className="block text-sm font-medium text-gray-700">
                Have you previously received Pre-Settlement funding?
              </label>
              <div className="remove-input-txt-border mt-1 text-sm">
                <ReactSelect
                  options={yesNoOptions}
                  isClearable
                  onChange={(params) => {
                    handleFundedPreviouslyChanged(params);
                  }}
                />
              </div>
            </div>
            {fundedPreviously && fundedPreviously.value === "Yes" ? (
              <div className="mt-6">
                <label htmlFor="previousFundingBy" className="block text-sm font-medium text-gray-700">
                  What company did you receive funding from?
                </label>
                <div className="mt-1">
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="previousFundingBy"
                      id="previousFundingBy"
                      className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setPreviousFundingBy(e.target.value)}
                      value={previousFundingBy}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {fundedPreviously && fundedPreviously.value === "Yes" ? (
              <div className="mt-6">
                <label htmlFor="previousAmountReceived" className="block text-sm font-medium text-gray-700">
                  How much money did you receive?
                </label>
                <div className="mt-1">
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="number"
                      name="previousAmountReceived"
                      id="previousAmountReceived"
                      className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setPreviousAmountReceived(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="mt-6">
              <hr />
            </div>
            <div className="mt-6">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address <span className="text-brand-700">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">
                Date of Birth
              </label>
              <div className="mt-1">
                <DatePicker
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                  selected={dateOfBirth}
                  onChange={(date) => setDateOfBirth(date)}
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <div className="mt-1 ">
                <ReactPhoneInput
                  country={"us"}
                  onlyCountries={["us"]}
                  onChange={(phoneNumber) => setPhone(phoneNumber)}
                  value={phone}
                />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="alternatPhone" className="block text-sm font-medium text-gray-700">
                Alternate Phone
              </label>
              <div className="mt-1">
                <ReactPhoneInput
                  country={"us"}
                  onlyCountries={["us"]}
                  onChange={(phoneNumber) => setAlternatePhone(phoneNumber)}
                />
              </div>
            </div>
            <div className="mt-6">
              <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                Gender?
              </label>
              <div className="remove-input-txt-border mt-1 text-sm">
                <ReactSelect
                  options={genderOptions}
                  isClearable
                  onChange={(params) => {
                    setGender(params);
                  }}
                />
              </div>
            </div>
            <div className="mt-6 rounded-md bg-gray-100 p-4">
              <label htmlFor="places" className="block text-sm font-medium text-gray-700">
                Address Lookup
              </label>
              <div className="mt-1">
                <GooglePlaces selected={handlePlacesChange} streetAddress={streetAddress} />
              </div>
              <div className="mt-6">
                <label htmlFor="streetAddress" className="block text-sm font-medium text-gray-700">
                  Street Address / P.O. Box
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="streetAddress"
                    id="streetAddress"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                    onChange={(e) => setStreetAddress(e.target.value)}
                    value={streetAddress}
                  />
                </div>
              </div>
              <div className="mt-6">
                <label htmlFor="apartmentNumber" className="block text-sm font-medium text-gray-700">
                  Apt / Unit #
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="apartmentNumber"
                    id="apartmentNumber"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                    onChange={(e) => setApartmentNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-6">
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  />
                </div>
              </div>
              <div className="mt-6">
                <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                  State
                </label>
                <div className="remove-input-txt-border mt-1 text-sm">
                  <ReactSelect
                    options={states}
                    isClearable
                    onChange={(params) => {
                      setSelectedState(params);
                    }}
                    value={selectedState}
                  />
                </div>
              </div>
              <div className="mt-6">
                <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700">
                  Zip Code
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                    onChange={(e) => setZipCode(e.target.value)}
                    value={zipCode}
                  />
                </div>
              </div>
              <div className="mt-6">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  Country
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="country"
                    id="country"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-700 focus:ring-gray-700 sm:text-sm"
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 text-end">
              <DuplicateCases
                firstName={firstName}
                lastName={lastName}
                email={email}
                phone={phone}
                create={handleSave}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
