import PropTypes from "prop-types";

export default function AvatarLabel(props) {
  if (props.profilePicURL) {
    return (
      <div className="flex items-center">
        <div className="h-10 w-10 flex-shrink-0">
          <img className="h-10 w-10 rounded-full" src={props.profilePicURL} alt="" />
        </div>
        <div className="ml-4">
          <div className="font-medium text-gray-900">
            {props.firstName} {props.lastName}
          </div>
          <div className="text-gray-500">{props.email}</div>
        </div>
      </div>
    );
  }

  if (props.strikeThrough) {
    return (
      <div>
        <div className="font-medium text-gray-900 line-through">
          {props.firstName} {props.lastName}
        </div>
        <div className="text-gray-500 line-through">{props.email}</div>
      </div>
    );
  }

  return (
    <div>
      <div className="font-medium text-gray-900">
        {props.firstName} {props.lastName}
      </div>
      <div className="text-gray-500">{props.email}</div>
    </div>
  );
}

AvatarLabel.propTypes = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  profilePicURL: PropTypes.string,
  strikeThrough: PropTypes.bool,
};
