import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { toast } from "react-toastify";
import numeral from "numeral";
import moment from "moment";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { DragDrop, StatusBar } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../datagrid.css";
import { useParams } from "react-router-dom";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { client, clientError } from "../../../libs/Client";
import { dateComparator, shortDateUTC } from "../../../libs/Utils";
import CaseDetailHeader from "../../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseOverviewTabs from "../../../layouts/FullLayout/Tabs/CaseOverviewTabs";
import DeleteDialog from "../../../components/Dialogs/DeleteDialog";
import EssureProductLiabilityAccident from "../../../components/Views/EssureProductLiabilityAccident";
import JonesActMaritimeAccident from "../../../components/Views/JonesActMaritimeAccident";
import MotorVehicleAccident from "../../../components/Views/MotorVehicleAccident";
import OtherAccident from "../../../components/Views/OtherAccident";
import SlipAndFallAccident from "../../../components/Views/SlipAndFallAccident";
import WorkersCompensationAccident from "../../../components/Views/WorkersCompensationAccident";
import EditAccidentType from "../../../components/SlideOvers/Cases/Accident/EditAccidentType";
import EditAccidentDate from "../../../components/SlideOvers/Cases/Accident/EditAccidentDate";
import EditAccidentState from "../../../components/SlideOvers/Cases/Accident/EditAccidentState";
import EditMissedWork from "../../../components/SlideOvers/Cases/Accident/EditMissedWork";
import EditHowMuchTimeMissed from "../../../components/SlideOvers/Cases/Accident/EditHowMuchTimeMissed";
import EditReturnedToWork from "../../../components/SlideOvers/Cases/Accident/EditReturnedToWork";

export default function CaseOverviewAccidentDetails() {
  const { id } = useParams();
  const gridRef = useRef();
  const [policeReports, setPoliceReports] = useState([]);

  const [accidentType, setAccidentType] = useState("");
  const [accidentDate, setAccidentDate] = useState(null);
  const [accidentState, setAccidentState] = useState("");

  const [missedWork, setMissedWork] = useState(null);
  const [howMuchTimeMissed, setHowMuchTimeMissed] = useState("");
  const [returnedToWork, setReturnedToWork] = useState("");

  const [eplID, setEplID] = useState("");
  const [eplAccidentDetails, setEplAccidentDetails] = useState("");
  const [eplYearPlaced, setEplYearPlaced] = useState("");
  const [eplSurgery, setEplSurgery] = useState("");
  const [eplSurgeryYear, setEplSurgeryYear] = useState("");
  const [eplSettlement, setEplSettlement] = useState("");

  const [jamID, setJamID] = useState("");
  const [jamAccidentDetails, setJamAccidentDetails] = useState("");
  const [jamInjuriesSustained, setJamInjuriesSustained] = useState("");
  const [jamTreatment, setJamTreatment] = useState("");
  const [jamHealthInsurance, setJamHealthInsurance] = useState("");
  const [jamNameOfEmployer, setJamNameOfEmployer] = useState("");
  const [jamJobTitle, setJamJobTitle] = useState("");
  const [jamStillCurrentlyEmployed, setJamStillCurrentlyEmployed] = useState("");

  const [mvID, setMvID] = useState("");
  const [mvAccidentDetails, setMvAccidentDetails] = useState("");
  const [mvInjuriesSustained, setMvInjuriesSustained] = useState("");
  const [mvTreatment, setMvTreatment] = useState("");
  const [mvHealthInsurance, setMvHealthInsurance] = useState("");
  const [mvAtFaultDriverInsurance, setMvAtFaultDriverInsurance] = useState("");
  const [mvDriverOrPassenger, setMvDriverOrPassenger] = useState("");
  const [mvPassengerNotes, setMvPassengerNotes] = useState("");
  const [mvDriverInsurance, setMvDriverInsurance] = useState("");
  const [mvOtherPeopleInVehicle, setMvOtherPeopleInVehicle] = useState(false);
  const [mvHowManyOthers, setMvHowManyOthers] = useState("");
  const [mvOtherDetails, setMvOtherDetails] = useState("");
  const [mvWasVehicleTotalled, setmvWasVehicleTotalled] = useState("");

  const [otherID, setOtherID] = useState("");
  const [otherInvolving, setOtherInvolving] = useState("");
  const [otherName, setOtherName] = useState("");
  const [otherRelationship, setOtherRelationship] = useState("");
  const [otherIncidentDetails, setOtherIncidentDetails] = useState("");

  const [safID, setSafID] = useState("");
  const [safAccidentDetails, setSafAccidentDetails] = useState("");
  const [safInjuriesSustained, setSafInjuriesSustained] = useState("");
  const [safTreatment, setSafTreatment] = useState("");
  const [safHealthInsurance, setSafHealthInsurance] = useState("");

  const [wcID, setWcID] = useState("");
  const [wcAccidentDetails, setWcAccidentDetails] = useState("");
  const [wcInjuriesSustained, setWcInjuriesSustained] = useState("");
  const [wcTreatment, setWcTreatment] = useState("");
  const [wcHealthInsurance, setWcHealthInsurance] = useState("");
  const [wcNameOfEmployer, setWcNameOfEmployer] = useState("");
  const [wcJobTitle, setWcJobTitle] = useState("");
  const [wcStillCurrentlyEmployed, setWcStillCurrentlyEmployed] = useState("");

  const uppy = new Uppy({ autoProceed: true });
  uppy.use(AwsS3, {
    getUploadParameters(file) {
      return client()
        .post(`/police-reports/signed-upload-url`, {
          filename: file.name,
          mimeType: file.type,
        })
        .then((response) => {
          const data = {
            method: "PUT",
            url: response.data.url,
            fields: { key: response.data.key },
            headers: {
              "Content-Type": file.type,
            },
          };

          return data;
        })
        .catch((err) => {
          clientError(err);
        });
    },
  });

  uppy.on("upload-success", (file) => {
    const data = {
      lawsuitID: parseInt(id),
      mimeType: file.type,
      size: file.size,
      originalName: file.meta.name,
      key: file.meta.key,
    };

    client()
      .post(`/police-reports`, data)
      .then(() => {
        refreshPoliceReports();
      })
      .catch((err) => {
        clientError(err);
      });
  });

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - Accident Details";
    client()
      .get(`/lawsuits/${id}/accident-details`)
      .then((res) => {
        let accidentType = res.data.accidentType;
        setAccidentType(accidentType);
        setAccidentDate(res.data.accidentDate);
        setAccidentState(res.data.accidentState.label);
        setMissedWork(res.data.missedWork);
        setHowMuchTimeMissed(res.data.timeMissed);
        setReturnedToWork(res.data.returnedToWork);
        if (accidentType === "Product Liability - Essure") {
          let epl = res.data.essureProductLiabilityAccident;
          if (epl) {
            setEplID(epl.id);
            setEplAccidentDetails(epl.details ? epl.details : "");
            setEplYearPlaced(epl.yearPlaced ? epl.yearPlaced : null);
            setEplSurgery(epl.surgery ? epl.surgery : false);
            setEplSurgeryYear(epl.surgeryYear ? epl.surgeryYear : null);
            setEplSettlement(epl.settlement ? epl.settlement : "");
          }
        } else if (accidentType === "Jones Act / Maritime") {
          let jam = res.data.jonesActAccident;
          if (jam) {
            setJamID(jam.id);
            setJamAccidentDetails(jam.details ? jam.details : "");
            setJamInjuriesSustained(jam.injuries ? jam.injuries : "");
            setJamTreatment(jam.treatment ? jam.treatment : "");
            setJamHealthInsurance(jam.healthInsurance ? jam.healthInsurance : "");
            setJamNameOfEmployer(jam.nameOfEmployer ? jam.nameOfEmployer : "");
            setJamJobTitle(jam.details ? jam.jobDetails : "");
            setJamStillCurrentlyEmployed(jam.stillEmployed ? jam.stillEmployed : "");
          }
        } else if (accidentType === "Motor Vehicle Accident") {
          let mv = res.data.motorVehicleAccident;
          if (mv) {
            setMvID(mv.id);
            setMvAccidentDetails(mv.details ? mv.details : "");
            setMvInjuriesSustained(mv.injuries ? mv.injuries : "");
            setMvTreatment(mv.treatment ? mv.treatment : "");
            setMvHealthInsurance(mv.healthInsurance ? mv.healthInsurance : "");
            setMvAtFaultDriverInsurance(mv.atFaultDriverInsurance ? mv.atFaultDriverInsurance : "");
            setMvDriverOrPassenger(mv.occupantType ? mv.occupantType : "");
            setMvPassengerNotes(mv.passengerNotes ? mv.passengerNotes : "");
            setMvDriverInsurance(mv.driverInsurance ? mv.driverInsurance : "");
            setMvOtherPeopleInVehicle(mv.otherOccupants ? mv.otherOccupants : false);
            setMvHowManyOthers(mv.howMany ? mv.howMany : "");
            setMvOtherDetails(mv.otherDetails ? mv.otherDetails : "");
            setmvWasVehicleTotalled(mv.totalled ? mv.totalled : "");
          }
        } else if (accidentType === "Other") {
          let other = res.data.otherAccident;
          if (other) {
            setOtherID(other.id);
            setOtherInvolving(other.involves ? other.involves : "");
            setOtherName(other.otherName ? other.otherName : "");
            setOtherRelationship(other.relationship ? other.relationship : "");
            setOtherIncidentDetails(other.details ? other.details : "");
          }
        } else if (accidentType === "Slip and Fall") {
          let saf = res.data.slipAndFallAccident;
          if (saf) {
            setSafID(saf.id);
            setSafAccidentDetails(saf.details ? saf.details : "");
            setSafInjuriesSustained(saf.injuries ? saf.injuries : "");
            setSafTreatment(saf.treatment ? saf.treatment : "");
            setSafHealthInsurance(saf.healthInsurance ? saf.healthInsurance : "");
          }
        } else if (accidentType === "Workers Compensation") {
          let wc = res.data.workersCompensationAccident;
          if (wc) {
            setWcID(wc.id);
            setWcAccidentDetails(wc.details ? wc.details : "");
            setWcInjuriesSustained(wc.injuries ? wc.injuries : "");
            setWcTreatment(wc.treatment ? wc.treatment : "");
            setWcHealthInsurance(wc.healthInsurance ? wc.healthInsurance : "");
            setWcNameOfEmployer(wc.nameOfEmployer ? wc.nameOfEmployer : "");
            setWcJobTitle(wc.jobDetails ? wc.jobDetails : "");
            setWcStillCurrentlyEmployed(wc.stillEmployed ? wc.stillEmployed : "");
          }
        }
      })
      .catch((err) => {
        clientError(err);
      });
  }, [id]);

  function handleClickDownload(documentID) {
    client()
      .get(`/police-reports/${documentID}/download`)
      .then((res) => {
        if (res.status === 204) {
          toast.error("Police report does not exist");
          return;
        }

        window.location.href = res.data;
      })
      .catch((err) => {
        clientError(err);
      });
  }

  const sizeFormatter = (params) => {
    return numeral(params.value).format("0.0 b");
  };

  const [columnDefs] = useState([
    {
      field: "originalName",
      headerName: "Report",
      minWidth: 450,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "mimeType",
      headerName: "Type",
      minWidth: 250,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "size",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      type: "numericColumn",
      valueFormatter: sizeFormatter,
    },
    {
      field: "createdAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Created",
      minWidth: 200,
      cellRenderer: (params) => {
        return moment(params.data.createdAt).fromNow();
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      sortable: false,
      width: 150,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            <Tippy content="Download">
              <button
                className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
                onClick={() => handleClickDownload(params.value)}
              >
                <DocumentArrowDownIcon className="h-4 w-4 text-indigo-500" />
              </button>
            </Tippy>

            <DeleteDialog
              tooltip="Delete"
              title="Delete Police Report"
              content="Are you sure you want to delete the police report?"
              id={params.value}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const onGridReady = useCallback(() => {
    client()
      .get(`/police-reports?lawsuitID=${id}`)
      .then((response) => {
        setPoliceReports(response.data);
      })
      .catch((err) => {
        clientError(err);
      });
  }, [id]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function handleRefresh() {
    client()
      .get(`/lawsuits/${id}/accident-details`)
      .then((res) => {
        let accidentType = res.data.accidentType;
        setAccidentType(accidentType);
        setAccidentDate(res.data.accidentDate);
        setAccidentState(res.data.accidentState.label);
        setMissedWork(res.data.missedWork);
        setHowMuchTimeMissed(res.data.timeMissed);
        setReturnedToWork(res.data.returnedToWork);
        if (accidentType === "Product Liability - Essure") {
          let epl = res.data.essureProductLiabilityAccident;
          if (epl) {
            setEplID(epl.id);
            setEplAccidentDetails(epl.details ? epl.details : "");
            setEplYearPlaced(epl.yearPlaced ? epl.yearPlaced : null);
            setEplSurgery(epl.surgery ? epl.surgery : false);
            setEplSurgeryYear(epl.surgeryYear ? epl.surgeryYear : null);
            setEplSettlement(epl.settlement ? epl.settlement : "");
          }
        } else if (accidentType === "Jones Act / Maritime") {
          let jam = res.data.jonesActAccident;
          if (jam) {
            setJamID(jam.id);
            setJamAccidentDetails(jam.details ? jam.details : "");
            setJamInjuriesSustained(jam.injuries ? jam.injuries : "");
            setJamTreatment(jam.treatment ? jam.treatment : "");
            setJamHealthInsurance(jam.healthInsurance ? jam.healthInsurance : "");
            setJamNameOfEmployer(jam.nameOfEmployer ? jam.nameOfEmployer : "");
            setJamJobTitle(jam.details ? jam.jobDetails : "");
            setJamStillCurrentlyEmployed(jam.stillEmployed ? jam.stillEmployed : "");
          }
        } else if (accidentType === "Motor Vehicle Accident") {
          let mv = res.data.motorVehicleAccident;
          if (mv) {
            setMvID(mv.id);
            setMvAccidentDetails(mv.details ? mv.details : "");
            setMvInjuriesSustained(mv.injuries ? mv.injuries : "");
            setMvTreatment(mv.treatment ? mv.treatment : "");
            setMvHealthInsurance(mv.healthInsurance ? mv.healthInsurance : "");
            setMvAtFaultDriverInsurance(mv.atFaultDriverInsurance ? mv.atFaultDriverInsurance : "");
            setMvDriverOrPassenger(mv.occupantType ? mv.occupantType : "");
            setMvPassengerNotes(mv.passengerNotes ? mv.passengerNotes : "");
            setMvDriverInsurance(mv.driverInsurance ? mv.driverInsurance : "");
            setMvOtherPeopleInVehicle(mv.otherOccupants ? mv.otherOccupants : null);
            setMvHowManyOthers(mv.howMany ? mv.howMany : "");
            setMvOtherDetails(mv.otherDetails ? mv.otherDetails : "");
            setmvWasVehicleTotalled(mv.totalled ? mv.totalled : "");
          }
        } else if (accidentType === "Other") {
          let other = res.data.otherAccident;
          if (other) {
            setOtherID(other.id);
            setOtherInvolving(other.involves ? other.involves : "");
            setOtherName(other.otherName ? other.otherName : "");
            setOtherRelationship(other.relationship ? other.relationship : "");
            setOtherIncidentDetails(other.details ? other.details : "");
          }
        } else if (accidentType === "Slip and Fall") {
          let saf = res.data.slipAndFallAccident;
          if (saf) {
            setSafID(saf.id);
            setSafAccidentDetails(saf.details ? saf.details : "");
            setSafInjuriesSustained(saf.injuries ? saf.injuries : "");
            setSafTreatment(saf.treatment ? saf.treatment : "");
            setSafHealthInsurance(saf.healthInsurance ? saf.healthInsurance : "");
          }
        } else if (accidentType === "Workers Compensation") {
          let wc = res.data.workersCompensationAccident;
          if (wc) {
            setWcID(wc.id);
            setWcAccidentDetails(wc.details ? wc.details : "");
            setWcInjuriesSustained(wc.injuries ? wc.injuries : "");
            setWcTreatment(wc.treatment ? wc.treatment : "");
            setWcHealthInsurance(wc.healthInsurance ? wc.healthInsurance : "");
            setWcNameOfEmployer(wc.nameOfEmployer ? wc.nameOfEmployer : "");
            setWcJobTitle(wc.jobDetails ? wc.jobDetails : "");
            setWcStillCurrentlyEmployed(wc.stillEmployed ? wc.stillEmployed : "");
          }
        }
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function refreshPoliceReports() {
    client()
      .get(`/police-reports?lawsuitID=${id}`)
      .then((res) => {
        setPoliceReports(res.data);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleDelete(idToDelete) {
    client()
      .delete(`/police-reports/${idToDelete}`)
      .then(() => {
        toast.success("Police report deleted");
        refreshPoliceReports();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="mb-4 overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Case Overview" lawsuitID={id} />
        <div className="ml-8 mr-8">
          <CaseOverviewTabs lawsuitID={id} selected="Accident Details" />
        </div>
        <div className="mb-4 mt-2">
          <dl className="ml-8 mr-8 divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Accident Type</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{accidentType}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditAccidentType id={id} accidentType={accidentType} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Accident Date</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{shortDateUTC(accidentDate)}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditAccidentDate id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Accident State</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{accidentState}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditAccidentState id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
          </dl>
          <hr className="ml-8 mr-8" />
          {accidentType === "Product Liability - Essure" && (
            <EssureProductLiabilityAccident
              id={eplID}
              details={eplAccidentDetails}
              yearPlaced={eplYearPlaced}
              surgery={eplSurgery}
              surgeryYear={eplSurgeryYear}
              settlement={eplSettlement}
              saved={handleRefresh}
            />
          )}
          {accidentType === "Jones Act / Maritime" && (
            <JonesActMaritimeAccident
              id={jamID}
              details={jamAccidentDetails}
              injuries={jamInjuriesSustained}
              treatment={jamTreatment}
              healthInsurance={jamHealthInsurance}
              nameOfEmployer={jamNameOfEmployer}
              jobDetails={jamJobTitle}
              stillEmployed={jamStillCurrentlyEmployed}
              saved={handleRefresh}
            />
          )}
          {accidentType === "Motor Vehicle Accident" && (
            <MotorVehicleAccident
              id={mvID}
              details={mvAccidentDetails}
              injuries={mvInjuriesSustained}
              treatment={mvTreatment}
              healthInsurance={mvHealthInsurance}
              atFaultDriverInsurance={mvAtFaultDriverInsurance}
              occupantType={mvDriverOrPassenger}
              passengerNotes={mvPassengerNotes}
              driverInsurance={mvDriverInsurance}
              otherOccupants={mvOtherPeopleInVehicle}
              howMany={mvHowManyOthers}
              otherDetails={mvOtherDetails}
              totalled={mvWasVehicleTotalled}
              saved={handleRefresh}
            />
          )}
          {accidentType === "Other" && (
            <OtherAccident
              id={otherID}
              involves={otherInvolving}
              otherName={otherName}
              relationship={otherRelationship}
              details={otherIncidentDetails}
              saved={handleRefresh}
            />
          )}
          {accidentType === "Slip and Fall" && (
            <SlipAndFallAccident
              id={safID}
              details={safAccidentDetails}
              injuries={safInjuriesSustained}
              treatment={safTreatment}
              healthInsurance={safHealthInsurance}
              saved={handleRefresh}
            />
          )}
          {accidentType === "Workers Compensation" && (
            <WorkersCompensationAccident
              id={wcID}
              details={wcAccidentDetails}
              injuries={wcInjuriesSustained}
              treatment={wcTreatment}
              healthInsurance={wcHealthInsurance}
              nameOfEmployer={wcNameOfEmployer}
              jobDetails={wcJobTitle}
              stillEmployed={wcStillCurrentlyEmployed}
              saved={handleRefresh}
            />
          )}
          <div className="ml-8 mr-8">
            <hr />
          </div>
          <dl className="ml-8 mr-8 divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Due to the injuries, were any days of work missed?</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">{missedWork}</span>
                <span className="ml-4 flex-shrink-0">
                  <EditMissedWork id={id} saved={handleRefresh} />
                </span>
              </dd>
            </div>
            {missedWork === "Yes" ? (
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">How much time have you missed since the accident?</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">{howMuchTimeMissed}</span>
                  <span className="ml-4 flex-shrink-0">
                    <EditHowMuchTimeMissed id={id} saved={handleRefresh} />
                  </span>
                </dd>
              </div>
            ) : null}
            {missedWork === "Yes" ? (
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">Have you been able to return to work?</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">{returnedToWork}</span>
                  <span className="ml-4 flex-shrink-0">
                    <EditReturnedToWork id={id} saved={handleRefresh} />
                  </span>
                </dd>
              </div>
            ) : null}
          </dl>
        </div>
        <div className="ml-8 mr-8 mt-2 text-lg font-medium">Police Reports</div>

        <div className="m-2">
          <hr />
        </div>
        <div className="mb-8 mt-2 flex flex-col">
          <div className="ml-8 mr-8 flex flex-col">
            <DragDrop
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: "Drop here or %{browse}",
                  browse: "browse",
                },
              }}
            />
            <div className="mt-1">
              <StatusBar uppy={uppy} />
            </div>
          </div>
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 350 }}>
              <AgGridReact
                ref={gridRef}
                rowData={policeReports}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
                overlayNoRowsTemplate={"No Police Reports"}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
