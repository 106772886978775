import { Fragment, useState, useRef, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { setAuthToken } from "../../../hooks/Authentication";
import { apiError } from "../../../libs/Utils";

import CampaignShortCodes from "../ShortCodes/CampaignShortCodes";

const { REACT_APP_API_URL, REACT_APP_TINY_MCE_API_KEY } = process.env;

export default function EditCampaignTemplate(props) {
  const [open, setOpen] = useState(false);
  const editorRef = useRef(null);
  const [title, setTitle] = useState("");
  const [contentBody, setContentBody] = useState("");
  const [providerType, setProviderType] = useState("");

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      setAuthToken();
      axios
        .get(`${REACT_APP_API_URL}/templates/campaigns/${props.id}`)
        .then((response) => {
          setTitle(response.data.title);
          setContentBody(response.data.body);
          setProviderType(response.data.providerType);
        })
        .catch((err) => {
          apiError(err);
        });
    }
  }, [open, props.id]);

  function handleSave() {
    setAuthToken();
    const body = editorRef.current.getContent();
    axios
      .put(`${REACT_APP_API_URL}/templates/campaigns/${props.id}`, {
        title,
        body,
        providerType,
      })
      .then(() => {
        setOpen(false);
        toast.success("Campaign template saved");
        props.saved(true);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      {props.tooltip ? (
        <Tippy content={props.tooltip}>
          <button
            className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
            onClick={handleClickOpen}
          >
            <PencilSquareIcon className="h-4 w-4 text-indigo-500" />
          </button>
        </Tippy>
      ) : (
        <button
          className="inline-flex rounded border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium hover:bg-indigo-200 focus:outline-none"
          onClick={handleClickOpen}
        >
          <PencilSquareIcon className="h-4 w-4 text-indigo-500" />
        </button>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="max-w-4-xl pointer-events-none fixed inset-y-0 right-0 flex pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-full">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Edit Campaign Template
                            </Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label htmlFor="title" className="block text-sm font-medium text-gray-900">
                                  Template Title
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                  Service Provider Type
                                </label>
                                <select
                                  id="providerType"
                                  name="providerType"
                                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  onChange={(e) => setProviderType(e.target.value)}
                                  value={providerType}
                                >
                                  <option value="">Select...</option>
                                  <option value="Insurance">Insurance</option>
                                  <option value="Law Firm">Law Firm</option>
                                </select>
                              </div>
                              <div>
                                <label htmlFor="title" className="block text-sm font-medium text-gray-900">
                                  Template Text
                                  <span className="float-right">
                                    <CampaignShortCodes />
                                  </span>
                                </label>
                                <div className="mt-1">
                                  <Editor
                                    initialValue={contentBody}
                                    apiKey={`${REACT_APP_TINY_MCE_API_KEY}`}
                                    onInit={(evt, editor) => (editorRef.current = editor)}
                                    init={{
                                      image_list: `${REACT_APP_API_URL}/images/list`,
                                      height: 300,
                                      menubar: "edit insert view format table tools",
                                      plugins: "lists link image table code",
                                      paste_as_text: false,
                                      paste_block_drop: true,
                                      toolbar:
                                        "undo redo | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | image link | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EditCampaignTemplate.propTypes = {
  tooltip: PropTypes.string,
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
