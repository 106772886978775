import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../datagrid.css";

import { apiError, currency, dateComparator } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

import CaseDetailHeader from "../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseDocumentTabs from "../../layouts/FullLayout/Tabs/CaseDocumentTabs";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import DownloadContract from "../../components/Buttons/DownloadContract";
import ContractDocumentLink from "../../components/Buttons/ContractDocumentLink";

const { REACT_APP_API_URL, REACT_APP_SITE_URL } = process.env;

export default function CaseDocumentContracts() {
  const { id } = useParams();
  const gridRef = useRef();
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - Documents - Contracts";
  });

  const [columnDefs] = useState([
    {
      field: "title",
      headerName: "Contract",
      minWidth: 450,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 250,
      filter: true,
      filterParams: {
        filterOptions: ["contains"],
        defaultOption: "contains",
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        if (params.value === "Waiting for Plaintiff") {
          return (
            <ContractDocumentLink
              link={`${REACT_APP_SITE_URL}/signature/contract/${params.data.id}/plaintiff`}
              title="Waiting for Plaintiff"
            />
          );
        } else if (params.value === "Waiting for Attorney") {
          return (
            <ContractDocumentLink
              link={`${REACT_APP_SITE_URL}/signature/contract/${params.data.id}/service-provider`}
              title="Waiting for Attorney"
            />
          );
        } else {
          return params.value;
        }
      },
    },
    {
      field: "expires",
      filter: false,
      comparator: dateComparator,
      headerName: "Expires",
      minWidth: 200,
      cellRenderer: (params) => {
        if (params.data.status === "Signing Complete") {
          return "-";
        }
        return moment(params.data.expires).fromNow();
      },
    },
    {
      field: "fees.payoffAmount",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      headerName: "Amount Due",
      type: "numericColumn",
      minWidth: 150,
      valueFormatter: (params) => {
        return currency(params.data.fees.payoffAmount);
      },
    },
    {
      field: "createdAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Created",
      minWidth: 200,
      cellRenderer: (params) => {
        return moment(params.data.createdAt).fromNow();
      },
    },
    {
      field: "updatedAt",
      filter: false,
      comparator: dateComparator,
      headerName: "Updated",
      minWidth: 200,
      cellRenderer: (params) => {
        return moment(params.data.updatedAt).fromNow();
      },
    },
    {
      field: "id",
      filter: false,
      headerName: "",
      minWidth: 150,
      cellRenderer: (params) => {
        return (
          <div className="flex justify-end space-x-4">
            {params.data.status === "Signing Complete" ? <DownloadContract id={params.data.id} /> : null}
            <DeleteDialog
              buttonTitle="Delete"
              title="Delete Document?"
              content="Are you sure you want to delete the document?"
              id={params.value}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
    }),
    []
  );

  const onGridReady = useCallback(() => {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/funding-application-contracts?lawsuitID=${id}`)
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function handleDelete(idToDelete) {
    setAuthToken();
    axios
      .delete(`${REACT_APP_API_URL}/funding-application-contracts/${idToDelete}`)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/funding-application-contracts?lawsuitID=${id}`)
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Documents" lawsuitID={id} />
        <div className="ml-8 mr-8">
          <CaseDocumentTabs lawsuitID={id} selected="Contracts" />
        </div>
        <div className="mb-8 mt-2 flex flex-col">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 750 }}>
              <AgGridReact
                ref={gridRef}
                rowData={documents}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                suppressCellFocus={true}
                overlayNoRowsTemplate={"No documents on file"}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
