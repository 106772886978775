import { Fragment, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { TimePicker, DatePicker } from "antd";
import dayjs from "dayjs";

import { apiError } from "../../../libs/Utils";
import { setAuthToken } from "../../../hooks/Authentication";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const { REACT_APP_API_URL } = process.env;

export default function AddAppointment() {
  const [open, setOpen] = useState(false);
  const format = "HH:mm";
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [appointment, setAppointment] = useState("");

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  const onStartTimeChange = (time) => {
    setStartTime(time);
  };

  const onEndTimeChange = (time) => {
    setEndTime(time);
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function onStartDateChange(date) {
    setStartDate(date);
  }

  function onEndDateChange(date) {
    setEndDate(date);
  }

  function handleSave() {
    setAuthToken();

    if (!startDate) {
      toast.error("Start date required");
      return;
    }

    if (!startTime) {
      toast.error("Start time required");
      return;
    }

    if (!endDate) {
      toast.error("End date required");
      return;
    }

    if (!endTime) {
      toast.error("End time required");
      return;
    }

    if (appointment === "") {
      toast.error("Appointment required");
      return;
    }

    const appointmentStart = dayjs
      .utc(startDate)
      .hour(dayjs.utc(startTime).hour())
      .minute(dayjs.utc(startTime).minute())
      .second(0);
    const appointmentEnd = dayjs
      .utc(endDate)
      .hour(dayjs.utc(endTime).hour())
      .minute(dayjs.utc(endTime).minute())
      .second(0);

    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/ms/appointments`, {
        startDate: appointmentStart.toISOString().slice(0, -5) + "Z",
        endDate: appointmentEnd.toISOString().slice(0, -5) + "Z",
        subject: appointment,
      })
      .then((res) => {
        toast.success("Appointment added");
        setOpen(false);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <button
        className="w-full bg-purple-100 px-6 py-3 text-center text-sm font-medium text-purple-500 hover:bg-purple-200"
        onClick={handleClickOpen}
      >
        Add Appointment
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Add Appointment</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="appointmentStart" className="block text-sm font-medium text-gray-700">
                                  Appointment Start
                                </label>
                                <div className="relative mt-1 space-x-2">
                                  <DatePicker
                                    disabledDate={disabledDate}
                                    value={startDate}
                                    onChange={onStartDateChange}
                                  />
                                  <TimePicker
                                    minuteStep={5}
                                    value={startTime}
                                    onChange={onStartTimeChange}
                                    format={format}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="appointmentEnd" className="block text-sm font-medium text-gray-700">
                                  Appointment End
                                </label>
                                <div className="relative mt-1 space-x-2">
                                  <div className="relative mt-1 space-x-2">
                                    <DatePicker
                                      disabledDate={disabledDate}
                                      value={endDate}
                                      onChange={onEndDateChange}
                                    />
                                    <TimePicker
                                      minuteStep={5}
                                      value={endTime}
                                      onChange={onEndTimeChange}
                                      format={format}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">
                                  Appointment
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="appointment"
                                    id="appointment"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setAppointment(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Add Appointment
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
