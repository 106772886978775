import React, { useEffect, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { dateFromNow, minHeight, currencyNoCents } from "../../libs/Utils";
import { client, clientError } from "../../libs/Client";
import AddToQuickbooks from "../../components/SlideOvers/Cases/AddToQuickbooks";
import UnlinkFromQuickbooks from "../../components/Dialogs/UnlinkFromQuickbooksDialog";
import FundingRequestTabs from "../../layouts/FullLayout/Tabs/FundingRequestTabs";
import ReassignCase from "../../components/SlideOvers/Cases/ReassignCase";

export default function ReadyToFundFundingRequests() {
  useEffect(() => {
    document.title = "Capital Now Funding - Funding Requests - Ready to Fund";
  });

  const gridStyle = { minHeight: minHeight };

  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/funding-requests?status=Ready to Fund&skip=${skip}&limit=${limit}`)
      .then((res) => {
        return { data: res.data.fundingRequests, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, []);

  const columns = [
    {
      name: "caseNumber",
      minWidth: 100,
      maxWidth: 100,
      header: "Case #",
      textAlign: "end",
      headerAlign: "end",
      render: ({ data }) => {
        if (!data.emailSent) {
          return (
            <a
              className="text-brand-700 font-semibold hover:text-brand-500"
              href={`/cases/${data.caseNumber}/applications`}
            >
              {data.caseNumber}
            </a>
          );
        }
        return (
          <a className="text-brand-700 hover:text-brand-500" href={`/cases/${data.caseNumber}/applications`}>
            {data.caseNumber}
          </a>
        );
      },
    },
    {
      name: "plaintiff",
      minWidth: 350,
      header: "Plaintiff",
      render: ({ data }) => {
        if (!data.emailSent) {
          return <span className="font-semibold">{data.plaintiff}</span>;
        }
        return data.plaintiff;
      },
    },
    {
      name: "lawFirm",
      minWidth: 350,
      header: "Law Firm",
      render: ({ data }) => {
        if (data.flagged) {
          if (!data.emailSent) {
            return <span className="font-semibold text-red-500">{data.lawFirm}</span>;
          }
          return <span className="text-red-500">{data.lawFirm}</span>;
        } else {
          if (!data.emailSent) {
            return <span className="font-semibold">{data.lawFirm}</span>;
          }
          return data.lawFirm;
        }
      },
    },
    {
      name: "assignedTo",
      minWidth: 200,
      maxWidth: 200,
      header: "Assigned To",
      render: ({ data }) => {
        if (!data.emailSent) {
          return <span className="font-semibold">{data.assignedTo}</span>;
        }
        return data.assignedTo;
      },
    },
    {
      name: "approvedAmount",
      minWidth: 200,
      maxWidth: 200,
      header: "Approved Amount",
      textAlign: "end",
      headerAlign: "end",
      render: ({ value }) => {
        return currencyNoCents(value);
      },
    },
    {
      name: "activeAt",
      minWidth: 200,
      maxWidth: 200,
      header: "Last Case Activity",
      render: ({ data }) => {
        if (!data.emailSent) {
          return <span className="font-semibold">{dateFromNow(data.activeAt)}</span>;
        }

        return dateFromNow(data.activeAt);
      },
    },
    {
      name: "applicationDate",
      header: "Application Date",
      minWidth: 200,
      maxWidth: 200,
      render: ({ data }) => {
        if (!data.emailSent) {
          return <span className="font-semibold">{dateFromNow(data.applicationDate)}</span>;
        }
        return dateFromNow(data.applicationDate);
      },
    },
    {
      name: "state",
      minWidth: 200,
      maxWidth: 200,
      header: "State",
      render: ({ data }) => {
        if (!data.emailSent) {
          return <span className="font-semibold">{data.state}</span>;
        }
        return data.state;
      },
    },
    {
      name: "id",
      header: "",
      minWidth: 125,
      maxWidth: 125,
      render: ({ data }) => {
        return (
          <div className="flex justify-end space-x-4">
            <ReassignCase lawsuitID={data.caseNumber} saved={handleRefresh} />
            {!data.quickbooksID ? (
              <AddToQuickbooks id={data.caseNumber.toString()} saved={handleRefresh} />
            ) : (
              <UnlinkFromQuickbooks
                tooltip="Unlink from Quickboooks"
                title="Unlink from Quickbooks"
                content="Remove lawsuit link to Quickbooks"
                id={data.caseNumber.toString()}
                idToUnlink={handleQuickbooksUnlink}
              />
            )}
          </div>
        );
      },
    },
  ];

  function handleRefresh() {
    window.location.reload();
  }

  function handleQuickbooksUnlink(idToUnlink) {
    client()
      .delete(`/lawsuits/${idToUnlink}/quickbooks`)
      .then(() => {
        handleRefresh();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <FundingRequestTabs selected="Ready to Fund" />
        <ReactDataGrid
          idProperty="readyFundingRequests"
          columns={columns}
          style={gridStyle}
          showColumnMenuTool={false}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
