import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { DragDrop, StatusBar } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";

import { apiError } from "../../libs/Utils";

const { REACT_APP_API_URL } = process.env;

export default function IdentityVerification() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [lawsuitID, setLawsuitID] = useState("");

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/signature/id-verification/${id}`)
      .then((res) => {
        setLawsuitID(res.data.lawsuit.id);
      })
      .catch(() => {
        navigate("/signature/expired");
      });
  }, [navigate, id]);

  const uppy = new Uppy({ autoProceed: true });
  uppy.use(AwsS3, {
    getUploadParameters(file) {
      return axios
        .post(`${REACT_APP_API_URL}/signature/id-verification/signed-upload-url`, {
          filename: file.name,
          mimeType: file.type,
        })
        .then((res) => {
          const data = {
            method: "PUT",
            url: res.data.url,
            fields: { key: res.data.key },
            headers: {
              "Content-Type": file.type,
            },
          };

          return data;
        })
        .catch((err) => {
          apiError(err);
        });
    },
  });

  uppy.on("upload-success", (file) => {
    const data = {
      lawsuitID: parseInt(lawsuitID),
      mimeType: file.type,
      size: file.size,
      originalName: file.meta.name,
      key: file.meta.key,
    };
    axios
      .post(`${REACT_APP_API_URL}/signature/id-verification/upload`, data)
      .then(() => {})
      .catch((err) => {
        apiError(err);
      });
  });

  function handleSubmit() {
    axios
      .delete(`${REACT_APP_API_URL}/signature/id-verification/${id}`)
      .then(() => {
        window.location.replace("https://capitalnowfunding.com/");
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-full">
        <img className="h-13 mx-auto w-auto" src="/images/logos/login-logo.png" alt="Capital Now Funding" />
        <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">Identity Verification</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-7xl">
        <div className="bg-white py-8 px-4 text-center shadow sm:rounded-lg sm:px-10">
          <div className="mb-6 text-base font-semibold">Please upload a copy of your ID and a selfie image</div>
          <DragDrop
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: "Drop here or %{browse}",
                browse: "browse",
              },
            }}
          />
          <div className="mt-1">
            <StatusBar hideUploadButton hideAfterFinish={false} showProgressDetails uppy={uppy} />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="flex w-full justify-center rounded-md border border-transparent bg-brand-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
              onClick={handleSubmit}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
