import PropTypes from "prop-types";

import EditAccidentDetails from "../SlideOvers/Cases/Accident/Essure/EditAccidentDetails";
import EditYearPlaced from "../SlideOvers/Cases/Accident/Essure/EditYearPlaced";
import EditSurgery from "../SlideOvers/Cases/Accident/Essure/EditSurgery";
import EditSurgeryYear from "../SlideOvers/Cases/Accident/Essure/EditSurgeryYear";
import EditReachedSettlement from "../SlideOvers/Cases/Accident/Essure/EditReachedSettlement";

export default function EssureProductLiabilityAccident(props) {
  return (
    <dl className="ml-8 mr-8 divide-y divide-gray-200">
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Accident Details - What Happened?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.details}</span>
          <span className="ml-4 flex-shrink-0">
            <EditAccidentDetails id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Do you know what year the product was placed?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.yearPlaced}</span>
          <span className="ml-4 flex-shrink-0">
            <EditYearPlaced id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Did you have surgery to remove the product?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.surgery ? "Yes" : "No"}</span>
          <span className="ml-4 flex-shrink-0">
            <EditSurgery id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      {props.surgery && (
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">What year did the surgery take place?</dt>
          <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span className="flex-grow">{props.surgeryYear}</span>
            <span className="ml-4 flex-shrink-0">
              <EditSurgeryYear id={props.id} saved={props.saved} />
            </span>
          </dd>
        </div>
      )}

      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Has your case reached settlement?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.settlement}</span>
          <span className="ml-4 flex-shrink-0">
            <EditReachedSettlement id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
    </dl>
  );
}

EssureProductLiabilityAccident.propTypes = {
  id: PropTypes.string.isRequired,
  details: PropTypes.string,
  yearPlaced: PropTypes.number,
  surgery: PropTypes.bool,
  surgeryYear: PropTypes.number,
  settlement: PropTypes.string,
  saved: PropTypes.func,
};
