import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import parsePhoneNumber from "libphonenumber-js";
import { client, clientError } from "../../libs/Client";
import { dateFromNow, minHeight } from "../../libs/Utils";
import LeadTabs from "../../layouts/FullLayout/Tabs/LeadTabs";
import ArchiveDialog from "../../components/Dialogs/ArchiveDialog";
import CreateCaseDialog from "../../components/Dialogs/CreateCaseDialog";
import RejectDialog from "../../components/Dialogs/RejectDialog";
import EditLead from "../../components/SlideOvers/EditLead";

export default function PendingLeads() {
  const navigate = useNavigate();
  const gridStyle = { minHeight: minHeight };

  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/leads?status=Pending&skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`)
      .then((res) => {
        return { data: res.data.leads, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };
  const dataSource = useCallback(loadData, []);
  useEffect(() => {
    document.title = "Capital Now Funding - Leads - Pending";
  });

  const columns = [
    {
      name: "id",
      minWidth: 150,
      maxWidth: 150,
      header: "Reference #",
      textAlign: "end",
      headerAlign: "end",
    },
    {
      name: "firstName",
      header: "First Name",
      minWidth: 200,
    },
    {
      name: "lastName",
      header: "Last Name",
      minWidth: 200,
    },
    {
      name: "email",
      header: "Email",
      minWidth: 200,
    },
    {
      name: "phone",
      header: "Phone",
      minWidth: 200,
      render: ({ value }) => {
        if (value && value !== "") {
          return parsePhoneNumber(value, "US").formatNational();
        } else {
          return "";
        }
      },
    },
    {
      name: "lawFirm",
      header: "Law Firm",
      minWidth: 200,
    },
    {
      name: "state.label",
      header: "State",
      minWidth: 200,
      render: ({ data }) => {
        return data.state.label;
      },
    },
    {
      name: "createdAt",
      minWidth: 200,
      header: "Created",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "edit",
      headerName: "",
      minWidth: 225,
      maxWidth: 225,
      render: ({ data }) => {
        return (
          <div className="flex justify-end space-x-4">
            <EditLead tooltip="Edit Lead" id={data.id.toString()} saved={loadData} />
            <CreateCaseDialog
              tooltip="Create Case"
              title="Create Case"
              content="Are you sure you want to create a case from the lead?"
              id={data.id.toString()}
              idToCreate={handleCreateCase}
            />
            <ArchiveDialog
              tooltip="Archive Lead"
              title="Archive Lead"
              content="Are you sure you want to archive the lead?"
              id={data.id.toString()}
              idToArchive={handleArchive}
            />
            <RejectDialog
              tooltip="Reject Lead"
              title="Reject Lead"
              content="Are you sure you want to reject the lead?"
              id={data.id.toString()}
              idToReject={handleReject}
            />
          </div>
        );
      },
    },
  ];

  function handleReject(idToReject) {
    client()
      .patch(`/leads/${idToReject}`, {
        status: "Rejected",
      })
      .then(() => {
        toast.success("Lead rejected");
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleArchive(idToArchive) {
    client()
      .patch(`/leads/${idToArchive}`, {
        status: "Archived",
      })
      .then(() => {
        toast.success("Lead archived");
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleCreateCase(idToCreate) {
    navigate(`/cases/new?lead=${idToCreate}`);
  }

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <LeadTabs selected="Pending" />
        <ReactDataGrid
          idProperty="pendingLeads"
          showColumnMenuTool={false}
          columns={columns}
          style={gridStyle}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
