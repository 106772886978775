import PropTypes from "prop-types";

export default function AttachmentLabel(props) {
  return (
    <span className="inline-flex items-center rounded-full bg-sky-100 py-0.5 pl-2 pr-0.5 text-xs font-medium text-sky-700">
      {props.originalName}
      <button
        type="button"
        className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-sky-400 hover:bg-indigo-200 hover:text-sky-500 focus:bg-sky-500 focus:text-white focus:outline-none"
        onClick={() => {
          props.indexToDelete(props.index);
        }}
      >
        <span className="sr-only">Remove small option</span>
        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
}

AttachmentLabel.propTypes = {
  originalName: PropTypes.string.isRequired,
  s3key: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  indexToDelete: PropTypes.func,
};
