import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { apiError } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

import CaseDetailHeader from "../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../layouts/FullLayout/Tabs/CaseDetailTabs";

const { REACT_APP_API_URL } = process.env;

export default function CaseLog() {
  const { id } = useParams();
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - Log";
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/notes?lawsuitID=${id}&section=General`)
      .then((response) => {
        setNotes(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  function handleRefresh() {
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/notes?lawsuitID=${id}&section=General`)
      .then((response) => {
        setNotes(response.data);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  function handleSave() {
    if (note === "") {
      toast.error("You need to enter some text for the note");
      return;
    }

    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/notes`, {
        lawsuitID: parseInt(id),
        text: note,
        section: "General",
      })
      .then(() => {
        setNote("");
        handleRefresh();
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow mb-10">
        <CaseDetailTabs selected="Log" lawsuitID={id} />
        <div className="m-4">
          <textarea
            rows="5"
            name="note"
            id="note"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          ></textarea>
        </div>
        <div className="m-2 text-right mr-4">
          <button
            type="button"
            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-brand-700 hover:border-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
        <div className="m-2">
          <hr />
        </div>
        <div className="mt-5 mb-10">
          {notes.length > 0 ? (
            <ul className="m-10">
              {notes.map((note) => (
                <li key={note.id} className="py-4 m-10 rounded-lg bg-gray-100 p-6">
                  <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <h3 className="text-sm font-medium">
                          {note.user.firstName} {note.user.lastName}
                        </h3>
                        <p className="text-sm text-gray-400">{moment(note.createdAt).format("LLLL")}</p>
                      </div>
                      <p className="text-sm text-gray-500 whitespace-pre-wrap">{note.text}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center">
              <p className="text-sm text-gray-500">No Notes</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
