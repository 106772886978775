import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { client, clientError } from "../../libs/Client";
import { dateFromNow, minHeight } from "../../libs/Utils";
import AddRecipients from "../../components/SlideOvers/Campaigns/AddRecipients";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";

export default function Campaigns() {
  const { id } = useParams();
  const [status, setStatus] = useState("");

  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(
        `/service-provider-campaign-recipients?campaignID=${id}&skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`
      )
      .then((res) => {
        return { data: res.data.recipients, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, [id]);

  useEffect(() => {
    document.title = "Capital Now Funding - Campaigns - Recipients";
    client()
      .get(`/service-provider-campaigns/${id}`)
      .then((res) => {
        setStatus(res.data.status);
      })
      .catch((err) => {
        clientError(err);
      });
  });

  const gridStyle = { minHeight: minHeight };

  function handleDelete(idToDelete) {
    client()
      .delete(`/service-provider-campaign-recipients/${idToDelete}`)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleRefresh() {
    window.location.reload();
  }

  const columns = [
    {
      name: "recipient",
      header: "Recipient",
      minWidth: 200,
      render: ({ data }) => {
        return data.firstName + " " + data.lastName;
      },
    },
    {
      name: "serviceProviderName",
      minWidth: 250,
      header: "Service Provider",
    },
    {
      name: "email",
      minWidth: 200,
      header: "Email",
    },
    {
      name: "status",
      minWidth: 150,
      header: "Status",
    },
    {
      name: "createdAt",
      minWidth: 200,
      header: "Created",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "updatedAt",
      minWidth: 200,
      header: "Updated",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "edit",
      header: "",
      minWidth: 75,
      maxWidth: 75,
      render: ({ data }) => {
        return (
          <div className="flex justify-end space-x-4">
            {data.status === "Ready to Send" ? (
              <DeleteDialog
                tooltip="Delete Recipient"
                title="Delete Recipient"
                content="Are you sure you want to delete the campaign recipient?"
                id={data.id}
                idToDelete={handleDelete}
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="text-right">
        {status === "Pending" ? <AddRecipients campaignID={id} saved={handleRefresh} /> : null}
      </div>
      <div className="mt-3 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <ReactDataGrid
            idProperty="recipients"
            columns={columns}
            style={gridStyle}
            showColumnMenuTool={false}
            dataSource={dataSource}
            pagination
            defaultLimit={100}
          />
        </div>
      </div>
    </div>
  );
}
