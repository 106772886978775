import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { client, clientError } from "../../libs/Client";
import { toast } from "react-toastify";
import PageHeader from "../../components/PageHeader";
import ServiceProviderDetailHeader from "../../components/Headers/ServiceProviderDetailHeader";
import ServiceProviderTabs from "../../layouts/FullLayout/Tabs/ServiceProviderTabs";
import DashboardStat from "../../components/Stats/DashboardStat";
import AddToQuickbooks from "../../components/SlideOvers/ServiceProviders/AddToQuickbooks";
import EditServiceProvider from "../../components/SlideOvers/ServiceProviders/EditServiceProvider";
import FlagServiceProvider from "../../components/SlideOvers/ServiceProviders/FlagSeviceProvider";
import UnflagDialog from "../../components/Dialogs/UnflagDialog";

export default function ServiceProviderOverview() {
  const { id } = useParams();
  const [providerType, setProviderType] = useState("");
  const [customerConcentration, setCustomerConcentration] = useState(0);
  const [totalFundingOutstanding, setTotalFundingOutstanding] = useState(0);
  const [totalOutstandingPayoff, setTotalOustandingPayoff] = useState(0);
  const [totalLoans, setTotalLoans] = useState(0);
  const [avgOpenLoanValue, setAvgOpenLoanValue] = useState(0);
  const [totalOpenCases, setTotalOpenCases] = useState(0);
  const [avgOpenCaseValue, setAvgOpenCaseValue] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [apr, setApr] = useState(0);
  const [lossRate, setLossRate] = useState(0);
  const [paybackRate, setPaybackRate] = useState(0);
  const [totalCasesClosed, setTotalCasesClosed] = useState(0);
  const [totalPayoffReceived, setTotalPayoffReceived] = useState(0);
  const [daysFromAccidentToFirstLoan, setDaysFromAccidentToFirstLoan] = useState(0);
  const [daysFromAccidentToPayoff, setDaysFromAccidentToPayoff] = useState(0);
  const [daysToPayoff, setDaysToPayoff] = useState(0);
  const [decisionTime, setDecisionTime] = useState(0);
  const [newCase, setNewCase] = useState(0);
  const [loanApproved, setLoanApproved] = useState(0);
  const [caseUpdate, setCaseUpdate] = useState(0);
  const [payoffReceived, setPayoffReceived] = useState(0);
  const [quickbooksID, setQuickbooksID] = useState("");
  const [flagged, setFlagged] = useState(false);

  useEffect(() => {
    document.title = "Capital Now Funding - Service Providers - Overview";
    Promise.all([client().get(`/service-providers/${id}`), client().get(`/service-providers/${id}/stats`)])
      .then((res) => {
        setFlagged(res[0].data.flagged);
        setProviderType(res[0].data.providerType);
        if (res[0].data.quickbooksID) {
          setQuickbooksID(res[0].data.quickbooksID);
        }
        const stats = res[1].data;
        stats.forEach((stat) => {
          switch (stat.label) {
            case "Customer Concentration":
              setCustomerConcentration(stat.total / 10000);
              break;
            case "Total Funding Outstanding":
              setTotalFundingOutstanding(stat.total);
              break;
            case "Total Outstanding Payoff":
              setTotalOustandingPayoff(stat.total);
              break;
            case "Total Loans":
              setTotalLoans(stat.total);
              break;
            case "Avg Open Loan Value":
              setAvgOpenLoanValue(stat.total);
              break;
            case "Total Open Cases":
              setTotalOpenCases(stat.total);
              break;
            case "Avg Open Case Value":
              setAvgOpenCaseValue(stat.total);
              break;
            case "Total Return":
              setTotalReturn(stat.total / 10000);
              break;
            case "APR":
              setApr(stat.total / 10000);
              break;
            case "Loss Rate":
              setLossRate(stat.total / 10000);
              break;
            case "Payback Rate":
              setPaybackRate(stat.total / 10000);
              break;
            case "Total Cases Closed":
              setTotalCasesClosed(stat.total);
              break;
            case "Total Payoff Received":
              setTotalPayoffReceived(stat.total);
              break;
            case "Days from Accident to First Loan":
              setDaysFromAccidentToFirstLoan(stat.total);
              break;
            case "Days from Accident to Payoff":
              setDaysFromAccidentToPayoff(stat.total);
              break;
            case "Days to Payoff":
              setDaysToPayoff(stat.total);
              break;
            case "Decision Time":
              setDecisionTime(stat.total);
              break;
            case "New Case":
              setNewCase(stat.total);
              break;
            case "Loan Approved":
              setLoanApproved(stat.total);
              break;
            case "Case Update":
              setCaseUpdate(stat.total);
              break;
            case "Payoff Received":
              setPayoffReceived(stat.total);
              break;
            default:
              break;
          }
        });
      })
      .catch((err) => {
        clientError(err);
      });
  });

  function handleRefresh() {
    window.location.reload();
  }

  function handleRemoveFlag(idToRemoveFlag) {
    client()
      .delete(`/service-providers/${idToRemoveFlag}/flag`)
      .then(() => {
        toast.success("Flag removed");
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div className="px-4 pt-4 sm:px-6 lg:px-8">
      <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-4">
          <div className="flex items-center">
            <PageHeader title="Service Provider" subtitle={providerType} />
          </div>
        </div>
        <div className="ml-4 mt-4 flex flex-shrink-0 space-x-2">
          {!flagged ? (
            <FlagServiceProvider tooltip="Flag Service Provider" id={id} saved={handleRefresh} />
          ) : (
            <UnflagDialog
              tooltip="Remove Flag"
              title="Remove Flag"
              content="Are you sure you want to remove the flag from the service provider?"
              id={id}
              idToRemoveFlag={handleRemoveFlag}
            />
          )}
          <EditServiceProvider tooltip="Edit Details" id={id} saved={handleRefresh} />
        </div>
      </div>
      <div className="mt-2">
        <ServiceProviderDetailHeader id={id} />
      </div>
      {quickbooksID === "" ? (
        <div className="mt-3">
          <AddToQuickbooks id={id} saved={handleRefresh} />
        </div>
      ) : null}
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
          <ServiceProviderTabs id={id} selected="Overview" />
          <div className="mb-8 ml-2 mr-2 rounded-md bg-gray-100">
            <div className="py-6">
              <div className="mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">Case Stats</h1>
              </div>
              <div className="mx-auto px-4 sm:px-6 md:px-8">
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  <div></div>
                  <DashboardStat
                    name="Customer Concentration"
                    type="percent"
                    value={customerConcentration}
                    comment=""
                  />
                  <div></div>
                  <DashboardStat
                    name="Total Funding Outstanding"
                    type="currency"
                    value={totalFundingOutstanding}
                    comment=""
                  />
                  <DashboardStat
                    name="Total Outstanding Payoff"
                    type="currency"
                    value={totalOutstandingPayoff}
                    comment=""
                  />
                  <DashboardStat name="Total Loans" type="number" value={totalLoans} comment="" />
                  <DashboardStat name="Avg Open Loan Value" type="currency" value={avgOpenLoanValue} comment="" />
                  <DashboardStat name="Total Open Cases" type="number" value={totalOpenCases} comment="" />
                  <DashboardStat name="Avg Open Case Value" type="currency" value={avgOpenCaseValue} comment="" />
                </dl>
              </div>
              <div className="mx-auto px-4 pt-8 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">Payoff Stats</h1>
              </div>
              <div className="mx-auto px-4 sm:px-6 md:px-8">
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  <DashboardStat name="Total Return" type="percent" value={totalReturn} comment="" />
                  <DashboardStat name="APR" type="percent" value={apr} comment="" />
                  <DashboardStat name="Loss Rate" type="percent" value={lossRate} comment="" />
                  <DashboardStat name="Payback Rate" type="percent" value={paybackRate} comment="" />
                  <DashboardStat name="Total Cases Closed" type="number" value={totalCasesClosed} comment="" />
                  <DashboardStat name="Total Payoff Received" type="currency" value={totalPayoffReceived} comment="" />
                </dl>
              </div>
              <div className="mx-auto px-4 pt-8 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">Avg Time Stats</h1>
              </div>
              <div className="mx-auto px-4 sm:px-6 md:px-8">
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
                  <DashboardStat
                    name="Days from Accident to First Loan"
                    type="number"
                    value={daysFromAccidentToFirstLoan}
                    comment=""
                  />
                  <DashboardStat
                    name="Days from Accident to Payoff"
                    type="number"
                    value={daysFromAccidentToPayoff}
                    comment=""
                  />
                  <DashboardStat name="Days to Payoff" type="number" value={daysToPayoff} comment="" />
                  <DashboardStat name="Decision Time" type="daysHoursMinutes" value={decisionTime} comment="" />
                </dl>
              </div>
              <div className="mx-auto px-4 pt-8 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">Days Since Last</h1>
              </div>
              <div className="mx-auto px-4 sm:px-6 md:px-8">
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
                  <DashboardStat name="New Case" type="number" value={newCase} comment="" />
                  <DashboardStat name="Loan Approved" type="number" value={loanApproved} comment="" />
                  <DashboardStat name="Case Update" type="number" value={caseUpdate} comment="" />
                  <DashboardStat name="Payoff Received" type="number" value={payoffReceived} comment="" />
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
