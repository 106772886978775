import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { client, clientError } from "../../../libs/Client";
import FundingApplicationNoteCard from "../../Cards/FundingApplicationNoteCard";

export default function FundingApplicationNotes(props) {
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      client()
        .get(`/funding-applications/${props.id}/notes`)
        .then((res) => {
          setNotes(res.data);
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [open, props.id]);

  return (
    <div>
      {props.total > 0 ? (
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-slate-200 px-3 py-2 text-sm font-medium leading-4 text-slate-700 shadow-sm hover:bg-slate-300 focus:outline-none"
          onClick={handleClickOpen}
        >
          <ClipboardDocumentListIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Notes
          <span className="ml-2 inline-flex items-center rounded-full bg-slate-100 px-2 text-xs font-medium text-slate-800">
            {props.total}
          </span>
        </button>
      ) : (
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-slate-200 px-3 py-2 text-sm font-medium leading-4 text-slate-700 shadow-sm hover:bg-slate-300 focus:outline-none"
          disabled
        >
          <ClipboardDocumentListIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Notes
          <span className="ml-2 inline-flex items-center rounded-full bg-slate-100 px-2 text-xs font-medium text-slate-800">
            0
          </span>
        </button>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-md pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Notes</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <ul className="divide-y divide-gray-200">
                              {notes.map((note) => (
                                <FundingApplicationNoteCard
                                  key={note.id}
                                  firstName={note.user.firstName}
                                  lastName={note.user.lastName}
                                  body={note.text}
                                  createdAt={note.createdAt}
                                  event={note.event}
                                />
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

FundingApplicationNotes.propTypes = {
  id: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};
