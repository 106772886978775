import React, { useEffect, useState } from "react";
import { client, clientError } from "../../libs/Client";

import DashboardStat from "../../components/Stats/DashboardStat";

export default function Dashboard() {
  const [openCases, setOpenCases] = useState(0);
  const [caseFundingRate, setCaseFundingRate] = useState(0.0);
  const [totalLossRate, setTotalLossRate] = useState(0.0);
  const [openLoans, setOpenLoans] = useState(0);
  const [applicationFundingRate, setApplicationFundingRate] = useState(0);
  const [paybackRate, setPaybackRate] = useState(0);
  const [activeLawFirms, setActiveLawFirms] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [apr, setApr] = useState(0);
  const [totalFundedThisWeek, setTotalFundedThisWeek] = useState(0);
  const [totalFundedThisYear, setTotalFundedThisYear] = useState(0);
  const [totalFundingOutstanding, setTotalFundingOutstanding] = useState(0);
  const [payoffCreatedThisWeek, setPayoffCreatedThisWeek] = useState(0);
  const [payoffCreatedThisYear, setPayoffCreatedThisYear] = useState(0);
  const [totalOutstandingPayoff, setTotalOutstandingPayoff] = useState(0);
  const [avgApprovalTime, setAvgApprovalTime] = useState(0);
  const [avgTimeFromAccidentToPayoff, setAvgTimeFromAccidentToPayoff] = useState(0);
  const [avgTimeFromAccidentToFirstLoan, setAvgTimeFromAccidentToFirstLoan] = useState(0);
  const [avgPayoffTime, setAvgPayoffTime] = useState(0);

  useEffect(() => {
    client()
      .get(`/reports/dashboard`)
      .then((res) => {
        const caseStats = res.data.lawsuitStats;
        caseStats.forEach((stat) => {
          switch (stat.label) {
            case "Open Cases":
              setOpenCases(stat.total);
              break;
            case "Case Funding Rate":
              setCaseFundingRate(stat.total / 10000);
              break;
            case "Total Loss Rate":
              setTotalLossRate(stat.total / 10000);
              break;
            case "Open Loans":
              setOpenLoans(stat.total);
              break;
            case "Application Funding Rate":
              setApplicationFundingRate(stat.total / 10000);
              break;
            case "Payback Rate":
              setPaybackRate(stat.total / 10000);
              break;
            case "Active Law Firms":
              setActiveLawFirms(stat.total);
              break;
            case "Total Return":
              setTotalReturn(stat.total / 10000);
              break;
            case "APR":
              setApr(stat.total / 10000);
              break;
            default:
              break;
          }
        });
        const fundingStats = res.data.fundingStats;
        fundingStats.forEach((stat) => {
          switch (stat.label) {
            case "Total Funded this Week":
              setTotalFundedThisWeek(stat.total);
              break;
            case "Total Funded this Year":
              setTotalFundedThisYear(stat.total);
              break;
            case "Total Funding Outstanding":
              setTotalFundingOutstanding(stat.total);
              break;
            case "Payoff Created this Week":
              setPayoffCreatedThisWeek(stat.total);
              break;
            case "Payoff Created this Year":
              setPayoffCreatedThisYear(stat.total);
              break;
            case "Total Outstanding Payoff":
              setTotalOutstandingPayoff(stat.total);
              break;
            default:
              break;
          }
        });

        const timeStats = res.data.timeStats;
        timeStats.forEach((stat) => {
          switch (stat.label) {
            case "Avg Approval Time":
              setAvgApprovalTime(stat.total);
              break;
            case "Avg Time from Accident to Payoff":
              setAvgTimeFromAccidentToPayoff(stat.total);
              break;
            case "Avg Time from Accident to First Loan":
              setAvgTimeFromAccidentToFirstLoan(stat.total);
              break;
            case "Avg Payoff Time":
              setAvgPayoffTime(stat.total);
              break;
            default:
              break;
          }
        });
      })
      .catch((err) => {
        clientError(err);
      });
  }, []);

  return (
    <div className="py-6">
      <div className="mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Cases</h1>
      </div>
      <div className="mx-auto px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <DashboardStat
            name="Open Cases"
            type="number"
            value={openCases}
            comment="Total number of cases with open loans"
          />
          <DashboardStat
            name="Case Funding Rate"
            type="percent"
            value={caseFundingRate}
            comment="Total cases with the status of Funded or Closed divided by the total number of cases. (only cases created after 10/27/2019)"
          />
          <DashboardStat
            name="Total Loss Rate"
            type="percent"
            value={totalLossRate}
            comment="Total approved amount for all closed cases with zero amount paid divided by total approved amount for all closed cases (only cases closed after 7/27/2019)"
          />
          <DashboardStat
            name="Open Loans"
            type="number"
            value={openLoans}
            comment="Total number of outstanding loans"
          />
          <DashboardStat
            name="Application Funding Rate"
            type="percent"
            value={applicationFundingRate}
            comment="Total funded applications divided by total applications (only cases created after 10/27/2019)"
          />
          <DashboardStat
            name="Payback Rate"
            type="percent"
            value={paybackRate}
            comment="Total Amount Paid divided by Total Payoff Amount (only cases closed after 10/27/2019)"
          />
          <DashboardStat
            name="Active Law Firms"
            type="number"
            value={activeLawFirms}
            comment="Total number of law firms with open cases"
          />
          <DashboardStat
            name="Total Return"
            type="percent"
            value={totalReturn}
            comment="Total Approved Amount divided by Total Amount Paid (only cases closed after 10/27/2019)"
          />
          <DashboardStat
            name="APR"
            type="percent"
            value={apr}
            comment="Annual Percentage Yield (only cases closed after 10/27/2019)"
          />
        </dl>
        {/* /End replace */}
      </div>
      <div className="mx-auto px-4 pt-8 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Funding Stats</h1>
      </div>
      <div className="mx-auto px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <DashboardStat
            name="Total Funded this Week"
            type="currency"
            value={totalFundedThisWeek}
            comment="Total approved amount on cases where the case status and the application status are set to funded since the begining of the week."
          />
          <DashboardStat
            name="Total Funded this Year"
            type="currency"
            value={totalFundedThisYear}
            comment="Total approved amount on cases where the case status and the application status are set to funded since the begining of the Year."
          />
          <DashboardStat
            name="Total Funding Outstanding"
            type="currency"
            value={totalFundingOutstanding}
            comment="Total approved amount on applications where the case status and the application status are set to funded."
          />
          <DashboardStat
            name="Payoff Created this Week"
            type="currency"
            value={payoffCreatedThisWeek}
            comment="Total payoff amount on cases where the case status and the application status are set to funded since the begining of the week."
          />
          <DashboardStat
            name="Payoff Created this Year"
            type="currency"
            value={payoffCreatedThisYear}
            comment="Total payoff amount on cases where the case status and the application status are set to funded since the begining of the year."
          />
          <DashboardStat
            name="Total Outstanding Payoff"
            type="currency"
            value={totalOutstandingPayoff}
            comment="Total payoff amount on cases where the case status and the application status are set to funded."
          />
        </dl>
        {/* /End replace */}
      </div>
      <div className="mx-auto px-4 pt-8 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Time Stats</h1>
      </div>
      <div className="mx-auto px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
          <DashboardStat
            name="Avg Approval Time"
            type="hours"
            value={avgApprovalTime}
            comment="The total hours from when an application is created until it is approvied, ready to fund or funded divided by the total number of applications that are approved, ready to fund or funded."
          />
          <DashboardStat
            name="Avg Time from Accident to Payoff"
            type="days"
            value={avgTimeFromAccidentToPayoff}
            comment="Payoff Date minus Accident Date for each case and then average the total."
          />
          <DashboardStat
            name="Avg Time from Accident to First Loan"
            type="days"
            value={avgTimeFromAccidentToFirstLoan}
            comment="The total days from the accident date until the first application is approved divided by the total number of cases where the case is created after 10/27/2019."
          />
          <DashboardStat
            name="Avg Payoff Time"
            type="days"
            value={avgPayoffTime}
            comment="The number of days from when a funded application is created until the case is closed divided by the number of applications."
          />
        </dl>
        {/* /End replace */}
      </div>
    </div>
  );
}
