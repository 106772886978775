import PropTypes from "prop-types";
import { longDate } from "../../libs/Utils";

import ReplyToEmail from "../SlideOvers/Email/ReplyToEmail";

export default function InboxEmailHeader(props) {
  return (
    <div className="m-2 rounded-lg bg-white p-4 shadow">
      <ul className="grid grid-cols-2">
        <li>
          <div className="text-sm font-medium sm:flex sm:space-x-5">
            From:
            <span className="ml-1 font-light text-gray-500">{props.from}</span>
          </div>
        </li>
        <li>
          <div className="float-right text-sm font-medium sm:flex sm:space-x-5">
            <span className="ml-1 font-light text-gray-500">{longDate(props.date)}</span>
          </div>
        </li>
        <li>
          <div className="text-sm font-medium sm:flex sm:space-x-5">
            To:
            <span className="ml-1 font-light text-gray-500">{props.to}</span>
          </div>
        </li>
        <li>
          <div className="float-right text-sm font-medium sm:flex sm:space-x-5">
            <ReplyToEmail id={props.id} />
          </div>
        </li>
      </ul>
      <div className="mt-2">
        <hr />
      </div>
      <div className="mt-2">{props.subject}</div>
    </div>
  );
}

InboxEmailHeader.propTypes = {
  id: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  subject: PropTypes.string,
  date: PropTypes.string,
};
