import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { classNames } from "../../../libs/Utils";
import { client } from "../../../libs/Client";

export default function CaseDetailTabs(props) {
  const navigate = useNavigate();
  const [linkedCaseCount, setLinkedCaseCount] = useState(0);

  useEffect(() => {
    client()
      .get(`/linked-lawsuits/count?lawsuitID=${props.lawsuitID}`)
      .then((res) => {
        setLinkedCaseCount(res.data.totalLinkedLawsuits);
      });
  }, [props.lawsuitID]);

  var tabs = [];
  if (props.selected === "Feed") {
    tabs.push({
      name: "Feed",
      href: `/cases/${props.lawsuitID}/feed`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Feed",
      href: `/cases/${props.lawsuitID}/feed`,
      current: false,
    });
  }

  if (props.selected === "Log") {
    tabs.push({
      name: "Log",
      href: `/cases/${props.lawsuitID}/log`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Log",
      href: `/cases/${props.lawsuitID}/log`,
      current: false,
    });
  }

  if (props.selected === "Email") {
    tabs.push({
      name: "Email",
      href: `/cases/${props.lawsuitID}/email/inbox`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Email",
      href: `/cases/${props.lawsuitID}/email/inbox`,
      current: false,
    });
  }

  if (props.selected === "SMS") {
    tabs.push({
      name: "SMS",
      href: `/cases/${props.lawsuitID}/sms/inbox`,
      current: true,
    });
  } else {
    tabs.push({
      name: "SMS",
      href: `/cases/${props.lawsuitID}/sms/inbox`,
      current: false,
    });
  }

  if (props.selected === "Applications") {
    tabs.push({
      name: "Applications",
      href: `/cases/${props.lawsuitID}/applications`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Applications",
      href: `/cases/${props.lawsuitID}/applications`,
      current: false,
    });
  }

  if (props.selected === "Documents") {
    tabs.push({
      name: "Documents",
      href: `/cases/${props.lawsuitID}/documents/contracts`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Documents",
      href: `/cases/${props.lawsuitID}/documents/contracts`,
      current: false,
    });
  }

  if (props.selected === "Case Overview") {
    tabs.push({
      name: "Case Overview",
      href: `/cases/${props.lawsuitID}/overview`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Case Overview",
      href: `/cases/${props.lawsuitID}/overview`,
      current: false,
    });
  }

  if (props.selected === "Case Update") {
    tabs.push({
      name: "Case Update",
      href: `/cases/${props.lawsuitID}/update`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Case Update",
      href: `/cases/${props.lawsuitID}/update`,
      current: false,
    });
  }

  if (props.selected === "Linked Cases") {
    if (linkedCaseCount > 0) {
      tabs.push({
        name: "Linked Cases (" + linkedCaseCount + ")",
        href: `/cases/${props.lawsuitID}/linked`,
        current: true,
      });
    } else {
      tabs.push({
        name: "Linked Cases",
        href: `/cases/${props.lawsuitID}/linked`,
        current: true,
      });
    }
  } else {
    if (linkedCaseCount > 0) {
      tabs.push({
        name: "Linked Cases (" + linkedCaseCount + ")",
        href: `/cases/${props.lawsuitID}/linked`,
        current: false,
      });
    } else {
      tabs.push({
        name: "Linked Cases",
        href: `/cases/${props.lawsuitID}/linked`,
        current: false,
      });
    }
  }

  function handleChange(url) {
    navigate(url);
  }

  return (
    <div className="p-2">
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-700 focus:border-brand-700 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(e) => handleChange(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "border-brand-700 text-brand-700"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

CaseDetailTabs.propTypes = {
  lawsuitID: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
};
