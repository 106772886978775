import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DOMPurify from "isomorphic-dompurify";

import { apiError } from "../../libs/Utils";
import { setAuthToken } from "../../hooks/Authentication";

import AttachmentsHeader from "../../components/Headers/AttachmentsHeader";
import CaseDetailHeader from "../../components/Headers/CaseDetailHeader";
import SentEmailHeader from "../../components/Headers/SentEmailHeader";

const { REACT_APP_API_URL } = process.env;

export default function SentMail() {
  const { id } = useParams();
  const [lawsuitID, setLawsuitID] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    document.title = "Capital Now Funding - Sent - Email";
    setAuthToken();
    axios
      .get(`${REACT_APP_API_URL}/mail/sent/${id}`)
      .then((res) => {
        setLawsuitID(res.data.lawsuit.id);
        if (res.data.fullName !== "") {
          setFrom(res.data.user.firstName + " " + res.data.user.lastName + " <" + res.data.user.email + ">");
        } else {
          setFrom(res.data.user.email);
        }

        var toAddresses = [];
        if (res.data.to.length > 0) {
          res.data.to.forEach((address) => {
            if (address.name !== "") {
              toAddresses.push(address.name + " <" + address.email + ">");
            } else {
              toAddresses.push(address.email);
            }
          });
          setTo(toAddresses.toString());
        }

        var ccAddresses = [];
        if (res.data.cc.length > 0) {
          res.data.cc.forEach((address) => {
            if (address.name !== "") {
              ccAddresses.push(address.name + " <" + address.email + ">");
            } else {
              ccAddresses.push(address.email);
            }
          });
          setCc(ccAddresses.toString());
        }

        var bccAddresses = [];
        if (res.data.bcc.length > 0) {
          res.data.bcc.forEach((address) => {
            if (address.name !== "") {
              bccAddresses.push(address.name + " <" + address.email + ">");
            } else {
              bccAddresses.push(address.email);
            }
          });
          setBcc(bccAddresses.toString());
        }

        setSubject(res.data.subject);
        setBody(DOMPurify.sanitize(res.data.html));
        setCreatedAt(res.data.createdAt);
        setAttachments(res.data.attachments);
      })
      .catch((err) => {
        apiError(err);
      });
  }, [id]);

  return (
    <div>
      {lawsuitID !== "" && <CaseDetailHeader lawsuitID={lawsuitID.toString()} removeButtons />}
      <SentEmailHeader id={id} from={from} to={to} cc={cc} bcc={bcc} subject={subject} date={createdAt} />
      {attachments.length > 0 && <AttachmentsHeader type="Sent" attachments={attachments} />}
      <div className="m-2 bg-gray-50 rounded-lg p-4 shadow">
        <div className="prose text-sm max-w-prose min-w-full" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  );
}
