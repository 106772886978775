import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import ReactSelect from "react-select";
import { client, clientError } from "../../../../libs/Client";

export default function EditAccidentState(props) {
  const [open, setOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [accidentState, setAccidentState] = useState(null);

  function handleClickOpen() {
    setOpen(true);
  }

  useEffect(() => {
    if (open) {
      Promise.all([client().get(`/states`), client().get(`/lawsuits/${props.id}`)])
        .then((res) => {
          setStates(
            res[0].data.map((element) => ({
              value: element.id,
              label: element.label,
            }))
          );
          if (res[1].data.accidentState && res[1].data.accidentState.id) {
            setAccidentState({
              value: res[1].data.accidentState.id,
              label: res[1].data.accidentState.label,
            });
          }
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [open, props.id]);

  function handleSave() {
    client()
      .patch(`/lawsuits/${props.id}`, {
        accidentStateID: accidentState.value,
      })
      .then(() => {
        setOpen(false);
        props.saved(true);
      })
      .catch((err) => {
        client(err);
      });
  }

  return (
    <div>
      <button
        type="button"
        className="rounded-md bg-white font-medium text-brand-700 hover:text-brand-500 focus:outline-none"
        onClick={handleClickOpen}
      >
        <PencilSquareIcon className="h-5 w-5" />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Edit Accident State</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="state" className="block text-sm font-medium text-gray-900">
                                  Accident State
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect
                                    options={states}
                                    isClearable
                                    onChange={(data) => {
                                      setAccidentState(data);
                                    }}
                                    value={accidentState}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EditAccidentState.propTypes = {
  id: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
