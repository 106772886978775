import PropTypes from "prop-types";
import numeral from "numeral";
import { daysHoursMinutes } from "../../libs/Utils";

export default function DashboardStat(props) {
  var value = "";
  if (props.type === "number") {
    value = numeral(props.value).format("0,0");
  } else if (props.type === "currency") {
    value = numeral(props.value / 100).format("$ 0,0");
  } else if (props.type === "percent") {
    value = numeral(props.value).format("0.0%");
  } else if (props.type === "hours") {
    value = numeral(props.value).format(0, 0) + " Hours";
  } else if (props.type === "days") {
    value = numeral(props.value).format(0, 0) + " Days";
  } else if (props.type === "daysHoursMinutes") {
    value = daysHoursMinutes(props.value);
  }

  return (
    <div
      key={props.name}
      className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
    >
      <dt>
        <p className="text-lg font-medium text-gray-500">{props.name}</p>
      </dt>
      <dd className="flex pb-6 sm:pb-7">
        <p className="text-2xl font-semibold text-gray-900">{value}</p>
      </dd>
      <div className="bottom-0 bg-white">
        <div className="text-sm text-gray-400">{props.comment}</div>
      </div>
    </div>
  );
}

DashboardStat.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
};
