import axios from "axios";
import { useState } from "react";

export const signOut = () => {
  localStorage.removeItem("CapTrack");
  window.location.reload(false);
};

export const getUser = () => {
  const userString = localStorage.getItem("CapTrack");
  const user = JSON.parse(userString);
  return user;
};

export const setAuthToken = () => {
  const user = getUser();
  const token = user.accessToken;
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export const useUser = () => {
  const [user, setUser] = useState(getUser());

  const saveUser = (currentUser) => {
    localStorage.setItem("CapTrack", JSON.stringify(currentUser));
    setUser(currentUser);
  };

  return {
    setUser: saveUser,
    user,
  };
};
