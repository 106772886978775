import { Fragment, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { toast } from "react-toastify";
import ReactSelect from "react-select";

import { setAuthToken } from "../../hooks/Authentication";
import { apiError, classNames } from "../../libs/Utils";

const { REACT_APP_API_URL } = process.env;

export default function AddStateFee(props) {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState("");
  const feeTypes = [
    { value: "Fixed Amount", label: "Fixed Amount" },
    { value: "Percentage", label: "Percentage" },
  ];
  const [feeType, setFeeType] = useState(null);
  const [fee, setFee] = useState(0);
  const [isAnnual, setIsAnnual] = useState(false);

  function handleClickOpen() {
    setIsAnnual(false);
    setFee(0);
    setFeeType(null);
    setLabel("");
    setOpen(true);
  }

  function handleSave() {
    if (label === "") {
      toast.error("Label required");
      return;
    }

    if (!feeType) {
      toast.error("Fee type required");
      return;
    }

    if (fee <= 0) {
      toast.error("Fee must be greater than 0");
      return;
    }

    var data = {
      stateID: props.stateID,
      label: label,
      type: feeType.value,
      annual: isAnnual,
    };

    if (feeType.value === "Percentage") {
      data.percentage = parseFloat(fee / 100);
    } else {
      data.amount = parseInt(fee * 100);
    }

    setAuthToken();
    axios
      .post(`${REACT_APP_API_URL}/state-fees`, data)
      .then(() => {
        setOpen(false);
        toast.success("State fee saved");
        props.saved(true);
      })
      .catch((err) => {
        apiError(err);
      });
  }

  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2 sm:w-auto"
        onClick={handleClickOpen}
      >
        Add State Fee
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Add State Fee</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label htmlFor="label" className="block text-sm font-medium text-gray-900">
                                  Label
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="label"
                                    id="label"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setLabel(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                  Fee Type
                                </label>
                                <div className="remove-input-txt-border mt-1 text-sm">
                                  <ReactSelect options={feeTypes} isClearable onChange={(data) => setFeeType(data)} />
                                </div>
                              </div>
                              <div>
                                <label htmlFor="fee" className="block text-sm font-medium text-gray-900">
                                  Fee
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="number"
                                    name="fee"
                                    id="fee"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-700 focus:ring-brand-700 sm:text-sm"
                                    onChange={(e) => setFee(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div>
                                <Switch.Group as="div" className="flex items-center justify-between">
                                  <span className="flex flex-grow flex-col">
                                    <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                      Annual Fee
                                    </Switch.Label>
                                    <Switch.Description
                                      as="span"
                                      className="text-sm text-gray-500"
                                    ></Switch.Description>
                                  </span>
                                  <Switch
                                    checked={isAnnual}
                                    onChange={setIsAnnual}
                                    className={classNames(
                                      isAnnual ? "bg-brand-700" : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        isAnnual ? "translate-x-5" : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-start px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

AddStateFee.propTypes = {
  stateID: PropTypes.string.isRequired,
  saved: PropTypes.func,
};
