import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import ReactSelect from "react-select";
import { client, clientError } from "../../../../libs/Client";

export default function LinkToQuickbooks(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [purchase, setPurchase] = useState(null);
  const [transactionDate, setTransactionDate] = useState("");

  const transactionDates = [
    { value: "readyToFund", label: "Ready to fund" },
    { value: "created", label: "Created" },
    { value: "approved", label: "Approved" },
    { value: "funded", label: "Funded" },
  ];

  function handleClickOpen() {
    setOpen(true);
  }

  function handleTransactionDateChange(data) {
    setTransactionDate(data);
    setLoading(true);
    client()
      .post(`/quickbooks/purchases?applicationID=${props.id}&txDateField=${data.value}`)
      .then((res) => {
        setLoading(false);
        let data = [{ value: "AddNew", label: "Add new..." }];
        if (res.data.length > 0) {
          res.data.forEach((item) => {
            data.push({ value: item.value, label: item.label });
          });
        }
        setPurchases(data);
      })
      .catch((err) => {
        setLoading(false);
        setOpen(false);
        clientError(err);
      });
  }

  function handleSave() {
    if (!purchase || !purchase.value) {
      toast.error("You need to select a Quickbooks purchase");
      return;
    }

    if (purchase.value === "AddNew") {
      setSaving(true);
      client()
        .post(`/funding-applications/${props.id}/quickbooks`, {})
        .then(() => {
          setSaving(false);
          setOpen(false);
          props.saved(true);
        })
        .catch((err) => {
          setSaving(false);
          clientError(err);
        });
    } else {
      setSaving(true);
      client()
        .put(`/funding-applications/${props.id}/quickbooks`, { quickbooksID: purchase.value })
        .then(() => {
          setSaving(false);
          setOpen(false);
          props.saved(true);
        })
        .catch((err) => {
          setSaving(false);
          clientError(err);
        });
    }
  }

  return (
    <div>
      <Tippy content="Add to Quickbooks">
        <button className="inline-flex rounded border-transparent py-1.5" onClick={handleClickOpen}>
          <ArrowPathRoundedSquareIcon className="h-5 w-5 text-red-500 hover:text-red-700" />
        </button>
      </Tippy>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Add to Quickbooks</Dialog.Title>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-red-50">Add to Quickbooks</p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="newValue" className="block text-sm font-medium text-gray-900">
                                  Transaction Date
                                </label>
                                <div className="remove-input-txt-border mt-1">
                                  <ReactSelect
                                    options={transactionDates}
                                    isClearable
                                    onChange={(data) => handleTransactionDateChange(data)}
                                    value={transactionDate}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <label htmlFor="newValue" className="block text-sm font-medium text-gray-900">
                                  Quickbooks Purchase
                                </label>
                                <div className="remove-input-txt-border mt-1">
                                  <ReactSelect options={purchases} isClearable onChange={(data) => setPurchase(data)} />
                                </div>
                                {loading && (
                                  <p className="mt-2 text-sm leading-6 text-gray-600">
                                    Fetching purchase list from Quickbooks...
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {saving ? (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="cursor-not-allowed rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex cursor-not-allowed justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-150 ease-in-out hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            <svg
                              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Saving...
                          </button>
                        </div>
                      ) : (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

LinkToQuickbooks.propTypes = {
  id: PropTypes.string.isRequired,
  saved: PropTypes.func.isRequired,
};
