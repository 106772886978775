import PropTypes from "prop-types";

import EditAccidentDetails from "../SlideOvers/Cases/Accident/WorkersCompensation/EditAccidentDetails";
import EditInjuries from "../SlideOvers/Cases/Accident/WorkersCompensation/EditInjuries";
import EditTreatment from "../SlideOvers/Cases/Accident/WorkersCompensation/EditTreatment";
import EditHealthInsurance from "../SlideOvers/Cases/Accident/WorkersCompensation/EditHealthInsurance";
import EditNameOfEmployer from "../SlideOvers/Cases/Accident/WorkersCompensation/EditNameOfEmployer";
import EditJobDetails from "../SlideOvers/Cases/Accident/WorkersCompensation/EditJobDetails";
import EditStillEmployedDetails from "../SlideOvers/Cases/Accident/WorkersCompensation/EditStillEmployedDetails";

export default function WorkersCompensationAccident(props) {
  return (
    <dl className="ml-8 mr-8 divide-y divide-gray-200">
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Accident Details - What Happened?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.details}</span>
          <span className="ml-4 flex-shrink-0">
            <EditAccidentDetails id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Injuries Sustained?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.injuries}</span>
          <span className="ml-4 flex-shrink-0">
            <EditInjuries id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">
          Treatment - when, what kind, how long, are you still treating?
        </dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.treatment}</span>
          <span className="ml-4 flex-shrink-0">
            <EditTreatment id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">
          Did you have health insurance at the time of the accident?
        </dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.healthInsurance}</span>
          <span className="ml-4 flex-shrink-0">
            <EditHealthInsurance id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">What is the name of your employer?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.nameOfEmployer}</span>
          <span className="ml-4 flex-shrink-0">
            <EditNameOfEmployer id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">What was your job title and job duty there?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.jobDetails}</span>
          <span className="ml-4 flex-shrink-0">
            <EditJobDetails id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500">Are you still currently employed there?</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{props.stillEmployed}</span>
          <span className="ml-4 flex-shrink-0">
            <EditStillEmployedDetails id={props.id} saved={props.saved} />
          </span>
        </dd>
      </div>
    </dl>
  );
}

WorkersCompensationAccident.propTypes = {
  id: PropTypes.string,
  details: PropTypes.string,
  injuries: PropTypes.string,
  treatment: PropTypes.string,
  healthInsurance: PropTypes.string,
  nameOfEmployer: PropTypes.string,
  jobDetails: PropTypes.string,
  stillEmployed: PropTypes.string,
  saved: PropTypes.func,
};
