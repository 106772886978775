import React, { useEffect } from "react";

import LeadCSVDownloadDialog from "../../components/Dialogs/LeadCSVDownloadDialog";
import ServiceProvidersCSVDownloadDialog from "../../components/Dialogs/ServiceProvidersCSVDownloadDialog";
import ActiveServiceProvidersCSVDownloadDialog from "../../components/Dialogs/ActiveServiceProvidersCSVDownloadDialog";
import MasterDetailCSVDownloadDialog from "../../components/Dialogs/MasterDetailCSVDownloadDialog";

export default function Export() {
  useEffect(() => {
    document.title = "Capital Now Funding - Export";
  });

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <div className="mt-6 p-5 grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <LeadCSVDownloadDialog />
          </div>
          <div>
            <ServiceProvidersCSVDownloadDialog />
          </div>
          <div>
            <ActiveServiceProvidersCSVDownloadDialog />
          </div>
          <div>
            <MasterDetailCSVDownloadDialog />
          </div>
        </div>
      </div>
    </div>
  );
}
