import React, { useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import parsePhoneNumber from "libphonenumber-js";
import { minHeight, dateFromNow } from "../../libs/Utils";
import { client, clientError } from "../../libs/Client";
import LeadTabs from "../../layouts/FullLayout/Tabs/LeadTabs";
import RestoreArchiveDialog from "../../components/Dialogs/RestoreArchiveDialog";
import RejectDialog from "../../components/Dialogs/RejectDialog";

export default function ArchivedLeads() {
  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/leads?status=Archived&skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`)
      .then((res) => {
        return { data: res.data.leads, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, []);

  useEffect(() => {
    document.title = "Capital Now Funding - Leads - Archived";
  });

  const gridStyle = { minHeight: minHeight };

  const columns = [
    {
      name: "id",
      defaultWidth: 150,
      header: "Reference #",
      textAlign: "end",
      headerAlign: "end",
    },
    {
      name: "firstName",
      header: "First Name",
      defaultWidth: 200,
    },
    {
      name: "lastName",
      header: "Last Name",
      defaultWidth: 200,
    },
    {
      name: "email",
      header: "Email",
      defaultWidth: 200,
    },
    {
      name: "phone",
      header: "Phone",
      defaultWidth: 200,
      render: ({ value }) => {
        if (value && value !== "") {
          return parsePhoneNumber(value, "US").formatNational();
        } else {
          return "";
        }
      },
    },
    {
      name: "lawFirm",
      header: "Service Provider",
      defaultWidth: 200,
    },
    {
      name: "state.label",
      defaultWidth: 200,
      header: "State",
      render: ({ data }) => {
        return data.state.label;
      },
    },
    {
      name: "createdAt",
      defaultWidth: 200,
      header: "Created",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      field: "edit",
      header: "",
      defaultWidth: 175,
      render: ({ data }) => {
        return (
          <div className="flex justify-end space-x-4">
            <RestoreArchiveDialog
              title="Restore Lead"
              content="Are you sure you want to restore the lead?"
              id={data.id.toString()}
              idToRestore={handleRestore}
              tooltip="Restore Lead"
            />
            <RejectDialog
              tooltip="Reject Lead"
              title="Reject Lead"
              content="Are you sure you want to reject the lead?"
              id={data.id.toString()}
              idToReject={handleReject}
            />
          </div>
        );
      },
    },
  ];

  function handleReject(idToReject) {
    client()
      .patch(`/leads/${idToReject}`, {
        status: "Rejected",
      })
      .then(() => {
        toast.success("Lead rejected");
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleRestore(idToRestore) {
    client()
      .patch(`/leads/${idToRestore}`, {
        status: "Pending",
      })
      .then(() => {
        toast.success("Lead restored");
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <LeadTabs selected="Archived" />
        <ReactDataGrid
          idProperty="archivedLeads"
          columns={columns}
          style={gridStyle}
          showColumnMenuTool={false}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
