import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { Select } from "antd";
import { toast } from "react-toastify";
import { client, clientError } from "./../../../libs/Client";

export default function AddRecipients(props) {
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [serviceProviders, setServiceProviders] = useState([]);
  const [serviceProviderUsers, setServiceProviderUsers] = useState([]);
  const [serviceProvider, setServiceProvider] = useState(null);
  const [serviceProviderUser, setServiceProviderUser] = useState(null);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleSelectionChange(event) {
    setSelectedOption(event.target.value);
    if (event.target.value === "serviceProvider") {
      setServiceProviderUser(null);
      setServiceProviderUsers([]);
      setServiceProvider(null);
      setLoading(true);
      client(30000)
        .get(`/service-providers/list`)
        .then((res) => {
          setLoading(false);
          console.log(res.data);
          setServiceProviders(res.data);
        })
        .catch((err) => {
          setLoading(false);
          clientError(err);
        });
    } else if (event.target.value === "serviceProviderUser") {
      setServiceProvider(null);
      setServiceProviders([]);
      setServiceProviderUser(null);
      setLoading(true);
      client(30000)
        .get(`/service-provider-users/list`)
        .then((res) => {
          setLoading(false);
          setServiceProviderUsers(res.data);
        })
        .catch((err) => {
          setLoading(false);
          clientError(err);
        });
    } else {
      setServiceProvider(null);
      setServiceProviders([]);
      setServiceProviderUser(null);
      setServiceProviderUsers([]);
    }
  }

  useEffect(() => {
    if (open) {
      client()
        .get(`/templates/campaigns/list`)
        .then((res) => {
          // setTemplates(res.data);
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }, [open]);

  function handleSave() {
    if (selectedOption === "allServiceProviders") {
      setProcessing(true);
      client(30000)
        .post(`/service-provider-campaign-recipients`, {
          campaignID: props.campaignID,
        })
        .then(() => {
          setProcessing(false);
          setOpen(false);
          props.saved(true);
        })
        .catch((err) => {
          setProcessing(false);
          clientError(err);
        });
    } else if (selectedOption === "serviceProvidersWithOpenFundings") {
      setProcessing(true);
      client(30000)
        .post(`/service-provider-campaign-recipients/service-provider-open-fundings`, {
          campaignID: props.campaignID,
        })
        .then(() => {
          setProcessing(false);
          setOpen(false);
          props.saved(true);
        })
        .catch((err) => {
          setProcessing(false);
          clientError(err);
        });
    } else if (selectedOption === "serviceProviderContactsWithOpenFundings") {
      setProcessing(true);
      client(30000)
        .post(`/service-provider-campaign-recipients/contact-open-fundings`, {
          campaignID: props.campaignID,
        })
        .then(() => {
          setProcessing(false);
          setOpen(false);
          props.saved(true);
        })
        .catch((err) => {
          setProcessing(false);
          clientError(err);
        });
    } else if (selectedOption === "serviceProvider") {
      setProcessing(true);
      client(30000)
        .post(`/service-provider-campaign-recipients/service-providers`, {
          campaignID: props.campaignID,
          serviceProviderID: serviceProvider,
        })
        .then(() => {
          setProcessing(false);
          setOpen(false);
          props.saved(true);
        })
        .catch((err) => {
          setProcessing(false);
          clientError(err);
        });
    } else if (selectedOption === "serviceProviderUser") {
      setProcessing(true);
      client(30000)
        .post(`/service-provider-campaign-recipients/contact`, {
          campaignID: props.campaignID,
          userID: serviceProviderUser,
        })
        .then(() => {
          setProcessing(false);
          setOpen(false);
          props.saved(true);
        })
        .catch((err) => {
          setProcessing(false);
          clientError(err);
        });
    } else {
      toast.error("You need to make a selection");
      return;
    }
  }

  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2 sm:w-auto"
        onClick={handleClickOpen}
      >
        Add Recipients
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => null}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="max-w-lg pointer-events-none fixed inset-y-0 right-0 flex pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-5xl">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Add Recipients</Dialog.Title>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <fieldset className="mt-4">
                                  <legend className="sr-only">Recipient</legend>
                                  <div className="space-y-4">
                                    <div className="flex items-center">
                                      <input
                                        id="allServiceProviders"
                                        name="allServiceProviders"
                                        type="radio"
                                        value="allServiceProviders"
                                        checked={selectedOption === "allServiceProviders"}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onChange={handleSelectionChange}
                                      />
                                      <label
                                        htmlFor="allServiceProviders"
                                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        All Service Providers
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="serviceProvidersWithOpenFundings"
                                        name="serviceProvidersWithOpenFundings"
                                        type="radio"
                                        value="serviceProvidersWithOpenFundings"
                                        checked={selectedOption === "serviceProvidersWithOpenFundings"}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onChange={handleSelectionChange}
                                      />
                                      <label
                                        htmlFor="serviceProvidersWithOpenFundings"
                                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        All Service Providers with Open Fundings
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="serviceProviderContactsWithOpenFundings"
                                        name="serviceProviderContactsWithOpenFundings"
                                        type="radio"
                                        value="serviceProviderContactsWithOpenFundings"
                                        checked={selectedOption === "serviceProviderContactsWithOpenFundings"}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onChange={handleSelectionChange}
                                      />
                                      <label
                                        htmlFor="serviceProviderContactsWithOpenFundings"
                                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        All Service Providers Contacts with Open Fundings
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="serviceProvider"
                                        name="serviceProvider"
                                        value="serviceProvider"
                                        type="radio"
                                        checked={selectedOption === "serviceProvider"}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onChange={handleSelectionChange}
                                      />
                                      <label
                                        htmlFor="serviceProvider"
                                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Select Service Provider
                                      </label>
                                    </div>
                                    {selectedOption === "serviceProvider" ? (
                                      <div>
                                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                          Service Provider
                                        </label>
                                        <div className="remove-input-txt-border-ant mt-1">
                                          <Select
                                            onChange={(data) => setServiceProvider(data)}
                                            options={serviceProviders}
                                            className="clear-both w-full"
                                            showSearch
                                            loading={loading}
                                            filterOption={(input, option) =>
                                              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                            }
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="flex items-center">
                                      <input
                                        id="serviceProviderUser"
                                        name="serviceProviderUser"
                                        type="radio"
                                        value="serviceProviderUser"
                                        checked={selectedOption === "serviceProviderUser"}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onChange={handleSelectionChange}
                                      />
                                      <label
                                        htmlFor="serviceProviderUser"
                                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                      >
                                        Select Individual Service Provider User
                                      </label>
                                    </div>
                                    {selectedOption === "serviceProviderUser" ? (
                                      <div>
                                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                          Service Provider User
                                        </label>
                                        <div className="remove-input-txt-border-ant mt-1">
                                          <Select
                                            onChange={(data) => setServiceProviderUser(data)}
                                            options={serviceProviderUsers}
                                            className="clear-both w-full"
                                            showSearch
                                            loading={loading}
                                            filterOption={(input, option) =>
                                              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                            }
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {processing ? (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="cursor-not-allowed rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex cursor-not-allowed justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-150 ease-in-out hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            disabled
                          >
                            <svg
                              className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            Saving...
                          </button>
                        </div>
                      ) : (
                        <div className="flex flex-shrink-0 justify-start px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

AddRecipients.propTypes = {
  saved: PropTypes.func,
};
