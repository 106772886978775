import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { client, clientError } from "../../../libs/Client";
import { longDate } from "../../../libs/Utils";
import CaseDetailHeader from "../../../components/Headers/CaseDetailHeader";
import CaseDetailTabs from "../../../layouts/FullLayout/Tabs/CaseDetailTabs";
import CaseOverviewTabs from "../../../layouts/FullLayout/Tabs/CaseOverviewTabs";
import EditCaseServiceProvider from "../../../components/SlideOvers/Cases/EditCaseServiceProvider";

export default function CaseOverviewServiceProvider() {
  const { id } = useParams();
  const [serviceProvider, setServiceProvider] = useState("-");
  const [serviceProviderType, setServiceProviderType] = useState("-");
  const [primaryContact, setPrimaryContact] = useState("-");
  const [additionalContacts, setAdditionalContacts] = useState([]);
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState("");

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Details - Overview - Service Provider";
    Promise.all([
      client().get(`/lawsuits/${id}/service-provider`),
      client().get(`/notes?lawsuitID=${id}&section=Service Provider`),
    ])
      .then((res) => {
        setNotes(res[1].data);
        setServiceProvider(res[0].data.serviceProvider.name);
        setServiceProviderType(res[0].data.serviceProvider.providerType);
        const primaryContactData = res[0].data.serviceProviderContact;
        setPrimaryContact(
          primaryContactData.firstName + " " + primaryContactData.lastName + " (" + primaryContactData.role + ")"
        );

        const additionalContactData = res[0].data.serviceProviderContacts;
        var additionalContacts = "";
        if (additionalContactData.length > 0) {
          additionalContactData.forEach((item) => {
            const additionalContact = item.firstName + " " + item.lastName + "(" + item.role + "), ";
            additionalContacts += additionalContact;
          });
        }
        setAdditionalContacts(additionalContacts);
      })
      .catch((err) => {
        clientError(err);
      });
  }, [id]);

  function handleRefresh() {
    Promise.all([
      client().get(`/lawsuits/${id}/service-provider`),
      client().get(`/notes?lawsuitID=${id}&section=Service Provider`),
    ])
      .then((res) => {
        setNotes(res[1].data);
        setServiceProvider(res[0].data.serviceProvider.name);
        setServiceProviderType(res[0].data.serviceProvider.providerType);
        const primaryContactData = res[0].data.serviceProviderContact;
        setPrimaryContact(
          primaryContactData.firstName + " " + primaryContactData.lastName + " (" + primaryContactData.role + ")"
        );

        const additionalContactData = res[0].data.serviceProviderContacts;
        var additionalContacts = "";
        if (additionalContactData.length > 0) {
          additionalContactData.forEach((item) => {
            const additionalContact = item.firstName + " " + item.lastName + "(" + item.role + "), ";
            additionalContacts += additionalContact;
          });
        }
        setAdditionalContacts(additionalContacts);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleSaveNote() {
    if (note === "") {
      toast.error("You need to enter some text for the note");
      return;
    }

    client()
      .post(`/notes`, {
        lawsuitID: parseInt(id),
        text: note,
        section: "Service Provider",
      })
      .then(() => {
        setNote("");
        handleRefresh();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div>
      <CaseDetailHeader lawsuitID={id} />
      <div className="overflow-hidden bg-white shadow">
        <CaseDetailTabs selected="Case Overview" lawsuitID={id} />
        <div className="ml-8 mr-8">
          <CaseOverviewTabs lawsuitID={id} selected="Service Provider" />
        </div>
        <div className="mb-8 mt-2 flex flex-col ">
          <div className="mb-4 mt-2">
            <dl className="ml-8 mr-8 divide-y divide-gray-200">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">Service Provider</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">
                    {serviceProvider} {serviceProviderType}
                  </span>
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">Primary Contact</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">{primaryContact}</span>
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">Additional Contacts</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">{additionalContacts}</span>
                </dd>
              </div>
            </dl>
            <div className="float-right mr-8">
              <EditCaseServiceProvider id={id} saved={handleRefresh} />
            </div>
          </div>
          <div className="ml-8 mr-8 mt-2 text-lg font-medium">Notes</div>
          <div className="m-4 px-4">
            <textarea
              rows="5"
              name="note"
              id="note"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              onChange={(e) => setNote(e.target.value)}
              value={note}
            ></textarea>
          </div>
          <div className="m-2 text-right">
            <button
              type="button"
              className="mr-6 inline-flex justify-center rounded-md border border-transparent bg-brand-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-brand-700 hover:bg-white hover:text-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-700 focus:ring-offset-2"
              onClick={handleSaveNote}
            >
              Save
            </button>
          </div>
          <div className="m-2">
            <hr />
          </div>
          <div className="mb-10 mt-5">
            {notes.length > 0 ? (
              <ul className="m-10">
                {notes.map((note) => (
                  <li key={note.id} className="m-10 rounded-lg bg-gray-100 p-6 py-4">
                    <div className="flex space-x-3">
                      <div className="flex-1 space-y-1">
                        <div className="flex items-center justify-between">
                          <h3 className="text-sm font-medium">
                            {note.user.firstName} {note.user.lastName}
                          </h3>
                          <p className="text-sm text-gray-400">{longDate(note.createdAt)}</p>
                        </div>
                        <p className="text-sm text-gray-500">{note.text}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-center">
                <p className="text-sm text-gray-500">No Notes</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
