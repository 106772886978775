import React, { useEffect, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { client, clientError } from "../../libs/Client";
import { currency, shortDate, minHeight, dateFromNow } from "../../libs/Utils";
import AddToQuickbooks from "../../components/SlideOvers/Cases/AddToQuickbooks";
import UnlinkFromQuickbooks from "../../components/Dialogs/UnlinkFromQuickbooksDialog";
import AvatarLabel from "../../components/Labels/AvatarLabel";
import CaseTabs from "../../layouts/FullLayout/Tabs/CaseTabs";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import ReassignCase from "../../components/SlideOvers/Cases/ReassignCase";

export default function FundedCases() {
  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/lawsuits?status=Funded&skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`)
      .then((res) => {
        return { data: res.data.lawsuits, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  useEffect(() => {
    document.title = "Capital Now Funding - Cases - Funded";
  });

  const dataSource = useCallback(loadData, []);

  const gridStyle = { minHeight: minHeight };

  const columns = [
    {
      name: "id",
      defaultWidth: 150,
      header: "Case #",
      textAlign: "end",
      headerAlign: "end",
      render: ({ value }) => {
        return (
          <a className="text-brand-700 hover:text-brand-500" href={`/cases/${value}/feed`}>
            {value}
          </a>
        );
      },
    },
    {
      name: "plaintiff",
      header: "Plaintiff",
      defaultWidth: 200,
      render: ({ data }) => {
        return (
          <AvatarLabel
            firstName={data.firstName}
            lastName={data.lastName}
            email={data.email}
            profilePicURL={data.profilePicURL}
          />
        );
      },
    },
    {
      name: "serviceProvider",
      header: "Service Provider",
      defaultWidth: 200,
      render: ({ data }) => {
        if (data.flagged) {
          return <span className="text-red-500">{data.serviceProvider}</span>;
        } else {
          return data.serviceProvider;
        }
      },
    },
    {
      name: "createdAt",
      defaultWidth: 200,
      header: "Case Creation Date",
      render: ({ value }) => {
        return shortDate(value);
      },
    },
    {
      name: "accidentDate",
      header: "Accident Date",
      defaultWidth: 200,
      render: ({ value }) => {
        return shortDate(value);
      },
    },
    {
      name: "totalFunding",
      defaultWidth: 200,
      header: "Total Funding",
      textAlign: "end",
      headerAlign: "end",
      render: ({ value }) => {
        return currency(value);
      },
    },
    {
      name: "tag",
      defaultWidth: 200,
      header: "Tag",
    },
    {
      name: "activeAt",
      defaultWidth: 200,
      header: "Last Case Activity",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "user",
      header: "Owner",
      defaultWidth: 300,
      render: ({ data }) => {
        return <AvatarLabel firstName={data.user.firstName} lastName={data.user.lastName} email={data.user.email} />;
      },
    },
    {
      name: "edit",
      header: "",
      minWidth: 175,
      maxWidth: 175,
      render: ({ data }) => {
        return (
          <div className="flex justify-end space-x-4">
            <ReassignCase lawsuitID={data.id} saved={loadData} />
            {!data.quickbooksID ? (
              <AddToQuickbooks id={data.id.toString()} saved={loadData} />
            ) : (
              <UnlinkFromQuickbooks
                tooltip="Unlink from Quickboooks"
                title="Unlink from Quickbooks"
                content="Remove lawsuit link to Quickbooks"
                id={data.id.toString()}
                idToUnlink={handleQuickbooksUnlink}
              />
            )}
            <DeleteDialog
              title="Delete Case"
              content="Are you sure you want to delete the case?"
              id={data.id.toString()}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ];

  function handleDelete(idToDelete) {
    client()
      .delete(`/lawsuits/${idToDelete}`)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleQuickbooksUnlink(idToUnlink) {
    client()
      .delete(`/lawsuits/${idToUnlink}/quickbooks`)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="-mx-4 -my-2 overflow-x-auto bg-white sm:-mx-6 lg:-mx-8">
        <CaseTabs selected="Funded" />
        <ReactDataGrid
          idProperty="fundedCases"
          columns={columns}
          style={gridStyle}
          showColumnMenuTool={false}
          dataSource={dataSource}
          pagination
          defaultLimit={100}
        />
      </div>
    </div>
  );
}
