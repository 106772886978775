import React, { useEffect, useState } from "react";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { client, clientError } from "../../libs/Client";

export default function Search() {
  const [tasks, setTasks] = useState(false);
  useEffect(() => {
    client()
      .get(`/me/tasks`)
      .then((res) => {
        if (res.data.tasks > 0) {
          setTasks(true);
        } else {
          setTasks(false);
        }
      })
      .catch((err) => {
        clientError(err);
      });
  }, []);

  if (tasks) {
    return (
      <Tippy content="Tasks">
        <a type="button" className="-m-2.5 p-2.5 text-red-400 hover:text-red-700" href="/tasks/todo">
          <span className="sr-only">View tasks</span>
          <ClipboardDocumentCheckIcon className="h-6 w-6 animate-bounce " aria-hidden="true" />
        </a>
      </Tippy>
    );
  }

  return (
    <Tippy content="Tasks">
      <a type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-700" href="/tasks/done">
        <span className="sr-only">View tasks</span>
        <ClipboardDocumentCheckIcon className="h-6 w-6" aria-hidden="true" />
      </a>
    </Tippy>
  );
}
