import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { classNames } from "../../../libs/Utils";

export default function CaseDocumentTabs(props) {
  const navigate = useNavigate();
  var tabs = [];
  if (props.selected === "Contracts") {
    tabs.push({
      name: "Contracts",
      href: `/cases/${props.lawsuitID}/documents/contracts`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Contracts",
      href: `/cases/${props.lawsuitID}/documents/contracts`,
      current: false,
    });
  }

  if (props.selected === "Uploads") {
    tabs.push({
      name: "Uploads",
      href: `/cases/${props.lawsuitID}/documents/uploads`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Uploads",
      href: `/cases/${props.lawsuitID}/documents/uploads`,
      current: false,
    });
  }

  if (props.selected === "Email Attachments") {
    tabs.push({
      name: "Email Attachments",
      href: `/cases/${props.lawsuitID}/documents/attachments`,
      current: true,
    });
  } else {
    tabs.push({
      name: "Email Attachments",
      href: `/cases/${props.lawsuitID}/documents/attachments`,
      current: false,
    });
  }

  function handleChange(url) {
    navigate(url);
  }

  return (
    <div className="relative border-b border-gray-200 pb-5 sm:pb-0 mt-4">
      {/* Tabs */}
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-700 focus:border-brand-700 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(e) => handleChange(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <nav className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current
                  ? "border-brand-700 text-brand-700"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}

CaseDocumentTabs.propTypes = {
  lawsuitID: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
};
