import React, { useEffect, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { client, clientError } from "../../libs/Client";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { dateFromNow, minHeight } from "../../libs/Utils";
import AddCampaign from "../../components/SlideOvers/Campaigns/AddCampaign";
import EditCampaign from "../../components/SlideOvers/Campaigns/EditCampaign";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import ConfirmSendCampaignDialog from "../../components/Dialogs/ConfirmSendCampaignDialog";

export default function Campaigns() {
  const loadData = ({ skip, limit, sortInfo }) => {
    return client()
      .get(`/service-provider-campaigns?skip=${skip ? skip : 0}&limit=${limit ? limit : 100}`)
      .then((res) => {
        return { data: res.data.campaigns, count: res.data.count };
      })
      .catch((err) => {
        clientError(err);
      });
  };

  const dataSource = useCallback(loadData, []);

  useEffect(() => {
    document.title = "Capital Now Funding - Campaigns";
  });

  const gridStyle = { minHeight: minHeight };

  function handleDelete(idToDelete) {
    client()
      .delete(`/service-provider-campaigns/${idToDelete}`)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  function handleRefresh() {
    window.location.reload();
  }

  function handleSendCampaign(idToSend) {
    client()
      .post(`/service-provider-campaigns/${idToSend}/send`)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        clientError(err);
      });
  }

  const columns = [
    {
      name: "subject",
      header: "Subject",
      minWidth: 400,
    },
    {
      name: "user",
      minWidth: 250,
      header: "Author",
      render: ({ data }) => {
        return data.user.firstName + " " + data.user.lastName;
      },
    },
    {
      name: "status",
      minWidth: 150,
      header: "Status",
    },
    {
      name: "createdAt",
      minWidth: 200,
      header: "Created",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "updatedAt",
      minWidth: 200,
      header: "Updated",
      render: ({ value }) => {
        return dateFromNow(value);
      },
    },
    {
      name: "edit",
      header: "",
      minWidth: 225,
      maxWidth: 225,
      render: ({ data }) => {
        return (
          <div className="flex justify-end space-x-4">
            {data.status === "Pending" ? (
              <ConfirmSendCampaignDialog
                tooltip="Send Campaign"
                title="Send Campaign"
                content="Are you sure you want to send the campaign?"
                id={data.id}
                idToSend={handleSendCampaign}
              />
            ) : null}
            <Tippy content="Recipients">
              <a
                type="button"
                className="inline-flex rounded border-transparent bg-sky-100 px-2.5 py-1.5 text-xs font-medium hover:bg-sky-200 focus:outline-none"
                href={`/campaigns/${data.id}/recipients`}
              >
                <UserGroupIcon className="h-4 w-4 text-sky-500" />
              </a>
            </Tippy>
            {data.status === "Pending" ? (
              <EditCampaign tooltip="Edit Campaign" id={data.id} saved={handleRefresh} />
            ) : null}

            <DeleteDialog
              tooltip="Delete Campaign"
              title="Delete Campaign"
              content="Are you sure you want to delete the campaign?"
              id={data.id}
              idToDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="px-4 pt-2 sm:px-6 lg:px-8">
      <div className="text-right">
        <AddCampaign saved={handleRefresh} />
      </div>
      <div className="mt-3 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <ReactDataGrid
            idProperty="campaigns"
            columns={columns}
            style={gridStyle}
            showColumnMenuTool={false}
            dataSource={dataSource}
            pagination
            defaultLimit={100}
          />
        </div>
      </div>
    </div>
  );
}
